<template>
  <td class="soprts_in_acc_2 "
      style="width: 25%;"
  >
    <div class="sports_v_l_1">
      {{ bets.name === 'AnyOther' ? '기타' : bets.name }}
    </div>
    <div class="sports_v_r_1">
      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
    </div>
  </td>
</template>

<script>
export default {
  name: "Price",
  props: ['bets'],
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
