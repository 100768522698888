<template>
  <div class="popup">
    <div class="pop-body">
      <div class="inq-head">
        <div>
          <div class="tit1">조회일자</div>
          <button class="btn1 active">오늘</button>
          <button class="btn1">1주일</button>
          <button class="btn1">15일</button>
          <button class="btn1">1개월</button>
        </div>
        <div>
          <input type="text" class="inp" value="2022-06-20">
          <span>-</span>
          <input type="text" class="inp" value="2022-06-20">
        </div>
        <div>
          <a href="#" class="btn2 active">전체</a>
          <a href="#" class="btn2">대기</a>
          <a href="#" class="btn2">당첨</a>
          <a href="#" class="btn2">낙첨</a>
          <a href="#" class="btn2">적특</a>
          <a href="#" class="btn2">취소</a>
        </div>
      </div>
      <div class="board-bet">
        <table class="titleTable01 ">
          <colgroup>
            <col width="100">
            <col width="150">
            <col width="200">
            <col width="75">
            <col width="200">
            <col width="70">
            <col width="">
          </colgroup>
          <tbody><tr>
            <th>시간</th>
            <th>리그</th>
            <th>승(홈)</th>
            <th>무(핸디)</th>
            <th>패(원정)</th>
            <th>스코어</th>
            <th>상태</th>
          </tr>
          </tbody>
        </table>

        <table class="listTable01 mt0">
          <colgroup>
            <col width="100">
            <col width="150">
            <col width="200">
            <col width="75">
            <col width="200">
            <col width="70">
            <col width="">
          </colgroup>
          <tbody><tr>
            <td>09/15 14:20</td>
            <td class="al">MLV</td>
            <td class="al">
              <div class="fbox">
                <span class="fl">히로시마</span>
                <span class="fr">1.03</span>
              </div>
            </td>
            <td>VS</td>
            <td class="ar">
              <div class="fbox">
                <span class="fl">1.03</span>
                <span class="fr">히로시마</span>
              </div>
            </td>
            <td>1:0</td>
            <td><span class="sts03">당첨</span></td>
          </tr>
          <tr>
            <td class="tfoot" colspan="7">
              <ul>
                <li>베팅내역 선택 <input type="checkbox"></li>
                <li>베팅시간 : 09/15 12:32</li>
                <li>베팅금액 <input type="text" value="5,000" class="ar"></li>
                <li>배당률 <input type="text" value="5,000" class="ar"></li>
                <li>당첨예상금 <input type="text" value="500,000" class="ar coRed"></li>
                <li>당첨금 <input type="text" value="500,000" class="ar coBlue"></li>
                <!--<li>결과 : <span class="coBlue">당첨</span></li>-->
                <li><a href="#" class="btn-del">첨부</a></li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BettingAdd"
}
</script>

<style scoped>

</style>
