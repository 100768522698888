import axios from 'axios';
import store from '../index';

const API_URL = process.env.VUE_APP_API_URL;
const getDefaultState = () => {
  return {
    originList: [],
    dateCnt: [],
    cart: [],
  }
}

const state = getDefaultState()

const getters = {
  //카트 안에 담긴 배당내역의 총합
  SPORT_TOTAL_ODDS: function(state) {
    return state.cart.reduce((prev, curr) => prev * Number(curr.odds), 1).toFixed(2);
  },
}

const actions = {
  GET_LIST({commit}, params) {
    return axios.post(`${API_URL}/v2/game/european/list3`, params)
      .then((response) => {
        if (response.data){
          commit('SET_LIST', response.data?.payload.list)
        }
        return response
      })
  },
  GET_KOREAN_LIST({commit}, params) {
    return axios.post(`${API_URL}/v2/game/korean/prematch2`, params)
      .then((response) => {
        if (response.data){
          commit('SET_LIST', response.data?.payload.list)
        }
        return response
      })
  },
  GET_KOREAN_SPECIAL_LIST({commit}, params) {
    return axios.post(`${API_URL}/v2/game/korean/special2`, params)
      .then((response) => {
        if (response.data){
          commit('SET_LIST', response.data?.payload.list)
        }
        return response
      })
  },
  GET_DATE_CNT({commit}, params) {
    return axios.post(`${API_URL}/v2/game/european/dateCnt`, params)
      .then((response) => {
        if (response.data){
          commit('SET_DATE', response.data?.payload)
        }
        return response
      })
  },
  GET_DETAIL(_, params) {
    return axios.post(`${API_URL}/v2/game/european/detail2`, params)
      .then((response) => {
        return response
      })
  },
  ADD_CART_ITEM({state, commit, getters}, obj){
    if (obj.sportNameKr === '보너스') {
      const cartItemIndex = state.cart.findIndex(item => item.sportNameKr === obj.sportNameKr);
      if (cartItemIndex >= 0) return commit('POP_ITEM_CART_INDEX', cartItemIndex); // 있을시 삭제

      const bonusConfig = store.state.setting.settings.config.option.siteOption2.find(e=> e.name === '보너스최소배당');
      // 보너스 최소배당
      if (bonusConfig){
        const minBonusOdds = bonusConfig.value;
        // const totalOdds = state.sports.reduce((prev, curr) => prev * Number(curr.odds), 1)
        // if (totalOdds < Number(minBonusOdds)){
        //     return alert(`보너스 폴더는 최소 ${minBonusOdds} 배당이상 가능하십니다.`);
        // }
        const checkMinOdds = state.cart.find((item) => Number(minBonusOdds) > Number(item.odds));
        if (checkMinOdds) return alert(`보너스 폴더는 최소 ${minBonusOdds} 배당이상 가능하십니다.`);
      }
      // 보너스 최소 폴더
      const limitBet = Number(obj.homeNameKr.replace('폴더', '')); // 기본폴더 체크 해당폴더에 도달 하지 못할시에러
      if (state.cart.length < limitBet) return alert(`보너스 폴더는 ${limitBet} 폴더 추가 후 가능합니다.`);

      if (state.cart.filter(e => e.sportNameKr === '보너스') > 0) return alert(`이미 보너스 폴더가 추가 되었습니다.`);
      return commit('PUSH_ITEM_CART', obj);
    }
    const checkBonusInclude = state.cart.find(e => e.betType === '보너스');
    if (checkBonusInclude) {
      const bonusConfig = store.state.setting.settings.config.option.siteOption2.find(e=> e.name === '보너스최소배당');
      // 보너스 최소배당
      if (bonusConfig){
        const minBonusOdds = bonusConfig.value;
        const checkMinOdds = state.cart.find((item) => Number(minBonusOdds) > Number(item.odds));
        if (checkMinOdds) return alert(`보너스 폴더는 최소 ${minBonusOdds} 배당이상 가능하십니다.`);
      }
    }
    const checkFolderCount = store.getters['LEVEL_SETTING'].prematchSetting.maxFolders;

    const cartInItemCnt = state.cart.filter(e => e.betType !== '보너스').length;
    if (checkFolderCount <= cartInItemCnt) return alert(`최대가능 폴더수는 ${checkFolderCount}개 입니다.`)
    // 카트에 동일경기 아이템이 추가되었을때
    const equalCart = state.cart.find(item => item.foldersSeq === obj.foldersSeq);
    if (equalCart){
      return commit('POP_ITEM_CART_BY_FOLDER', obj); // 있을시 삭제
    }

    const checkMaxOdds = store.getters['LEVEL_SETTING'].prematchSetting.maxOdds;
    if (Number(getters.SPORT_TOTAL_ODDS) * Number(obj.odds) >  Number(checkMaxOdds)) return alert('최대배당을 초과하셨습니다.')

    const cartItem = state.cart.find(item => item.betsSeq === obj.betsSeq);
    if (!cartItem) {
      // 추가할 item 이 카트의 게임과 일치하지 않을 경우, 카트에 새로 추가
      // 동일경기는 기본적인 동일경기 설정
      const dupItem = state.cart.filter(item => item.gameId === obj.gameId);
      // 조합 가능 구분 여부
      const betOption = store.state.setting.settings.sportConfig.combination.filter(e=> e.status === '사용');

      // const betOption =  state.bettingOptions.filter(e => e.list === true)
      const sportsBetOption = betOption.filter(e => Number(e.sport) === obj.sportsId)
      // console.log(sportsBetOption)
      if ( dupItem.length >= 2 ) {
        return alert('동일경기에서 3가지 이상 조합은 불가능합니다.')
      }

      if (dupItem.length > 0) {
        // 종목별 조합 검증항목 배열에 담음
        const cartItemInMarketId = dupItem.map(e => Number(e.marketsId));
        cartItemInMarketId.push(Number(obj.marketsId));
        const verifyResult = [];
        // console.log(sportsBetOption)
        const alloweds = sportsBetOption.map(e => e.allowed);
        for (const i in alloweds){
          let verify = alloweds[i].filter(e => e !== null);
          let inResult = [];
          for (const j in verify){
            let combination = verify[j].id;
            inResult.push(cartItemInMarketId.indexOf(combination));
          }
          verifyResult.push(inResult);
        }

        // 배열에 담긴 조합 검증항목을 체크
        let res = false;
        for (const v in verifyResult) {
          const verify = verifyResult[v];
          if (verify.indexOf(-1) === -1){
            res = true;
          }
        }
        if (!res){
          store.state.modals.errorMsg = '동일경기 불가능한 조합입니다.';
          store.state.modals.deduction = true;
          return;
        }

        // 축구일때 승무패 배당차감
        const soccerOption = store.state.setting.settings.sportConfig.common.soccerCombination;
        if (obj.sportsId === 210 && soccerOption.active) {
          const SMP = state.cart.find(e => e.marketNameKr.indexOf('승무패') >= 0 && e.gameId === obj.gameId);
          if ((SMP && SMP.betsName === 'X') || obj.betsName === 'X') {
            store.state.modals.errorMsg = '해당 조합은 베팅이 불가합니다.';
            store.state.modals.deduction = true;
            return
          }

          store.state.modals.errorMsg = '축구 동일경기 조합베팅시 차감 배당값으로 적용됩니다.';
          store.state.modals.deduction = true;

          // 이미 승무패가 등록되어있을시
          if (SMP){
            if (Number(SMP.odds) >= 1.01 && Number(SMP.odds) < 1.50) {
              SMP.betsPrevOdds = Number(SMP.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(SMP.odds) - Number(soccerOption.division1)).toFixed(2);
              SMP.odds = Number(SMP.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(SMP.odds) - Number(soccerOption.division1)).toFixed(2);
              SMP.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division1}`
            } else if (Number(SMP.odds) >= 1.51 && Number(SMP.odds) < 1.99) {
              SMP.betsPrevOdds = (Number(SMP.odds) - Number(soccerOption.division2)).toFixed(2);
              SMP.odds = (Number(SMP.odds) - Number(soccerOption.division2)).toFixed(2);
              SMP.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division2}`
            } else {
              SMP.betsPrevOdds = (Number(SMP.odds) - Number(soccerOption.division3)).toFixed(2);
              SMP.odds = (Number(SMP.odds) - Number(soccerOption.division3)).toFixed(2);
              SMP.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division3}`
            }
          }
          // 승무패가 후에 추가될시
          if (obj.marketNameKr.indexOf('승무패') >= 0) {
            if (Number(obj.odds) >= 1.01 && Number(obj.odds) < 1.50) {
              obj.betsPrevOdds = Number(obj.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(obj.odds) - Number(soccerOption.division1)).toFixed(2);
              obj.odds = Number(obj.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(obj.odds) - Number(soccerOption.division1)).toFixed(2);
              obj.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division1}`
            } else if (Number(obj.odds) >= 1.51 && Number(obj.odds) < 1.99) {
              obj.betsPrevOdds = (Number(obj.odds) - Number(soccerOption.division2)).toFixed(2);
              obj.odds = (Number(obj.odds) - Number(soccerOption.division2)).toFixed(2);
              obj.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division2}`
            } else {
              obj.betsPrevOdds = (Number(obj.odds) - Number(soccerOption.division3)).toFixed(2);
              obj.odds = (Number(obj.odds) - Number(soccerOption.division3)).toFixed(2);
              obj.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division3}`
            }
          }
        }
      }
      commit('PUSH_ITEM_CART', obj);
    } else {
      commit('POP_ITEM_CART', obj); // 있을시 삭제
    }
  },
  ADD_EUROPEAN_CART_ITEM({state, commit, getters}, obj){
    const checkFolderCount = store.getters['LEVEL_SETTING'].prematchSetting.maxFolders;

    const cartInItemCnt = state.cart.filter(e => e.betType !== '보너스').length;
    if (checkFolderCount <= cartInItemCnt) return alert(`최대가능 폴더수는 ${checkFolderCount}개 입니다.`)
    // 카트에 동일경기 아이템이 추가되었을때
    const equalCart = state.cart.find(item => item.foldersSeq === obj.foldersSeq);
    if (equalCart){
      return commit('POP_ITEM_CART_BY_FOLDER', obj); // 있을시 삭제
    }

    const checkMaxOdds = store.getters['LEVEL_SETTING'].prematchSetting.maxOdds;
    if (Number(getters.SPORT_TOTAL_ODDS) * Number(obj.odds) >  Number(checkMaxOdds)) return alert('최대배당을 초과하셨습니다.')

    const dupItem = state.cart.filter(item => item.gameId === obj.gameId);
    if ( dupItem.length > 0 ) {
      return alert('동일경기에서 조합은 불가능합니다.')
    }

    commit('PUSH_ITEM_CART', obj);
  },
  DELETE_CART_ITEM({ commit }, obj){
    commit('POP_ITEM_CART', obj); // 있을시 삭제
  },
  DELETE_CART_ITEM_GAME({commit}, gameId){
    commit('POP_ITEM_CART_BY_GAME', gameId); // 있을시 삭제
  },
  ALL_CLEAR_ITEM({ commit }){
    return commit('RESET_SPORTS_CART')
  },
  CLEAR_SPORT_CART({commit}, type){
    return commit('RESET_TYPE_CART', type)
  },
  UPDATE_DATA({commit}, data){
    commit('SET_DETAIL_DATA', data)
  },
}

const mutations = {
  SET_LIST(state, payload) {
    state.originList = payload
  },
  SET_DATE(state, payload) {
    state.dateCnt = payload
  },
  PUSH_ITEM_CART(state, obj){
    state.cart.push(obj)
  },
  POP_ITEM_CART_INDEX(state, index){
    return state.cart.splice(index, 1);
  },
  POP_ITEM_CART_BY_FOLDER(state, obj){
    const index = state.cart.findIndex(i => i.foldersSeq === obj.foldersSeq);
    if (index === -1) return state.cart = [...state.cart, obj];
    return state.cart.splice(index, 1);
  },
  POP_ITEM_CART(state, obj){
    const index = state.cart.findIndex(i => i.betsSeq === obj.betsSeq);
    if (index === -1) return state.cart = [...state.cart, obj];
    return state.cart.splice(index, 1);
  },
  POP_ITEM_CART_BY_GAME(state, gameId){
    const index = state.cart.findIndex(i => i.gameId === gameId);
    if (index === -1) {
      state.cart = [...state.cart ]
      return;
    }
    return state.cart.splice(index, 1);
  },
  RESET_TYPE_CART(state, type = '프리매치'){
    state.cart = state.cart.filter(e => e.betType === type);
  },
  RESET_SPORTS_CART(state){
    state.cart = [];
  },
  SET_DETAIL_DATA(state, data) {
    // console.log(data.data)
    const result = data.data;
    const game = state.originList.find(game => game.id === result.game.id)
    if (game){
      const gameFolder = game.folders[0];
      if(gameFolder){
        const folder = result.list.find(folder => String(folder.id) === String(gameFolder.id));
        gameFolder.bets.map((bet) => {
          folder.bets.map(recentBet => {
            if (recentBet.id === bet.id) bet.price = recentBet.price;
          })
        })
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
