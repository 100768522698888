<template>
  <div v-if="isLoading">
    <div class="header2" v-if="isLoading && game">
      <div class="left">
        <a @click="movePage('inplay')" class="btn-prev"></a>
      </div>
      <div class="bet-h1">
        <img v-if="game.sport.icon" :src="game.sport.icon" style="width: 20px;">
        <img v-if="game.league.icon"
             :src="game.league.icon"
             style="width: 30px; height: 15px;">
        <p>
          {{ game.homeTeam | teamName }}
          <span>vs</span>
          {{ game.awayTeam | teamName }}
        </p>
      </div>
    </div>
    <div class="live-info" :class="{
      'baseball': game.sportSeq === 220,
      'hockey': game.sportSeq === 211,
      'basketball': game.sportSeq === 212,
      'volleyball': game.sportSeq === 214,
      'rugby': game.sportSeq === 215,
      'lol': game.sportSeq === 267,

    }">
      <div class="tit">{{ game.league | leagueName }}</div>
      <dl>
        <dt v-if="game.sportSeq === 210">
          {{ game.currentPeriod | currentPeriod2(game.sportSeq) }} {{ game.currentTime }} {{ game[`set${game.currentPeriod}Score`] }}
        </dt>
        <dt v-else>
          {{ game.currentPeriod | currentPeriod2(game.sportSeq) }} {{ game[`set${game.currentPeriod}Score`] }}
        </dt>
        <!--   스코어     -->
        <dd>
          <span v-for="l in Number(game.currentPeriod)" :key="'SportDetailPeriod'+l" class="score">
            {{ l }}
          </span>
          <span class="score">T</span>
        </dd>
      </dl>
      <dl>
        <dt>
          {{ game.homeTeam | teamName }}
        </dt>
        <dd>
          <!--   스코어    -->
          <span class="score" v-for="l in Number(game.currentPeriod)" :key="'SportDetailPeriod'+l">
            {{ game[`set${l}Score`].split(':')[0] }}
          </span>

          <span class="yellow score" style="width: 15px;">{{ game.homeResult }}</span>
        </dd>
      </dl>
      <dl>
        <dt>
          {{ game.awayTeam | teamName }}
        </dt>
        <dd>
          <span v-for="l in Number(game.currentPeriod)" :key="'SportDetailPeriod'+l" class="score">
            {{ game[`set${l}Score`].split(':')[1] }}
          </span>
          <span class="yellow score" style="width: 15px;">{{ game.awayResult }}</span>
        </dd>
      </dl>
    </div>
    <div class="categorys2" v-if="isLoading && originList">
      <button @click="selectMarket('STAR')">
        <img src="@/assets/img/ico/favor_on.png">
      </button>
      <button @click="selectMarket(null)">
        전체
      </button>
      <button @click="selectMarket('승무패')">
        승무패
      </button>
      <button @click="selectMarket('핸디캡')">
        핸디캡
      </button>
      <button @click="selectMarket('오버언더')">
        오버언더
      </button>
      <button @click="selectMarket('ETC')">
        기타
      </button>
    </div>
    <template v-if="game.status !== '진행' || game.isVisible === 'n' || game.isSuspended === 'y' || !originList.find(e => e.isVisible === 'y') || checkBlockGame(game)">
      <div data-v-2c8ccbbb="" style="padding-top: 100px; text-align: center; color: #a97bf5; min-height: 400px;">
        현재 가능한 베팅이 없습니다.
        <br data-v-2c8ccbbb=""><br data-v-2c8ccbbb=""><br data-v-2c8ccbbb="">
        베팅이 가능한 항목은 화면에 자동으로 나타납니다
      </div>
    </template>
    <template v-else>
      <template v-if="!selectedMarket">
        <div class="m-game2" v-for="(row, index) in marketSort(originList)" :key="'mobileDetailMarket'+index">
          <!--  마켓시퀀스 필터링 -->
          <template v-if="row.nameEn?.indexOf('{sw}') >= 0">
            <!--  마켓시퀀스 기간별 -->
            <template v-for="period in uniqPeriod(row.seq)">
              <template v-if="Number(game.currentPeriod) <= Number(period) && sportPeriodCheck(period)">
                <div class="head" :key="'game'+game.seq+period"
                     v-if="originList.filter(e => e.marketSeq === row.seq && String(e.periodSequence) === String(period))?.find(f => f.isVisible ==='y')">
                  <div class="tit" v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0">
                    {{ row | marketName(period) }}
                  </div>
                  <div class="tit" v-else>{{ row | teamMarketNamePeriod(game.homeTeam, game.awayTeam, period) }}</div>
                  <div class="right">
<!--                    <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === period)"-->
<!--                         @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_on.png">-->
<!--                    <img v-else @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_off.png">-->
                    <span @click="folderClosed(row.seq, period)" class="material-icons" :class="{'up': closed.find(e => e.seq === e.period === period), 'down': !closed.find(e => e.seq === e.period === period)}">expand_more</span>
                  </div>
                </div>
                <div class="body" :key="'gameFolders'+game.seq+period" v-if="!closed.find(e => e.seq === e.period === period)">
                  <template v-for="(row2, index2) in originList.filter(e => e.marketSeq === row.seq && String(e.periodSequence) === String(period))"
                  >
                    <template v-if="row2.bets.length < 4">
                      <div class="item"
                           :key="'marketFolderDetail'+index2">
                        <template v-if="row2.isSuspended === 'y' && row2.isVisible === 'y'">
                          <MDetailLock></MDetailLock>
                          <MDetailLock
                              v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                          ></MDetailLock>
                          <MDetailLock></MDetailLock>
                        </template>
                        <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y'">
                          <MDetailPrice
                              :add-cart="addCart"
                              :check-cart="checkCart"
                              :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home' || e.name === 'Team1')"
                              :folder="row2"
                              :game="game"
                              :team="game.homeTeam"
                          >
                          </MDetailPrice>
                          <MDetailPrice
                              v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                              :add-cart="addCart"
                              :check-cart="checkCart"
                              :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                              :folder="row2"
                              :game="game"
                              :team="game.homeTeam"
                          >
                          </MDetailPrice>
                          <MDetailPrice
                              :add-cart="addCart"
                              :check-cart="checkCart"
                              :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away' || e.name === 'Team2')"
                              :folder="row2"
                              :game="game"
                              :team="game.awayTeam"
                          >
                          </MDetailPrice>
                          <!--              <dl-->
                          <!--                  :set="home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                          <!--                  :class="{'active': checkCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                          <!--                  @click="addCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), row2)"-->
                          <!--              >-->
                          <!--                <dt>-->
                          <!--                  {{ home.name | betsName }} {{row2.folders_line | lineSet2(home.name)}}-->
                          <!--                </dt>-->
                          <!--                <dd>-->
                          <!--              <span-->
                          <!--                  v-if="home.name === 'Over'"-->
                          <!--                  class="material-icons up"-->
                          <!--              >expand_more-->
                          <!--              </span>-->
                          <!--                  {{ home.price }}-->
                          <!--                </dd>-->
                          <!--              </dl>-->
                          <!--              <dl-->
                          <!--                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                          <!--                  :class="{'active': checkCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                          <!--                  @click="addCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), row2)"-->
                          <!--              >-->
                          <!--                <dt>-->
                          <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                          <!--                </dt>-->
                          <!--                <dd>-->
                          <!--                  {{ draw.price }}-->
                          <!--                </dd>-->
                          <!--              </dl>-->
                          <!--              <dl-->
                          <!--                  :set="away=row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                          <!--                  :class="{'active': checkCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                          <!--                  @click="addCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), row2)"-->
                          <!--              >-->
                          <!--                <dt>-->
                          <!--                  {{ away.name | betsName}} {{ row2.folders_line | lineSet2(away.name, '2')}}-->
                          <!--                </dt>-->
                          <!--                <dd>-->
                          <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                          <!--                  {{ away.price }}-->
                          <!--                </dd>-->
                          <!--              </dl>-->
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      <template v-for="l in arrLenDiv(row2.bets, 3)">
                        <template v-if="row2.isVisible === 'n' || row2.isSuspended === 'y'">
                          <div class="item" :key="'CorrectScoreBets' + l">
                            <template  v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                              <dl
                                  :key="'betsDetail'+bet.id"
                                  style="width: 33%;"
                              >
                                <dt>
                                  {{ manyBetsName(bet.name)}}
                                </dt>
                                <dd>
                                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                </dd>
                              </dl>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="row2.isVisible ==='y' && row2.isSuspended==='n'">
                          <div class="item" :key="'CorrectScoreBets' + l">
                            <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                              <template v-if="bet.isVisible === 'y'">
                                <dl
                                    :class="{'active': checkCart(bet)}"
                                    @click="addCart(bet, row2, game)"
                                    :key="'betsDetail'+bet.id"
                                    style="width: 33%;"
                                >
                                  <dt>
                                    {{ manyBetsName(bet.name)}}
                                  </dt>
                                  <dd>
                                    {{ bet.price }}
                                  </dd>
                                </dl>
                              </template>
                              <template v-else>
                                <dl :key="'betsDetail'+bet.id" style="width: 33%;">
                                  <dt>
                                    {{ manyBetsName(bet.name)}}
                                  </dt>
                                  <dd>
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </dd>
                                </dl>
                              </template>
                            </template>
                          </div>
                        </template>
                      </template>
                    </template>
                  </template>
                </div>
              </template>
            </template>
          </template>
          <template v-else>
            <div class="head" v-if="originList.filter(e => e.marketSeq === row.seq)?.find(f => f.isVisible ==='y')">
              <div class="tit" v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0">
                {{ row | marketName }}
              </div>
              <div class="tit" v-else>{{ row | teamMarketName(game.homeTeam, game.awayTeam) }}</div>
              <div class="right">
<!--                <img v-if="stared.find(e => e.gameId === game.id &&  e.seq === row.seq && e.periodSequence === '0')"-->
<!--                     @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">-->
<!--                <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">-->
                <span @click="folderClosed(row.seq, '0')" class="material-icons" :class="{'up': closed.find(e => e.seq === row.seq && e.period === '0'), 'down': !closed.find(e => e.seq === row.seq && e.period === '0')}">expand_more</span>
              </div>
            </div>
            <template v-if="originList.filter(e => e.marketSeq === row.seq)?.find(f => f.isVisible ==='y')">
              <div class="body" v-if="!closed.find(e => e.seq === row.seq &&  e.period === '0')">
                <template v-for="(row2, index2) in lists.filter(e => e.marketSeq === row.seq)">
                  <template v-if="row2.bets.length < 4">
                    <div class="item" :key="'marketFolderDetail'+index2">
                      <template v-if="row2.isSuspended === 'y' && row2.isVisible === 'y'">
                        <MDetailLock></MDetailLock>
                        <MDetailLock
                            v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                        ></MDetailLock>
                        <MDetailLock></MDetailLock>
                      </template>
                      <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y'">
                        <MDetailPrice
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home' || e.name === 'Team1')"
                            :folder="row2"
                            :game="game"
                            :team="game.homeTeam"
                        >
                        </MDetailPrice>
                        <MDetailPrice
                            v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                            :folder="row2"
                            :game="game"
                            :team="game.homeTeam"
                        >
                        </MDetailPrice>
                        <MDetailPrice
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away' || e.name === 'Team2')"
                            :folder="row2"
                            :game="game"
                            :team="game.awayTeam"
                        >
                        </MDetailPrice>
                        <!--              <dl-->
                        <!--                  :set="home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                        <!--                  :class="{'active': checkCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                        <!--                  @click="addCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), row2)"-->
                        <!--              >-->
                        <!--                <dt>-->
                        <!--                  {{ home.name | betsName }} {{row2.folders_line | lineSet2(home.name)}}-->
                        <!--                </dt>-->
                        <!--                <dd>-->
                        <!--              <span-->
                        <!--                  v-if="home.name === 'Over'"-->
                        <!--                  class="material-icons up"-->
                        <!--              >expand_more-->
                        <!--              </span>-->
                        <!--                  {{ home.price }}-->
                        <!--                </dd>-->
                        <!--              </dl>-->
                        <!--              <dl-->
                        <!--                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                        <!--                  :class="{'active': checkCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                        <!--                  @click="addCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), row2)"-->
                        <!--              >-->
                        <!--                <dt>-->
                        <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                        <!--                </dt>-->
                        <!--                <dd>-->
                        <!--                  {{ draw.price }}-->
                        <!--                </dd>-->
                        <!--              </dl>-->
                        <!--              <dl-->
                        <!--                  :set="away=row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                        <!--                  :class="{'active': checkCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                        <!--                  @click="addCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), row2)"-->
                        <!--              >-->
                        <!--                <dt>-->
                        <!--                  {{ away.name | betsName}} {{ row2.folders_line | lineSet2(away.name, '2')}}-->
                        <!--                </dt>-->
                        <!--                <dd>-->
                        <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                        <!--                  {{ away.price }}-->
                        <!--                </dd>-->
                        <!--              </dl>-->
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    <template v-for="l in arrLenDiv(row2.bets, 3)">
                      <template v-if="row2.isVisible === 'n' || row2.isSuspended === 'y'">
                        <div class="item" :key="'CorrectScoreBets' + l">
                          <template  v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                            <dl
                                :key="'betsDetail'+bet.id"
                                style="width: 33%;"
                            >
                              <dt>
                                {{ manyBetsName(bet.name)}}
                              </dt>
                              <dd>
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </dd>
                            </dl>
                          </template>
                        </div>
                      </template>
                      <template v-else-if="row2.isVisible ==='y' && row2.isSuspended==='n'">
                        <div class="item" :key="'CorrectScoreBets' + l">
                          <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                            <template v-if="bet.isVisible === 'y'">
                              <dl
                                  :class="{'active': checkCart(bet)}"
                                  @click="addCart(bet, row2, game)"
                                  :key="'betsDetail'+bet.id"
                                  style="width: 33%;"
                              >
                                <dt>
                                  {{ manyBetsName(bet.name)}}
                                </dt>
                                <dd>
                                  {{ bet.price }}
                                </dd>
                              </dl>
                            </template>
                            <template v-else>
                              <dl :key="'betsDetail'+bet.id" style="width: 33%;">
                                <dt>
                                  {{ manyBetsName(bet.name)}}
                                </dt>
                                <dd>
                                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                </dd>
                              </dl>
                            </template>
                          </template>
                        </div>
                      </template>
                    </template>
                  </template>
                </template>

              </div>
            </template>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="m-game2" v-for="(row, index) in marketSort(lists)" :key="'mobileDetailMarket'+index">
          <!--  마켓시퀀스 필터링 -->
          <template v-if="row.nameEn?.indexOf('{sw}') >= 0">
            <!--  마켓시퀀스 기간별 -->
            <template v-for="period in uniqPeriod(row.seq)">
              <template v-if="Number(game.currentPeriod) <= Number(period)">
                <div class="head" :key="'game'+game.seq+period"
                     v-if="lists.filter(e => e.marketSeq === row.seq && String(e.periodSequence) === String(period))?.find(f => f.isVisible ==='y')">
                  <div class="tit" v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0">
                    {{ row | marketName(period) }}
                  </div>
                  <div class="tit" v-else>{{ row | teamMarketNamePeriod(game.homeTeam, game.awayTeam, period) }}</div>
                  <div class="right">
<!--                    <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === period)"-->
<!--                         @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_on.png">-->
<!--                    <img v-else @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_off.png">-->
                    <span @click="folderClosed(row.seq, period)" class="material-icons" :class="{'up': closed.find(e => e.seq === e.period === period), 'down': !closed.find(e => e.seq === e.period === period)}">expand_more</span>
                  </div>
                </div>
                <div class="body" :key="'gameFolders'+game.seq+period" v-if="!closed.find(e => e.seq === e.period === period)">
                  <template v-for="(row2, index2) in lists.filter(e => e.marketSeq === row.seq && String(e.periodSequence) === String(period))">
                    <template
                        v-if="row2.bets.length < 4"
                    >
                      <div class="item"
                           :key="'marketFolderDetail'+index2">
                        <template v-if="row2.isSuspended === 'y' && row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                          <MDetailLock></MDetailLock>
                          <MDetailLock
                              v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                          ></MDetailLock>
                          <MDetailLock></MDetailLock>
                        </template>
                        <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                          <MDetailPrice
                              :add-cart="addCart"
                              :check-cart="checkCart"
                              :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home' || e.name === 'Team1')"
                              :folder="row2"
                              :game="game"
                              :team="game.homeTeam"
                          >
                          </MDetailPrice>
                          <MDetailPrice
                              v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                              :add-cart="addCart"
                              :check-cart="checkCart"
                              :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                              :folder="row2"
                              :game="game"
                              :team="game.homeTeam"
                          >
                          </MDetailPrice>
                          <MDetailPrice
                              :add-cart="addCart"
                              :check-cart="checkCart"
                              :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away' || e.name === 'Team2')"
                              :folder="row2"
                              :game="game"
                              :team="game.awayTeam"
                          >
                          </MDetailPrice>
                          <!--              <dl-->
                          <!--                  :set="home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                          <!--                  :class="{'active': checkCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                          <!--                  @click="addCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), row2)"-->
                          <!--              >-->
                          <!--                <dt>-->
                          <!--                  {{ home.name | betsName }} {{row2.folders_line | lineSet2(home.name)}}-->
                          <!--                </dt>-->
                          <!--                <dd>-->
                          <!--              <span-->
                          <!--                  v-if="home.name === 'Over'"-->
                          <!--                  class="material-icons up"-->
                          <!--              >expand_more-->
                          <!--              </span>-->
                          <!--                  {{ home.price }}-->
                          <!--                </dd>-->
                          <!--              </dl>-->
                          <!--              <dl-->
                          <!--                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                          <!--                  :class="{'active': checkCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                          <!--                  @click="addCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), row2)"-->
                          <!--              >-->
                          <!--                <dt>-->
                          <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                          <!--                </dt>-->
                          <!--                <dd>-->
                          <!--                  {{ draw.price }}-->
                          <!--                </dd>-->
                          <!--              </dl>-->
                          <!--              <dl-->
                          <!--                  :set="away=row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                          <!--                  :class="{'active': checkCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                          <!--                  @click="addCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), row2)"-->
                          <!--              >-->
                          <!--                <dt>-->
                          <!--                  {{ away.name | betsName}} {{ row2.folders_line | lineSet2(away.name, '2')}}-->
                          <!--                </dt>-->
                          <!--                <dd>-->
                          <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                          <!--                  {{ away.price }}-->
                          <!--                </dd>-->
                          <!--              </dl>-->
                        </template>
                      </div>
                    </template>
                    <template v-else-if="row2.bets.length > 4">
                      <template v-for="l in arrLenDiv(row2.bets, 3)">
                        <template v-if="row2.isVisible === 'n' || row2.isSuspended === 'y'">
                          <div class="item" :key="'CorrectScoreBets' + l">
                            <template  v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                              <dl
                                  :key="'betsDetail'+bet.id"
                                  style="width: 33%;"
                              >
                                <dt>
                                  {{ manyBetsName(bet.name)}}
                                </dt>
                                <dd>
                                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                </dd>
                              </dl>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="row2.isVisible ==='y' && row2.isSuspended==='n'">
                          <div class="item" :key="'CorrectScoreBets' + l">
                            <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                              <template v-if="bet.isVisible === 'y'">
                                <dl
                                    :class="{'active': checkCart(bet)}"
                                    @click="addCart(bet, row2, game)"
                                    :key="'betsDetail'+bet.id"
                                    style="width: 33%;"
                                >
                                  <dt>
                                    {{ manyBetsName(bet.name)}}
                                  </dt>
                                  <dd>
                                    {{ bet.price }}
                                  </dd>
                                </dl>
                              </template>
                              <template v-else>
                                <dl :key="'betsDetail'+bet.id" style="width: 33%;">
                                  <dt>
                                    {{ manyBetsName(bet.name)}}
                                  </dt>
                                  <dd>
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </dd>
                                </dl>
                              </template>
                            </template>
                          </div>
                        </template>
                      </template>
                    </template>
                  </template>
                </div>
              </template>
            </template>
          </template>
          <template v-else>
            <div class="head"
                 v-if="lists.filter(e => e.marketSeq === row.seq && String(e.periodSequence) === String(period))?.find(f => f.isVisible ==='y')"
            >
              <div class="tit" v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0">
                {{ row | marketName }}
              </div>
              <div class="tit" v-else>{{ row | teamMarketName(game.homeTeam, game.awayTeam) }}</div>
              <div class="right">
<!--                <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === '0')"-->
<!--                     @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">-->
<!--                <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">-->
                <span @click="folderClosed(row.seq, '0')" class="material-icons" :class="{'up': closed.find(e => e.seq === row.seq && e.period === '0'), 'down': !closed.find(e => e.seq === row.seq && e.period === '0')}">expand_more</span>
              </div>
            </div>
            <div class="body" v-if="!closed.find(e => e.seq === row.seq && e.period === '0')">
              <template v-for="(row2, index2) in lists.filter(e => e.marketSeq === row.seq)">
                <template v-if="row2.bets.length < 4">
                  <template v-if="row.nameKr.indexOf('정확한') >= 0">
                    <template v-for="l in arrLenDiv(row2.bets, 3)">
                      <template v-if="row2.isVisible === 'n' || row2.isSuspended === 'y'">
                        <div class="item" :key="'CorrectScoreBets' + l">
                          <template  v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                            <dl
                                :key="'betsDetail'+bet.id"
                                style="width: 33%;"
                            >
                              <dt>
                                {{ manyBetsName(bet.name)}}
                              </dt>
                              <dd>
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </dd>
                            </dl>
                          </template>
                        </div>
                      </template>
                      <template v-else-if="row2.isVisible ==='y' && row2.isSuspended==='n'">
                        <div class="item" :key="'CorrectScoreBets' + l">
                          <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                            <template v-if="bet.isVisible === 'y'">
                              <dl
                                  :class="{'active': checkCart(bet)}"
                                  @click="addCart(bet, row2, game)"
                                  :key="'betsDetail'+bet.id"
                                  style="width: 33%;"
                              >
                                <dt>
                                  {{ manyBetsName(bet.name)}}
                                </dt>
                                <dd>
                                  {{ bet.price }}
                                </dd>
                              </dl>
                            </template>
                            <template v-else>
                              <dl :key="'betsDetail'+bet.id" style="width: 33%;">
                                <dt>
                                  {{ manyBetsName(bet.name)}}
                                </dt>
                                <dd>
                                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                </dd>
                              </dl>
                            </template>
                          </template>
                        </div>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <div class="item" :key="'marketFolderDetail'+index2">
                      <template v-if="row2.isSuspended === 'y' && row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                        <MDetailLock></MDetailLock>
                        <MDetailLock
                            v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                        ></MDetailLock>
                        <MDetailLock></MDetailLock>
                      </template>
                      <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                        <MDetailPrice
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home')"
                            :folder="row2"
                            :game="game"
                            :team="game.homeTeam"
                        >
                        </MDetailPrice>
                        <MDetailPrice
                            v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                            :folder="row2"
                            :game="game"
                            :team="game.homeTeam"
                        >
                        </MDetailPrice>
                        <MDetailPrice
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away')"
                            :folder="row2"
                            :game="game"
                            :team="game.awayTeam"
                        >
                        </MDetailPrice>
                        <!--              <dl-->
                        <!--                  :set="home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                        <!--                  :class="{'active': checkCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                        <!--                  @click="addCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), row2)"-->
                        <!--              >-->
                        <!--                <dt>-->
                        <!--                  {{ home.name | betsName }} {{row2.folders_line | lineSet2(home.name)}}-->
                        <!--                </dt>-->
                        <!--                <dd>-->
                        <!--              <span-->
                        <!--                  v-if="home.name === 'Over'"-->
                        <!--                  class="material-icons up"-->
                        <!--              >expand_more-->
                        <!--              </span>-->
                        <!--                  {{ home.price }}-->
                        <!--                </dd>-->
                        <!--              </dl>-->
                        <!--              <dl-->
                        <!--                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                        <!--                  :class="{'active': checkCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                        <!--                  @click="addCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), row2)"-->
                        <!--              >-->
                        <!--                <dt>-->
                        <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                        <!--                </dt>-->
                        <!--                <dd>-->
                        <!--                  {{ draw.price }}-->
                        <!--                </dd>-->
                        <!--              </dl>-->
                        <!--              <dl-->
                        <!--                  :set="away=row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                        <!--                  :class="{'active': checkCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                        <!--                  @click="addCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), row2)"-->
                        <!--              >-->
                        <!--                <dt>-->
                        <!--                  {{ away.name | betsName}} {{ row2.folders_line | lineSet2(away.name, '2')}}-->
                        <!--                </dt>-->
                        <!--                <dd>-->
                        <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                        <!--                  {{ away.price }}-->
                        <!--                </dd>-->
                        <!--              </dl>-->
                      </template>
                    </div>
                  </template>
                </template>
                <template>
                  <template v-for="l in arrLenDiv(row2.bets, 3)">
                    <template v-if="row2.isVisible === 'n' || row2.isSuspended === 'y'">
                      <div class="item" :key="'CorrectScoreBets' + l">
                        <template  v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                          <dl
                              :key="'betsDetail'+bet.id"
                              style="width: 33%;"
                          >
                            <dt>
                              {{ manyBetsName(bet.name)}}
                            </dt>
                            <dd>
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </dd>
                          </dl>
                        </template>
                      </div>
                    </template>
                    <template v-else-if="row2.isVisible ==='y' && row2.isSuspended==='n'">
                      <div class="item" :key="'CorrectScoreBets' + l">
                        <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                          <template v-if="bet.isVisible === 'y'">
                            <dl
                                :class="{'active': checkCart(bet)}"
                                @click="addCart(bet, row2, game)"
                                :key="'betsDetail'+bet.id"
                                style="width: 33%;"
                            >
                              <dt>
                                {{ manyBetsName(bet.name)}}
                              </dt>
                              <dd>
                                {{ bet.price }}
                              </dd>
                            </dl>
                          </template>
                          <template v-else>
                            <dl :key="'betsDetail'+bet.id" style="width: 33%;">
                              <dt>
                                {{ manyBetsName(bet.name)}}
                              </dt>
                              <dd>
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </dd>
                            </dl>
                          </template>
                        </template>
                      </div>
                    </template>
                  </template>
                </template>
              </template>
            </div>
          </template>
        </div>
      </template>
    </template>
    <MCart :type="cartType"></MCart>
  </div>
  <div style="text-align: center; min-height: 120px;" v-else>
    <Loading style="margin-top: 30px; "></Loading>
  </div>
</template>

<script>
import MCart from '@/components/Betting/MInplayCart'
import MDetailPrice from '@/components/Game/Sports/Inplay/MDetailPrice2'
import MDetailLock from '@/components/Game/Sports/Inplay/MDetailLock'
import Loading from '@/components/Loading'
import {mapState} from "vuex";
import MSG from "@/contants/msg";

export default {
  name: "InplayDetail",
  components: {
    MCart,
    MDetailPrice,
    MDetailLock,
    Loading
  },
  data: function(){
    return {
      cartType: 'sports',
      path: 'v2/game/inplay/detail',
      originList: null,
      lists: null,
      selectedMarket: null,
      game: null,
      isLoading: false,
      closed: [],
      timeCheck: true,
      scoreCheck: true,
    }
  },
  watch: {
    selectedMarket: function (val) {
      if (!val) return this.lists = this.originList;
      if (val === '승무패') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11557, 11558, 11575, 11649, 11930].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11603, 11604, 11611].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 212) return [11531, 11537, 11667].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11521, 11544].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11622, 11629, 11625].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 220) return [11515, 11507, 12972, 13103].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12240, 12242, 12243, 12244, 12245, 12246, 16817, 16818].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === '핸디캡') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11562, 11563, 11580, 11752, 11968].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11608, 11615, 11615, 11615].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 212) return [11532, 11539, 11668].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11904, 11724, 11546].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11623, 11631, 11626].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 220) return [11516, 11509, 17055].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12651, 12775, 12786, 12797, 12808, 12823].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === '오버언더') {
        this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11559, 11560, 11561, 11577, 11753, 11969, 11592, 11586].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11605, 11607, 11606, 11612, 11612, 11612, 11613, 11613, 11613, 11614, 11614, 11614].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 212) return [11533, 11534, 11535, 11662, 11663, 11664, 11669].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11726, 11723, 11720, 12104, 11545].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 220) return [11512, 11510, 11511, 13655, 11508, 17056, 13106].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12651, 12776, 12787, 12798, 12809, 12824].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === 'ETC') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11573, 11574, 11923, 12930, 11684, 11685, 11567, 11572, 11568, 11727, 13619, 11732].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11652, 11653, 11654, 11658, 11659, 11656, 11717, 11718].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 212) return [11536, 16637, 12530, 12531, 12566, 12567, 12568, 13558, 14049].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11522, 11540, 11543, 11675, 12105].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 220) return [13102, 13101].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12646, 12648, 12653, 12812, 12827, 12777, 12788, 12799, 12813, 12828, 12645, 12647, 12652, 12810, 12825, 12780, 12791, 12802, 12816, 12831, 12778, 12789, 12800, 12814, 12829, 12782, 12793, 12804, 12818, 12833, 12783, 12794, 12805, 12819, 12834, 12784, 12795, 12806, 12820, 12835].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === 'STAR') {
        // console.log(this.stared);
        this.lists = [];
        this.stared.map(s => {
          const lists = this.originList.filter(l => String(s.gameId) === String(this.game.id) && String(s.seq) === String(l.marketSeq) && String(s.periodSequence) === String(l.periodSequence))
          lists.map(folder => this.lists.push(folder));
        })

        return;
      }


      this.lists = this.originList.filter(e => e.market.nameKr?.indexOf(val) >= 0);
    },
    game:{
      handler: function(val, newVal){
        //경기 시간 변동체크
        if (val && newVal && val.id !== newVal.id) this.initFolder();
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    /* 해당경기의 베팅을 방지하는 설정*/
    blockSetting: function(){
      return this.$store.getters['INPLAY_SETTING'];
    },
    ...mapState({
      user: state => state.user.user,
    }),
  },
  mounted() {
    this.initFolder();
    this.interval = setInterval(this.initFolder, 5000);
    this.$socket.client.on('inplayMatchStats2', this.matchStats2)
    this.$socket.client.on('inplayStats', this.matchStats)
    this.$socket.client.on('inplayMatchUpdate', this.matchUpdate)
    this.$socket.client.on('inplay', this.updateData)
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    initFolder: function () {
      const payload = {
        gameSeq: this.$route.query.gid
      }

      return this.$store.dispatch('GET_INPLAY_DETAIL',  { payload })
          .then(data => {
            const result = data.data;
            this.game = result.payload.game;
            if (!result.payload.game) this.$router.push({ path: '/sport/livematch'});
            this.originList = result.payload.list;
            this.lists = this.originList;
            this.isLoading = true;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    matchUpdate: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const matchData = results[r];
        // console.log('detailMatchUpdate', matchData);
        if (String(this.game.id) === String(matchData.Id)) {
          // console.log('thisGameUpdate');
          if (matchData.IsStarted) this.game.status = '진행';
          this.game.isVisible = matchData.IsVisible ? 'y' : 'n';
          this.game.isSuspended = matchData.IsSuspended ? 'y' : 'n';
          if (matchData.MatchStatus === 2){
            this.game.status = '종료';
          }
        }
      }
    },
    updateData: function (data) {
      const result = JSON.parse(data);
      const gameMarket = result.filter(e => String(e.MatchId) === (this.game.id))
      if (gameMarket.length > 0) {
        gameMarket.map(m => {
          const folder = this.lists.find(e => String(e.id) === String(m.Id));
          if (folder) {
            folder.isVisible = m.IsVisible ? 'y' : 'n';
            folder.isSuspended = m.IsSuspended ? 'y' : 'n';
            m.Selections.map(s => {
              const bet = folder.bets.find(b => String(b.id) === String(s.Id));
              bet.price = s.Price;
              bet.isVisible = s.IsVisible ? 'y' : 'n';
              bet.isSuspended = s.IsSuspended ? 'y' : 'n';
            })
          }
        })
      }
    },
    // 경기 스코어 업데이트
    matchStats: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const result = results[r];
        if (String(result.EventId) === String(this.game.id)) {
          const score = result.Score;
          this.game.homeResult = score.split(':')[0] || 0;
          this.game.awayResult = score.split(':')[1] || 0;
          this.game.currentPeriod = result.Period;
          if (result.Set1Score) this.game.set1Score = result.Set1Score;
          if (result.Set2Score) this.game.set2Score = result.Set2Score;
          if (result.Set3Score) this.game.set3Score = result.Set3Score;
          if (result.Set4Score) this.game.set4Score = result.Set4Score;
          if (result.Set5Score) this.game.set5Score = result.Set5Score;
          if (result.Set6Score) this.game.set6Score = result.Set6Score;
          if (result.Set7Score) this.game.set7Score = result.Set7Score;
          if (result.Set8Score) this.game.set8Score = result.Set8Score;
          if (result.Set9Score) this.game.set9Score = result.Set9Score;

          if (result.RemainingTime) this.game.remainTime = result.RemainingTime;
        }
      }
    },

    // 마켓선택
    selectMarket: function (market) {
      this.selectedMarket = market;
    },
    // 마켓정렬
    marketSort: function(folders){
      const markets = folders.map(e => e.market);
      const arrUnique = markets.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.seq === character.seq) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    // 베팅카트에 담기
    // 베팅카트에 담기
    addCart: function (bets, folders) {
      if (!this.user) return alert(MSG.isNotLogin);
      const config = this.user.members_detail_setting;
      if (!config.베팅권한.인플레이.active) {
        return alert(MSG.authorization);
      }
      const cartItem = {
        gameId: this.game.id,
        gameSeq: this.game.seq,
        betsSeq: bets.seq,
        betsStatus: bets.status,
        betsId: bets.id,
        betsName: bets.name,
        betsUpdated: false,
        betsPrevOdds: bets.price,
        sportsId: this.game.sportSeq,
        marketsId: folders.marketSeq,
        foldersId: folders.id,
        foldersSeq: folders.seq,
        foldersType: folders.type,
        odds: bets.price,
        line: bets.line ? bets.line : null,
        leagueNameKr: this.game.league.nameKr,
        leagueNameEn: this.game.league.nameEn,
        leagueNameIcon: this.game.league.icon,
        sportNameKr: this.game.sport.nameKr,
        sportNameEn: this.game.sport.nameEn,
        sportNameIcon: this.game.sport.icon,
        homeNameKr: this.game.homeTeam.nameKr,
        homeNameEn: this.game.homeTeam.nameEn,
        awayNameKr: this.game.awayTeam.nameKr,
        awayNameEn: this.game.awayTeam.nameEn,
        marketNameKr: folders.periodSequence !== '0' ? folders.market.nameKr?.replace('{sw}', folders.periodSequence) : folders.market.nameKr,
        marketNameEn: folders.periodSequence !== '0' ? folders.market.nameEn?.replace('{sw}', folders.periodSequence) : folders.market.nameEn,
        startTime: this.game.startTime,
        odds1: null,
        odds2: null,
        odds3: null,
        betType: '인플레이',
        detailType: null,
        currentPeriod: this.game.currentPeriod,
        currentTime: `${this.game.subResult}`,
        homeScore: this.game.homeResult,
        awayScore: this.game.awayResult,
        penalty: null,
        maxAmount: this.game.league.maxAmount,
      }

      if (cartItem.marketNameKr?.indexOf('{sw}') >= 0) {
        if (folders.periodSequence !== '0'){
          cartItem.marketNameKr = cartItem.marketNameKr.replace('{sw}', folders.periodSequence)
          cartItem.marketNameEn = cartItem.marketNameEn.replace('{sw}', folders.periodSequence)
        }
      }

      if (cartItem.marketNameKr?.indexOf('1팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('1팀', cartItem.homeNameKr || cartItem.homeNameEn)
      }

      if (cartItem.marketNameKr?.indexOf('2팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('2팀', cartItem.awayNameKr || cartItem.awayNameEn)
      }
      this.$store.dispatch('cart/actionSportsCart', cartItem)
    },
    // 카트내역 체크해서 표시
    checkCart: function (bets, type) {
      if (type === '보너스') {
        // let index = this.$store.state.cart[this.cartType].find(item => item.odds === event)
        // return !!index;
        return null;
      } else {
        let index = this.$store.state.cart[this.cartType].find(item => item.betsSeq === bets.seq)
        return !!index;
      }
    },
    /**
     * 토글 액션
     * */
    toggleDetail: function (id) {
      this.toggle(this.openDetailBet, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailBet.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailBet = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    // sortMarket:function(market){
    //   this.lists = this.originList.filter(e => e.marketSeq === market)
    // }
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    arrLenDiv: function (arr, div = 4) {
      return Math.floor(arr.length / div) + 1
    },
    uniqPeriod: function (marketSeq) {
      const periods = this.originList.filter(e => e.marketSeq === marketSeq).map(e => e.periodSequence);
      let set = new Set(periods);
      const uniqueArr = [...set];
      uniqueArr.sort()
      return uniqueArr
    },
    addStartedMarket: function (seq, period) {
      const market = {
        gameId: this.game.id,
        seq: seq,
        periodSequence: period,
      }
      this.$store.dispatch('list/inplayStarted', market);
    },
    manyBetsName: function (betName) {
      let name = betName;
      if (name.indexOf('W1andOver') >= 0) {
        name = name.replace('W1andOver', `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 오버`)
      }
      if (name.indexOf('W2andOver') >= 0) {
        name = name.replace('W2andOver', `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 오버`)
      }
      if (name.indexOf('12andOver') >= 0) {
        name = name.replace('12andOver', '12 & 오버')
      }
      if (name.indexOf('W1andUnder') >= 0) {
        name = name.replace('W1andUnder', `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 언더`)
      }
      if (name.indexOf('W2andUnder') >= 0) {
        name = name.replace('W2andUnder', `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 언더`)
      }
      if (name.indexOf('12andUnder') >= 0) {
        name = name.replace('12andUnder', '12 & 언더')
      }

      if (name.indexOf('1XandOver') >= 0) {
        name = name.replace('1XandOver', '1X & 오버')
        return name;
      }
      if (name.indexOf('XandOver') >= 0) {
        name = name.replace('XandOver', '무 & 오버')
      }
      if (name.indexOf('X2andOver') >= 0) {
        name = name.replace('X2andOver', 'X2 & 오버')
      }
      if (name.indexOf('1XandUnder') >= 0) {
        name = name.replace('1XandUnder', '1X & 언더')
      }
      if (name.indexOf('XAndUnder') >= 0) {
        name = name.replace('XAndUnder', '무 & 언더')
      }
      if (name.indexOf('X2andUnder') >= 0) {
        name = name.replace('X2andUnder', 'X2 & 언더')
      }
      if (name.indexOf('ormore') >= 0) {
        name = name.replace('ormore', '골 또는 그이상')
      }
      if (name.indexOf('AnyOther') >= 0) {
        name = name.replace('AnyOther', '기타')
      }
      if (name.indexOf('Team1') >= 0){
        name = name.replace('Team1', this.game.homeTeam.nameKr || this.game.homeTeam.nameEn)
      }
      if (name.indexOf('Team2') >= 0){
        name = name.replace('Team2', this.game.awayTeam.nameKr || this.game.awayTeam.nameEn)
      }
      name = name.replace(':', ' : ')
      return name;
    },
    folderClosed: function(seq, period){
      const data = {
        seq,
        period
      };
      const index = this.closed.findIndex(e => e.seq === seq && e.period === period)
      if (index >= 0) return this.closed.splice(index, 1);
      this.closed.push(data);
    },
    // 스포츠별 피어리드별 타임 체크
    sportPeriodCheck: function(period){
      if (this.game.sportSeq === 212 && Number(period) >= 4) return false;
      return true;
    },
    checkBlockGame: function(game){
      let blockConfig = {};
      if (game.sportSeq === 210) {
        blockConfig = this.blockSetting['football'];
        if (game.subResult.indexOf('HT') >= 0) return false;
        if (Number(game.currentPeriod) === 1 && 45 - Number(game.currentTime) <= Number(blockConfig.first)) {
          const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 2 && 90 - Number(game.currentTime) <= Number(blockConfig.second)) {
          const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
          return true;
        }
      }
      if (game.sportSeq === 211) {
        blockConfig = this.blockSetting['hockey'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (game.subResult.indexOf('20:00') >= 0) return false;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
          return true;
        }
      }
      if (game.sportSeq === 212) {
        blockConfig = this.blockSetting['basketball'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (game.subResult.indexOf('12:00') >= 0) return false;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) >= 4) {
          const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
          const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
          return true;
        }
      }
      if (game.sportSeq === 214) {
        blockConfig = this.blockSetting['volleyball'];
        const name = `set${game.currentPeriod}Score`;
        const setHomeScore = game[name] ? game[name].split(':')[0] : 0;
        const setAwayScore = game[name] ? game[name].split(':')[1] : 0;
        if (Number(game.currentPeriod) === 1){
          if (Number(setHomeScore) >= Number(blockConfig.first) - 1) {
            const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.first) - 1) {
            const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
            return true;
          }
        }

        if (Number(game.currentPeriod) === 2){
          if (Number(setHomeScore) >= Number(blockConfig.second) - 1) {
            const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.second) - 1) {
            const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
            return true;
          }
        }

        if (Number(game.currentPeriod) === 3){
          if (Number(setHomeScore) >= Number(blockConfig.third) - 1) {
            const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.third) - 1) {
            const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
            return true;
          }
        }

        if (Number(game.currentPeriod) === 4){
          if (Number(setHomeScore) >= Number(blockConfig.four) - 1) {
            const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.four) - 1) {
            const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
            return true;
          }
        }

        if (Number(game.currentPeriod) === 5){
          if (Number(setHomeScore) >= Number(blockConfig.five) - 1) {
            const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.five) - 1) {
            const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
            return true;
          }
        }

      }
      if (game.sportSeq === 215) {
        blockConfig = this.blockSetting['americanFootball'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
          const cartIndex = this.$store.state.cart.sports.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.cart.sports.splice(cartIndex, 1);
          return true;
        }
      }
      return false;
    },
    matchStats2: function(data){
      const results = JSON.parse(data);
      for (const r in results) {
        const result = results[r];
        if (String(result.EventId) === String(this.game.id)) {
          const score = result.Score;
          // console.log('stats', result);f
          this.game.homeResult = score.split(':')[0] || 0;
          this.game.awayResult = score.split(':')[1] || 0;
          if (result.EventType !== 'period' && !result.IsTimeout) this.game.currentPeriod = result.Period;
          if (result.Set1Score) this.game.set1Score = result.Set1Score;
          if (result.Set2Score) this.game.set2Score = result.Set2Score;
          if (result.Set3Score) this.game.set3Score = result.Set3Score;
          if (result.Set4Score) this.game.set4Score = result.Set4Score;
          if (result.Set5Score) this.game.set5Score = result.Set5Score;
          if (result.Set6Score) this.game.set6Score = result.Set6Score;
          if (result.Set7Score) this.game.set7Score = result.Set7Score;
          if (result.Set8Score) this.game.set8Score = result.Set8Score;
          if (result.Set9Score) this.game.set9Score = result.Set9Score;
          // console.log(result.RemainingTime)
          if (result.RemainingTime) this.game.remainTime = result.RemainingTime;

          if (result.Info) this.game.subResult = result.Info
        }
      }
    },
  }
}
</script>

<style scoped>
.score {
  width: 15px;
  margin-left: 5px;
}
</style>
