<template>
  <td class="soprts_in_acc_2 "
      style="width: 25%;"
      :class="{'sel': checkCart(bets)}"
      @click="addCart(bets, folder, game)"
  >
    <div class="sports_v_l_1">
      {{ name }}
    </div>
    <div class="sports_v_r_1">
      <i v-if="up" class="ico-up blink"></i>
      <i v-if="down" class="ico-down blink"></i>
      <span class="font_002 ">
        {{ Number(bets.price).toFixed(2) }}
      </span>
      <i v-if="up || down" class="sel_old ">({{ startPrice }})</i>
    </div>
  </td>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game'],
  data: function(){
    return {
      startPrice: null,
      up: false,
      down: false
    }
  },
  computed: {
    name: function(){
      let name = this.bets.name;
      if (name?.includes('1 And Over')){
        name = name.replace('1 And Over', `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 오버`)
      }
      if (name?.includes('2 And Over')){
        name = name.replace('2 And Over', `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 오버`)
      }
      // if (name?.includes('12andOver') >= 0){
      //   name = name.replace('12andOver', '12 & 오버')
      // }
      if (name?.includes('1 And Under')){
        name = name.replace('1 And Under', `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 언더`)
      }
      if (name?.includes('2 And Under')){
        name = name.replace('2 And Under', `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 언더`)
      }
      // if (name?.includes('12andUnder')){
      //   name = name.replace('12andUnder', '12 & 언더')
      // }
      //
      // if (name?.includes('1XandOver')){
      //   name = name.replace('1XandOver', '1X & 오버')
      //   return name;
      // }
      if (name?.includes('X And Over')){
        name = name.replace('X And Over', '무 & 오버')
      }
      // if (name?.includes('X2andOver')){
      //   name = name.replace('X2andOver', 'X2 & 오버')
      // }
      // if (name?.includes('1XandUnder')){
      //   name = name.replace('1XandUnder', '1X & 언더')
      // }
      if (name?.includes('X And Under')){
        name = name.replace('X And Under', '무 & 언더')
      }
      // if (name?.includes('X2andUnder')){
      //   name = name.replace('X2andUnder', 'X2 & 언더')
      // }
      if (this.bets.line){
        name += ` ${this.bets.line}`
      }
      return name;
    }
  },
  watch: {
    price: function(newVal, val){
      if (Number(newVal) - Number(val) < 0) this.down = true;
      if (Number(newVal) - Number(val) > 0) this.up = true;
      if (Number(newVal) !== Number(val)) this.startPrice = val;
    },
    up: function(){
      setTimeout(() => {
        this.up = false
        this.startPrice = null;
      } ,10000)
    },
    down: function(){
      setTimeout(() => {
        this.down = false
        this.startPrice = null;
      } ,10000)
    }
  },
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
