<template>
  <div class="sports-days">
    <ul>
      <li @click="filterDo(row.REG_DT)" :class="{'active': payload.date.indexOf(row.REG_DT) >= 0}"  v-for="(row, index) in date" :key="'europeanDate'+row+index" style="width: 107px;">
        <button v-if="index === 0" style="height: 31px !important; width: 107px !important;">오늘경기 <span>{{ row.cnt }}</span></button>
        <button v-else style="height: 31px !important; width: 107px !important;">{{ dateSplit(row.REG_DT) }} <span>{{ row.cnt }}</span></button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Date",
  props: ['dateCnt', 'initFolder', 'payload'],
  computed: {
    date: function(){
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dateCnt.sort((a, b) => {
        if (a.REG_DT > b.REG_DT) return 1;
        else if (b.REG_DT > a.REG_DT) return -1;
        else return 0;
      })
      return this.dateCnt;
    }
  },
  created() {
    if (this.date[0]?.cnt === '0'){
      this.dateCnt.splice(0, 1);
      this.filterDo(this.dateCnt[0].REG_DT)
    }
  },
  methods: {
    dateSplit(date){
      const result = date.slice(3, 8)
      return result
    },
    filterDo: function (date) {
      this.payload.date = '20' + date
      this.initFolder(this.payload)
    },
  },
}
</script>

<style scoped>
.row-detail-list-move {
  transition: transform 1s;
}

.row-detail-list {
  transition: transform 1s;
}
ul::-webkit-scrollbar{
  display:none;
}
</style>
