<template>
  <div class="vs" @click="addCart(bets, folder, game)">
    <div class="moo" :class="{'active': checkCart(bets)}">
      <div class="icos"></div>
      <div class="val">
        <div >
          <span class="ib"></span>
          <span class="orange ib">{{ price }}</span>
          <br >
          <span class="ib"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game', 'team'],
  data: function(){
    return {
      startPrice: null,
      up: false,
      down: false
    }
  },
  computed: {
    type: function(){
      let type = 'home';
      if (this.bets.name === 'W1' || this.bets.name === 'Over' || this.bets.name === 'Home' || this.bets.name === 'Odd') return 'home';
      if (this.bets.name === 'X') return 'draw';
      if (this.bets.name === 'W2' || this.bets.name === 'Under' || this.bets.name === 'Away' || this.bets.name === 'Even') return 'away';
      return type
    },
    price: function(){
      return Number(this.bets.price).toFixed(2)
    },
  },
  watch: {
    price: function(newVal, val){
      if (Number(newVal) - Number(val) < 0) this.down = true;
      if (Number(newVal) - Number(val) > 0) this.up = true;
      if (Number(newVal) !== Number(val)) this.startPrice = val;
    },
    up: function(){
      this.down = false;
      setTimeout(() => {
        this.up = false
        this.startPrice = null;
      } ,10000)
    },
    down: function(){
      this.up = false;
      setTimeout(() => {
        this.down = false
        this.startPrice = null;
      } ,10000)
    }
  },
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
.active span{
  color: #FFFFFF !important;
}
</style>
