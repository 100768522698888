<template>
  <div>
    <div class="header2">
      <div class="left">
        <router-link to="/index" class="btn-prev"></router-link>
      </div>
      <h2>슬롯게임</h2>
    </div>
    <div id="contents_wrap">
      <img src="@/assets/img/etc/flechas-arrow.gif" class="swipe_arrow">
      <Swiper :options="swiperOption" class="swiper-horizontal swiper-wrapper">
        <!-- <Swiper-slide class="swiper-slide swipe_tab" >
          <span @click="changeCategory('hot')" class="swiper-slide-bookmark" :class="{'swiper-slide-active': selectedCategoryName === 'hot'}">인기게임</span>
        </Swiper-slide> -->
        <Swiper-slide class="swiper-slide swipe_tab" v-for="(slotGameCompany, index) in slotGameList" :key="index+'slotGameCategory'">
          <span @click="changeCategory(slotGameCompany.name)" :class="{'swiper-slide-active': selectedCategoryName === slotGameCompany.name}">{{slotGameCompany.name}}</span>
        </Swiper-slide>
      </Swiper>
    <!-- <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span> -->
      <div class="contents">
        <template v-if="selectedCategoryName === 'hot'">
          <div v-if="hotList.length > 0">
            <template v-for="(slotGame, index) in hotList">
              <div v-if="index < 9 || extention" class="game_item" align="center"  :key="'slotGame' + index" @click="openCasino(slotGame.thirdpartycode, slotGame.code)">
                <center>
                  <table border="0" cellpadding="0" cellspacing="0" width="95%" class="game_table">
                    <tbody>
                      <tr>
                        <td colspan="2" style="background-color: black">
                          <a>
                            <img class="casino-image" :src="slotGame.img" style="width: 100%" width="114" height="114"/>
                          </a>
                        </td>
                      </tr>
                      <tr style="height: 35px">
                        <td style="width: 100%" class="short_string">{{slotGame.name}}</td>
                      </tr>
                    </tbody>
                  </table>
                </center>
              </div>
            </template>
          </div>
          <table v-else class="example_table_1 " cellpadding="0" cellspacing="0" >
            <tbody>
              <tr>
                <td width="100%" align="center" style="padding:15px 0 15px 0">
                  이용가능한 게임이 없습니다.
                </td>
              </tr>
            </tbody>
          </table>
          <div id="BTN_MORE" style="width: 100%" v-if="!extention && hotList.length > 9">
            <center>
              <a href="javascript:click_more()">
                <table cellpadding="0" cellspacing="0" width="95%" style="background-color: #111111; border-color: #444444; border-style: solid; border-width: 1px; height: 40px;">
                  <tbody>
                    <tr>
                      <td style="text-align: center; color: rgb(1, 174, 240)" @click="extendList"> 더 보기</td>
                    </tr>
                  </tbody>
                </table>
              </a>
            </center>
          </div>
        </template>
        <template v-else>
          <div v-if="selectedCategory.gameList.length > 0">
            <template v-for="(slotGame, index) in selectedCategory.gameList">
              <div v-if="index < 9 || extention" class="game_item" align="center"  :key="'slotGame' + index" @click="openCasino(selectedCategory.code, slotGame.code)">
                <center>
                  <table border="0" cellpadding="0" cellspacing="0" width="95%" class="game_table">
                    <tbody>
                      <tr>
                        <td colspan="2" style="background-color: black">
                          <a>
                            <img class="casino-image" :src="slotGame.img_1" style="width: 100%" width="114" height="114"/>
                          </a>
                        </td>
                      </tr>
                      <tr style="height: 35px">
                        <td style="width: 100%" class="short_string">{{slotGame.name_kor}}</td>
                        <!-- <td style="padding-right: 5px; text-align: right">
                          <a href="javascript:click_favor('EVOLUTION',  'zaidasfortune000')">
                            <img name="FAVOR" state="OFF" id="FAVOR_zaidasfortune000" src="/design/images/icon_bookmark.png"/>
                          </a>
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </center>
              </div>
            </template>
          </div>
          <table v-else class="example_table_1 " cellpadding="0" cellspacing="0" >
            <tbody>
              <tr>
                <td width="100%" align="center" style="padding:15px 0 15px 0">
                  이용가능한 게임이 없습니다.
                </td>
              </tr>
            </tbody>
          </table>
          <table width="100%" height="20">
            <tbody>
              <tr>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
          <div id="BTN_MORE" style="width: 100%" v-if="!extention && selectedCategory.gameList.length > 9">
            <center>
              <a href="javascript:click_more()">
                <table cellpadding="0" cellspacing="0" width="95%" style="background-color: #111111; border-color: #444444; border-style: solid; border-width: 1px; height: 40px;">
                  <tbody>
                    <tr>
                      <td style="text-align: center; color: rgb(1, 174, 240)" @click="extendList"> 더 보기</td>
                    </tr>
                  </tbody>
                </table>
              </a>
            </center>
          </div>
        </template>

        <table width="100%" height="20">
          <tbody>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapState } from "vuex";
import MSG from "../../../contants/msg";
export default {
  name: "Main",
  components: {
    Swiper,
    SwiperSlide,
  },
  data: function () {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      slotGameList: [],
      selectedCategoryName: null,
      selectedCategory: null,
      extention: false,
      hotList: [],
      bookMarkedGameList: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    menuVisible(){
      return this.$store.getters['MENU_VISIBLE'];
    },
  },
  mounted() {
    if (!this.menuVisible.find(e => e.name === '슬롯게임').visible){
      return this.$store.dispatch('SHOW_ERR_MSG3', '슬롯게임은 점검중입니다.');
    }
    // this.checkMemberType();
    this.goToTop();
    this.reqData();
  },
  methods: {
    reqData: function () {
      this.$store
        .dispatch("GET_SLOT", { payload: { sitename: "viking" } })
        .then((res) => {
          const data = res.data.payload.data;
          console.log('데이터', data)
          // this.hotList = data.hot
          this.slotGameList = data.slot;
          // 나중에 풀어야함
          this.changeCategory(this.slotGameList[0].name)

        });
    },
    changeCategory: function (category) {
      this.selectedCategoryName = category
      this.selectedCategory = this.slotGameList.find(slotGame=> slotGame.name === category);
      this.extention = false;
      console.log("카테고리 변경", this.selectedCategory);
    },

    openCasino: function (lobbyId, gameId) {
      console.log('게임사 아이디', lobbyId)
      console.log('rpdla 아이디', gameId)
      console.log(this.$store.state.page.isMobile ? "MOBILE" : "PC")
      if (!this.user) return alert(MSG.isNotLogin);
      const config = this.user.members_detail_setting;
      // this.$store.dispatch('CHECK_CASINO_WALLET');
      if (gameId === "evolution" && !config.베팅권한.에볼루션.active) {
        this.$store.dispatch("SET_ERR_MSG", "에볼루션은 이용하실 수 없습니다.");
        this.$store.dispatch("SHOW_MODAL", "error2");
        return;
      }
      if (gameId === "asia" && !config.베팅권한.아시아게이밍.active) {
        this.$store.dispatch(
          "SET_ERR_MSG",
          "아시아게이밍은 이용하실 수 없습니다."
        );
        this.$store.dispatch("SHOW_MODAL", "error2");
        return;
      }
      const data = {
        payload: {
          lobbyId,
          gameId,
          platform: this.$store.state.page.isMobile ? "MOBILE" : "PC",
        },
      };
      this.$store
        .dispatch("GET_GAME", data)
        .then((data) => {
          const result = data.data;
          console.log("리절트", result);
          if (result.success) {
            window.open(result.payload.url, "Casino", "_blank");
          } else {
            alert(result.payload.msg);
          }
        })
        .catch((err) => {
          alert(err.response.data.error);
        });
    },
    movePage: function (page) {
      this.$router.push({ path: `/${page}` });
    },
    goToTop: function () {
      window.scrollTo(0, 0);
    },
    extendList: function () {
      this.extention = true
    },
    checkMemberType: function () {
      if(this.user.members_type === '정산제외회원') return
      else {
        alert('카지노는 점검 중입니다.')
        this.$router.push({ path: '/main' })
      }
    }
  },
};
</script>

<style scoped>
.swiper-horizontal {
    touch-action: pan-y;
    background-color:black;
    font-size: 15px;
    color: #ffffff;
    margin: 0 0 0 0;
    font-family: Nanum Gothic, 굴림, 돋음, Dotum, Arial, Helvetica, sans-serif;
}
.swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
    transform: translate3d(0px,0,0);
}
.swiper-wrapper {
    position: relative;
    /* width: 100%; */
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function,initial);
    box-sizing: content-box;
}
.swiper-slide {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-align: center;
    width: 93.75px;
    color: rgb(128, 128, 128);
}
.swiper-slide {
    flex-shrink: 0;
    height: 100%;
    position: relative;
    transition-property: transform;
    display: block;
}
.swiper-slide-bookmark {
    color: yellowgreen;
}
.swiper-slide-active {
    color: #a97bf5;
}
.swipe_tab {
    height: 30px;
    vertical-align: middle;
    padding-top: 10px;
    font-size: 14px;
    font-weight: bold;
}
.swipe_arrow {
    height:8px;
    position: absolute;
    z-index: 10;
    margin-left: 95%;
    opacity: 0.3;
}
.contents {
    padding: 5px;
    margin: 0px auto;
}
.game_item {
    width: 33.33333%;
    float: left;
    padding: 5px 0px 5px 0px;
    text-align: center;
    align-items: center;
    overflow: hidden;
}
.casino-image {
  object-fit: cover;
}
.game_table {
    border-color: #444444;
    border-style: solid;
    border-width: 1px;
}
.short_string {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #CCCCCC;
    font-size: 9pt;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 10px;
}
</style>
