<template>
  <div class="game-side">
    <div class="list">
      <a @click="movePage('token/roulette')" :class="{'active': gType ==='roulette'}">
        <strong>룰렛</strong>
      </a>
      <a @click="movePage('token/highlow')" :class="{'active': gType ==='hilo'}">
        <strong>하이로우</strong>
      </a>
      <a @click="movePage('token/highlow5s')" :class="{'active': gType ==='hilo_5s'}">
        <strong>하이로우5초</strong>
      </a>
<!--      <a :class="{'active': gType ==='graph'}">-->
<!--        <strong>그래프</strong>-->
<!--      </a>-->
      <a @click="movePage('token/baccarat')" :class="{'active': gType ==='baccarat'}">
        <strong>바카라</strong>
      </a>
<!--      <a :class="{'active': gType ==='horse'}">-->
<!--        <strong>가상경마</strong>-->
<!--      </a>-->
<!--      <a :class="{'active': gType ==='keno'}">-->
<!--        <strong>월드키노</strong>-->
<!--      </a>-->
<!--      <a :class="{'active': gType ==='fw'}">-->
<!--        <strong>토큰휠</strong>-->
<!--      </a>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "SideRight",
  props: ['gType'],
  methods: {
    movePage: function(route){
      this.$router.push({ path: '/' + route})
    }
  }
}
</script>

<style scoped>

</style>
