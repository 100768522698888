<template>
  <div class="side">
    <div class="search">
      <input type="text" class="inp" placeholder="게임찾기" />
      <button class="btn-srch"></button>
    </div>
    <ul class="nav">
      <li><router-link to="/casino/live" :class="{'active': gType ==='live'}">라이브 카지노</router-link></li>
      <li><router-link to="/casino/slot" :class="{'active': gType ==='slot'}">슬롯 게임</router-link></li>
    </ul>
  </div>

      <!-- <div class="game-side">
    <div class="list">
      <a @click="movePage('casino/evolution')" :class="{'active': gType ==='evolution'}">
        <strong>에볼루션</strong>
      </a>
      <a @click="movePage('casino/asiagaming')" :class="{'active': gType ==='agcasino'}">
        <strong>아시안게이밍</strong>
      </a>
      <a @click="movePage('casino/sexygaming')" :class="{'active': gType ==='sexygaming'}">
        <strong>섹시게이밍</strong>
      </a>
    </div>
  </div> -->
</template>

<script>
export default {
  name: "SideRight",
  props: ['gType'],
  mounted () {
    console.log(this.gType)
  },
  methods: {
    movePage: function(route){
      this.$router.push({ path: '/' + route})
    }
  }
}
</script>

<style scoped>

</style>
