import axios from "../../services/axios";
import AuthenticationService from "../../services/authentication.service";
import moment from "moment-timezone";
// import * as Sentry from "@sentry/vue";

const getDefaultState = () => {
  return {
    authToken: localStorage.authToken,
    // isLoggedIn: !!localStorage.authToken,
    user: null,
    isUserLoaded: false,
    balanceHistory: [],
    depositHistory: [],
    withdrawalHistory: [],
    bettingHistory: [],
    process: null,
  };
};

const state = getDefaultState();

const getters = {};

const actions = {
  LOGIN({ commit }, params) {
    params.sitename = "luna";
    return axios.post("/auth/login", params).then(
      (response) => {
        if (response.data?.success) {
          commit("LOGIN", response.data.payload.token.jwt_token);
          commit("ME", response.data.payload.members);
        }
        return response;
      }
    );
  },

  LOGOUT({ commit }) {
    commit("LOGOUT");
  },

  ERROR_LOG(_, params) {
    return axios.post("/auth/error", params)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.error);
      });
  },

  ME({commit}) {
    return AuthenticationService.ME('/auth/info/new')
      .then(
        response => {
          // commit('LOGIN', response.data.data.authToken)
          commit('ME', response.data.payload.member)
          return response
        },
        error => {
          // if (error.response.status === 401) return commit('LOGOUT');
          return Promise.reject(error);
        }
      )
  },

  SIGNUP(_, params) {
    const requestParam = {
      userId: params.userId,
      userPw: params.userPw,
      nickname: params.nickname,
      phone: params.contact,
      code: params.code,
      referrer: params.referrer,
      cashoutPw: params.cashoutPw,
      owner: params.bankDepositor,
      account: params.bankAccount,
      bankName: params.bankName,
      sitename: "luna",
    };
    return axios.post("/auth/signup/", {
      payload: requestParam,
    });
  },

  CHECK_CODE(_, data) {
    return axios.post("/auth/checkCode/", data).then(
      (response) => {
        return response;
      }
    );
  },

  CHARGE(_, params) {
    const requestParam = {
      amount: params.amount,
      bonus_rate: params.bonusRate,
      rolling: params.isRolling,
    };
    return axios.post("/payment/charge/", requestParam);
  },

  EXCHANGE(_, params) {
    const requestParam = {
      amount: params.amount,
      exchange_password: params.exchangePassword,
    };
    return axios.post("/payment/exchange/", requestParam);
  },

  // eslint-disable-next-line no-unused-vars
  REQ_ACCOUNT({commit}, params) {
    return axios.post("/balance/account_request", params).then(
      (data) => {
        return data;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  // eslint-disable-next-line no-unused-vars
  DEPOSIT_HISTORY({ commit }, params) {
    return axios.post("/balance/list", params).then(
      (response) => {
        commit("SAVE_DEPOSIT_HISTORY", response.data.payload.list);
        return response;
      },
      (error) => {
        // if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  // eslint-disable-next-line no-unused-vars
  WITHDRAWAL_HISTORY({ commit }, params) {
    return axios.post("/balance/list", params).then(
      (response) => {
        commit("SAVE_WITHDRAWAL_HISTORY", response.data.payload.list);
        return response;
      },
      (error) => {
        // if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  WITHDRAWAL_HISTORY2({ commit }, params) {
    return axios
      .post2("/balance/list", params)
      .then((response) => {
        console.log(response)
        commit("SAVE_WITHDRAWAL_HISTORY", response.payload.list);
        return response;
      },
    );
  },

  // eslint-disable-next-line no-unused-vars
  BALANCE_HISTORY({ commit }, params) {
    return axios.post("/balance/list", params).then(
      (response) => {
        commit("SAVE_BALANCE_HISTORY", response.data.payload.list);
        return response;
      },
      (error) => {
        // if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  // eslint-disable-next-line no-unused-vars
  PROCESS_DEPOSIT({ commit }, params) {
    return axios.post("/balance/deposit/process", params).then(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  // eslint-disable-next-line no-unused-vars
  PROCESS_WITHDRAW({ commit }, params) {
    return axios.post(
      "/balance/withdrawal/process",
      params
    ).then(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  PROCESS_POINT_CHANGE({ commit }, params) {
    return axios.post("/balance/point/change", params).then(
      (response) => {
        commit("ME", response.data.payload.member);
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  PROCESS_POINT_CHANGE_AMOUNT({ commit }, params) {
    return axios.post(
      "/balance/point/change/amount",
      params
    ).then(
      (response) => {
      commit("ME", response.data.payload.member);
      return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  PROCESS_SUPPORT({ commit }, params) {
    return axios.post("/board/customer/create", params).then(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  GET_SUPPORT_LIST(_, params) {
    return axios.post("/board/customer/list", params).then(
      (response) => {
        return response;
      },
      (error) => {
        // if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  GET_ACCOUNT_REQ() {
    const params = {
      payload: {
        title: "계좌 문의",
        content: "계좌 문의 드립니다.",
      },
    };
    return axios.post("/board/customer/create", params).then(
      (response) => {
        return response;
      },
      (error) => {
        // if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  GET_CHAT_LIST(_, params) {
    return axios.post("/chat/list", params).then(
      (response) => {
        return response;
      },
      (error) => {
        // if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },
  GET_CHAT_DETAIL(_, params) {
    return axios.post("/chat/detail", params).then(
      (response) => {
        return response;
      },
      (error) => {
        // if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  GET_RECOMMENDER(_, params) {
    return axios.post("/auth/child", params).then(
      (response) => {
        return response;
      },
      (error) => {
        // if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },
  PROCESS_CHAT_NEW(_, params) {
    return axios.post("/chat/new", params).then(
      (response) => {
        return response;
      },
      (error) => {
        // if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },
  PROCESS_CHAT(_, params) {
    return axios.post("/chat", params).then(
      (response) => {
        return response;
      },
      (error) => {
        // if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },
  GET_NOTES(_, params) {
    return axios.post("/notes/list", params).then(
      (response) => {
        return response;
      },
      (error) => {
        // if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },
  READ_NOTES({ commit }, params) {
    return axios.put("/notes/read", params).then(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },
  DELETE_NOTES({ commit }, params) {
    return axios.post("/notes/delete", params).then(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  DELETE_ALL_NOTES(_, params) {
    return axios.post("/notes/delete/all", params).then(
      (response) => {
        return response;
      },
      (error) => {
        // if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  READ_ALL_NOTES(_, params) {
    return axios.post("/notes/read/all", params).then(
      (response) => {
        return response;
      }
    );
  },

  // eslint-disable-next-line no-unused-vars
  BETTING_HISTORY({ commit }, params) {
    return axios.post("/betting/results", params).then(
      (response) => {
        commit("SAVE_BETTING_HISTORY", response.data.payload.list);
        return response;
      },
      (error) => {
        // if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },
  CANCEL_BETTING({ commit }, params) {
    return axios.post("/betting/cancel", params).then(
      (response) => {
        commit("SAVE_BETTING_HISTORY", response.data.payload.list);
        return response;
      }
    );
  },

  GET_BOARD(_, params) {
    return axios.post("/board/list/all", params).then(
      (response) => {
        return response;
      }
    );
  },
  PROCESS_EUROPEAN_BETTING({ commit, state }) {
    const data = state.process;
    const bettings = data.bettings;
    const startedGame = bettings
      .filter((e) => e.betType !== "보너스")
      .filter(
        (e) =>
          e.startTime.replace("T", " ") <
          moment()
            .tz("Asia/Seoul")
            .add(16, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
      );
    if (startedGame.length > 0) {
      return alert("이미 시작한 경기가 있습니다.");
    }
    return axios.post("/betting/european/process", data).then(
      (response) => {
        commit("CLEAR_PROCESS_DATA");
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },
  PROCESS_EUROPEAN_BETTING_SINGLE({ commit, state }) {
    const data = state.process;
    const bettings = data.bettings;
    const startedGame = bettings
      .filter((e) => e.betType !== "보너스")
      .filter(
        (e) =>
          e.startTime.replace("T", " ") <
          moment()
            .tz("Asia/Seoul")
            .add(16, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
      );
    if (startedGame.length > 0) {
      return alert("이미 시작한 경기가 있습니다.");
    }
    return axios.post("/betting/single/process", data).then(
      (response) => {
        commit("CLEAR_PROCESS_DATA");
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },
  PROCESS_KOREAN_LSPORT_BETTING({ commit, state }) {
    const data = state.process;
    const bettings = data.bettings;
    const startedGame = bettings
      .filter((e) => e.betType !== "보너스")
      .filter(
        (e) =>
          e.startTime.replace("T", " ") <
          moment()
            .tz("Asia/Seoul")
            .add(16, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
      );
    if (startedGame.length > 0) {
      return alert("이미 시작한 경기가 있습니다.");
    }
    return axios.post("/betting/lsport/process", data).then(
      (response) => {
        commit("CLEAR_PROCESS_DATA");
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },
  PROCESS_KOREAN_LSPORT_BETTING_SINGLE({ commit, state }) {
    const data = state.process;
    const bettings = data.bettings;
    const startedGame = bettings
      .filter((e) => e.betType !== "보너스")
      .filter(
        (e) =>
          e.startTime.replace("T", " ") <
          moment()
            .tz("Asia/Seoul")
            .add(16, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
      );
    if (startedGame.length > 0) {
      return alert("이미 시작한 경기가 있습니다.");
    }
    return axios.post(
      "/betting/lsport/single/process",
      data
    ).then(
      (response) => {
        commit("CLEAR_PROCESS_DATA");
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  PROCESS_EUROPEAN_LSPORT_BETTING({ commit, state }) {
    const data = state.process;
    const bettings = data.bettings;
    const startedGame = bettings
      .filter((e) => e.betType !== "보너스")
      .filter(
        (e) =>
          e.startTime.replace("T", " ") <
          moment()
            .tz("Asia/Seoul")
            .add(16, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
      );
    if (startedGame.length > 0) {
      return alert("이미 시작한 경기가 있습니다.");
    }
    return axios.post(
      "/betting/lsport/european/process",
      data
    ).then(
      (response) => {
        commit("CLEAR_PROCESS_DATA");
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  PROCESS_EUROPEAN_LSPORT_BETTING_SINGLE({ commit, state }) {
    const data = state.process;
    const bettings = data.bettings;
    const startedGame = bettings
      .filter((e) => e.betType !== "보너스")
      .filter(
        (e) =>
          e.startTime.replace("T", " ") <
          moment()
            .tz("Asia/Seoul")
            .add(16, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
      );
    if (startedGame.length > 0) {
      return alert("이미 시작한 경기가 있습니다.");
    }
    return axios.post(
      "/betting/lsport/european/single/process",
      data
    ).then(
      (response) => {
        commit("CLEAR_PROCESS_DATA");
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  PROCESS_KOREAN_BETCON_BETTING({ commit, state }) {
    const data = state.process;
    const bettings = data.bettings;
    const startedGame = bettings
      .filter((e) => e.betType !== "보너스")
      .filter(
        (e) =>
          e.startTime.replace("T", " ") <
          moment()
            .tz("Asia/Seoul")
            .add(16, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
      );
    if (startedGame.length > 0) {
      return alert("이미 시작한 경기가 있습니다.");
    }
    return axios.post("/betting/korean/process", data)
      .then(
        (response) => {
          commit("CLEAR_PROCESS_DATA");
          return response;
        },
        (error) => {
          if (error.response.status === 401) return commit('LOGOUT');
          return Promise.reject(error);
        }
      );
  },
  PROCESS_KOREAN_BETCON_BETTING_SINGLE({ commit, state }) {
    const data = state.process;
    const bettings = data.bettings;
    const startedGame = bettings
      .filter((e) => e.betType !== "보너스")
      .filter(
        (e) =>
          e.startTime.replace("T", " ") <
          moment()
            .tz("Asia/Seoul")
            .add(16, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
      );
    if (startedGame.length > 0) {
      return alert("이미 시작한 경기가 있습니다.");
    }
    return axios.post(
      "/betting/korean/single/process",
      data
    ).then(
      (response) => {
        commit("CLEAR_PROCESS_DATA");
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  PROCESS_INPLAY_BETTING({ commit, state }) {
    const data = state.process;
    return axios.post("/betting/inplay/process", data)
      .then(
        (response) => {
          commit("CLEAR_PROCESS_DATA");
          return response;
        },
        (error) => {
          if (error.response.status === 401) return commit('LOGOUT');
          return Promise.reject(error);
        }
      );
  },

  PROCESS_INPLAY_BETTING_SINGLE({ commit, state }) {
    const data = state.process;
    return axios.post(
      "/betting/inplay/single/process",
      data
    ).then(
      (response) => {
        commit("CLEAR_PROCESS_DATA");
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  PROCESS_INPLAY_DELAY_BETTING({ commit, state }) {
    const data = state.process;
    return axios.post(
      "/betting/inplay/delay/process",
      data
    ).then((response) => {
      commit("CLEAR_PROCESS_DATA");
      return response;
    });
  },

  PROCESS_INPLAY_DELAY_BETTING_SINGLE({ commit, state }) {
    const data = state.process;
    return axios.post(
      "/betting/inplay/delay/single/process",
      data
    ).then((response) => {
      commit("CLEAR_PROCESS_DATA");
      return response;
    });
  },

  // 실시간
  PROCESS_LIVE_BETTING({ commit, state }) {
    const data = state.process;
    const bettings = data.bettings;
    const startedGame = bettings
      .filter((e) => e.betType !== "보너스")
      .filter(
        (e) =>
          e.startTime.replace("T", " ") <
          moment()
            .tz("Asia/Seoul")
            .add(16, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
      );
    if (startedGame.length > 0) {
      return alert("이미 시작한 경기가 있습니다.");
    }
    return axios.post("/betting/live/process", data).then(
      (response) => {
        commit("CLEAR_PROCESS_DATA");
        return response;
      }
    );
  },

  // 실시간 싱글
  PROCESS_LIVE_BETTING_SINGLE({ commit, state }) {
    const data = state.process;
    const bettings = data.bettings;
    const startedGame = bettings
      .filter((e) => e.betType !== "보너스")
      .filter(
        (e) =>
          e.startTime.replace("T", " ") <
          moment()
            .tz("Asia/Seoul")
            .add(16, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
      );
    if (startedGame.length > 0) {
      return alert("이미 시작한 경기가 있습니다.");
    }
    return axios.post(
      "/betting/live/single/process",
      data
    ).then(
      (response) => {
        commit("CLEAR_PROCESS_DATA");
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  PROCESS_LOTUS_BETTING({ commit, state }) {
    const data = state.process;
    const bettings = data.bettings;
    const startedGame = bettings
      .filter((e) => e.betType !== "보너스")
      .filter(
        (e) =>
          e.startTime.replace("T", " ") <
          moment()
            .tz("Asia/Seoul")
            .add(16, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
      );
    if (startedGame.length > 0) {
      return alert("이미 시작한 경기가 있습니다.");
    }
    return axios.post("/betting/lc/process", data)
      .then(
        (response) => {
          commit("CLEAR_PROCESS_DATA");
          return response;
        },
        (error) => {
          if (error.response.status === 401) return commit('LOGOUT');
          return Promise.reject(error);
        }
      );
  },

  PROCESS_MINI_BETTING({ commit, state }) {
    const data = state.process;
    const bettings = data.bettings;
    const startedGame = bettings
      .filter((e) => e.betType !== "보너스")
      .filter(
        (e) =>
          e.startTime.replace("T", " ") <
          moment()
            .tz("Asia/Seoul")
            .add(16, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
      );
    if (startedGame.length > 0) {
      return alert("이미 시작한 경기가 있습니다.");
    }
    return axios.post("/betting/mini/process", data)
      .then(
        (response) => {
          commit("CLEAR_PROCESS_DATA");
          return response;
        },
        (error) => {
          if (error.response.status === 401) return commit('LOGOUT');
          return Promise.reject(error);
        }
      );
  },

  PROCESS_CANCEL_BETTING({commit}, params) {
    return axios.post("/betting/cancel", params).then(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  DELETE_BET_RESULT({commit}, params) {
    return axios.post("/betting/delete", params).then(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  DELETE_ALL_BET_RESULT({commit}, params) {
    return axios.post("/betting/delete/all", params).then(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },

  GET_RESULT(_, params) {
    return axios.post(`/v2/game/result/sports`, params).then(
      (response) => {
        return response;
      }
    );
  },

  GET_RESULT_DETAIL(_, params) {
    return axios.post(`/v2/game/result/detail`, params).then(
      (response) => {
        return response;
      }
    );
  },

  GET_CHILD(_, params) {
    return axios.post(`/auth/child`, params).then(
      (response) => {
        return response;
      }
    );
  },

  GET_POPULAR_GAME(_, params) {
    return axios.post(`/v2/game/popular`, params).then(
      (response) => {
        return response;
      }
    );
  },
  REQ_TOKEN_INFO(_, params) {
    return axios.post(`/token/user`, params).then(
      (response) => {
        return response;
      }
    );
  },
  REQ_TKSPORT_INFO(_, params) {
    return axios.post(`/tk/user`, params).then(
      (response) => {
        return response;
      }
    );
  },
  SET_PROCESS({ commit }, params) {
    commit("SET_PROCESS_DATA", params);
  },
  GET_LOTTO_LIST(_, params) {
    return axios.post(`/events/lotto/list`, params).then(
      (response) => {
        return response;
      }
    );
  },
  PROCESS_LOTTO({commit}, params) {
    return axios.post(`/events/lotto`, params).then(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },
  CHECK_CHULSUK_STATUS(_, params) {
    return axios.post(`/events/chulsuk/status`, params).then(
      (response) => {
        return response;
      }
    );
  },
  PROCESS_CHULSUK({commit}, params) {
    return axios.post(`/events/chulsuk/process`, params).then(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },
  GET_ROULETTE_DATA(_, params) {
    return axios.post(`/events/roulette`, params).then(
      (response) => {
        return response;
      }
    );
  },
  GET_ROULETTE_LIST(_, params) {
    return axios.post(`/events/roulette/list`, params).then(
      (response) => {
        return response;
      }
    );
  },
  CHECK_CASINO_WALLET(_, params) {
    return axios.post(`/casino/v2/check/wallet`, params).then(
      (response) => {
        return response;
      }
    );
  },
  // eslint-disable-next-line no-unused-vars
  PROCESS_CASINO_DEPOSIT({ commit }, params) {
    return axios.post("/casino/v2/deposit", params)
      .then((response) => {
        // commit('SAVE_EXCHANGE_HISTORY', response.data)
        alert("충전이 신청되었습니다.");
        return response;
      })
      .catch((err) => {
        alert(err.response.data.error);
      });
  },

  // eslint-disable-next-line no-unused-vars
  PROCESS_CASINO_WITHDRAW({ commit }, params) {
    return axios.post("/casino/v2/withdrawal", params)
      .then((response) => {
        // commit('SAVE_EXCHANGE_HISTORY', response.data)
        alert("환전이 신청되었습니다.");
        return response;
      })
      .catch((err) => {
        alert(err.response.data.error);
      });
  },
  // eslint-disable-next-line no-unused-vars
  CHECK_DEPOSIT_BONUS_CODE({ commit }, params) {
    return axios.post("/balance/deposit_code", params).then(
        (response) => {
          console.log(response)
          return response;
        },
        (error) => {
          // if (error.response.status === 401) return commit('LOGOUT');
          return Promise.reject(error);
        }
    );
  },
  DELETE_ARTICLE(_, params) {
    return axios.put("/board/delete", params).then(
        (response) => {
          console.log(response)
          return response;
        },
        (error) => {
          // if (error.response.status === 401) return commit('LOGOUT');
          return Promise.reject(error);
        }
    );
  },

};

const mutations = {
  ME(state, payload) {
    state.user = payload;
    localStorage.user = JSON.stringify(state.user);
    state.isUserLoaded = true;
  },
  READ(state, item) {
    state.user.notes[
      state.user.notes.findIndex((note) => note.seq === item.seq)
    ].readAt = moment().tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:SS");
  },
  READ_ALL(state) {
    state.user.notes.map(note => {
      if(note.readAt === null) note.readAt = moment().tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:SS");
    })
  },
  LOGIN(state, payload) {
    state.authToken = payload;
    state.isLoggedIn = true;
    localStorage.authToken = state.authToken;
  },
  LOGOUT(state) {
    state.authToken = null;
    state.isLoggedIn = false;
    state.isUserLoaded = false;
    state.user = null;
    delete localStorage.user;
    delete localStorage.authToken;
  },
  SAVE_BALANCE_HISTORY(state, payload) {
    state.balanceHistory = payload;
  },
  SAVE_DEPOSIT_HISTORY(state, payload) {
    state.depositHistory = payload;
  },
  SAVE_WITHDRAWAL_HISTORY(state, payload) {
    state.withdrawalHistory = payload;
  },
  SAVE_BETTING_HISTORY(state, payload) {
    state.bettingHistory = payload;
  },
  SET_PROCESS_DATA(state, payload) {
    state.process = payload;
  },
  CLEAR_PROCESS_DATA(state) {
    state.process = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
