<template>
  <section class="customer" v-if="!this.$store.state.page.isMobile">
    <div class="cusbox" style="background-color: #1c2021;">
      <div class="nbox game-type">
        <div class="cusRight" style="width: 100%;">
          <div class="board-write" style="background: #252929;">
            <table>
              <colgroup>
                <col style="width:140px">
                <col style="">
              </colgroup>
              <tr>
                <th>제목</th>
                <td><input type="text" class="" v-model="payload.title" style="width: 98%; background-color: #252929; color: white;"></td>
              </tr>
              <tr>
                <th>내용</th>
                <td >
                  <textarea class="textarea" placeholder="내용 입력" v-model="payload.content" style="width: 98%; background-color: #252929; color: white;"></textarea>
                </td>
              </tr>
              <tr>
                <th>베팅내역첨부</th>
                <td>
                  <div class="btnWrap type2">
                    <a @click="openList" class="btnList">베팅내역 추가</a>
                    <a @click="deleteBetting" class="btnList" style="margin-left: 5px;">베팅내역 전체삭제</a>
                  </div>
                  <div class="board-bet" v-if="payload.betting">
                    <table id="tableAll1" cellspacing="0" cellpadding="0" width="100%" class="dataTable no-footer" role="grid" style="width: 100%;">
                      <thead>
                        <tr role="row">
                          <th class="sorting" style="width: 220px;">베팅 NO</th>
                          <th class="sorting_desc" style="width: 120px;" aria-sort="descending">베팅 일시</th>
                          <th class="sorting_asc" style="width: 130px;">베팅 종목</th>
                          <th class="sorting" style="width: 130px;">베팅 결과</th>
                          <th class="sorting" style="width: 168px;">배팅 금액</th>
                          <th class="sorting" style="width: 143px;">배당율</th>
                          <th class="sorting" style="width: 144px;">예상 적중 금액</th>
                          <th class="sorting" style="width: 71px;">폴더</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template>
                        <tr id="tableall1_2802777" role="row" class="odd">
                          <td >{{ payload.betting[0].betting_betcode }}</td>
                          <td  class="sorting_1">{{ payload.betting[0].betting_regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                          <td >{{ payload.betting[0].betting_game_sports_name_kr }} {{ payload.betting.length > 1 ? '외' : '' }}</td>
                          <td >
                            <span class="font_002"
                                  :class="{
                                    'text-yellow': payload.betting[0].betting_total_result === 'exception',
                                    'text-red': payload.betting[0].betting_total_result === 'miss',
                                    'text-green': payload.betting[0].betting_total_result === 'hit',
                                    'text-gray': payload.betting[0].betting_total_result === 'wait',
                                }"
                            >{{ payload.betting[0].betting_total_result | bettingStatus }}</span>
                          </td>
                          <td :class="{'orange--text': payload.betting[0].betting_total_result === 'hit' || payload.betting[0].betting_total_result === 'exception'}">
                            <span class="">{{ payload.betting[0].betting_bet_amount | makeComma }}</span>
                          </td>
                          <td :class="{'orange--text': payload.betting[0].betting_total_result === 'hit' || payload.betting[0].betting_total_result === 'exception'}">
                            <span class="">{{ (Number(payload.betting[0].betting_total_odds) + Number(payload.betting[0].betting_total_odds_penalty)).toFixed(2) }}</span>
                          </td>
                          <td :class="{'orange--text': payload.betting[0].betting_total_result === 'hit' || payload.betting[0].betting_total_result === 'exception'}">
                            <span class="">{{ payload.betting[0].betting_expected_prize | makeComma }}</span>
                          </td>
                          <td >{{ payload.betting.length > 1 ? `${payload.betting.length}폴더` : '단폴더' }}</td>
                        </tr>
                        <transition name="fade">
                          <tr>
                            <td colspan="9">
                              <div class="sub_table">
                                <table id="sub_tableall1_2802725" class="panel-body bet_dd_p" cellpadding="0" cellspacing="0"
                                       :class="{
                                        'acc_list_table_in_01': payload.betting[0].betting_total_result === 'exception',
                                        'acc_list_table_in_02': payload.betting[0].betting_total_result === 'miss',
                                        'acc_list_table_in_04': payload.betting[0].betting_total_result === 'hit',
                                        'acc_list_table_in_03': payload.betting[0].betting_total_result === 'wait',
                                       }"
                                >
                                  <tbody>
                                  <tr>
                                    <td class="acc_list_table_in_t" width="11%">경기일시</td>
                                    <td class="acc_list_table_in_t" width="8%">종목</td>
                                    <td class="acc_list_table_in_t" width="15%">리그</td>
                                    <td class="acc_list_table_in_t" width="18%">팀</td>
                                    <td class="acc_list_table_in_t" width="10%">타입</td>
                                    <td class="acc_list_table_in_t" width="14%">배팅</td>
                                    <td class="acc_list_table_in_t" width="6%">배당율</td>
                                    <td class="acc_list_table_in_t" width="6%">결과</td>
                                  </tr>
                                  <tr v-for="(row2, index2) in payload.betting" :key="'bettingDetail' + index2">
                                    <td class="bet_time">{{ row2.betting_game_starttime | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                                    <td class="bet_event">{{ row2.betting_game_sports_name_kr }}</td>
                                    <td class="bet_name">{{ row2.betting_game_leagues_name_kr }}</td>
                                    <td class="bet_name">{{ row2.betting_game_home_name_kr }} - {{ row2.betting_game_away_name_kr }}</td>
                                    <td class="bet_type">{{ row2.betting_game_markets_name_kr }}</td>
                                    <td class="bet_type">{{ row2.betting_side === 'home' ? row2.betting_game_home_name_kr : row2.betting_game_away_name_kr }} <span v-if="row2.betting_line">({{ row2.betting_line }})</span></td>
                                    <td class="bet_type">{{ payload.betting.length === 1 ? (Number(payload.betting[0].betting_total_odds) + Number(payload.betting[0].betting_total_odds_penalty)).toFixed(2) : row2.betting_odds}}</td>
                                    <td class="beting_in_btn">
                                      <span class="font_002"
                                        :class="{
                                          'text-yellow': row2.betting_result === 'exception',
                                          'text-red': row2.betting_result === 'miss',
                                          'text-green': row2.betting_result === 'hit',
                                          'text-gray': row2.betting_result === 'wait',
                                        }"
                                      > {{ row2.betting_result | bettingStatus }}
                                      </span>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>

                            </td>
                          </tr>
                        </transition>
                      </template>
                      </tbody>
                    </table>

                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btnWrap" v-if="!processing">
            <a @click="processWrite" class="btnList" >글쓰기</a>
            <router-link to="/bbs/c/l" class="btnList" style="margin-left: 5px;">취소</router-link>
          </div>
          <div class="btnWrap" style="text-align: center;" v-else>
            <Loading></Loading>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <BettingAdd v-if="isView" :open-list="openList" :add-betting-list="addBetting"></BettingAdd>
    </transition>
  </section>
  <div v-else style="background-color: #1c2021;">
    <div class="bg-content" style="background: #252929;">

      <div class="header3">
        <h2>고객센터</h2>
        <div class="btns">
          <a @click="processWrite" style="margin-right: 3px;">글쓰기</a>
          <router-link to="/bbs/c/l">취소</router-link>
        </div>
      </div>
      <div class="m-write" style="background: #252929;">
        <div class="td">
          <input type="text" style="background-color: #252929; color: white;" class="inp2" placeholder="제목" v-model="payload.title">
        </div>
        <div class="td">
          <textarea class="textarea" style="background-color: #252929; color: white;" placeholder="내용 입력" v-model="payload.content"></textarea>
        </div>
        <div class="td">
          <div class="btnWrap type2 ">
            <a @click="openList" style="margin-right: 3px;" class="btnList">베팅내역 추가</a>
            <a @click="deleteBetting" class="btnList">베팅내역 전체삭제</a>
          </div>
          <div class="m-game3 mt10" v-if="payload.betting">
            <div class="m_betting_div">
              <div
                  class="total_betting_div"
                  :class="{
                  'bg-y': payload.betting[0].betting_total_result === 'exception',
                  'bg-r': payload.betting[0].betting_total_result === 'miss',
                  'bg-g': payload.betting[0].betting_total_result === 'hit',
                  'bg-gr': payload.betting[0].betting_total_result === 'wait',
                }"
              >
                <table border="0" width="100%">
                  <tr>
                    <td class="text-gray">
                      일시 :
                      <span>{{
                          payload.betting[0].betting_regdatetime | formatDate("YY-MM-DD")
                        }}</span>
                    </td>
                    <td width="20%" rowspan="6">
                    </td>
                  </tr>
                  <tr>
                    <td style="color: rgb(153, 153, 153)">
                      베팅아이디 :
                      <span
                      >{{ payload.betting[0].betting_betcode }} /
                  {{ payload.betting[0].betting_type }}</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      폴더
                      <span class="orange--text">{{ payload.betting.length }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      베팅금액
                      <span class="orange--text">{{
                          payload.betting[0].betting_bet_amount | makeComma
                        }}</span
                      >원
                    </td>
                  </tr>
                  <tr>
                    <td>
                      베당율
                      <span
                          class="orange--text"
                          v-if="payload.betting[0].betting_total_result !== 'hit'"
                      >
                  {{
                          (
                              Number(payload.betting[0].betting_total_odds) +
                              Number(payload.betting[0].betting_total_odds_penalty)
                          ).toFixed(2)
                        }}
                </span>
                      <span class="orange--text" v-else>{{
                          payload.betting[0].betting_payed_odds | makeComma
                        }}</span
                      >배
                    </td>
                  </tr>
                  <tr>
                    <td>
                      (예상)적중금액
                      <span
                          class="orange--text"
                          v-if="payload.betting[0].betting_total_result !== 'hit'"
                      >{{ payload.betting[0].betting_expected_prize | makeComma }}</span
                      >
                      <span class="orange--text" v-else>{{
                          payload.betting[0].betting_payed_amount | makeComma
                        }}</span
                      >원
                    </td>
                  </tr>
                </table>
              </div>
              <transition name="fade">
                <div style="" >
                  <div
                      class="sub_betting_div"
                      :class="{
                'border-y': row2.betting_result === 'exception',
                'border-r': row2.betting_result === 'miss',
                'border-g': row2.betting_result === 'hit',
                'border-gr': row2.betting_result === 'wait',
              }"
                      v-for="(row2, index2) in payload.betting"
                      :key="'bettingDetail' + index2 + row2.betting_folder"
                  >
                    <table
                        border="0"
                        width="100%"
                        style="color: white; font-size: 14px; padding-left: 10px"
                    >
                      <tr class="text-gray">
                        <td colspan="2" class="pt-2 text-gray">
                          {{
                            row2.betting_game_starttime | formatDate("YY-MM-DD HH:mm")
                          }}
                          |
                          {{
                            row2.betting_game_leagues_name_kr ||
                            row2.betting_game_leagues_name_en
                          }}
                        </td>
                      </tr>
                      <tr class="text-gray">
                        <td class="text-gray">게임아이디: {{ row2.betting_game }}</td>
                        <td
                            class="text-gray text-right pr-3"
                            style="text-align: right; line-height: 1.4"
                        >
                          {{
                            payload.betting.length === 1
                                ? (
                                    Number(payload.betting[0].betting_odds) +
                                    Number(payload.betting[0].betting_total_odds_penalty)
                                ).toFixed(2)
                                : row2.betting_odds
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td style="color: white">
                          {{
                            row2.betting_game_home_name_kr ||
                            row2.betting_game_home_name_en
                          }}
                          <span class="grey--text text-gray"> vs </span>
                          {{
                            row2.betting_game_away_name_kr ||
                            row2.betting_game_away_name_en
                          }}
                        </td>
                        <td
                            class="text-right pr-3"
                            v-if="row2.betting_status.indexOf('취소') < 0"
                            style="text-align: right; line-height: 1.4; color: white"
                        >
                          {{ row2.betting_result | bettingStatus }}
                        </td>
                        <td
                            class="text-right pr-3"
                            style="text-align: right; line-height: 1.4; color: white"
                            v-else
                        >
                          취소
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="pb-2" style="color: white">
                    <span style="color: deepskyblue">{{
                        row2.betting_game_markets_name_kr ||
                        row2.betting_game_markets_name_en
                      }}</span>
                          <span v-if="row2.betting_round"
                          >( {{ row2.betting_round }} )</span
                          >
                          / {{ row2 | betsName2 }}
                        </td>
                      </tr>
                    </table>
                  </div>
<!--                  <div-->
<!--                      class="btnBox02"-->
<!--                      style="margin-top: 10px; margin-bottom: 50px"-->
<!--                      v-if="cancelStatus"-->
<!--                  >-->
<!--                    <template-->
<!--                        v-if="bettingCancelLoading.indexOf(payload.betting[0].betting_betcode) < 0"-->
<!--                    >-->
<!--                      <a-->
<!--                          v-if="cancelStatus(payload.betting)"-->
<!--                          @click="cancelBetting(payload.betting[0].betting_betcode)"-->
<!--                          class="btn05"-->
<!--                          style="width: 100%"-->
<!--                      >베팅 취소</a-->
<!--                      >-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                      <Loading></Loading>-->
<!--                    </template>-->
<!--                  </div>-->
                </div>
              </transition>
            </div>
            <!--            <div class="title" style="margin: 5px 0px;">-->
            <!--              <div class="left">-->
            <!--                <div class="subj">베팅결과 : <strong class="yellow2">{{ payload.betting[0].betting_total_result | bettingStatus }}</strong></div>-->
            <!--              </div>-->
            <!--              <div class="right">{{ payload.betting[0].betting_regdatetime | formatDate('MM-DD HH:mm') }}</div>-->
            <!--            </div>-->
            <!--            <template v-for="(row2, index2) in payload.betting" >-->
            <!--              <div :key="'bets'+row2.bets_id+index2">-->
            <!--                <div class="title type2" style="margin: 5px 0px;">-->
            <!--                  <div class="left">-->
            <!--                    <div class="subj">{{ row2.betting_game_leagues_name_kr || row2.betting_game_leagues_name_en }} &nbsp;&nbsp;[{{ row2.betting_score_home }}:{{ row2.betting_score_away }}]</div>-->
            <!--                  </div>-->
            <!--                  <div class="right">{{ row2.betting_game_starttime | formatDate('MM-DD HH:mm') }}</div>-->
            <!--                </div>-->
            <!--                <div class="game-item">-->
            <!--                  <dl :class="{'active': row2.betting_side === 'home'}">-->
            <!--                    <dt>{{ row2.betting_game_home_name_kr }}</dt>-->
            <!--                    <dd><span>{{ row2.betting_odds1 }}</span></dd>-->
            <!--                  </dl>-->
            <!--                  <div class="vs" :class="{'active': row2.betting_side === 'draw'}" v-if="row2.betting_odds2">{{ row2.betting_odds2 }}</div>-->
            <!--                  <div class="vs" v-else-if="row2.betting_line">{{ row2.betting_line }}</div>-->
            <!--                  <div class="vs" v-else>VS</div>-->
            <!--                  <dl :class="{'active': row2.betting_side === 'away'}">-->
            <!--                    <dt>{{ row2.betting_game_away_name_kr }}</dt>-->
            <!--                    <dd><span>{{ row2.betting_odds3 }}</span></dd>-->
            <!--                  </dl>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </template>-->
            <!--            <div class="bet-result">-->
            <!--              <dl>-->
            <!--                <dt>베팅금액</dt>-->
            <!--                <dd>{{ payload.betting[0].betting_bet_amount | makeComma }}원</dd>-->
            <!--              </dl>-->
            <!--              <dl>-->
            <!--                <dt>배당률</dt>-->
            <!--                <dd>{{ (Number(payload.betting[0].betting_total_odds) + Number(payload.betting[0].betting_total_odds_penalty)).toFixed(2) }}</dd>-->
            <!--              </dl>-->
            <!--              <dl>-->
            <!--                <dt>당첨예상금</dt>-->
            <!--                <dd>{{ payload.betting[0].betting_expected_prize | makeComma }}원</dd>-->
            <!--              </dl>-->
            <!--              <dl>-->
            <!--                <dt>당첨금</dt>-->
            <!--                <dd>{{ payload.betting[0].betting_payed_amount | makeComma }}원</dd>-->
            <!--              </dl>-->
            <!--            </div>-->
            <div class="btns">
              <a @click="deleteBetting" class="b1">첨부삭제</a>
            </div>

          </div>

        </div>
      </div>
    </div>
    <transition name="fade">
      <MBettingAdd v-if="isView" :open-list="openList" :add-betting-list="addBetting"></MBettingAdd>
    </transition>
  </div>
</template>

<script>
import BettingAdd from '../../../components/Board/BettingAdd'
import MBettingAdd from '../../../components/Board/MBettingAdd'
import Loading  from '../../../components/Loading'

export default {
  name: "Write",
  components: {
    BettingAdd,
    MBettingAdd,
    Loading
  },
  data: function(){
    return {
      isView: false,
      processing: false,
      payload: {
        title: null,
        content: null,
        betting: null,
      },
    }
  },
  methods: {
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    processWrite: function(){
      if (this.processing) return alert('등록중 입니다.')
      if (!this.payload.title || this.payload.title === '' || this.payload.content === '' || !this.payload.content) return alert('제목과 내용을 확인해주세요.')
      this.processing = true;
      return this.$store.dispatch('PROCESS_SUPPORT', { payload: this.payload })
          .then(data => {
            const result = data.data;
            alert(result.msg);
            this.$router.push({ path: 'bbs/c/l' })
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    openList: function(){
      this.isView = !this.isView;
    },
    addBetting: function(betting){
      this.payload.betting = betting;
      this.openList();
    },
    deleteBetting: function(){
      this.payload.betting = null;
    },
  }
}
</script>

<style scoped>
.not_scroll{
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%
}
.not_scroll {
  position: relative;
  top: 0;
}
</style>
