<template>
  <td class="soprts_in_acc_2 "
      :style="[width === 2 ? {'width': '50%'} : {'width': '33%'}]"
  >
    <div class="sports_v_l_1">
      {{ teamName }}
    </div>
    <div class="sports_v_r_1">
      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
    </div>
  </td>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game', 'team', 'width'],
  data: function(){
    return {
      startPrice: null,
      up: false,
      down: false
    }
  },
  computed: {
    type: function(){
      let type = 'home';
      if (this.bets.bets_name === 'W1' || this.bets.bets_name === 'Over' || this.bets.bets_name === 'Odd' || this.bets.bets_name === 'Yes') return 'home';
      if (this.bets.bets_name === 'X') return 'draw';
      if (this.bets.bets_name === 'W2' || this.bets.bets_name === 'Under' || this.bets.bets_name === 'Even' || this.bets.bets_name === 'No') return 'away';
      if (this.bets.bets_name === '1X') return '1팀승 또는 무승부';
      if (this.bets.bets_name === '12') return '1팀승 또는 2팀승';
      if (this.bets.bets_name === 'X2') return '무승부 또는 2팀승';
      return type
    },
    price: function(){
      return this.bets.price
    },
    teamName: function(){
      let name = this.team.nameKr || this.team.nameEn;
      if (this.bets.name === 'W1') name = '승';
      if (this.bets.name === 'W2') name = '패';
      if (this.bets.name === 'Home') name = `${this.team.nameKr || this.team.nameEn}`;
      if (this.bets.name === 'Away') name = `${this.team.nameKr || this.team.nameEn}`;
      if (this.bets.name === 'Over') return `오버 ${this.bets.line}` ;
      if (this.bets.name === 'Under') return `언더 ${this.bets.line}`;
      if (this.bets.name === 'X') name = '무';
      if (this.bets.name === '1X') name = '1팀승 또는 무승부';
      if (this.bets.name === '12') name = '1팀승 또는 2팀승';
      if (this.bets.name === 'X2') name = '무승부 또는 2팀승';
      if (this.bets.name === 'Odd') name = '홀';
      if (this.bets.name === 'Even') name = '짝';
      if (this.bets.name === 'Yes') name = '예';
      if (this.bets.name === 'No') name = '아니오';
      if (this.bets.name === 'No Goal') name = '노골';
      if (this.folder.market.isHandicap === 'y') name += ` (${this.bets.line})`
      return name;
    },
  },
  watch: {
    price: function(newVal, val){
      if (Number(newVal) - Number(val) < 0) this.down = true;
      if (Number(newVal) - Number(val) > 0) this.up = true;
      if (Number(newVal) !== Number(val)) this.startPrice = val;
    },
    up: function(){
      setTimeout(() => {
        this.up = false
        this.startPrice = null;
      } ,10000)
    },
    down: function(){
      setTimeout(() => {
        this.down = false
        this.startPrice = null;
      } ,10000)
    }
  },
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
