<template>
  <dl class="away">
    <dt>
      {{ team | teamName }}
    </dt>
    <dd>
      <span class="ib">
        <img style="width: 9px; margin-top: -3px;" src="@/assets/img/ico/ico_secret.png">
      </span>
    </dd>
  </dl>
</template>

<script>
export default {
  name: "Price",
  props: ['team'],
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
