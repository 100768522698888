<template>
  <td width="260" valign="top" style="min-width: 260px;">
    <div class="game_left_wrap" v-if="isLoading">
      <div>
        <div style="display: block;" id="myDiv" class="animate-bottom ">
          <div class="left_search_wrap">
            <div class="left_search">
              <form name="sm">
                <input id="pop" class="input_style05" name="k" placeholder="팀 이름" v-model="teamName"
                       @keyup="filterByGames('team', teamName)">
                <span id="popo" class="popover-wrapper">
                  <a href="#" data-role="popover" data-target="example-popover">
                  <img id="spop" src="@/assets/img/ico/search_icon.jpg">
                  </a>
                  <div class="popover-modal example-popover">
                    <div class="popover-body">
                      <div class="example-body-in">
                        <table class="example_table_1 " cellpadding="0" cellspacing="0">
                        <tbody>
                        <tr>
                          <td width="100%" align="center" style="padding:15px 0 15px 0">
                            검색 결과가 없습니다.
                          </td>
                        </tr>
                        </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </span>
              </form>
            </div>
          </div>
          <div class="left_tab_wrap">
            <router-link :to="'/sport/livematch'">
              <div class="left_tab_1">
                <div class="left_tab_l_1 ">
                  실시간
                </div>
                <div class="menu_count_on ">
                  {{ count | makeComma }}
                </div>
              </div>
            </router-link>
            <div class="left_tab_on_1">
              <div class="left_tab_l_1 ">
                스포츠
              </div>
              <div class="menu_count_on ">
                {{ originList.length | makeComma }}
              </div>
            </div>
          </div>
          <table class="example_table_1 " cellpadding="0" cellspacing="0" style="display:none">
            <tbody>
            <tr>
              <td width="100%" align="center" style="padding:15px 0 15px 0">
                현재 진행중인 경기가 없습니다.
              </td>
            </tr>
            </tbody>
          </table>

          <!-- 스포츠 > 국가 > 리그 -->
          <div class="left_menu_wrap " style="">
<!--            <div @click="isViewLink = !isViewLink">-->
<!--              <table class="accordion-header " >-->
<!--                <tbody>-->
<!--                <tr>-->
<!--                  <td width="100%">-->
<!--                    <img style="vertical-align:middle;width:15px;" src="@/assets/img/ico/favor_on.png">&nbsp;-->
<!--                    <span class="font_002">즐겨찾기</span>-->
<!--                  </td>-->
<!--                  <td style="float:right; border-radius:10px;background:#1b1d1e;margin:7px 7px 0 0; line-height:20px; padding: 0 7px 0 7px;">-->
<!--                    <div style="color: #8a8c8d;">-->
<!--                      {{ $store.state.list.gameLink.length }}-->
<!--                    </div>-->
<!--                  </td>-->
<!--                </tr>-->
<!--                </tbody>-->
<!--              </table>-->
<!--            </div>-->
<!--            <transition name="fade" v-if="isViewLink">-->
<!--              <div>-->
<!--                <template v-for="(game, index) in linkedGame">-->
<!--                  <table class="left_menu_in_2" :key="'linkedGame'+index">-->
<!--                    <tbody>-->
<!--                    <tr>-->
<!--                      <td class="left_menu_in_2_l_1">-->
<!--                        <img width="15" :src="game.sport.icon">&nbsp;<span>{{ game.sport | sportName}}</span>-->
<!--                        <a @click="toggleGameLink(game)" class="left_menu_in_1_r_1"><i class="fa fa-star"></i></a>-->
<!--                      </td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                      <td class="left_menu_in_2_l_1"><a href="#"><span>{{ game.league | leagueName }}</span></a></td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                      <td colspan="2" class="left_menu_in_2_l_1">-->
<!--                        <a @click="selectGame(game)">-->
<!--                          <span class="font_002 ">{{ game.homeTeam | teamName }}</span>-->
<!--                          <span class="5c7785">&nbsp; vs &nbsp;</span>-->
<!--                          <span class="font_002 ">{{ game.awayTeam | teamName }}</span>-->
<!--                        </a>-->
<!--                      </td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                      <td colspan="2" class="left_menu_in_2_l_1"><a href="#"><span>{{ game.startTime | formatDate('YYYY-MM-DD HH:mm:ss') }}</span></a></td>-->
<!--                    </tr>-->
<!--                    </tbody>-->
<!--                  </table>-->
<!--                </template>-->
<!--              </div>-->
<!--            </transition>-->
            <template v-for="(sport, sportIndex) in sports">
              <div class="accordion-header"
                   :key="'sports'+sportIndex"
                   :class="{'open': checkSportsArray(sport.id)}"
                   @click="toggleSportsDetail(sport.id)"
              >
                <img width="15" :src="sport.icon">&nbsp;
                <span>{{ sport | sportName }}</span>
                <div class="left_menu_count ">
                  <span>{{ sportsCnt(sport.id) }}</span>
                </div>
              </div>

              <template v-for="location in locations(sport.id)">
                <transition name="fade" :key="'locationRightSort'+ location">
                  <div
                      v-if="checkSportsArray(sport.id)"
                      class="accordion-header"
                      :class="{'open': checkSportsArray(sport.id) && checkLocationsArray(location.id)}"
                      style="display: block;"
                      @click="toggleLocationsDetail(location.id)"
                  >
                    <img width="15" :src="location.icon">&nbsp;
                    <span>{{ location | locationName }}</span>
                    <div class="left_menu_count">
                      <span>{{ locationsCnt(sport.id, location.id) }}</span>
                    </div>
                  </div>
                </transition>
                <template v-for="league in leagues(sport.id, location.id)">
                  <div
                      class="accordion-header3_1"
                      :key="'leaguesDetail'+ location.id + league.id"
                      style="display: block; color: #8a8c8d;"
                      v-if="checkSportsArray(sport.id) && checkLocationsArray(location.id)"
                      @click="filterByGames('league', league.id)"
                  >
                    <img width="15" :src="league.icon">&nbsp;
                    <span>{{ league | leagueName }}</span>
                    <div class="left_menu_count">
                      <span>{{ leaguesCnt(sport.id, location.id, league.id) }}</span>
                    </div>
                  </div>
                </template>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div style="min-width: 260px; text-align: center;" v-else>
<!--      <Loading></Loading>-->
    </div>
  </td>
</template>

<script>
// import Loading from '../../../../components/Loading'

export default {
  name: "RightSide",
  // components: {Loading},
  props: ['filterByGames', 'originList', 'isLoading', 'selectGame'],
  data: function () {
    return {
      teamName: null,
      openSports: [],
      openLocations: [],
      count: 0,
      isViewLink: false,
    }
  },
  computed: {
    sports: function () {
      const sports = this.originList.map(e => e.sport);
      const arrUnique = sports.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.seq === character.seq) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    // linkedGame: function () {
    //   return this.$store.state.list.gameLink;
    // }
  },
  methods: {
    // reqCnt: function () {
    //   return this.$store.dispatch('auth/post', {path: 'v2/game/inplayCnt', data: {payload: this.payload}})
    //       .then(data => {
    //         const result = data.data;
    //         this.count = result.payload.count
    //       }).catch(err => {
    //         alert(err.response.data.error);
    //       })
    // },
    sportsCnt: function (sport) {
      return this.originList.filter(e => e.sportId === sport).length
    },
    leaguesCnt: function (sport, location, league) {
      return this.originList.filter(e => e.sportId === sport && e.locationId === location && e.leagueId === Number(league)).length
    },
    locationsCnt: function (sport, location) {
      return this.originList.filter(e => e.sportId === sport && e.locationId === location).length
    },
    leagues: function (sport, location) {
      const data = this.originList.filter(e => e.sportId === sport && e.locationId === location);
      const leagues = data.map(e => e.league)
      const arrUnique = leagues.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    locations: function (sport) {
      const data = this.originList.filter(e => e.sportId === sport);
      const locations = data.map(e => e.location)
      const arrUnique = locations.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    checkSportsArray: function (id) {
      let sportResult = this.openSports.indexOf(id) >= 0
      return sportResult
    },
    checkLocationsArray: function (id) {
      let locationResult = this.openLocations.indexOf(id) >= 0
      return locationResult
    },
    toggleSportsDetail: function (id) {
      this.sportsToggle(this.openSports, id)
    },
    removeAtSportsIndex: function (arr, index) {
      // const copy = [...arr];
      this.openSports.splice(index, 1);
      // return this.openDetailBet;
    },
    sportsToggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openSports = [...arr, item]
        return;
      }

      return this.removeAtSportsIndex(arr, index);
    },
    toggleLocationsDetail: function (id) {
      this.locationsToggle(this.openLocations, id)
    },
    removeAtLocationsIndex: function (arr, index) {
      // const copy = [...arr];
      this.openLocations.splice(index, 1);
      // return this.openDetailBet;
    },
    locationsToggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openLocations = [...arr, item]
        return;
      }

      return this.removeAtLocationsIndex(arr, index);
    },
    toggleGameLink(game){
      return this.$store.dispatch('list/gameStared', game)
    }
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
