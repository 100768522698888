<template>
  <div>
    <div class="main desktop">
      <h1>
          자유게시판
      </h1>
      <div class="post-info">
          <div class="title">
              [일반] 배팅 내역 첨부 합니다~
          </div>
          <div class="author-info">
              <img alt="author icon" height="20"
                  src="https://oaidalleapiprodscus.blob.core.windows.net/private/org-SteOp6BHgYZbZ5Ct5m8mzzMz/user-42eBDkP1fhGWwdrLRVEiD23u/img-WtxQGu2vUOc8s1CuI5ki58G0.png?st=2024-12-11T05%3A38%3A42Z&amp;se=2024-12-11T07%3A38%3A42Z&amp;sp=r&amp;sv=2024-08-04&amp;sr=b&amp;rscd=inline&amp;rsct=image/png&amp;skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&amp;sktid=a48cca56-e6da-484e-a814-9c849652bcb3&amp;skt=2024-12-10T21%3A41%3A46Z&amp;ske=2024-12-11T21%3A41%3A46Z&amp;sks=b&amp;skv=2024-08-04&amp;sig=mNDd9jRP0in%2Bl1ZZ3f1/QmeJuE7XgFW2iCIRKwRMKvc%3D"
                  width="20"/>
              <span>
        작성자
      </span>
              <span>
        토토공주
      </span>
          </div>
          <div class="post">
              오늘 먹을수 있을까요? ㅠㅠㅠ
          </div>
          <div class="date">
              작성일 2024-11-15 23:01 조회수 5
          </div>
      </div>
      <div class="table-container">
          <table>
              <thead>
              <tr>
                  <th>
                      배팅 NO
                  </th>
                  <th>
                      배팅 일시
                  </th>
                  <th>
                      배팅 종목
                  </th>
                  <th>
                      배팅 결과
                  </th>
                  <th>
                      배팅 금액
                  </th>
                  <th>
                      배당율
                  </th>
                  <th>
                      예상 적중 금액
                  </th>
                  <th>
                      결과
                  </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                  <td>
                      K241119106357376077
                  </td>
                  <td>
                      2024-11-19 16:05:35
                  </td>
                  <td>
                      LOL 외
                  </td>
                  <td>
                      대기
                  </td>
                  <td>
                      5,000
                  </td>
                  <td>
                      10.92
                  </td>
                  <td>
                      54,600
                  </td>
                  <td>
                      2폴더
                  </td>
              </tr>
              <tr>
                  <td colspan="8">
                      <table>
                          <tbody>
                          <tr>
                              <td>
                                  2024-11-19 17:00:00
                              </td>
                              <td>
                                  LOL
                              </td>
                              <td>
                                  Legend Cup
                              </td>
                              <td>
                                  Gank Me - All i Want
                              </td>
                              <td>
                                  단식
                              </td>
                              <td>
                                  배당
                              </td>
                              <td>
                                  Gank Me
                              </td>
                              <td>
                                  1.56
                              </td>
                              <td>
                                  대기
                              </td>
                          </tr>
                          <tr>
                              <td>
                                  2024-11-19 17:30:00
                              </td>
                              <td>
                                  축구
                              </td>
                              <td>
                                  THA FAC
                              </td>
                              <td>
                                  Roi Et United FC - 부리람 유나이티드 FC
                              </td>
                              <td>
                                  승무패
                              </td>
                              <td>
                                  배당
                              </td>
                              <td>
                                  Roi Et United FC
                              </td>
                              <td>
                                  7.00
                              </td>
                              <td>
                                  대기
                              </td>
                          </tr>
                          </tbody>
                      </table>
                  </td>
              </tr>
              </tbody>
          </table>
      </div>
      <div class="buttons">
          <div class="left-buttons">
              <button>
                  수정하기
              </button>
              <button>
                  삭제하기
              </button>
          </div>
          <div class="right-button">
              <button>
                  목록으로
              </button>
          </div>
      </div>
      <div class="comment-section">
          <input placeholder="댓글을 입력하세요" type="text"/>
          <button>
              댓글 등록
          </button>
      </div>
      <div class="comments">
          <div class="comment">
              <div class="user">
                  <img alt="user icon" height="20"
                      src="https://oaidalleapiprodscus.blob.core.windows.net/private/org-SteOp6BHgYZbZ5Ct5m8mzzMz/user-42eBDkP1fhGWwdrLRVEiD23u/img-quzD0PAHh0sL45hjPAWyfoPW.png?st=2024-12-11T05%3A38%3A42Z&amp;se=2024-12-11T07%3A38%3A42Z&amp;sp=r&amp;sv=2024-08-04&amp;sr=b&amp;rscd=inline&amp;rsct=image/png&amp;skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&amp;sktid=a48cca56-e6da-484e-a814-9c849652bcb3&amp;skt=2024-12-10T21%3A31%3A46Z&amp;ske=2024-12-11T21%3A31%3A46Z&amp;sks=b&amp;skv=2024-08-04&amp;sig=7WcxQwcVbeZPgKYVtKPbRUaaBflCmtR81hbsRz3bTfo%3D"
                      width="20"/>
                  <span>
        토토공주
        </span>
                  <span>
        ^^
        </span>
                  <i class="fas fa-times" style="color: red;">
                  </i>
              </div>
              <div class="date">
                  2024-11-15 23:40
              </div>
          </div>
          <div class="comment">
              <div class="user">
                  <img alt="user icon" height="20"
                      src="https://oaidalleapiprodscus.blob.core.windows.net/private/org-SteOp6BHgYZbZ5Ct5m8mzzMz/user-42eBDkP1fhGWwdrLRVEiD23u/img-quzD0PAHh0sL45hjPAWyfoPW.png?st=2024-12-11T05%3A38%3A42Z&amp;se=2024-12-11T07%3A38%3A42Z&amp;sp=r&amp;sv=2024-08-04&amp;sr=b&amp;rscd=inline&amp;rsct=image/png&amp;skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&amp;sktid=a48cca56-e6da-484e-a814-9c849652bcb3&amp;skt=2024-12-10T21%3A31%3A46Z&amp;ske=2024-12-11T21%3A31%3A46Z&amp;sks=b&amp;skv=2024-08-04&amp;sig=7WcxQwcVbeZPgKYVtKPbRUaaBflCmtR81hbsRz3bTfo%3D"
                      width="20"/>
                  <span>
        kingking
        </span>
                  <span>
        건승기원!
        </span>
              </div>
              <div class="date">
                  2024-11-15 23:45
              </div>
          </div>
      </div>
    </div>
    <div class="main mobile">
      <div class="title">
          자유게시판
      </div>
      <div class="post">
          <div class="header">
              <div class="left">
                  <div class="category">
                      일반
                  </div>
                  <div class="title">
                      첫 베팅입니다
                  </div>
                  <div class="bet">
                      BET
                  </div>
              </div>
              <div class="right">
                  <div class="date">
                      <i class="far fa-clock">
                      </i>
                      2024-11-19 11:13
                  </div>
                  <div class="views">
                      <i class="far fa-eye">
                      </i>
                      5
                  </div>
                  <div class="comments">
                      <i class="far fa-comment">
                      </i>
                      2
                  </div>
              </div>
          </div>
          <div class="content">
              첫 베팅입니다
              <br/>
              먹고싶어용
          </div>
          <div class="bet-info">
              <div>
                  일시 : 24-11-22 18:21:29
              </div>
              <div>
                  베팅아이디 : n2411218212963265861 / 프리매치(엘스포츠-유럽형)
              </div>
              <div>
                  폴더 4
              </div>
              <div>
                  베팅금액 5,000원
              </div>
              <div>
                  배당율 4.26배
              </div>
              <div>
                  (예상)적중금액 21,300원
              </div>
              <div class="match">
                  <div class="team">
                      <img alt="산둥 (여) 팀 로고" height="30"
                          src="https://oaidalleapiprodscus.blob.core.windows.net/private/org-SteOp6BHgYZbZ5Ct5m8mzzMz/user-42eBDkP1fhGWwdrLRVEiD23u/img-XIlgQD4p2N0SObgzSltVLQ74.png?st=2024-12-11T05%3A16%3A07Z&amp;se=2024-12-11T07%3A16%3A07Z&amp;sp=r&amp;sv=2024-08-04&amp;sr=b&amp;rscd=inline&amp;rsct=image/png&amp;skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&amp;sktid=a48cca56-e6da-484e-a814-9c849652bcb3&amp;skt=2024-12-10T21%3A29%3A53Z&amp;ske=2024-12-11T21%3A29%3A53Z&amp;sks=b&amp;skv=2024-08-04&amp;sig=yjJclTqdhTuSFHZJ7ldJOdoNDqHe4r2kCNBGxG4yg/Y%3D"
                          width="30"/>
                      산둥 (여)
                  </div>
                  <div>
                      VS
                  </div>
                  <div class="team">
                      <img alt="쓰촨 (여) 팀 로고" src="https://placehold.co/30x30"/>
                      쓰촨 (여)
                  </div>
                  <div class="result">
                      <div>
                          승패_연장포함 / 쓰촨 (여)
                      </div>
                      <div class="icon">
                          <i class="fas fa-minus-circle" style="color: yellow;">
                          </i>
                          적특
                      </div>
                  </div>
              </div>
              <div class="match">
                  <div class="team">
                      <img alt="KB 손해보험 팀 로고" src="https://placehold.co/30x30"/>
                      KB 손해보험
                  </div>
                  <div>
                      VS
                  </div>
                  <div class="team">
                      <img alt="삼성화재 팀 로고" src="https://placehold.co/30x30"/>
                      삼성화재
                  </div>
                  <div class="result">
                      <div>
                          승패 / 삼성화재
                      </div>
                      <div class="icon">
                          <i class="fas fa-check-circle" style="color: green;">
                          </i>
                          당첨
                      </div>
                  </div>
              </div>
              <div class="match">
                  <div class="team">
                      <img alt="부리람 유나이티드 FC 팀 로고" height="30"
                          src="https://oaidalleapiprodscus.blob.core.windows.net/private/org-SteOp6BHgYZbZ5Ct5m8mzzMz/user-42eBDkP1fhGWwdrLRVEiD23u/img-ue9ZVTfCFUaq6HnZTFwfQkbK.png?st=2024-12-11T05%3A15%3A59Z&amp;se=2024-12-11T07%3A15%3A59Z&amp;sp=r&amp;sv=2024-08-04&amp;sr=b&amp;rscd=inline&amp;rsct=image/png&amp;skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&amp;sktid=a48cca56-e6da-484e-a814-9c849652bcb3&amp;skt=2024-12-10T21%3A23%3A22Z&amp;ske=2024-12-11T21%3A23%3A22Z&amp;sks=b&amp;skv=2024-08-04&amp;sig=jP1zOh86Vdst0V3VfCSScTfcwPvo0a/GFGElA3XciM8%3D"
                          width="30"/>
                      부리람 유나이티드 FC
                  </div>
                  <div>
                      VS
                  </div>
                  <div class="team">
                      <img alt="수코타이 FC 팀 로고" src="https://placehold.co/30x30"/>
                      수코타이 FC
                  </div>
                  <div class="result">
                      <div>
                          승패 / 부리람 유나이티드 FC
                      </div>
                      <div class="icon">
                          <i class="fas fa-times-circle" style="color: red;">
                          </i>
                          낙첨
                      </div>
                  </div>
              </div>
          </div>
          <div class="actions">
              <div class="button">
                  목록으로
              </div>
              <div class="button">
                  수정하기
              </div>
              <div class="button" style="background-color: #8b0000;">
                  삭제하기
              </div>
          </div>
      </div>
      <div class="comments">
          <div class="comment">
              <div class="left">
                  <div class="username" style="color: #ffcc00;">
                      vikibet
                  </div>
                  <div class="message">
                      kingking
                  </div>
                  <div class="message">
                      건승을 기원합니다!
                  </div>
              </div>
              <div class="right">
                  <div class="date">
                      <i class="far fa-clock">
                      </i>
                      2024-11-19 11:13
                  </div>
              </div>
          </div>
          <div class="comment">
              <div class="left">
                  <div class="username" style="color: #b19cd9;">
                      logo
                  </div>
                  <div class="message">
                      가나다라마바사
                  </div>
                  <div class="message">
                      ㅋㅋㅋㅋ
                  </div>
              </div>
              <div class="right">
                  <div class="date">
                      <i class="far fa-clock">
                      </i>
                      2024-11-19 11:13
                  </div>
                  <div class="icon">
                      <i class="fas fa-times-circle" style="color: red;">
                      </i>
                  </div>
              </div>
          </div>
      </div>
      <div class="actions">
          <div class="button">
              댓글등록
          </div>
      </div>
  </div>
  </div>

</template>

<script>
// import SideLink from '../../../components/Board/SideLink'
export default {
  name: "Detail",
  components: {
    // SideLink
  },
  data: function(){
    return {
      path: 'board/detail/' + this.$route.params.seq,
      article: null,
    }
  },
  mounted: function() {
    this.reqData()
  },
  methods: {
    reqData: function () {
      return this.$store.dispatch('auth/post', {path: this.path, data: {payload: this.payload}})
          .then(data => {
            const result = data.data;
            this.article = result.payload.article;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    movePage: function(page){
      this.$router.push({ path: `/${page}`})
    },
    renderHtml: function(str){
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    }
  }
}
</script>

<style scoped>
@media all and (min-width:600px) {
  .mobile { display: none; }
  .main {
      padding: 20px;
  }

  .main h1 {
      color: #a78aff;
      text-align: center;
      margin-bottom: 20px;
  }

  .post-info {
      background-color: #2a2a2a;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 20px;
  }

  .post-info .title {
      font-size: 18px;
      margin-bottom: 10px;
      background-color: #3a3a3a;
      padding: 5px;
      border-radius: 3px;
  }

  .post-info .author-info {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
  }

  .post-info .author-info img {
      width: 20px;
      height: 20px;
  }

  .post-info .author-info span {
      color: #ff6666;
  }

  .post-info .post {
      margin-bottom: 10px;
  }

  .post-info .date {
      text-align: right;
      color: #999;
  }

  .table-container {
      overflow-x: auto;
  }

  table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
  }

  table th, table td {
      border: 1px solid #444;
      padding: 10px;
      text-align: center;
  }

  table th {
      background-color: #333;
      color: #fff;
  }

  table td {
      background-color: #2a2a2a;
  }

  .buttons {
      display: flex;
      justify-content: space-between;
      background-color: #2a2a2a;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 20px;
  }

  .buttons .left-buttons {
      display: flex;
      gap: 10px;
  }

  .buttons .right-button {
      display: flex;
      justify-content: flex-end;
  }

  .buttons button {
      background-color: #a78aff;
      border: none;
      padding: 10px 20px;
      color: #fff;
      cursor: pointer;
      border-radius: 5px;
  }

  .buttons button:hover {
      background-color: #8a6de9;
  }

  .comment-section {
      background-color: #2a2a2a;
      padding: 10px;
      border-radius: 5px;
  }

  .comment-section input[type="text"] {
      width: calc(100% - 120px);
      padding: 10px;
      border: 1px solid #444;
      background-color: #1a1a1a;
      color: #ccc;
      border-radius: 5px;
  }

  .comment-section button {
      background-color: #a78aff;
      border: none;
      padding: 10px 20px;
      color: #fff;
      cursor: pointer;
      border-radius: 5px;
  }

  .comment-section button:hover {
      background-color: #8a6de9;
  }

  .comments {
      margin-top: 20px;
  }

  .comment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid #444;
  }

  .comment .user {
      display: flex;
      align-items: center;
      gap: 10px;
  }

  .comment .user img {
      width: 20px;
      height: 20px;
  }

  .comment .user span {
      color: #ff6666;
  }

  .comment .date {
      color: #999;
  }
}
@media all and (max-width: 600px) {
  .desktop { display: none; }

  .main .title {
      color: #b19cd9;
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
  }

  .post {
      background-color: #333333;
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 20px;
  }

  .post .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #444444;
      padding-bottom: 10px;
      margin-bottom: 10px;
  }

  .post .header .left {
      display: flex;
      align-items: center;
  }

  .post .header .left .category {
      background-color: #444444;
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 10px;
  }

  .post .header .left .title {
      font-size: 18px;
      margin-right: 10px;
  }

  .post .header .left .bet {
      background-color: #b19cd9;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 12px;
  }

  .post .header .right {
      display: flex;
      align-items: center;
  }

  .post .header .right .date {
      margin-right: 10px;
  }

  .post .header .right .views,
  .post .header .right .comments {
      margin-right: 5px;
  }

  .post .content {
      margin-bottom: 20px;
  }

  .post .bet-info {
      background-color: #444444;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 20px;
  }

  .post .bet-info .match {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
  }

  .post .bet-info .match .team {
      display: flex;
      align-items: center;
  }

  .post .bet-info .match .team img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
  }

  .post .bet-info .match .result {
      display: flex;
      align-items: center;
  }

  .post .bet-info .match .result .icon {
      margin-left: 10px;
  }

  .post .actions {
      display: flex;
      justify-content: space-between;
  }

  .post .actions .button {
      background-color: #b19cd9;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
  }

  .comments {
      margin-top: 20px;
  }

  .comments .comment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid #444444;
  }

  .comments .comment .left {
      display: flex;
      align-items: center;
  }

  .comments .comment .left .username {
      margin-right: 10px;
  }

  .comments .comment .left .message {
      margin-right: 10px;
  }

  .comments .comment .right {
      display: flex;
      align-items: center;
  }

  .comments .comment .right .date {
      margin-right: 10px;
  }

  .comments .comment .right .icon {
      margin-left: 10px;
  }
}

</style>
