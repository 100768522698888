<template>
  <dl
      :class="{'active': checkCart(bets)}"
      v-if="folder.isSuspended === 'y'"
  >
    <!-- 종목 -->
    <dt>
      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
    </dt>
  </dl>
  <dl
      v-else
      :class="{'active': checkCart(bets)}"
      @click="addCart(bets, folder, game)"
  >
    <!-- 종목 -->
    <dt>
      {{ name }}
    </dt>
    <!-- 배당  -->
    <dd>
      <i v-if="up" class="ico-up blink"></i>
      <i v-if="down" class="ico-down blink"></i>
      <p>{{ price }}</p>
    </dd>
  </dl>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game'],
  data: function(){
    return {
      startPrice: null,
      up: false,
      down: false,
      init: false,
    }
  },
  computed: {
    type: function(){
      let type = 'home';
      if (this.bets.name === 'W1' || this.bets.name === 'Over' || this.bets.name === 'Home') type = 'home';
      if (this.bets.name === 'X' || this.bets.name === '12') type = 'draw';
      if (this.bets.name === 'W2' || this.bets.name === 'Under' || this.bets.name === 'Home') type = 'away';
      return type
    },
    price: function(){
      return Number(this.bets.price).toFixed(2)
    },
    name: function(){
      if (this.bets.name === 'W1') return '승'
      if (this.bets.name === 'W2') return '패'
      if (this.bets.name === 'X') return '무'
      if (this.bets.name === 'Over') return '오버'
      if (this.bets.name === 'Under') return '언더'
      if (this.bets.name === 'Home') return '승'
      if (this.bets.name === 'Away') return '패'
      return '승'
    }
  },
  mounted: function() {
    this.up = false;
    this.down = false;
  },
  watch: {
    price: function(newVal, val){
      if (this.init){
        if (Number(newVal) - Number(val) < 0) this.down = true;
        if (Number(newVal) - Number(val) > 0) this.up = true;
        if (Number(newVal) !== Number(val)) this.startPrice = val;
      }
    },
    up: function(){
      setTimeout(() => {
        this.up = false
        this.startPrice = null;
      } ,5000)
    },
    down: function(){
      setTimeout(() => {
        this.down = false
        this.startPrice = null;
      } ,5000)
    }
  },
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
