<template>
  <dl
      :class="{'active': checkCart(bets)}"
      @click="addCart(bets, folder, game)"
  >
    <dt>
      {{ teamName }} {{ line }}
    </dt>
    <dd>
      <span v-if="folder.isOverUnder === 'y' && bets.name === 'Over'" class="material-icons up">
        expand_more
      </span>
      {{ bets.price }}
      <span v-if="folder.isOverUnder === 'y' && bets.name === 'Under'" class="material-icons down">
      expand_more
      </span>
    </dd>
  </dl>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game', 'team'],
  data: function(){
    return {
      startPrice: null,
      up: false,
      down: false
    }
  },
  computed: {
    type: function(){
      let type = 'home';
      if (this.bets.name === 'W1' || this.bets.name === 'Over' || this.bets.name === '1X' || this.bets.name === 'Odd' || this.bets.name === 'Yes' || this.bets.name === 'Home') return 'home';
      if (this.bets.name === 'X' || this.bets.name === '12') return 'draw';
      if (this.bets.name === 'W2' || this.bets.name === 'Under' || this.bets.name === 'X2' || this.bets.name === 'Odd' || this.bets.name === 'No' || this.bets.name === 'Away') return 'away';
      return type
    },
    price: function(){
      return this.bets.price
    },
    teamName: function(){
      if (this.folder.market.nameEn.indexOf('Player Points') >= 0 ||
          this.folder.market.nameEn.indexOf('Player Rebounds') >= 0 ||
          this.folder.market.nameEn.indexOf('Player Assists') >= 0 ||
          this.folder.market.nameEn.indexOf('Player Points and Assists') >= 0 ||
          this.folder.market.nameEn.indexOf('Player Points and Rebounds') >= 0 ||
          this.folder.market.nameEn.indexOf('Player Assists and Rebounds') >= 0 ||
          this.folder.market.nameEn.indexOf('Player Points, Assists and Rebounds') >= 0){
        const playerName = this.folder.name.split(')')[0];
        if (this.bets.name === 'Over') return `${playerName}) 오버 `;
        if (this.bets.name === 'Under') return `${playerName}) 언더 `;
      }
      if ([13750, 13751,13752,13753,13754,13755,13756,13757,13758,13759,13760,13761,13761,13762,14591,14594].includes(this.folder.marketId)){
        const playerName = this.folder.name.split('(')[0];
        if (this.bets.name === 'Over') return `${playerName}) 오버 (${this.bets.line})`;
        if (this.bets.name === 'Under') return `${playerName}) 언더 (${this.bets.line})`;
      }
      if (
        this.folder.market.nameEn.indexOf('Player Total Kills') >= 0 ||
        this.folder.market.nameEn.indexOf('Player Total Assists') >= 0
      ){
        const playerName = this.folder.name.split(' ')[0];
        if (this.bets.name === 'Over') return `${playerName} 오버 `;
        if (this.bets.name === 'Under') return `${playerName} 언더 `;
      }
      if (this.bets.name in ['Home', 'Away']) return `${this.team.nameKr || this.team.nameEn} (${this.bets.line})`;
      if (this.bets.name === 'Over') return '오버';
      if (this.bets.name === 'Under') return '언더';
      if (this.bets.name === 'X') return '무';
      if (this.bets.name === '1X') return '1팀승 또는 무승부';
      if (this.bets.name === '12') return '1팀승 또는 2팀승';
      if (this.bets.name === 'X2') return '무승부 또는 2팀승';
      if (this.bets.name === 'Odd') return '홀';
      if (this.bets.name === 'Even') return '짝';
      if (this.bets.name === 'Yes') return '예';
      if (this.bets.name === 'No') return '아니오';
      if (this.bets.name === 'No Goal') return '노골';
      if (this.bets.name === 'NoGoal') return '노골';

      return this.team.nameKr || this.team.nameEn;
    },
    line: function(){
      if (this.folder.market.isHandicap === 'y' && this.folder.market.isOverUnder === 'n') {
        return ` (${this.bets.line})`
      }
      if (this.folder.market.isHandicap === 'y' && this.folder.market.isOverUnder === 'y') {
        return ` (${this.bets.line})`
      }
      return ''
    }
  },
  watch: {
    price: function(newVal, val){
      // console.log(newVal)
      if (Number(newVal) - Number(val) < 0) this.down = true;
      if (Number(newVal) - Number(val) > 0) this.up = true;
      if (Number(newVal) !== Number(val)) this.startPrice = val;
    },
    up: function(){
      setTimeout(() => {
        this.up = false
        this.startPrice = null;
      } ,10000)
    },
    down: function(){
      setTimeout(() => {
        this.down = false
        this.startPrice = null;
      } ,10000)
    }
  },
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
