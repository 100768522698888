<template>
  <table class="listTable02" style="margin-bottom: 30px;" v-if="game.sportSeq === 210">
    <thead>
      <tr>
        <th></th>
        <th>위험한공격</th>
        <th>유효슛팅</th>
        <th>슛팅</th>
        <th>코너킥</th>
        <th>옐로우카드</th>
        <th>레드카드</th>
        <th>프리킥</th>
        <th>페널티킥</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ game.homeTeam | teamName }}</td>
        <td>{{ homeDangerousAttack }}</td>
        <td>{{ homeShotOnTarget }}</td>
        <td>0</td>
        <td>{{ homeCornerScore }}</td>
        <td>{{ homeYellowCardScore }}</td>
        <td>{{ homeRedCardScore }}</td>
        <td>{{ homeFreekickScore }}</td>
        <td>{{ homePenaltyScore }}</td>
      </tr>
      <tr>
        <td>{{ game.awayTeam | teamName }}</td>
        <td>{{ awayDangerousAttack }}</td>
        <td>{{ awayShotOnTarget }}</td>
        <td>0</td>
        <td>{{ awayCornerScore }}</td>
        <td>{{ awayYellowCardScore }}</td>
        <td>{{ awayRedCardScore }}</td>
        <td>{{ awayFreekickScore }}</td>
        <td>{{ awayPenaltyScore }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "Statics",
  props: ['game'],
  computed: {
    homeDangerousAttack(){
      const result = this.game.dangerousAttack ? this.game.dangerousAttack.split(':')[0] : 0
      return result
    },
    awayDangerousAttack(){
      const result = this.game.dangerousAttack ? this.game.dangerousAttack.split(':')[1] : 0
      return result
    },
    homeShotOnTarget(){
      const result = this.game.shotOnTargetScore ? this.game.shotOnTargetScore.split(':')[0] : 0
      return result
    },
    awayShotOnTarget(){
      const result = this.game.shotOnTargetScore ? this.game.shotOnTargetScore.split(':')[1] : 0
      return result
    },
    homeCornerScore(){
      const result = this.game.cornerScore ? this.game.cornerScore.split(':')[0] : 0
      return result
    },
    awayCornerScore(){
      const result = this.game.cornerScore ? this.game.cornerScore.split(':')[1] : 0
      return result
    },
    homeYellowCardScore(){
      const result = this.game.yellowCardScore ? this.game.yellowCardScore.split(':')[0] : 0
      return result
    },
    awayYellowCardScore(){
      const result = this.game.yellowCardScore ? this.game.yellowCardScore.split(':')[1] : 0
      return result
    },
    homeRedCardScore(){
      const result = this.game.redCardScore ? this.game.redCardScore.split(':')[0] : 0
      return result
    },
    awayRedCardScore(){
      const result = this.game.redCardScore ? this.game.redCardScore.split(':')[1] : 0
      return result
    },
    homeFreekickScore(){
      const result = this.game.freekickScore ? this.game.freekickScore.split(':')[0] : 0
      return result
    },
    awayFreekickScore(){
      const result = this.game.freekickScore ? this.game.freekickScore.split(':')[1] : 0
      return result
    },
    homePenaltyScore(){
      const result = this.game.penaltyScore ? this.game.penaltyScore.split(':')[0] : 0
      return result
    },
    awayPenaltyScore(){
      const result = this.game.penaltyScore ? this.game.penaltyScore.split(':')[1] : 0
      return result
    },
  },
}
</script>

<style scoped>
.listTable02 th {
  height: 40px;
}
</style>
