<template>
  <dl :class="{'active': checkCart(bets)}" @click="addCart(bets, folder, game)">
    <!-- 종목 -->
    <dt style="width: 60%;">
      {{ team | teamName | substring(15) }} ({{ marketType }})
    </dt>
    <!-- 배당  -->
    <dd v-if="folder.isSuspended === 'n'" >
      <img v-if="name === '핸디캡홈' && !checkCart(bets)" style="width: 8px; margin-right: 4px;" src="@/assets/img/ico/red/h.png" alt="">
      <img v-if="name === '핸디캡홈' && checkCart(bets)" style="width: 8px; margin-right: 4px;" src="@/assets/img/ico/white/h.png" alt="">
      <img v-if="name === '오버' && !checkCart(bets)" style="width: 9px; height: 8px; margin-right: 4px;" src="@/assets/img/ico/m_ico_up.png" alt="">
      <img v-if="name === '오버' && checkCart(bets)" style="width: 9px; height: 8px; margin-right: 4px;" src="@/assets/img/ico/m_ico_up.png" alt="">
      <span>{{ price }}</span>
      <img v-if="name === '핸디캡원정' && !checkCart(bets)" style="width: 8px; margin-left: 4px;" src="@/assets/img/ico/red/h.png" alt="">
      <img v-if="name === '핸디캡원정' && checkCart(bets)" style="width: 8px; margin-left: 4px;" src="@/assets/img/ico/white/h.png" alt="">
      <img v-if="name === '언더' && !checkCart(bets)" style="width: 9px; height: 8px; margin-left: 4px;" src="@/assets/img/ico/m_ico_down.png" alt="">
      <img v-if="name === '언더' && checkCart(bets)" style="width: 9px; height: 8px; margin-left: 4px;" src="@/assets/img/ico/m_ico_down.png" alt="">
    </dd>
  </dl>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game', 'team'],
  data: function(){
    return {
    }
  },
  computed: {
    type: function(){
      let type = 'home';
      if (this.bets.name === 'W1' || this.bets.name === 'Over' || this.bets.name === 'Home') type = 'home';
      if (this.bets.name === 'X') type = 'draw';
      if (this.bets.name === 'W2' || this.bets.name === 'Under' || this.bets.name === 'Away') type = 'away';
      return type
    },
    price: function(){
      return Number(this.bets.price).toFixed(2)
    },
    name: function(){
      if (this.folder.market.isHandicap === 'y' && this.bets.name === 'Home') return '핸디캡홈'
      if (this.folder.market.isHandicap === 'y' && this.bets.name === 'Away') return '핸디캡원정'
      if (this.bets.name === 'W1') return '승'
      if (this.bets.name === 'W2') return '패'
      if (this.bets.name === 'Home') return '승'
      if (this.bets.name === 'Away') return '패'
      if (this.bets.name === 'X') return '무'
      if (this.bets.name === 'Over') return '오버'
      if (this.bets.name === 'Under') return '언더'
      return '승'
    },
    marketType: function(){
      return this.folder.market.nameKr?.replace(' ', '').replace('{sw}', this.folder.periodSequence).replace('핸디캡', '').replace('오버언더', '');
    },
  },
}
</script>

<style scoped>
</style>
