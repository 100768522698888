<template>
  <div class="m-game3">
    <div class="title" style="margin: 5px 0px;">
      <div class="left">
        <div class="subj type1">주의사항</div>
      </div>
    </div>
    <div style="background-color: #222627;">
      <p>※ 실시간 베팅 주의사항</p> <br>

      <p>베팅 전 필히 팀명 확인후 베팅해주세요</p> <br>

      <p>한국형/유럽형/인플레이 팀명 표기 순서가 상이할수 있습니다.</p> <br>

      <p>회원님의 불찰로 인한 베팅의 경우 운영진은 책임지지 않습니다.</p> <br>
    </div>
    <template v-for="date in this.uniqGameStartTime">
      <template v-for="(league, leagueIdx) in timeLeagueSort(date)">
        <div class="title" style="margin: 5px 0 5px 0;" :key="'leagueTitle' + $options.filters.randomNum(leagueIdx)">
          <div class="left">
            <div class="subj">
              <img v-if="lists.find(e => e.leagueName === league).sportsName === '축구'"  src="@/assets/img/ico/1.png" style="margin-right: 8px;">
              <img v-if="lists.find(e => e.leagueName === league).sportsName === '야구'"  src="@/assets/img/ico/11.png" style="margin-right: 8px;">
              <img v-if="lists.find(e => e.leagueName === league).sportsName === '농구'"  src="@/assets/img/ico/3.png" style="margin-right: 8px;">
              <img v-if="lists.find(e => e.leagueName === league).sportsName === '배구'"  src="@/assets/img/ico/5.png" style="margin-right: 8px;">
              <img v-if="lists.find(e => e.leagueName === league).sportsName === '아이스하키'"  src="@/assets/img/ico/2.png" style="margin-right: 8px;">
              <img v-if="lists.find(e => e.leagueName === league).sportsName === '이스포츠'"  src="@/assets/img/ico/77.png" style="margin-right: 8px;">
              <img v-if="lists.find(e => e.leagueName === league).sportsName === '미식축구'"  src="@/assets/img/ico/6.png" style="margin-right: 8px;">
              <img v-if="lists.find(e => e.leagueName === league).league" :src="lists.find(e => e.leagueName === league).league?.img" style="margin-right: 8px;" alt="">
              {{ league }}
            </div>
          </div>
          <div class="right">{{ date | formatDate('MM-DD HH:mm') }}</div>
        </div>
        <template v-for="team in gameTimeTeam(date, league)">
          <template v-for="(game, index2) in gameTimeLeagueSort(date, league, team)">
            <div class="game-item"
                 :key="'gameSeq'+ game.seq +index2"
                 v-if="index2===0 || checkArray(game)"
            >
              <template>
                <dl :class="{'active': checkCart(game, 'home')}" v-on:click="addCart(game, 'home')">
                  <!-- 종목 -->
                  <dt style="width: 60%;">
                    {{ game.teamName1 }}
                  </dt>
                  <!-- 배당  -->
                  <dd>
                    <img v-if="game.betType === 'handicap' && !checkCart(game, 'home')" style="width: 8px; margin-right: 4px;" src="@/assets/img/ico/red/h.png" alt="">
                    <img v-if="game.betType === 'handicap' && checkCart(game, 'home')" style="width: 8px; margin-right: 4px;" src="@/assets/img/ico/white/h.png" alt="">
                    <img v-if="game.betType === 'underover' && !checkCart(game, 'home')" style="width: 9px; height: 8px; margin-left: 4px;" src="@/assets/img/ico/m_ico_up.png" alt="">
                    <img v-if="game.betType === 'underover' && checkCart(game, 'home')" style="width: 9px; height: 8px; margin-left: 4px;" src="@/assets/img/ico/m_ico_up.png" alt="">
                    <span>{{ game.oddsTeam1 }}</span>
                  </dd>
                </dl>
                <div class="vs">
                  {{ game.oddsTeam3 }}
                </div>
                <dl :class="{'active': checkCart(game, 'away')}" v-on:click="addCart(game, 'away')">
                  <!-- 종목 -->
                  <dt style="width: 60%;">
                    {{ game.teamName2 }}
                  </dt>
                  <!-- 배당  -->
                  <dd>
                    <span>{{ game.oddsTeam2 }}</span>
                    <img v-if="game.betType === 'handicap' && !checkCart(game, 'away')" style="width: 8px; margin-left: 4px;" src="@/assets/img/ico/red/h.png" alt="">
                    <img v-if="game.betType === 'handicap' && checkCart(game, 'away')" style="width: 8px; margin-left: 4px;" src="@/assets/img/ico/white/h.png" alt="">
                    <img v-if="game.betType === 'underover' && !checkCart(game, 'away')" style="width: 9px; height: 8px; margin-left: 4px;" src="@/assets/img/ico/m_ico_down.png" alt="">
                    <img v-if="game.betType === 'underover' && checkCart(game, 'away')" style="width: 9px; height: 8px; margin-left: 4px;" src="@/assets/img/ico/m_ico_down.png" alt="">
                  </dd>
                </dl>
                <button
                    class="btn-val"
                    v-if="index2 === 0"
                    @click="toggleDetail(game)">+ {{ countFolder(game) }}
                </button>
                <button v-else class="btn-bet">배팅</button>
              </template>
            </div>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>

export default {
  name: "MFolderWrapper",
  props: ['lists', 'cartType', 'checkCart', 'addCart', 'addBonus', 'bonus'],
  components: {
    // MKoreanPrice
  },
  computed: {
    uniqGameStartTime: function(){
      const dates = this.lists.map(e => e.gameDate);
      const time = this.$options.filters.uniq(dates)
      return time;
    }
  },
  data: function () {
    return {
      openDetailBet: [],
    }
  },
  created() {
    this.sortLeagues()
  },
  methods: {
    /*
    * 종목의 자세히 버튼
    */
    checkArray: function (game) {
      let result = this.openDetailBet.filter(g => g.leagueName === game.leagueName && g.gameDate === game.gameDate && g.teamName1 === game.teamName1).length > 0
      return result
    },
    /*
     * 토글 액션
     */
    toggleDetail: function (game) {
      this.toggle(this.openDetailBet, game)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailBet.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailBet = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    timeLeagueSort: function(date){
      const games = this.lists.filter(e => e.gameDate === date);
      const leagues = games.map(e => e.leagueName);
      const leaguesName = this.$options.filters.uniq(leagues);
      return leaguesName;
    },
    sortLeagues: function(){
      for(const u in this.uniqGameStartTime){
        this.timeLeagueSort(this.uniqGameStartTime[u]);
      }
    },
    gameTimeLeagueSort: function(date, league, team){
      const games = this.lists.filter(e => e.gameDate === date && e.leagueName === league && e.teamName1 === team);
      return games;
    },
    gameTimeTeam: function(date, league){
      const games = this.lists.filter(e => e.gameDate === date && e.leagueName === league);
      const teams = games.map(e => e.teamName1);
      const teamNames = this.$options.filters.uniq(teams);
      return teamNames;
    },
    countFolder: function(game){
      return this.lists.filter(g => g.leagueName === game.leagueName && g.gameDate === game.gameDate && g.teamName1 === game.teamName1).length
    }
  }
}
</script>

<style scoped>
.vs img {
  margin-top: -3px !important;
}
</style>
