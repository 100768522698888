import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
Plugin.install = function(Vue, options) {
    Vue.prototype.$sendMessage = (data) => {
        if (Vue.prototype.$socket.readyState === 0) {
            setTimeout(() => {
                try {
                    Vue.prototype.$socket.sendObj(data)
                    // if (type === 'page'){
                    //     localStorage.lastMessage = JSON.stringify(data)
                    // }
                } catch (e) {
                    console.log(e);
                }
            }, 1000)
        } else {
            try {
                Vue.prototype.$socket.sendObj(data)
                // if (type === 'page'){
                //     localStorage.lastMessage = JSON.stringify(data)
                // }
            } catch (e) {
                console.log(e);
            }
        }
    }

    Vue.prototype.$gameDateFilter = (date) => {
        console.log(date)
        let now = new Date();
        let startDate = new Date(date);
        return now <= startDate;
    }


};

Vue.use(Plugin)

export default Plugin;
