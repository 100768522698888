<template>
  <section v-if="!this.$store.state.page.isMobile">
    <div class="nbox" style="width: 1450px">
      <div class="game-side" style="margin-top: 0px;">
        <div class="list">
          <a  @click="movePage('lotus/oe')" >
            <strong>홀짝</strong>
          </a>
          <a @click="movePage('lotus/baccarat1')">
            <strong>바카라1</strong>
          </a>
          <a class="active">
            <strong>바카라2</strong>
          </a>
          <!--      <a :class="{'active': gType ==='graph'}">-->
          <!--        <strong>그래프</strong>-->
          <!--      </a>-->
          <a @click="movePage('lotus/sicbo')" >
            <strong>식보</strong>
          </a>
        </div>
      </div>
      <div class="contLeft">

        <!--        <ul class="tabs">-->
        <!--          <li><a @click="movePage('lotus/baccarat1')">로투스 바카라</a></li>-->
        <!--          <li><a @click="movePage('lotus/oe')">로투스 홀짝</a></li>-->
        <!--          <li><a @click="movePage('lotus/sicbo')">식보</a></li>-->
        <!--        </ul>-->

<!--        <div class="cateTabs">-->
<!--          <a @click="movePage('lotus/baccarat1')" class="on">로투스 바카라</a>-->
<!--          <a @click="movePage('lotus/oe')" class="on">홀짝</a>-->
<!--          <a @click="movePage('lotus/sicbo')">로투스 식보</a>-->
<!--        </div>-->
        <div class="gameBox">
          <iframe v-if="token" ref="gameIframe" :src="`https://stream03.lotusgm02.com?gType=mid&rType=2&token=${token}`" frameborder="0" scrolling="no" style="width:888px;height:660px;" allowtransparency="true"></iframe>
        </div>


        <h3 class="tit-bet">베팅내역</h3>
        <table class="listTable03">
          <tbody><tr>
            <th>베팅일시</th>
            <th>회차</th>
            <th>베팅</th>
            <th>베팅액</th>
            <th>결과</th>
            <th>적중액</th>
          </tr>
          <tr>
            <td>2022-02-22</td>
            <td>1371</td>
            <td><span class="blue">홀</span></td>
            <td>10,000</td>
            <td>진행중</td>
            <td><strong class="black">0</strong></td>
          </tr>

          </tbody>
        </table>

        <div class="pagingBox black">

          <div class="paging">
            <a href="javascript:void(0)" class="btnFirst"><img src="@/assets/img/ico/ico_first_black.png"></a>
            <a href="javascript:void(0)" class="btnPrev"><img src="@/assets/img/ico/ico_prev_black.png"></a>

            <a href="javascript:void(0)" class="on">1</a>
            <a href="javascript:void(0)">2</a>
            <a href="javascript:void(0)">3</a>
            <a href="javascript:void(0)">4</a>
            <a href="javascript:void(0)">5</a>
            <a href="javascript:void(0)">6</a>
            <a href="javascript:void(0)">7</a>
            <a href="javascript:void(0)">8</a>
            <a href="javascript:void(0)">9</a>
            <a href="javascript:void(0)">10</a>

            <a href="javascript:void(0)" class="btnNext"><img src="@/assets/img/ico/ico_next_black.png"></a>
            <a href="javascript:void(0)" class="btnLast"><img src="@/assets/img/ico/ico_last_black.png"></a>
          </div>
        </div>



      </div>

      <div class="contRight">
        <OddEvenCart></OddEvenCart>
      </div>
    </div>

  </section>
  <div class="mini-game" v-else>
    <div class="game-wrap powerball">
      <iframe name="miniViewFrame" src="https://pb.one-mini.com/miniview" frameborder="0" scrolling="no" style="width: 700px; height: 271px; transform: scale(0.498795); transform-origin: left top;" allowtransparency="true"></iframe>
    </div>
    <!--    <MCartPowerball :type="cartType" :game="game" :add-cart="addCart" :check-cart="checkCart"></MCartPowerball>-->
    <div class="tab-cont">
      <div class="cont">
        <div class="tit">파워볼 홀짝</div>
        <div class="circle-wrap">
          <div class="head">
            <p><span class="blue">홀</span></p>
            <p><span class="red">짝</span></p>
            <p><span class="blue">홀</span></p>
            <p><span class="red">짝</span></p>
            <p><span class="blue">홀</span></p>
            <p><span class="red">짝</span></p>
            <p><span class="blue">홀</span></p>
            <p><span class="red">짝</span></p>
            <p><span class="blue">홀</span></p>
            <p><span class="red">짝</span></p>
            <p><span class="blue">홀</span></p>
            <p><span class="red">짝</span></p>
            <p><span class="blue">홀</span></p>
            <p><span class="red">짝</span></p>
            <p><span class="blue">홀</span></p>
            <p><span class="red">짝</span></p>
          </div>
          <div class="body">
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
          </div>
          <div class="body">
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
          </div>
          <div class="body">
            <p><span class="bg-blue">123</span></p>
            <p><span class="bg-red">123</span></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OddEvenCart from "@/components/Betting/OddEvenCart";
export default {
  name: "OddEven",
  components: {
    OddEvenCart
  },
  data: function(){
    return {
      path: 'lotus/baca2',
      token: null,
    }
  },
  created: function(){
    this.getToken()
    this.interval = setInterval(this.continuousSession, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    getToken: function(){
      return this.$store.dispatch('auth/post', {path: this.path })
          .then(data => {
            const result = data.data;
            this.token = result.payload.access_token;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    // 베팅카트에 담기
    addCart: function (bet, folder) {
      this.$store.dispatch('cart/actionLotusCart', {
        gameId: this.game.game_id,
        betsSeq: bet.bets_seq,
        betsName: bet.bets_name,
        sportsId: null,
        foldersSeq: folder.folders_seq,
        marketsId: null,
        odds: bet.bets_price,
        line: null,
        marketNameKr: folder.folders_name,
        marketNameEn: folder.folders_name,
        leagueNameKr: '홀짝',
        leagueNameEn: '홀짝',
        leagueNameIcon: null,
        sportNameKr: '로투스',
        sportNameEn: '로투스',
        sportNameIcon: null,
        homeNameKr: folder.folders_name.indexOf('홀/짝') !== -1 ? '홀' : folder.folders_name.indexOf('언더/오버') !== -1 ? '언더' : '소',
        homeNameEn: folder.folders_name.indexOf('홀/짝') !== -1 ? '홀' : folder.folders_name.indexOf('언더/오버') !== -1 ? '언더' : '소',
        drawNameKr: folder.folders_name.indexOf('소/중/대') !== -1 ? '중' : null,
        awayNameKr: folder.folders_name.indexOf('홀/짝') !== -1 ? '짝' : folder.folders_name.indexOf('언더/오버') !== -1 ? '오버' : '대',
        awayNameEn: folder.folders_name.indexOf('홀/짝') !== -1 ? '짝' : folder.folders_name.indexOf('언더/오버') !== -1 ? '오버' : '대',
        startTime: this.game.game_starttime,
        odds1: folder.folders_name.indexOf('소/중/대') !== -1 ? '2.9' : '1.95',
        odds2: folder.folders_name.indexOf('소/중/대') !== -1 ? '2.6' : '',
        odds3: folder.folders_name.indexOf('소/중/대') !== -1 ? '2.9' : '1.95',
        betType: '미니게임',
      })
    },
    // 카트내역 체크해서 표시
    checkCart: function (bets, type) {
      // console.log(this.$store.state.cart[this.cartType]);
      if (type === '보너스') {
        // let index = this.$store.state.cart[this.cartType].find(item => item.odds === event)
        // return !!index;
        return null;
      } else {
        let index = this.$store.state.cart['minigame'].find(item => item.betsSeq === bets.bets_seq)
        return !!index;
      }
    },
    movePage:function(path){
      this.$router.push(`/${path}`)
    },
    continuousSession: function(){
      const msg = { game: 'oe', domain: 'vv-11.com'};
      this.$refs.gameIframe.contentWindow.postMessage(msg, 'https://stream.lotusgm02.com')
    },
  }
}
</script>

<style scoped>
.powerball {
  height: 131px;
  margin: 0px 0px !important;
  padding: 5px 5px !important;
}
</style>
