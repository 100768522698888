<template>
  <table class="gameTable02">
    <colgroup>
      <col width="" />
      <col width="214" />
      <col width="120" />
      <col width="120" />
      <col width="214" />
    </colgroup>
    <tr v-for="(folder, index) in game.folders" :key="'powerballFolder'+index">
      <th class="rtl">{{ index + 1 }}게임-{{ folder.folders_name }}</th>
      <td colspan="2" v-for="bet in folder.bets" :key="'powerballDetail'+bet.bets_id"
          @click="addCart(bet, folder)"
      >
        <span class="sts ovHidden" :class="{'odd': bet.bets_name === '홀', 'even': bet.bets_name === '짝'}">
          <span class="fl">{{ bet.bets_name }}</span>
          <span class="fr">{{ bet.bets_price }}</span>
        </span>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "PowerLadder",
  props: ['game'],
  methods: {
    // 베팅카트에 담기
    addCart: function (bet, folder) {
      this.$store.dispatch('cart/actionMinigameCart', {
        gameId: this.game.game_id,
        betsSeq: bet.bets_seq,
        betsName: bet.bets_name,
        betsId: bet.bets_id,
        sportsId: null,
        foldersSeq: folder.folders_seq,
        marketsId: null,
        odds: bet.bets_price,
        line: null,
        marketNameKr: folder.folders_name,
        marketNameEn: folder.folders_name,
        leagueNameKr: 'EOS파워볼',
        leagueNameEn: 'EOS파워볼',
        leagueNameIcon: null,
        sportNameKr: '파워사다리',
        sportNameEn: '파워사다리',
        sportNameIcon: null,
        homeNameKr: folder.folders_name.indexOf('홀/짝') !== -1 ? '홀' : folder.folders_name.indexOf('줄수') !== -1 ? '3' : '왼쪽',
        homeNameEn: folder.folders_name.indexOf('홀/짝') !== -1 ? '홀' : folder.folders_name.indexOf('줄수') !== -1 ? '3' : '왼쪽',
        drawNameKr: null,
        awayNameKr: folder.folders_name.indexOf('홀/짝') !== -1 ? '짝' : folder.folders_name.indexOf('줄수') !== -1 ? '4' : '오른쪽',
        awayNameEn: folder.folders_name.indexOf('홀/짝') !== -1 ? '짝' : folder.folders_name.indexOf('줄수') !== -1 ? '4' : '오른쪽',
        startTime: this.game.game_starttime,
        odds1: '1.95',
        odds2: '',
        odds3: '1.95',
        betType: '미니게임',
      })
    },
    // 카트내역 체크해서 표시
    checkCart: function (bets, type) {
      // console.log(this.$store.state.cart[this.cartType]);
      if (type === '보너스') {
        // let index = this.$store.state.cart[this.cartType].find(item => item.odds === event)
        // return !!index;
        return null;
      } else {
        let index = this.$store.state.cart['minigame'].find(item => item.betsSeq === bets.bets_seq)
        return !!index;
      }
    },
  }
}
</script>

<style scoped>

</style>
