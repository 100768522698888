<template>
  <div>
    <div class="header2">
      <div class="left">
        <a @click="movePage('notes/list')" class="btn-prev"></a>
      </div>
      <h2>쪽지함</h2>
    </div>
    <div class="wrap">
      <div class="btnBox02 mobile_two_btn">
        <a @click="movePage('notes/list')" class="btn04">목록</a>
        <a @click="deleteNotes(article)" class="btn05">삭제</a>
      </div>
      <div class="message_view">
        <dl>
          <dt>{{ article.title }}</dt>
          <dd>수신 : {{ article.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }} 확인 : {{ article.readAt | formatDate('YYYY-MM-DD HH:mm:ss') }}</dd>
        </dl>
        <p v-html="renderHtml(article.content)">
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotesDetail",
  components: {
  },
  data: function(){
    return {
      article: null,
    }
  },
  mounted: function() {
    this.reqData()
  },
  methods: {
    reqData: function(){
      const seq = this.$route.params.seq;
      const path = `notes/detail/${seq}`;
      return this.$store.dispatch('auth/post', {path, data: { payload: this.payload} })
          .then(data => {
            const result = data.data;
            this.article = result.payload.article;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    deleteNotes: function(item){
      if(confirm('삭제 하시겠습니까?')){
        return this.$store.dispatch('auth/post', {path: 'notes/delete', data: { payload: { item }} })
            .then(data => {
              const result = data.data;
              alert(result.msg);
              this.reqData();
            }).catch(err => {
              alert(err.response.data.error);
            })
      }
    },
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    renderHtml: function(str){
      if (!str) return '';
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
