<template>
  <dl>
    <dt>
      {{ teamName }} {{ line }}
    </dt>
    <dd>
      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
    </dd>
  </dl>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'game'],
  data: function(){
    return {
      startPrice: null,
      up: false,
      down: false
    }
  },
  computed: {
    type: function(){
      let type = 'home';
      if (this.bets.name === 'W1' || this.bets.name === 'Home' || this.bets.name === 'Over' || this.bets.name === '1X' || this.bets.name === 'Odd') type = 'home';
      if (this.bets.name === 'X' || this.bets.name === '12') type = 'draw';
      if (this.bets.name === 'W2' || this.bets.name === 'Away' || this.bets.name === 'Under'|| this.bets.name === 'X2' || this.bets.name === 'Even') type = 'away';
      return type
    },
    price: function(){
      return this.bets.price
    },
    teamName: function(){
      let name = '승';
      if (this.bets.name === 'Over') name = '오버';
      if (this.bets.name === 'X') name = '무';
      if (this.bets.name === 'W2') name = '패';
      if (this.bets.name === 'Home') name = `승 (${this.bets.line})`;
      if (this.bets.name === 'Away') name = `패 (${this.bets.line})`;
      if (this.bets.name === 'Under') name = '언더';
      return name
    },
    line: function(){
      if (this.folder.folders_line) {
        if (this.folder.folders_line.indexOf('(') >= 0) {
          return `(${this.folder.folders_line.split(' ')[0]})`
        }
        return `(${this.folder.folders_line})`
      }
      return ''
    }
  },
  watch: {
    price: function(newVal, val){
      if (Number(newVal) - Number(val) < 0) this.down = true;
      if (Number(newVal) - Number(val) > 0) this.up = true;
      if (Number(newVal) !== Number(val)) this.startPrice = val;
    },
    up: function(){
      setTimeout(() => {
        this.up = false
        this.startPrice = null;
      } ,10000)
    },
    down: function(){
      setTimeout(() => {
        this.down = false
        this.startPrice = null;
      } ,10000)
    }
  },
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
