<template>
  <section class="customer">
    <ul class="locationList">
      <li>HOME</li>
      <li>고객센터</li>
    </ul>
    <div class="cusbox">

      <div class="nbox">
        <SideLink :category="'customer'"></SideLink>

        <div class="cusRight" v-if="article">
          <div class="noticeView">
            <div class="title">
              <p>{{ article.board_title }}</p>
              <span class="writer">{{ article.board_author_name }}<span class="date">{{ article.board_regdatetime | formatDate('YYYY-MM-DD') }}</span></span>
            </div>
            <div class="context" v-html="renderHtml(article.board_content)">
            </div>
          </div>
          <div v-if="article && article.comments" class="noticeView replay" v-for="(row, index) in article.comments" :key="'reply'+index">
            <div class="title">
              <p>{{ row.title }}</p>
              <span class="writer">{{ row.authorName }}<span class="date">{{ row.createdAt | formatDate('YYYY-MM-DD') }}</span></span>
            </div>
            <div class="context" v-html="renderHtml(row.content)"></div>
          </div>
          <div class="btnWrap">
            <a @click="movePage('bbs/c/l')" class="btnList">목록으로</a>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import SideLink from '../../../components/Board/SideLink'
export default {
  name: "Detail",
  components: {
    SideLink
  },
  data: function(){
    return {
      path: 'board/detail/' + this.$route.params.seq,
      article: null,
    }
  },
  mounted: function() {
    this.reqData()
  },
  methods: {
    reqData: function () {
      return this.$store.dispatch('auth/post', {path: this.path, data: {payload: this.payload}})
          .then(data => {
            const result = data.data;
            this.article = result.payload.article;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    movePage: function(page){
      this.$router.push({ path: `/${page}`})
    },
    renderHtml: function(str){
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    }
  }
}
</script>

<style scoped>

</style>
