<template>
  <div class="game-side" style="margin-top: 0px;">
    <div class="list">
<!--      <a @click="alert('현재 메뉴는 구현중입니다.')">-->
<!--        <strong>EOS 파워볼 5분</strong>-->
<!--        <span>{{ remainTime | countDown }}</span>-->
<!--      </a>-->
      <a :class="{'active': this.$route.params.type === 'npowerball'}" @click="$router.push({path: `/mini/npowerball`})">
        <strong>N파워볼</strong>
        <span>{{ remainTime5 | countDown }}</span>
      </a>
      <a :class="{'active': this.$route.params.type === 'npowerladder'}" @click="$router.push({path: `/mini/npowerladder`})">
        <strong>N파워사다리</strong>
        <span>{{ remainTime5 | countDown }}</span>
      </a>
      <!-- <a :class="{'active': this.$route.params.type === 'dhpowerball'}" @click="$router.push({path: `/mini/dhpowerball`})">
        <strong>동행파워볼</strong>
        <span>{{ remainTime5 | countDown }}</span>
      </a> -->
<!--      <a :class="{'active': this.$route.params.type === 'EOS2m'}" @click="$router.push({path: `/minigame/powerball/EOS2m`})">-->
<!--        <strong>EOS 2분 파워볼</strong>-->
<!--        <span>{{ remainTime2 | countDown }}</span>-->
<!--      </a>-->
<!--      <a :class="{'active': this.$route.params.type === 'EOS3m'}" @click="$router.push({path: `/minigame/powerball/EOS3m`})">-->
<!--        <strong>EOS 3분 파워볼</strong>-->
<!--        <span>{{ remainTime3 | countDown }}</span>-->
<!--      </a>-->
<!--      <a :class="{'active': this.$route.params.type === 'EOS5m'}" @click="$router.push({path: `/minigame/powerball/EOS5m`})">-->
<!--        <strong>EOS 5분 파워볼</strong>-->
<!--        <span>{{ remainTime5 | countDown }}</span>-->
<!--      </a>-->
    </div>
  </div>
</template>

<script>
// import CountDown from './CountDown'
import moment from "moment";

export default {
  name: "MiniSideRight",
  props: ['game'],

  data: function() {
    return {
      path: 'game/timer',
      m5: null,
      m3: null,
      m2: null,
      m1: null,
      remainTime5: 0,
      remainTime3: 0,
      remainTime2: 0,
      remainTime1: 0,

    }
  },
  mounted() {
    this.getGame('N파워볼');
    // this.getGame('EOS3m');
    // this.getGame('EOS2m');
    // this.getGame('EOS1m');

    this.interval = setInterval(() => {
      this.calcTime5();
      // this.calcTime3();
      // this.calcTime2();
      // this.calcTime1();
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    getGame: function(type){
      return this.$store.dispatch('TIMER', { type })
          .then(response => {
            let result;
            if (Array.isArray(response)){
              result = response[0].data;
            } else {
              result = response.data
            }

            if (type === 'N파워볼') this.m5 = result.payload.game;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    calcTime5: function(){
      const now = moment().unix();
      const startTime = moment(this.m5.game_starttime).subtract(9, 'hours').unix();
      // const startTime = moment(this.m5.game_starttime).unix();
      this.remainTime5 = startTime - now;
      if (this.remainTime5 <= 0) {
        this.getGame('N파워볼');
      }
    },
    calcTime3: function(){
      const now = moment().unix();
      const startTime = moment(this.m3.game_starttime).subtract(9, 'hours').unix();
      this.remainTime3 = startTime - now;
      if (this.remainTime3 <= 0) {
        this.getGame('EOS3m');
      }
    },
    calcTime2: function(){
      const now = moment().unix();
      const startTime = moment(this.m2.game_starttime).subtract(9, 'hours').unix();
      this.remainTime2 = startTime - now;
      if (this.remainTime2 <= 0) {
        this.getGame('EOS2m');
      }
    },
    calcTime1: function(){
      const now = moment().unix();
      const startTime = moment(this.m1.game_starttime).subtract(9, 'hours').unix();
      this.remainTime1 = startTime - now;
      if (this.remainTime1 <= 0) {
        this.getGame('EOS1m');
      }
    },
  },
}
</script>

<style scoped>

</style>
