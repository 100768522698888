<template>
  <table width="100%" valign="top" border="0" cellspacing="0" cellpadding="0" class="ng-isolate-scope">
    <tbody>
    <tr v-if="lists">
      <td width="100%">
        <!-- ngInclude: centerTab -->
        <div class="ng-scope"
             :class="{
                    'soccer_wrap': game.sportSeq === 210,
                    'tennis_wrap': game.sportSeq === 54094,
                    'hockey_wrap': game.sportSeq === 211,
                    'volleyball_wrap': game.sportSeq === 214,
                    'basketball_wrap': game.sportSeq === 212,
                    'baseball_wrap': game.sportSeq === 220,
                    'football_wrap': game.sportSeq === 215,
                 }"
        >
          <div class="etc_left_45">
            <div class="etc_left_in_tennis">
              <!--    리그명    -->
              <p class="ng-binding">
                {{ game.league | leagueName }}
                <span style="text-align:right;font-size:17px; color: white;">{{
                    game.homeTeam | teamName
                  }}  :  {{ game.awayTeam | teamName }}</span>
              </p>
              <p class="ng-binding ng-scope">
                <span class="orange--text">{{ game.startTime | formatDate('YYYY-MM-DD HH:mm:ss') }}</span>
              </p>
              <!--       팀명       -->
              <span class="etc_time ng-binding ng-hide">
                  {{ game.homeTeam | teamName }}
                </span>
              <span class="etc_time ng-binding ng-hide">
                  {{ game.awayTeam | teamName }}
                </span>
            </div>
          </div>
        </div>
        <div style="padding:5px 0" v-if="originList">
          <table class="sports_in_tab" cellpadding="0" cellspacing="0">
            <tbody>
            <tr>
              <td>
          <span class="sports_in_tab_off_1" @click="selectMarket('STAR')">
            <img src="@/assets/img/ico/favor_on.png"> <span
              class="live_tab_count">{{ stared.filter(e => e.gameId === game.id).length }}</span>
          </span>
              </td>
              <td>
                <a @click="selectMarket(null)">
            <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === null}">
              전체 <span class="live_tab_count">{{ originList.length }}</span>
            </span>
                </a>
              </td>
              <td>
                <a @click="selectMarket('승무패')">
            <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === '승무패'}">
              승무패 <span class="live_tab_count">{{
                originList.filter(e => e.market.nameKr?.indexOf('승무패') >= 0 || e.market.nameKr?.indexOf('승패') >= 0).length
              }}</span>
            </span>
                </a>
              </td>
              <td>
                <a @click="selectMarket('오버언더')">
            <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === '오버언더'}">
              오버/언더 <span class="live_tab_count">{{
                originList.filter(e => e.market.nameKr?.indexOf('오버언더') >= 0).length
              }}</span>
            </span>
                </a>
              </td>
              <td>
                <a @click="selectMarket('핸디캡')">
            <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === '핸디캡'}">
              핸디캡 <span class="live_tab_count">{{
                originList.filter(e => e.market.nameKr?.indexOf('핸디캡') >= 0).length
              }}</span>
            </span>
                </a>
              </td>
              <td>
                <a @click="selectMarket('ETC')">
            <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === 'ETC'}">
              기타  <span class="live_tab_count">{{
                originList.filter(e =>
                    e.market.nameKr?.indexOf('핸디캡') === -1 &&
                    e.market.nameKr?.indexOf('승패') === -1 &&
                    e.market.nameKr?.indexOf('승패') === -1 &&
                    e.market.nameKr?.indexOf('승무패') === -1 &&
                    e.market.nameKr?.indexOf('오버언더') === -1
                ).length
              }}</span>
            </span>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
          <template v-if="!selectedMarket">
            <template v-for="(row, index) in marketSort(originList)">
              <template v-if="row.nameEn?.indexOf('{sw}') >= 0">
                <template v-for="period in uniqPeriod(row.seq)">
                  <!--    마켓명        -->
                  <div
                      class="accordion-header_2  open"
                      :key="'europeanFoldersDetail'+index+game.seq+period"
                      v-if="lists.filter(e => e.marketSeq === row.seq && String(e.periodSequence) === String(period))?.find(f => f.isVisible === 'y')"
                  >
              <span>
                <a href="#" class="left_menu_in_1_l_1">
                  <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === period)"
                       @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_on.png">
                  <img v-else @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_off.png">
                </a>
              </span>
                    <span style="margin-left:10px;">
                <b v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0 ">
                    {{ row | marketName(period) }}
                </b>
                <b v-else>
                  {{ row | teamMarketNamePeriod(game.homeTeam, game.awayTeam, period) }}
                </b>
              </span>
                    <span style="float: right; width:20px;" @click="folderClosed(row.seq, period)"
                          v-if="!closed.find(e => e.seq === row.seq && e.period === period)"><img
                        src="@/assets/img/ico/accordion-opened.png" alt=""></span>
                    <span style="float: right; width:20px;" @click="folderClosed(row.seq, period)" v-else><img
                        src="@/assets/img/ico/accordion-closed.png" alt=""></span>
                  </div>
                  <div :key="'europeanFoldersDetailOdds'+period+row.seq+index"
                       v-if="!closed.find(e => e.seq === row.seq && e.period === period)">
                    <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
                      <tbody>
                      <template
                          v-for="(row2, index2) in lists.filter(e => e.marketSeq === row.seq && String(e.periodSequence) === String(period))"
                      >
                        <tr :key="'europeanfolders2'+index2" v-if="row2.isVisible === 'y'">
                          <template v-if="row2.isSuspended === 'y'">
                            <DetailLock
                                :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Team1')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailLock>
                            <DetailLock
                                v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailLock>
                            <DetailLock
                                :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name ==='Away' || e.name === 'Team2')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.awayTeam"
                                :width="row2.bets.length"
                            ></DetailLock>
                          </template>
                          <template v-else>
                            <DetailPrice
                                :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes' || e.name === 'Team1')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailPrice>
                            <DetailPrice
                                v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailPrice>
                            <DetailPrice
                                :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No' || e.name === 'Team2')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.awayTeam"
                                :width="row2.bets.length"
                            ></DetailPrice>
                          </template>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </div>

                </template>
              </template>
              <template v-else>
                <div class="accordion-header_2" :key="'europeanFoldersDetail'+row.seq+index"
                     v-if="lists.filter(e => e.marketSeq === row.seq)?.find(f => f.isVisible === 'y')"
                >
            <span>
              <a href="#" class="left_menu_in_1_l_1">
                <a href="#" class="left_menu_in_1_l_1">
                  <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === '0')"
                       @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">
                  <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">
                </a>
              </a>
            </span>
                  <span style="margin-left:10px;">
              <b v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0 ">
                  {{ row | marketName }}
              </b>
              <b v-else>
                {{ row | teamMarketName(game.homeTeam, game.awayTeam) }}
              </b>
            </span>
                  <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')"
                        v-if="!closed.find(e => e.seq === row.seq && e.period === '0')"><img
                      src="@/assets/img/ico/accordion-opened.png" alt=""></span>
                  <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')" v-else><img
                      src="@/assets/img/ico/accordion-closed.png" alt=""></span>
                </div>
                <div v-if="!closed.find(e => e.seq === row.seq && e.period === '0')"
                     :key="'europeanFoldersDetailOdds'+row.seq+index">
                  <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
                    <tbody>
                    <template v-for="(row2, index2) in lists.filter(e => e.marketSeq === row.seq)">
                      <template v-if="row2.bets.length < 4">
                        <tr :key="'europeanfolders2'+index2" v-if="row2.isVisible === 'y'">
                          <template v-if="row2.isSuspended === 'y'">
                            <DetailLock
                                :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Team1')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailLock>
                            <DetailLock
                                v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal' || e.name === 'NoGoal')"
                                :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal' || e.name === 'NoGoal')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailLock>
                            <DetailLock
                                :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name ==='Away' || e.name === 'Team2')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.awayTeam"
                                :width="row2.bets.length"
                            ></DetailLock>
                          </template>
                          <template v-else>
                            <DetailPrice
                                :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes' || e.name === 'Team1')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailPrice>
                            <DetailPrice
                                v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal' || e.name === 'NoGoal')"
                                :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal' || e.name === 'NoGoal')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailPrice>
                            <DetailPrice
                                :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No' || e.name === 'Team2')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.awayTeam"
                                :width="row2.bets.length"
                            ></DetailPrice>
                          </template>
                        </tr>
                      </template>
                      <template v-else-if="row2.bets.length > 4">
                        <template v-for="l in arrLenDiv(row2.bets)">
                          <tr v-if="row2.isVisible === 'y'" :key="'CorrectScore'+l+row2.seq">
                            <template v-if="row2.isSuspended === 'y'">
                              <DetailCorrectScoreLock
                                  v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)"
                                  :key="'betDetail'+bet.id"
                                  :bets="bet"
                              >
                              </DetailCorrectScoreLock>
                            </template>
                            <template v-else>
                              <DetailCorrectScorePrice
                                  v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)"
                                  :key="'betDetail'+bet.id"
                                  :bets="bet"
                                  :folder="row2"
                                  :add-cart="addCart"
                                  :check-cart="checkCart"
                                  :game="game"
                              >
                              </DetailCorrectScorePrice>
                            </template>
                          </tr>
                        </template>
                      </template>
                    </template>
                    </tbody>
                  </table>
                </div>
              </template>
            </template>
          </template>
          <template v-else>
            <template v-for="(row, index) in marketSort(lists)">
              <template
                  v-if="row.nameEn?.indexOf('{sw}') >= 0"
              >
                <template v-for="period in uniqPeriod(row.seq)">
                  <div
                      class="accordion-header_2"
                      :key="'europeanFoldersDetail'+row+game.seq+period"
                  >
              <span>
                  <a href="#" class="left_menu_in_1_l_1">
                    <img
                        v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === period)"
                        @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_on.png">
                    <img v-else @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_off.png">
                  </a>
              </span>
                    <span style="margin-left:10px;">
                <b>
                    {{ row | marketName(period) }}
                </b>
              </span>
                    <span style="float: right; width:20px;" @click="folderClosed(row.seq, period)"
                          v-if="!closed.find(e => e.seq === row.seq && e.period === period)"><img
                        src="@/assets/img/ico/accordion-opened.png" alt=""></span>
                    <span style="float: right; width:20px;" @click="folderClosed(row.seq, period)" v-else><img
                        src="@/assets/img/ico/accordion-closed.png" alt=""></span>
                  </div>
                  <div v-if="!closed.find(e => e.seq === row.seq && e.period === period)"
                       :key="'europeanFoldersDetailOdds'+period+row.seq+index">
                    <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
                      <tbody>
                      <template
                          v-for="(row2, index2) in lists.filter(e => e.marketSeq === row.seq && String(e.periodSequence) === String(period))"
                      >
                        <tr :key="'europeanfolders2'+index2" v-if="row2.isVisible">
                          <template v-if="row2.isSuspended === 'y'">
                            <DetailLock
                                :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailLock>
                            <DetailLock
                                v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailLock>
                            <DetailLock
                                :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name ==='Away')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.awayTeam"
                                :width="row2.bets.length"
                            ></DetailLock>
                          </template>
                          <template v-else>
                            <DetailPrice
                                :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailPrice>
                            <DetailPrice
                                v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailPrice>
                            <DetailPrice
                                :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.awayTeam"
                                :width="row2.bets.length"
                            ></DetailPrice>
                          </template>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </div>

                </template>
              </template>
              <template v-else>
                <div class="accordion-header_2" :key="'europeanFoldersDetail'+row.seq+index">
                  <span>
                    <a href="#" class="left_menu_in_1_l_1">
                      <img
                          v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === '0')"
                          @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">
                      <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">
                    </a>
                  </span>
                  <span style="margin-left:10px;">
                    <b>
                        {{ row | marketName }}
                    </b>
                  </span>
                  <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')"
                        v-if="!closed.find(e => e.seq === row.seq && e.period === '0')"><img
                      src="@/assets/img/ico/accordion-opened.png" alt=""></span>
                  <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')" v-else><img
                      src="@/assets/img/ico/accordion-closed.png" alt=""></span>
                </div>
                <div v-if="!closed.find(e => e.seq === row.seq && e.period === '0')"
                     :key="'europeanFoldersDetailOdds'+row.seq+index">
                  <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
                    <tbody>
                    <template v-for="(row2, index2) in lists.filter(e => e.marketSeq === row.seq)">
                      <template v-if="row2.bets.length < 4">
                        <tr :key="'europeanfolders2'+index2" v-if="row2.isVisible === 'y'">
                          <template v-if="row2.isSuspended === 'y'">
                            <DetailLock
                                :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailLock>
                            <DetailLock
                                v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailLock>
                            <DetailLock
                                :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name ==='Away')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.awayTeam"
                                :width="row2.bets.length"
                            ></DetailLock>
                          </template>
                          <template v-else>
                            <DetailPrice
                                :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailPrice>
                            <DetailPrice
                                v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.homeTeam"
                                :width="row2.bets.length"
                            ></DetailPrice>
                            <DetailPrice
                                :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No')"
                                :folder="row2"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :game="game"
                                :team="game.awayTeam"
                                :width="row2.bets.length"
                            ></DetailPrice>
                          </template>
                        </tr>
                      </template>
                      <template v-else-if="row2.bets.length > 4">
                        <template v-for="l in arrLenDiv(row2.bets)">
                          <tr v-if="row2.isVisible === 'y'" :key="'CorrectScore'+l+row2.seq">
                            <template v-if="row2.isSuspended === 'y'">
                              <DetailCorrectScoreLock
                                  v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)"
                                  :key="'betDetail'+bet.id"
                                  :bets="bet"
                              >
                              </DetailCorrectScoreLock>
                            </template>
                            <template v-else>
                              <DetailCorrectScorePrice
                                  v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)"
                                  :key="'betDetail'+bet.id"
                                  :bets="bet"
                                  :folder="row2"
                                  :add-cart="addCart"
                                  :check-cart="checkCart"
                                  :game="game"
                              >
                              </DetailCorrectScorePrice>
                            </template>
                          </tr>
                        </template>
                      </template>
                    </template>
                    </tbody>
                  </table>
                </div>
              </template>
            </template>
          </template>
        </div>
      </td>
    </tr>
    <tr v-else>
      <td style="text-align: center;">
        <Loading></Loading>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
// import Price from '@/components/Game/Sports/Inplay/DetailPrice3';
// import Lock from '@/components/Game/Sports/Inplay/DetailLock2';
import Loading from '@/components/Loading';
import DetailPrice from './DetailPrice'
import DetailLock from './DetailLock'
import DetailCorrectScoreLock from './DetailCorrectScoreLock'
import DetailCorrectScorePrice from './DetailCorrectScorePrice'

export default {
  name: "SelectedGame",
  components: {
    // Price,
    // Lock,
    Loading,
    DetailPrice,
    DetailLock,
    DetailCorrectScorePrice,
    DetailCorrectScoreLock,
  },
  props: ['game', 'addCart', 'checkCart'],
  data: function () {
    return {
      path: 'v2/game/european/detail',
      cartType: 'sports',
      originList: null,
      lists: null,
      isLoading: false,
      selectedMarket: null,
      closed: [],
    }
  },
  mounted() {
    this.initFolder();
    this.$socket.client.on('prematch', this.updateData)
  },
  beforeDestroy: function () {
    this.$socket.client.off('prematch');
  },
  computed: {
    stared: function () {
      return this.$store.state.list.stared;
    },
  },
  watch: {
    selectedMarket: function (val) {
      if (!val) return this.lists = this.originList;
      if (val === '승무패') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11557, 11558, 11575, 11649, 11930].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11603, 11604, 11611].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 212) return [11531, 11537, 11667].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11521, 11544].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11622, 11629, 11625].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 220) return [11515, 11507, 12972, 13103].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12240, 12242, 12243, 12244, 12245, 12246, 16817, 16818].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === '핸디캡') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11562, 11563, 11580, 11752, 11968].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11608, 11615, 11615, 11615].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 212) return [11532, 11539, 11668].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11904, 11724, 11546].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11623, 11631, 11626].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 220) return [11516, 11509, 17055].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12651, 12775, 12786, 12797, 12808, 12823].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === '오버언더') {
        this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11559, 11560, 11561, 11577, 11753, 11969, 11592, 11586].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11605, 11607, 11606, 11612, 11612, 11612, 11613, 11613, 11613, 11614, 11614, 11614].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 212) return [11533, 11534, 11535, 11662, 11663, 11664, 11669].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11726, 11723, 11720, 12104, 11545].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 220) return [11512, 11510, 11511, 13655, 11508, 17056, 13106].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12651, 12776, 12787, 12798, 12809, 12824].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === 'ETC') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11573, 11574, 11923, 12930, 11684, 11685, 11567, 11572, 11568, 11727, 13619, 11732].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11652, 11653, 11654, 11658, 11659, 11656, 11717, 11718].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 212) return [11536, 16637, 12530, 12531, 12566, 12567, 12568, 13558, 14049].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11522, 11540, 11543, 11675, 12105].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 220) return [13102, 13101].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12646, 12648, 12653, 12812, 12827, 12777, 12788, 12799, 12813, 12828, 12645, 12647, 12652, 12810, 12825, 12780, 12791, 12802, 12816, 12831, 12778, 12789, 12800, 12814, 12829, 12782, 12793, 12804, 12818, 12833, 12783, 12794, 12805, 12819, 12834, 12784, 12795, 12806, 12820, 12835].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === 'STAR') {
        console.log(this.stared);
        this.lists = [];
        this.stared.map(s => {
          const lists = this.originList.filter(l => String(s.gameId) === String(this.game.id) && String(s.seq) === String(l.marketSeq) && String(s.periodSequence) === String(l.periodSequence))
          lists.map(folder => this.lists.push(folder));
        })

        return;
      }

      this.lists = this.originList.filter(e => e.market.nameKr?.indexOf(val) >= 0);
    },
    game: function () {
      this.initFolder();
    }
  },
  methods: {
    initFolder: function () {
      const payload = {
        gameSeq: this.game.seq
      }
      this.lists = null;
      return this.$store.dispatch('auth/post', {path: this.path, data: {payload}})
          .then(data => {
            const result = data.data;
            this.originList = result.payload.list;
            this.lists = this.originList;
            this.isLoading = true;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    /*
    * 종목의 자세히 버튼
    * **/
    checkArray: function (id) {
      let result = this.openDetailBet.indexOf(id) >= 0
      return result
    },
    /**
     * 토글 액션
     * */
    marketSort: function (folders) {
      const markets = folders.map(e => e.market);
      const arrUnique = markets.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.seq === character.seq) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    toggleDetail: function (id) {
      this.toggle(this.openDetailBet, id)
    },
    removeAtIndex: function (arr, index) {
      this.openDetailBet.splice(index, 1);
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailBet = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    uniqPeriod: function (marketSeq) {
      const periods = this.lists.filter(e => e.marketSeq === marketSeq).map(e => e.periodSequence);
      let set = new Set(periods);
      const uniqueArr = [...set];
      uniqueArr.sort()
      return uniqueArr
    },
    arrLenDiv: function (arr, div = 4) {
      return Math.floor(arr.length / div) + 1
    },
    // 인플레이 경기 자세히 내에 해당 폴더 변경
    updateData: function (data) {
      const result = JSON.parse(data);
      const gameMarket = result.filter(e => String(e.MatchId) === (this.game.id))
      if (gameMarket.length > 0) {
        gameMarket.map(m => {
          const folder = this.lists.find(e => String(e.id) === String(m.Id));
          if (folder) {
            folder.isVisible = m.IsVisible ? 'y' : 'n';
            folder.isSuspended = m.IsSuspended ? 'y' : 'n';
            m.Selections.map(s => {
              const bet = folder.bets.find(b => String(b.id) === String(s.Id));
              bet.price = s.Price;
              const cartItem = this.$store.state.cart.sports.find(e => String(e.betsId) === String(s.Id));
              if (cartItem) {
                cartItem.odds = Number(s.Price).toFixed(2);
                cartItem.betsStatus = s.IsSuspended ? 'y' : 'n';
                cartItem.betsUpdated = true;
              }
            })
          }
        })
      }
    },
    addStartedMarket: function (seq, period) {
      console.log(seq, period);
      const market = {
        gameId: this.game.id,
        seq: seq,
        periodSequence: period,
      }
      this.$store.dispatch('list/started', market);
    },
    folderClosed: function (seq, period) {
      const data = {
        seq,
        period
      };
      const index = this.closed.findIndex(e => e.seq === seq && e.period === period)
      if (index >= 0) return this.closed.splice(index, 1);
      this.closed.push(data);
    },
    selectMarket: function (market) {
      this.selectedMarket = market;
    },
  },

}
</script>

<style scoped>
</style>
