<template>
  <div style="padding:5px 0" v-if="originList">
    <table class="sports_in_tab" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td>
        </td>
        <td>
          <a @click="selectMarket(null)">
            <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === null}">
              전체 <span class="live_tab_count">{{ originList.length }}</span>
            </span>
          </a>
        </td>
        <td>
          <a @click="selectMarket('승무패')">
            <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === '승무패'}">
              승무패 <span class="live_tab_count">{{
                originList.filter(e => e.market.nameKr?.indexOf('승무패') >= 0 || e.market.nameKr?.indexOf('승패') >= 0).length
              }}</span>
            </span>
          </a>
        </td>
        <td>
          <a @click="selectMarket('오버언더')">
            <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === '오버언더'}">
              오버/언더 <span class="live_tab_count">{{ originList.filter(e=> e.market.nameKr?.indexOf('오버언더') >= 0).length }}</span>
            </span>
          </a>
        </td>
        <td>
          <a @click="selectMarket('핸디캡')">
            <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === '핸디캡'}">
              핸디캡 <span class="live_tab_count">{{ originList.filter(e=> e.market.nameKr?.indexOf('핸디캡') >= 0).length }}</span>
            </span>
          </a>
        </td>
        <td>
          <a @click="selectMarket('ETC')">
            <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === 'ETC'}">
              기타  <span class="live_tab_count">{{
                originList.filter(e =>
                    e.market.nameKr?.indexOf('핸디캡') === -1 &&
                    e.market.nameKr?.indexOf('승패') === -1 &&
                    e.market.nameKr?.indexOf('승패') === -1 &&
                    e.market.nameKr?.indexOf('승무패') === -1 &&
                    e.market.nameKr?.indexOf('오버언더') === -1
                ).length
              }}</span>
            </span>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
    <template v-if="!selectedMarket">
      <template v-for="(row, index) in marketSort(originList)" >
        <div class="accordion-header_2" :key="'europeanFoldersDetail'+row.seq+index"
             v-if="lists.filter(e => String(e.marketId) === String(row.id))"
        >
            <span>
              <a href="#" class="left_menu_in_1_l_1">
                <a href="#" class="left_menu_in_1_l_1">
                </a>
              </a>
            </span>
          <span style="margin-left:10px;">
              <b>
                  {{ row | marketNameLsport(game) }}
              </b>
            </span>
          <span style="float: right; width:20px;" @click="folderClosed(row.seq)" v-if="!closed.find(e => e.seq === row.seq)"><img src="@/assets/img/ico/accordion-opened.png" alt=""></span>
          <span style="float: right; width:20px;" @click="folderClosed(row.seq)" v-else><img src="@/assets/img/ico/accordion-closed.png" alt=""></span>
        </div>
        <div v-if="!closed.find(e => e.seq === row.seq)" :key="'europeanFoldersDetailOdds'+row.seq+index">
          <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
            <tbody>
            <template v-for="(row2, index2) in lists.filter(e => String(e.marketId) === String(row.id))">
              <template v-if="row2.bets.length < 4">
                <tr :key="'europeanfolders2'+index2" v-if="row2.isVisible === 'y'">
                  <template v-if="row2.isSuspended === 'y'  || game.isSuspended === 'y'">
                    <DetailLock
                        :bets="row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Team1' || e.name === 'Yes')"
                        :folder="row2"
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :game="game"
                        :team="game.homeTeam"
                        :width="row2.bets.length"
                    ></DetailLock>
                    <DetailLock
                        v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal' || e.name === 'NoGoal')"
                        :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal' || e.name === 'NoGoal')"
                        :folder="row2"
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :game="game"
                        :team="game.homeTeam"
                        :width="row2.bets.length"
                    ></DetailLock>
                    <DetailLock
                        :bets="row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name ==='Away' || e.name === 'Team2' || e.name === 'No')"
                        :folder="row2"
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :game="game"
                        :team="game.awayTeam"
                        :width="row2.bets.length"
                    ></DetailLock>
                  </template>
                  <template v-else>
                    <DetailPrice
                        :bets="row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes' || e.name === 'Team1')"
                        :folder="row2"
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :game="game"
                        :team="game.homeTeam"
                        :width="row2.bets.length"
                    ></DetailPrice>
                    <DetailPrice
                        v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal' || e.name === 'No Goal')"
                        :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal' || e.name === 'No Goal')"
                        :folder="row2"
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :game="game"
                        :team="game.homeTeam"
                        :width="row2.bets.length"
                    ></DetailPrice>
                    <DetailPrice
                        :bets="row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No' || e.name === 'Team2')"
                        :folder="row2"
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :game="game"
                        :team="game.awayTeam"
                        :width="row2.bets.length"
                    ></DetailPrice>
                  </template>
                </tr>
              </template>
              <template v-else-if="row2.bets.length >= 4" >
                <template v-for="l in arrLenDiv(row2.bets)">
                  <tr v-if="row2.isVisible === 'y'" :key="'CorrectScore'+l+row2.seq">
                    <template v-if="row2.isSuspended === 'y'|| game.isSuspended === 'y'">
                      <DetailCorrectScoreLock
                          v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)"
                          :key="'betDetail'+bet.id"
                          :bets="bet"
                      >
                      </DetailCorrectScoreLock>
                    </template>
                    <template v-else>
                      <DetailCorrectScorePrice
                          v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)"
                          :key="'betDetail'+bet.id"
                          :bets="bet"
                          :folder="row2"
                          :add-cart="addCart"
                          :check-cart="checkCart"
                          :game="game"
                      >
                      </DetailCorrectScorePrice>
                    </template>
                  </tr>
                </template>
              </template>
            </template>
            </tbody>
          </table>
        </div>
      </template>
    </template>
    <template v-else>
      <template v-for="(row, index) in marketSort(lists)" >
        <div class="accordion-header_2" :key="'europeanFoldersDetail'+row + game.seq +index">
            <span>
                <a href="#" class="left_menu_in_1_l_1">
                </a>
            </span>
          <span style="margin-left:10px;">
              <b>
                  {{ row | marketNameLsport(game) }}
              </b>
            </span>
          <span style="float: right; width:20px;" @click="folderClosed(row.seq)" v-if="!closed.find(e => e.seq === row.seq)"><img src="@/assets/img/ico/accordion-opened.png" alt=""></span>
          <span style="float: right; width:20px;" @click="folderClosed(row.seq)" v-else><img src="@/assets/img/ico/accordion-closed.png" alt=""></span>
        </div>
        <div v-if="!closed.find(e => e.seq === row.seq)" :key="'europeanFoldersDetailOdds'+row + game.seq +index">
          <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
            <tbody>
            <template v-for="(row2, index2) in lists.filter(e => String(e.marketId) === String(row.id))">
              <template v-if="row2.bets.length < 4">
                <tr :key="'europeanfolders2'+index2" v-if="row2.isVisible === 'y'">
                  <template v-if="row2.isSuspended === 'y' || game.isSuspended === 'y'">
                    <DetailLock
                        :bets="row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes')"
                        :folder="row2"
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :game="game"
                        :team="game.homeTeam"
                        :width="row2.bets.length"
                    ></DetailLock>
                    <DetailLock
                        v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                        :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                        :folder="row2"
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :game="game"
                        :team="game.homeTeam"
                        :width="row2.bets.length"
                    ></DetailLock>
                    <DetailLock
                        :bets="row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name ==='Away' || e.name === 'No')"
                        :folder="row2"
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :game="game"
                        :team="game.awayTeam"
                        :width="row2.bets.length"
                    ></DetailLock>
                  </template>
                  <template v-else>
                    <DetailPrice
                        :bets="row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes')"
                        :folder="row2"
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :game="game"
                        :team="game.homeTeam"
                        :width="row2.bets.length"
                    ></DetailPrice>
                    <DetailPrice
                        v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                        :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                        :folder="row2"
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :game="game"
                        :team="game.homeTeam"
                        :width="row2.bets.length"
                    ></DetailPrice>
                    <DetailPrice
                        :bets="row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No')"
                        :folder="row2"
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :game="game"
                        :team="game.awayTeam"
                        :width="row2.bets.length"
                    ></DetailPrice>
                  </template>
                </tr>
              </template>
              <template v-else-if="row2.bets.length >= 4" >
                <template v-for="l in arrLenDiv(row2.bets)">
                  <tr v-if="row2.isVisible === 'y'" :key="'CorrectScore'+l+row2.seq">
                    <template v-if="row2.isSuspended === 'y' || game.isSuspended === 'y'">
                      <DetailCorrectScoreLock
                          v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)"
                          :key="'betDetail'+bet.id"
                          :bets="bet"
                      >
                      </DetailCorrectScoreLock>
                    </template>
                    <template v-else>
                      <DetailCorrectScorePrice
                          v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)"
                          :key="'betDetail'+bet.id"
                          :bets="bet"
                          :folder="row2"
                          :add-cart="addCart"
                          :check-cart="checkCart"
                          :game="game"
                      >
                      </DetailCorrectScorePrice>
                    </template>
                  </tr>
                </template>
              </template>
            </template>
            </tbody>
          </table>
        </div>
      </template>
    </template>
  </div>
  <div style="text-align: center;" v-else>
    <Loading></Loading>
  </div>
</template>

<script>
import {mapState} from "vuex";
import DetailPrice from './LsportDetailPrice'
import DetailLock from './LsportDetailLock'
import DetailCorrectScoreLock from './LsportDetailCorrectScoreLock'
import DetailCorrectScorePrice from './LsportDetailCorrectScorePrice'
import Loading from '@/components/Loading'

export default {
  name: "European",
  props: ['game', 'addCart'],
  components: {
    DetailPrice,
    DetailLock,
    DetailCorrectScorePrice,
    DetailCorrectScoreLock,
    Loading
  },
  data: function () {
    return {
      lists: null,
      originList: null,
      openDetailBet: [],
      selectedMarket: null,
      isLoading: false,
      closed: [],
    }
  },
  mounted() {
    this.initFolder();
  },
  watch: {
    selectedMarket: function (val) {
      if (!val) return this.lists = this.originList;
      if (val === '승무패') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportId === 6046) return [11557, 11558, 11575, 11649, 11930].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 211) return [11603, 11604, 11611].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 212) return [11531, 11537, 11667].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 214) return [11521, 11544].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 215) return [11622, 11629, 11625].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 220) return [11515, 11507, 12972, 13103].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 267) return [12240, 12242, 12243, 12244, 12245, 12246, 16817, 16818].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === '핸디캡') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportId === 210) return [11562, 11563, 11580, 11752, 11968].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 211) return [11608, 11615, 11615, 11615].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 212) return [11532, 11539, 11668].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 214) return [11904, 11724, 11546].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 215) return [11623, 11631, 11626].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 220) return [11516, 11509, 17055].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 267) return [12651, 12775, 12786, 12797, 12808, 12823].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === '오버언더') {
        this.lists = this.originList.filter(e => {
          if (this.game.sportId === 210) return [11559, 11560, 11561, 11577, 11753, 11969, 11592, 11586].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 211) return [11605, 11607, 11606, 11612, 11612, 11612, 11613, 11613, 11613, 11614, 11614, 11614].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 212) return [11533, 11534, 11535, 11662, 11663, 11664, 11669].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 214) return [11726, 11723, 11720, 12104, 11545].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 215) return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 220) return [11512, 11510, 11511, 13655, 11508, 17056, 13106].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 267) return [12651, 12776, 12787, 12798, 12809, 12824].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === 'ETC') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportId === 210) return [11573, 11574, 11923, 12930, 11684, 11685, 11567, 11572, 11568, 11727, 13619, 11732].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 211) return [11652, 11653, 11654, 11658, 11659, 11656, 11717, 11718].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 212) return [11536, 16637, 12530, 12531, 12566, 12567, 12568, 13558, 14049].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 214) return [11522, 11540, 11543, 11675, 12105].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 215) return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 220) return [13102, 13101].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 267) return [12646, 12648, 12653, 12812, 12827, 12777, 12788, 12799, 12813, 12828, 12645, 12647, 12652, 12810, 12825, 12780, 12791, 12802, 12816, 12831, 12778, 12789, 12800, 12814, 12829, 12782, 12793, 12804, 12818, 12833, 12783, 12794, 12805, 12819, 12834, 12784, 12795, 12806, 12820, 12835].indexOf(e.marketSeq) >= 0
        });
      }


      this.lists = this.originList.filter(e => e.market.nameKr?.indexOf(val) >= 0);
    }
  },
  computed: {
    ...mapState({
      cartItem: state => state.lsport.cart,
    }),
  },
  methods: {
    initFolder: function () {
      const payload = {
        gameId: this.game.id
      }
      return this.$store.dispatch('GET_LSPORT_DETAIL', { payload })
          .then(data => {
            const result = data.data;
            this.originList = result.payload.list;
            this.game = result.payload.game;
            this.lists = this.originList;
            this.$store.dispatch('UPDATE_LSPORT_DATA', { data: result.payload })
            this.isLoading = true;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    /*
    * 종목의 자세히 버튼
    * **/
    checkArray: function (id) {
      let result = this.openDetailBet.indexOf(id) >= 0
      return result
    },
    /**
     * 토글 액션
     * */
    marketSort: function(lists){
      const markets = lists.map(e => e.market);
      const arrUnique = markets.filter((character, idx, arr)=>{
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    toggleDetail: function (id) {
      this.toggle(this.openDetailBet, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailBet.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailBet = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    // 베팅카트에 담기
    // 카트내역 체크해서 표시
    checkCart: function (bets, type) {
      // console.log(this.$store.state.cart[this.cartType]);
      if (type === '보너스') {
        // let index = this.$store.state.cart[this.cartType].find(item => item.odds === event)
        // return !!index;
        return null;
      } else {
        let index = this.cartItem.find(item => item.betsSeq === bets.seq)
        return !!index;
      }
    },
    selectMarket: function(market) {
      this.selectedMarket = market;
    },
    arrLenDiv: function (arr, div = 4) {
      return Math.floor(arr.length / div) + 1
    },
    addStartedMarket: function (seq, period) {
      const market = {
        gameId: this.game.id,
        seq: seq,
        periodSequence: period,
      }
      this.$store.dispatch('list/started', market);
    },
    folderClosed: function(seq){
      const data = {
        seq,
      };
      const index = this.closed.findIndex(e => e.seq === seq)
      if (index >= 0) return this.closed.splice(index, 1);
      this.closed.push(data);
    }
  },
}
</script>

<style scoped>
.row-detail-list-move {
  transition: transform 1s;
}

.row-detail-list {
  transition: transform 1s;
}
</style>
