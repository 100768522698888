<template>
  <div>
    <div class="content desktop" style="min-height: 800px;">
      <h1>
          자유게시판
      </h1>
      <div class="search-bar">
          <input placeholder="검색" type="text"/>
          <div class="write-button">
              <button @click="$router.push({name: '자유게시판-글쓰기'})">
                  글쓰기
              </button>
          </div>
      </div>
      <div class="table-container">
          <table>
              <thead>
              <tr>
                  <th>
                      업체명
                  </th>
                  <th>
                      카테고리
                  </th>
                  <th>
                      제목
                  </th>
                  <th>
                      작성자
                  </th>
                  <th>
                      작성일
                  </th>
                  <th>
                      조회
                  </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                  <td>
                      전체
                  </td>
                  <td>
          <span style="color: #ffcc00;">
          이벤트
          </span>
                  </td>
                  <td>
                      Luna 이벤트 진행 목록
                  </td>
                  <td>
                      운영진
                  </td>
                  <td>
                      2024-11-08 12:30
                  </td>
                  <td>
                      1000
                  </td>
              </tr>
              <tr>
                  <td>
                      전체
                  </td>
                  <td>
          <span style="color: #ff0000;">
          공지
          </span>
                  </td>
                  <td>
                      안녕하세요
                  </td>
                  <td>
                      운영진
                  </td>
                  <td>
                      2024-11-08 12:30
                  </td>
                  <td>
                      1000
                  </td>
              </tr>
              <tr>
                  <td>
                      전체
                  </td>
                  <td>
          <span style="color: #ff0000;">
          공지
          </span>
                  </td>
                  <td>
                      자유게시판 이용규정 안내
                  </td>
                  <td>
                      운영진
                  </td>
                  <td>
                      2024-11-08 12:30
                  </td>
                  <td>
                      1000
                  </td>
              </tr>
              <tr>
                  <td>
                      <img alt="Logo 1" height="20"
                          src="https://oaidalleapiprodscus.blob.core.windows.net/private/org-SteOp6BHgYZbZ5Ct5m8mzzMz/user-42eBDkP1fhGWwdrLRVEiD23u/img-ubs2pLfBzQ4xl3YlRFTDNuoX.png?st=2024-12-11T05%3A34%3A31Z&amp;se=2024-12-11T07%3A34%3A31Z&amp;sp=r&amp;sv=2024-08-04&amp;sr=b&amp;rscd=inline&amp;rsct=image/png&amp;skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&amp;sktid=a48cca56-e6da-484e-a814-9c849652bcb3&amp;skt=2024-12-10T21%3A33%3A19Z&amp;ske=2024-12-11T21%3A33%3A19Z&amp;sks=b&amp;skv=2024-08-04&amp;sig=1xZiL6BuYU3AFGCfRzob4/Dg0mBi8QRWLssd%2BoyZIEQ%3D"
                          width="50"/>
                  </td>
                  <td>
                      일반
                  </td>
                  <td>
                      안녕하세요 반갑습니다
                      <span style="color: #ff0000;">
          ✘
          </span>
                  </td>
                  <td>
                      토토왕주
                  </td>
                  <td>
                      2024-11-15 17:30
                  </td>
                  <td>
                      0
                  </td>
              </tr>
              <tr>
                  <td>
                      <img alt="Logo 2" height="20"
                          src="https://oaidalleapiprodscus.blob.core.windows.net/private/org-SteOp6BHgYZbZ5Ct5m8mzzMz/user-42eBDkP1fhGWwdrLRVEiD23u/img-7EiD3L87Ig413QQPWgaC4ff3.png?st=2024-12-11T05%3A34%3A32Z&amp;se=2024-12-11T07%3A34%3A32Z&amp;sp=r&amp;sv=2024-08-04&amp;sr=b&amp;rscd=inline&amp;rsct=image/png&amp;skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&amp;sktid=a48cca56-e6da-484e-a814-9c849652bcb3&amp;skt=2024-12-10T21%3A53%3A10Z&amp;ske=2024-12-11T21%3A53%3A10Z&amp;sks=b&amp;skv=2024-08-04&amp;sig=faXZ6nHNkf0CqgIlWiqSHJBHDUypIBJpLgPOy1EI/GE%3D"
                          width="50"/>
                  </td>
                  <td>
                      일반
                  </td>
                  <td>
                      베팅내역
                      <span style="color: #ffcc00;">
          🟡
          </span>
                  </td>
                  <td>
                      kingking
                  </td>
                  <td>
                      2024-11-15 17:10
                  </td>
                  <td>
                      2
                  </td>
              </tr>
              <tr>
                  <td>
                      <img alt="Logo 3" height="20"
                          src="https://oaidalleapiprodscus.blob.core.windows.net/private/org-SteOp6BHgYZbZ5Ct5m8mzzMz/user-42eBDkP1fhGWwdrLRVEiD23u/img-Y0t2yDMYW2Hduj4shjqMLJgk.png?st=2024-12-11T05%3A34%3A31Z&amp;se=2024-12-11T07%3A34%3A31Z&amp;sp=r&amp;sv=2024-08-04&amp;sr=b&amp;rscd=inline&amp;rsct=image/png&amp;skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&amp;sktid=a48cca56-e6da-484e-a814-9c849652bcb3&amp;skt=2024-12-10T21%3A22%3A05Z&amp;ske=2024-12-11T21%3A22%3A05Z&amp;sks=b&amp;skv=2024-08-04&amp;sig=qlWEjkWIBca67hqHTOdShTNrUIrugoqRPeG1jzL46tw%3D"
                          width="50"/>
                  </td>
                  <td>
                      일반
                  </td>
                  <td>
                      ㅋㅋㅋㅋ
                      <span style="color: #ffcc00;">
          (1)
          </span>
                  </td>
                  <td>
                      이지은
                  </td>
                  <td>
                      2024-11-15 17:08
                  </td>
                  <td>
                      5
                  </td>
              </tr>
              <tr>
                  <td>
                      <img alt="Logo 4" height="20"
                          src="https://oaidalleapiprodscus.blob.core.windows.net/private/org-SteOp6BHgYZbZ5Ct5m8mzzMz/user-42eBDkP1fhGWwdrLRVEiD23u/img-eMHeCYSUgaaV9suOB4lyFlMJ.png?st=2024-12-11T05%3A34%3A31Z&amp;se=2024-12-11T07%3A34%3A31Z&amp;sp=r&amp;sv=2024-08-04&amp;sr=b&amp;rscd=inline&amp;rsct=image/png&amp;skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&amp;sktid=a48cca56-e6da-484e-a814-9c849652bcb3&amp;skt=2024-12-10T21%3A27%3A49Z&amp;ske=2024-12-11T21%3A27%3A49Z&amp;sks=b&amp;skv=2024-08-04&amp;sig=7O4hTPmVoyZ2AKNTTH2nntXUd4NkKTJrL9U0SRVNoiE%3D"
                          width="50"/>
                  </td>
                  <td>
                      일반
                  </td>
                  <td>
                      오늘 뭐기좋나요?
                      <span style="color: #ffcc00;">
          (3)
          </span>
                  </td>
                  <td>
                      가나다라마바사
                  </td>
                  <td>
                      2024-11-15 17:08
                  </td>
                  <td>
                      5
                  </td>
              </tr>
              </tbody>
          </table>
      </div>
      <div class="pagination">
          <a href="#">
              «
          </a>
          <a class="active" href="#">
              1
          </a>
          <a href="#">
              2
          </a>
          <a href="#">
              3
          </a>
          <a href="#">
              4
          </a>
          <a href="#">
              5
          </a>
          <a href="#">
              6
          </a>
          <a href="#">
              7
          </a>
          <a href="#">
              8
          </a>
          <a href="#">
              9
          </a>
          <a href="#">
              10
          </a>
          <a href="#">
              »
          </a>
      </div>
    </div>
    <div class="content mobile">
      <h1>
          자유게시판
      </h1>
      <div class="post event">
          <div class="title">
              <div class="label">
                  이벤트
              </div>
              <div class="text">
                  Luna 이벤트 진행목록
              </div>
          </div>
          <div class="details">
              <div class="author">
                  <img alt="운영진" src="https://placehold.co/20x20"/>
                  운영진
              </div>
              <div class="info">
                  <i class="far fa-clock">
                  </i>
                  2024-11-19 05:13
                  <i class="far fa-eye">
                  </i>
                  1000
                  <i class="far fa-comments">
                  </i>
                  2
              </div>
          </div>
      </div>
      <div class="post notice">
          <div class="title">
              <div class="label">
                  공지
              </div>
              <div class="text">
                  자유게시판 이용규정 안내
              </div>
          </div>
          <div class="details">
              <div class="author">
                  <img alt="운영진" src="https://placehold.co/20x20"/>
                  운영진
              </div>
              <div class="info">
                  <i class="far fa-clock">
                  </i>
                  2024-11-19 05:13
                  <i class="far fa-eye">
                  </i>
                  1000
                  <i class="far fa-comments">
                  </i>
                  5
              </div>
          </div>
      </div>
      <div class="post general">
          <div class="title">
              <div class="label">
                  일반
              </div>
              <div class="text">
                  안녕하세요 반갑습니다.
              </div>
          </div>
          <div class="details">
              <div class="author">
                  <img alt="토토공주" height="20"
                      src="https://oaidalleapiprodscus.blob.core.windows.net/private/org-SteOp6BHgYZbZ5Ct5m8mzzMz/user-42eBDkP1fhGWwdrLRVEiD23u/img-qjM1v8LhMXCPkBfybxUk3JVp.png?st=2024-12-11T05%3A13%3A00Z&amp;se=2024-12-11T07%3A13%3A00Z&amp;sp=r&amp;sv=2024-08-04&amp;sr=b&amp;rscd=inline&amp;rsct=image/png&amp;skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&amp;sktid=a48cca56-e6da-484e-a814-9c849652bcb3&amp;skt=2024-12-10T22%3A01%3A02Z&amp;ske=2024-12-11T22%3A01%3A02Z&amp;sks=b&amp;skv=2024-08-04&amp;sig=h7USZlXpAFf61LzL9dQYmPDwOhCcbJsd7U2XuRhUOEE%3D"
                      width="20"/>
                  토토공주
              </div>
              <div class="info">
                  <i class="far fa-clock">
                  </i>
                  2024-11-19 11:13
                  <i class="far fa-eye">
                  </i>
                  5
                  <i class="far fa-comments">
                  </i>
                  0
              </div>
          </div>
      </div>
      <div class="post general">
          <div class="title">
              <div class="label">
                  일반
              </div>
              <div class="text">
                  베팅내역.
              </div>
              <div class="label" style="background-color: #b68aff; color: #ffffff; margin-left: 10px;">
                  BET
              </div>
          </div>
          <div class="details">
              <div class="author">
                  <img alt="kingking" height="20"
                      src="https://oaidalleapiprodscus.blob.core.windows.net/private/org-SteOp6BHgYZbZ5Ct5m8mzzMz/user-42eBDkP1fhGWwdrLRVEiD23u/img-QVTaPWZ3QyBvbul4vpVzHHzq.png?st=2024-12-11T05%3A13%3A01Z&amp;se=2024-12-11T07%3A13%3A01Z&amp;sp=r&amp;sv=2024-08-04&amp;sr=b&amp;rscd=inline&amp;rsct=image/png&amp;skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&amp;sktid=a48cca56-e6da-484e-a814-9c849652bcb3&amp;skt=2024-12-10T21%3A47%3A33Z&amp;ske=2024-12-11T21%3A47%3A33Z&amp;sks=b&amp;skv=2024-08-04&amp;sig=AcpmofP4fpD4Ao/ibm42JoZDCG6Z5hsqyLrOYFXSlQM%3D"
                      width="20"/>
                  kingking
              </div>
              <div class="info">
                  <i class="far fa-clock">
                  </i>
                  2024-11-19 11:13
                  <i class="far fa-eye">
                  </i>
                  10
                  <i class="far fa-comments">
                  </i>
                  1
              </div>
          </div>
      </div>
      <div class="post general">
          <div class="title">
              <div class="label">
                  일반
              </div>
              <div class="text">
                  ㅋㅋㅋ
              </div>
              <div class="label" style="background-color: #0000ff; color: #ffffff; margin-left: 10px;">
                  BET
              </div>
          </div>
          <div class="details">
              <div class="author">
                  <img alt="이지은" height="20"
                      src="https://oaidalleapiprodscus.blob.core.windows.net/private/org-SteOp6BHgYZbZ5Ct5m8mzzMz/user-42eBDkP1fhGWwdrLRVEiD23u/img-CZ3PuCfJgmx1tcdVliZUuHxs.png?st=2024-12-11T05%3A12%3A59Z&amp;se=2024-12-11T07%3A12%3A59Z&amp;sp=r&amp;sv=2024-08-04&amp;sr=b&amp;rscd=inline&amp;rsct=image/png&amp;skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&amp;sktid=a48cca56-e6da-484e-a814-9c849652bcb3&amp;skt=2024-12-10T21%3A18%3A35Z&amp;ske=2024-12-11T21%3A18%3A35Z&amp;sks=b&amp;skv=2024-08-04&amp;sig=2av/2ZG4aO1gGqqEc4QLQ1qxvxNuXlb7n%2Br7R4ew9DE%3D"
                      width="20"/>
                  이지은
              </div>
              <div class="info">
                  <i class="far fa-clock">
                  </i>
                  2024-11-19 11:13
                  <i class="far fa-eye">
                  </i>
                  3
                  <i class="far fa-comments">
                  </i>
                  2
              </div>
          </div>
      </div>
      <div class="post general">
          <div class="title">
              <div class="label">
                  일반
              </div>
              <div class="text">
                  출첵이용
              </div>
              <div class="label" style="background-color: #ff0000; color: #ffffff; margin-left: 10px;">
                  X
              </div>
          </div>
          <div class="details">
              <div class="author">
                  <img alt="가나다라마바사" height="20"
                      src="https://oaidalleapiprodscus.blob.core.windows.net/private/org-SteOp6BHgYZbZ5Ct5m8mzzMz/user-42eBDkP1fhGWwdrLRVEiD23u/img-6u7yxuWC4LrzVIZSSjxYLagY.png?st=2024-12-11T05%3A13%3A06Z&amp;se=2024-12-11T07%3A13%3A06Z&amp;sp=r&amp;sv=2024-08-04&amp;sr=b&amp;rscd=inline&amp;rsct=image/png&amp;skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&amp;sktid=a48cca56-e6da-484e-a814-9c849652bcb3&amp;skt=2024-12-10T21%3A30%3A08Z&amp;ske=2024-12-11T21%3A30%3A08Z&amp;sks=b&amp;skv=2024-08-04&amp;sig=80fU4xkO0%2BrtjhNUCWuPt%2BDTZfr6apFolgSlkEZDPS0%3D"
                      width="20"/>
                  가나다라마바사
              </div>
              <div class="info">
                  <i class="far fa-clock">
                  </i>
                  2024-11-19 11:13
                  <i class="far fa-eye">
                  </i>
                  1
                  <i class="far fa-comments">
                  </i>
                  0
              </div>
          </div>
      </div>
  </div>

  </div>


</template>

<script>
// import SideLink from '../../../components/Board/SideLink'
// import Loading from '../../../components/LoadingGray'

// import Pagination from '../../../components/Pagination03'
import MSG from '@/contants/msg';
export default {
  name: "Customer",
  components: {
    // Pagination,
    // SideLink,
    // Loading
  },
  data: function(){
    return {
      path: 'bbs/c/l',
      lists: null,
      pagination: null,
      payload: {
        page: 1,
        size: 20,
        keyword: null
      },
      expandedArticle: []

    }
  },
  mounted: function() {
    this.reqData(this.payload)
  },
  methods: {
    reqData: function(payload){
      return this.$store.dispatch('GET_SUPPORT_LIST', { payload } )
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.pagination = result.payload.pagination;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    renderHtml: function(str){
      if (!str) return '';
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
    toggle: function (index) {
      if (this.expandedArticle.indexOf(index) >= 0) return this.expandedArticle.splice(this.expandedArticle.indexOf(index), 1)
      this.expandedArticle.push(index)
    },
    accountReq: function(){
      if (!confirm(MSG.accReqMsg)) return;
      this.$store.dispatch('GET_ACCOUNT_REQ')
          .then(data => {
            if(data.data.success) {
              this.reqData(this.payload)
              alert(data.data.msg)
            }
          }).catch(err => {
        alert(err.response.data.error);
      })
    },
    deleteArticle: function(item){
      if (!confirm('삭제 하시겠습니까?')) return;
      const payload = {
        item
      };
      this.$store.dispatch('DELETE_ARTICLE', { payload })
          .then(data => {
            if(data.data.success) {
              this.reqData(this.payload)
              alert(data.data.msg)
            }
          }).catch(err => {
        alert(err.response.data.error);
      })
    },
    checkArray: function (id) {
      let result = this.expandedArticle.indexOf(id) >= 0
      return result
    },
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

@media all and (min-width:600px){
.search-bar {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
}

.search-bar input {
    width: 300px;
    padding: 10px;
    border: 1px solid #444;
    background-color: #2b2b2b;
    color: #ccc;
}

.search-bar .write-button button {
    background-color: #a97bf5;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 3px 3px 3px 3px;
}

.content {
    padding: 20px;
}

.content h1 {
    text-align: center;
    color: #9a9aff;
}

.table-container {
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

table th, table td {
    padding: 10px;
    border: 1px solid #444;
    text-align: center;
}

table th {
    background-color: #333;
    color: #ccc;
}

table td {
    background-color: #2b2b2b;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination a {
    color: #ccc;
    padding: 10px 15px;
    text-decoration: none;
    border: 1px solid #444;
    margin: 0 5px;
}

.pagination a.active {
    background-color: #444;
    color: #fff;
}
}

@media all and (max-width: 600px) {

  .content {
      padding: 20px;
  }

  .content h1 {
      color: #b68aff;
      border-bottom: 1px solid #b68aff;
      padding-bottom: 10px;
      margin-bottom: 20px;
  }

  .post {
      background-color: #2b2b2b;
      padding: 10px;
      margin-bottom: 10px;
      border-left: 5px solid;
  }

  .post.event {
      border-color: #d4ff00;
  }

  .post.notice {
      border-color: #ff0000;
  }

  .post.general {
      border-color: #ffffff;
  }

  .post .title {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
  }

  .post .title .label {
      background-color: #d4ff00;
      color: #000000;
      padding: 2px 5px;
      border-radius: 3px;
      margin-right: 10px;
  }

  .post.notice .title .label {
      background-color: #ff0000;
  }

  .post.general .title .label {
      background-color: #ffffff;
      color: #000000;
  }

  .post .title .text {
      flex-grow: 1;
  }

  .post .details {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #aaaaaa;
  }

  .post .details .author {
      display: flex;
      align-items: center;
  }

  .post .details .author img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
  }

  .post .details .info {
      display: flex;
      align-items: center;
  }

  .post .details .info i {
      margin-right: 5px;
  }

  .pagination {
      display: flex;
      justify-content: center;
      padding: 20px 0;
  }

  .pagination .page {
      background-color: #444444;
      color: #ffffff;
      padding: 5px 10px;
      margin: 0 5px;
      border-radius: 3px;
      cursor: pointer;
  }

  .pagination .page.active {
      background-color: #b68aff;
  }
}
</style>

