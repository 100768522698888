<template>
  <div>
    <div class="main desktop" style="min-height: 800px;">
        <h1>자유게시판 글쓰기</h1>
        <div class="form-container">
            <div class="form-group">
                <label for="category">카테고리</label>
                <select id="category">
                    <option>일반</option>
                </select>
            </div>
            <div class="form-group">
                <label for="title">제목</label>
                <input type="text" id="title" placeholder="제목입력">
            </div>
            <div class="form-group">
                <label for="content">내용</label>
                <textarea id="content" placeholder="내용입력"></textarea>
            </div>
            <div class="form-group">
                <label for="betting">베팅내역첨부</label>
                <div class="form-actions">
                    <button class="add-bet">베팅내역 추가</button>
                    <button class="clear-bet">베팅내역 전부삭제</button>
                </div>
            </div>
        </div>
        <div class="form-footer">
            <button class="submit">글쓰기</button>
            <button class="cancel">취소</button>
        </div>
    </div>
    <div class="content mobile">
        <h1>자유게시판 글쓰기</h1>
        <div class="form-actions">
            <button>글쓰기</button>
            <button>취소</button>
        </div>
        <div class="form-group">
            <label for="category">카테고리</label>
            <select id="category">
                <option>일반</option>
            </select>
        </div>
        <div class="form-group">
            <input type="text" placeholder="제목입력">
        </div>
        <div class="form-group">
            <textarea placeholder="내용입력"></textarea>
        </div>
        <div class="form-actions">
            <button>베팅내역 추가</button>
            <button>베팅내역 전체삭제</button>
        </div>
    </div>
  </div>

</template>

<script>
// import BettingAdd from '../../../components/Board/BettingAdd'
// import MBettingAdd from '../../../components/Board/MBettingAdd'
// import Loading  from '../../../components/Loading'

export default {
  name: "Write",
  components: {
    // BettingAdd,
    // MBettingAdd,
    // Loading
  },
  data: function(){
    return {
      isView: false,
      processing: false,
      payload: {
        title: null,
        content: null,
        betting: null,
      },
    }
  },
  methods: {
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    processWrite: function(){
      if (this.processing) return alert('등록중 입니다.')
      if (!this.payload.title || this.payload.title === '' || this.payload.content === '' || !this.payload.content) return alert('제목과 내용을 확인해주세요.')
      this.processing = true;
      return this.$store.dispatch('PROCESS_SUPPORT', { payload: this.payload })
          .then(data => {
            const result = data.data;
            alert(result.msg);
            this.$router.push({ path: 'bbs/c/l' })
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    openList: function(){
      this.isView = !this.isView;
    },
    addBetting: function(betting){
      this.payload.betting = betting;
      this.openList();
    },
    deleteBetting: function(){
      this.payload.betting = null;
    },
  }
}
</script>

<style scoped>

@media all and (min-width:600px){
  .mobile {
    display: none;
  }
  .main {
    padding: 20px;
}
.main h1 {
    color: #b38ff5;
    text-align: center;
}
.form-container {
    background-color: #2b2b2b;
    padding: 20px;
    border-radius: 5px;
}
.form-group {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.form-group label {
    width: 100px;
    margin-right: 10px;
}
.form-group select,
.form-group input,
.form-group textarea {
    flex: 1;
    padding: 10px;
    border: 1px solid #444444;
    border-radius: 5px;
    background-color: #1c1c1c;
    color: #ffffff;
}
.form-group textarea {
    height: 200px;
}
.form-actions {
    display: flex;
    justify-content: space-between;
}
.form-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.form-actions .add-bet {
    background-color: #b38ff5;
    color: #ffffff;
}
.form-actions .clear-bet {
    background-color: #8b0000;
    color: #ffffff;
}
.form-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.form-footer button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}
.form-footer .submit {
    background-color: #b38ff5;
    color: #ffffff;
}
.form-footer .cancel {
    background-color: #444444;
    color: #ffffff;
}
}
@media all and (max-width: 600px) {
  .desktop {
    display: none;
  }
  .content {
            padding: 20px;
        }
        .content h1 {
            color: #b68aff;
            text-align: center;
            margin-bottom: 20px;
        }
        .form-group {
            margin-bottom: 20px;
        }
        .form-group label {
            display: inline-block;
            margin-bottom: 5px;
            font-size: 18px;
            margin-right: 10px;
        }
        .form-group select,
        .form-group input,
        .form-group textarea {
            width: calc(100% - 20px);
            padding: 10px;
            background-color: #2c2c2c;
            border: 1px solid #444444;
            color: #ffffff;
            font-size: 16px;
        }
        .form-group textarea {
            height: 200px;
        }
        .form-actions {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 20px;
        }
        .form-actions button {
            background-color: #b68aff;
            border: none;
            color: #ffffff;
            padding: 10px 20px;
            cursor: pointer;
            margin-bottom: 10px;
            flex: 1 1 48%;
            box-sizing: border-box;
        }
        .form-actions button:last-child {
            background-color: #a00000;
        }
        @media (max-width: 600px) {
            .header .menu .info {
                display: none;
            }
            .header .menu .buttons button {
                padding: 5px 10px;
                margin-left: 5px;
            }
            .form-actions button {
                flex: 1 1 45%;
                gap: 3px;
            }
            .form-group label,
            .form-group select {
                display: block;
                width: 100%;
            }
            .form-group select {
                width: 100%;
            }
        }
}
</style>
