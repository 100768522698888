<template>
  <table width="100%" valign="top" border="0" cellspacing="0" cellpadding="0"  class="ng-isolate-scope">
    <tbody>
    <tr>
      <td width="100%">
        <!-- ngInclude: centerTab -->
        <div style="">
          <div style="display: block;" id="clive" class="animate-bottom ">
            <div class="game_center_wrap">
              <div  class="sports_wrap" >
                <template v-if="originList.length === 0">
                  <table class="sports_title_table_1" cellpadding="0" cellspacing="0">
                    <tbody>
                    <tr>
                      <td colspan="5">
                        <span class="live_tab_text_2"></span>
                      </td>
                      <!--                      <td width="7%" style="color: #d82731;">맥스</td>-->
                      <td width="110px">승</td>
                      <td width="110px">무</td>
                      <td width="110px">패</td>
                      <td width="4%">맥스</td>
                      <td width="6%" class="font_002">더보기</td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="sports_table_1" cellpadding="0" cellspacing="0">
                    <tbody>
                    <tr>
                      <td width="100%" align="center" style="padding:15px 0 15px 0; color:#8a8c8d; text-align: center;">
                        현재 진행중인 경기가 없습니다.
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </template>
                <template v-else>
                  <template v-for="sport in sortBySports(lists)">
                    <table :key="'titleSportName'+sport.nameEn" class="sports_title_table_1" cellpadding="0" cellspacing="0">
                      <tbody>
                      <tr>
                        <td colspan="5">
                          <span class="live_tab_text_2"><img :src="sport.icon" style="width: 15px; height: 15px;" alt="">&nbsp;&nbsp;<span>{{ sport | sportName }}</span></span>
                        </td>
                        <!--                        <td width="7%" style="color: #d82731;">맥스</td>-->
                        <td width="110px">승</td>
                        <td width="110px">무</td>
                        <td width="110px">패</td>
                        <td width="4%">맥스</td>
                        <td width="6%" class="font_002">더보기</td>
                      </tr>
                      </tbody>
                    </table>

                    <template v-if="Number(blockTypeSetting) === 1">
                      <table v-for="(game, gameIndex) in lists.filter(e => e.sportSeq === sport.seq)" :key="'gameIndex'+gameIndex + sport.nameEn" class="sports_table_1"
                             cellpadding="0" cellspacing="0"
                             style="border-bottom: solid 1px #A97BF5;"
                             >
                        <template v-if="game.isVisible === 'y'">
                          <tbody v-if="game.status ==='종료' || game.isSuspended === 'y'">
                            <tr style="background-color: rgb(34, 38, 39);">
                            <td class="pre_0_0">
                              <a @click="addStaredGame(game)" style="color: #8a8c8d;">
                                <font-awesome-icon v-if="linkedGame.find(e => e.id === game.id)" icon="fa fa-star"/>
                                <font-awesome-icon v-else icon="fa-regular fa-star"/>
                              </a>
                            </td>
                            <td class="sports_3_2" v-if="game.status === '종료'">종료</td>
                            <td class="sports_3_2" v-else>{{ game | currentPeriod3('period') }}</td>
                            <td class="sports_2_2" style="text-align: center; cursor: pointer;" @click="selectedGame(game)">
                              {{ game.homeResult }} - {{ game.awayResult }}
                            </td>
                            <td class="sports_5_2" @click="selectedGame(game)" style="cursor: pointer;">
                              <img style="width: 14px; height: 10px;" v-if="game.location.icon" :src="game.location.icon"
                                   alt="">
                              {{ game.league | leagueName }}
                            </td>

                            <td class="sports_6_2" @click="selectedGame(game)" style="cursor: pointer;">
                              <table width="100%" cellpadding="0" cellspacing="0">
                                <tbody>
                                <tr href="#">
                                  <td class="pre_4_1_1">{{ game.homeTeam | teamName }}</td>
                                  <td class="pre_4_1_2">vs</td>
                                  <td class="pre_4_1_3">{{ game.awayTeam | teamName }}</td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                            <!--                        <td class="sports_6_5">-->
                            <!--                          {{ game.league.maxAmount | amtKor }}-->
                            <!--                        </td>-->
                            <td class="sports_7_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_8_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_9_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_6_5">
                              {{ game.league.maxAmount | amtKor }}
                            </td>
                            <td class="sports_10_4" @click="selectedGame(game)">
                              +{{ game.foldersCnt.cnt }}
                            </td>
                          </tr>
                          </tbody>
                          <tbody v-else-if="game.status ==='진행' && game.isSuspended === 'n'">
                            <tr style="background-color: rgb(34, 38, 39);">
                            <td class="sports_1" style="background-color: rgb(34, 38, 39);">
                              <a @click="addStaredGame(game)" style="color: #8a8c8d;">
                                <font-awesome-icon v-if="linkedGame.find(e => e.id === game.id)" icon="fa fa-star"/>
                                <font-awesome-icon v-else icon="fa-regular fa-star"/>
                              </a>
                            </td>
                            <td class="sports_3_2">
                              {{ game | currentPeriod3('period') }}
                              {{ game.sportSeq === 210 ? `${game.currentTime}'` : '' }}
                            </td>
                            <td class="sports_2_2" style="text-align: center; cursor: pointer;" @click="selectedGame(game)">
                              {{ game.homeResult }} - {{ game.awayResult }}
                            </td>
                            <td class="sports_5_2" @click="selectedGame(game)" style="cursor: pointer;">
                              <img style="width: 14px; height: 10px;" v-if="game.location.icon" :src="game.location.icon"
                                   alt="">
                              {{ game.league | leagueName }}
                            </td>
                            <td class="sports_6_2">
                              <table width="100%" cellpadding="0" cellspacing="0">
                                <tbody>
                                <tr @click="selectedGame(game)" style="cursor: pointer;">
                                  <td class="pre_4_1_1">{{ game.homeTeam | teamName }}</td>
                                  <td class="pre_4_1_2">vs</td>
                                  <td class="pre_4_1_3">{{ game.awayTeam | teamName }}</td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                            <template v-if="checkBlockGame(game)">
                              <td class="sports_7_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_8_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_9_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_6_5">
                                {{ game.league.maxAmount | amtKor }}
                              </td>
                              <td class="sports_10_4">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                            </template>
                            <template v-else-if="game.folders.length === 0 && Number(game.foldersCnt.cnt) > 0">
                              <td class="sports_7_3" @click="selectedGame(game)" style="color: orange;">
                                <font-awesome-icon icon="fs fa-circle-plus" style="color: orange;"/>
                              </td>
                              <td class="sports_8_3" @click="selectedGame(game)" style="color: orange;">
                                <font-awesome-icon icon="fs fa-circle-plus" style="color: orange;"/>
                              </td>
                              <td class="sports_9_3" @click="selectedGame(game)" style="color: orange;">
                                <font-awesome-icon icon="fs fa-circle-plus" style="color: orange;"/>
                              </td>
                              <td class="sports_6_5">
                                {{ game.league.maxAmount | amtKor }}
                              </td>
                              <td class="sports_10_4" @click="selectedGame(game)">
                                +{{ game.foldersCnt.cnt }}
                              </td>
                            </template>
                            <template v-else-if="game.folders.length === 0 && Number(game.foldersCnt.cnt) === 0">
                              <td class="sports_7_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_8_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_9_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_6_5">
                                {{ game.league.maxAmount | amtKor }}
                              </td>
                              <td class="sports_10_4">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                            </template>
                            <template v-else>
                              <template
                                  v-if="game.folders[0].isVisible === 'n' || game.folders[0].isSuspended === 'y' ||  game.folders[0].bets.find(b => Number(b.price) === 0)">
                                <template v-if="game.foldersCnt.cnt > 0">
                                  <td class="sports_7_3" @click="selectedGame(game)" style="color: orange;">
                                    <font-awesome-icon icon="fs fa-circle-plus"/>
                                  </td>
                                  <td class="sports_8_3" @click="selectedGame(game)" style="color: orange;">
                                    <font-awesome-icon icon="fs fa-circle-plus"/>
                                  </td>
                                  <td class="sports_9_3" @click="selectedGame(game)" style="color: orange;">
                                    <font-awesome-icon icon="fs fa-circle-plus"/>
                                  </td>
                                  <td class="sports_6_5">
                                    {{ game.league.maxAmount | amtKor }}
                                  </td>
                                  <td class="sports_10_4" @click="selectedGame(game)">
                                    <font-awesome-icon icon="fs fa-circle-plus"/>
                                  </td>
                                </template>
                                <template v-else>
                                  <td class="sports_7_3">
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </td>
                                  <td class="sports_8_3">
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </td>
                                  <td class="sports_9_3">
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </td>
                                  <td class="sports_6_5">
                                    {{ game.league.maxAmount | amtKor }}
                                  </td>
                                  <td class="sports_10_4" @click="selectedGame(game)">
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </td>
                                </template>

                              </template>
                              <template v-else-if="game.folders[0].isVisible === 'y' && game.folders[0].isSuspended === 'n'">
                                <td class="sports_7_3"
                                    @click="addCart(game.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), game.folders[0], game)"
                                    :class="{'sel': checkCart(game.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"
                                >
                                  <ListPrice
                                      :price="game.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes').price"></ListPrice>
                                </td>
                                <td class="sports_8_3"
                                    @click="addCart(game.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), game.folders[0], game)"
                                    :class="{'sel': checkCart(game.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"
                                >
                                  <ListPrice v-if="game.folders[0].bets.find(e => e.name === 'X')"
                                             :price="game.folders[0].bets.find(e => e.name === 'X').price"></ListPrice>
                                </td>
                                <td class="sports_9_3"
                                    @click="addCart(game.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), game.folders[0], game)"
                                    :class="{'sel': checkCart(game.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"
                                >
                                  <ListPrice
                                      :price="game.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No').price"></ListPrice>
                                </td>
                                <td class="sports_6_5">
                                  {{ game.league.maxAmount | amtKor }}
                                </td>
                                <td class="sports_10_4" @click="selectedGame(game)">
                                  +{{ game.foldersCnt.cnt }}
                                </td>
                              </template>
                            </template>
                          </tr>
                          </tbody>
                          <tbody v-else-if="game.status === '대기'">
                            <tr style="background-color: rgb(34, 38, 39);">
                            <td class="pre_0_0">
                              <a href="#">
                                <i class="fa fa-star-o"></i>
                              </a>
                            </td>
                            <td class="sports_3_2">대기중</td>
                            <td class="sports_2_2" style="text-align: center;">
                              {{ game.homeResult }} - {{ game.awayResult }}
                            </td>
                            <td class="sports_5_2">
                              <img style="width: 14px; height: 10px;" v-if="game.location.icon" :src="game.location.icon"
                                   alt="">
                              {{ game.league | leagueName }}
                            </td>

                            <td class="sports_6_2">
                              <table width="100%" cellpadding="0" cellspacing="0">
                                <tbody>
                                <tr href="#">
                                  <td class="pre_4_1_1">{{ game.homeTeam | teamName }}</td>
                                  <td class="pre_4_1_2">vs</td>
                                  <td class="pre_4_1_3">{{ game.awayTeam | teamName }}</td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                            <td class="sports_7_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_8_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_9_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_6_5">
                              {{ game.league.maxAmount | amtKor }}
                            </td>
                            <td class="sports_10_4">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                          </tr>
                          </tbody>
                          <RenewalInplayDetail
                            v-if="checkArray(game.id)"
                            :game="game"
                            :check-block-game="checkBlockGame"
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :block-type-setting="blockTypeSetting"
                          ></RenewalInplayDetail>
                          <tfoot v-if="checkArray(game.id)">
                            <tr>
                                <td colspan="10" style="text-align: center; color: rgb(183, 183, 183)">
                                    인플레이 베팅에 표시되는 시간 표시는 지표 역할을 합니다. 회사는 점수 또는 시간과 같이
                                    표시되는 정보의 정확성 및 최신성에 대해 책임을 지지 않습니다.
                                </td>
                            </tr>
                          </tfoot>
                        </template>
                      </table>
                    </template>
                    <template v-else-if="Number(blockTypeSetting === 2)">
                      <table v-for="(game, gameIndex) in lists.filter(e => e.sportSeq === sport.seq)" :key="'gameIndex'+gameIndex + sport.nameEn" class="sports_table_1"
                             style="border-bottom: solid 1px #A97BF5;"
                             cellpadding="0" cellspacing="0">
                        <template v-if="game.isVisible === 'y'">
                          <tbody v-if="game.status ==='종료' || game.isSuspended === 'y'">
                            <tr style="background-color: rgb(34, 38, 39);">
                            <td class="pre_0_0">
                              <a @click="addStaredGame(game)" style="color: #8a8c8d;">
                                <font-awesome-icon v-if="linkedGame.find(e => e.id === game.id)" icon="fa fa-star"/>
                                <font-awesome-icon v-else icon="fa-regular fa-star"/>
                              </a>
                            </td>
                            <td class="sports_3_2" v-if="game.status === '종료'">종료</td>
                            <td class="sports_3_2" v-else>{{ game | currentPeriod3('period') }}</td>
                            <td class="sports_2_2" style="text-align: center; cursor: pointer;" @click="selectedGame(game)">
                              {{ game.homeResult }} - {{ game.awayResult }}
                            </td>
                            <td class="sports_5_2" @click="selectedGame(game)" style="cursor: pointer;">
                              <img style="width: 14px; height: 10px;" v-if="game.location.icon" :src="game.location.icon"
                                   alt="">
                              {{ game.league | leagueName }}
                            </td>

                            <td class="sports_6_2" @click="selectedGame(game)" style="cursor: pointer;">
                              <table width="100%" cellpadding="0" cellspacing="0">
                                <tbody>
                                <tr href="#">
                                  <td class="pre_4_1_1">{{ game.homeTeam | teamName }}</td>
                                  <td class="pre_4_1_2">vs</td>
                                  <td class="pre_4_1_3">{{ game.awayTeam | teamName }}</td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                            <!--                        <td class="sports_6_5">-->
                            <!--                          {{ game.league.maxAmount | amtKor }}-->
                            <!--                        </td>-->
                            <td class="sports_7_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_8_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_9_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_6_5">
                              {{ game.league.maxAmount | amtKor }}
                            </td>
                            <td class="sports_10_4" @click="selectedGame(game)">
                              +{{ game.foldersCnt.cnt }}
                            </td>
                          </tr>
                          </tbody>
                          <tbody v-else-if="game.status ==='진행' && game.isSuspended === 'n'">
                            <tr style="background-color: rgb(34, 38, 39);">
                            <td class="sports_1" style="background-color: rgb(34, 38, 39);">
                              <a @click="addStaredGame(game)" style="color: #8a8c8d;">
                                <font-awesome-icon v-if="linkedGame.find(e => e.id === game.id)" icon="fa fa-star"/>
                                <font-awesome-icon v-else icon="fa-regular fa-star"/>
                              </a>
                            </td>
                            <td class="sports_3_2">
                              {{ game | currentPeriod3('period') }}
                              {{ game.sportSeq === 210 ? `${game.currentTime}'` : '' }}
                            </td>
                            <td class="sports_2_2" style="text-align: center; cursor: pointer;" @click="selectedGame(game)">
                              {{ game.homeResult }} - {{ game.awayResult }}
                            </td>
                            <td class="sports_5_2" @click="selectedGame(game)" style="cursor: pointer;">
                              <img style="width: 14px; height: 10px;" v-if="game.location.icon" :src="game.location.icon"
                                   alt="">
                              {{ game.league | leagueName }}
                            </td>
                            <td class="sports_6_2">
                              <table width="100%" cellpadding="0" cellspacing="0">
                                <tbody>
                                <tr @click="selectedGame(game)" style="cursor: pointer;">
                                  <td class="pre_4_1_1">{{ game.homeTeam | teamName }}</td>
                                  <td class="pre_4_1_2">vs</td>
                                  <td class="pre_4_1_3">{{ game.awayTeam | teamName }}</td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                            <template v-if="game.folders.length === 0 && Number(game.foldersCnt.cnt) > 0">
                              <td class="sports_7_3" @click="selectedGame(game)" style="color: orange;">
                                <font-awesome-icon icon="fs fa-circle-plus" style="color: orange;"/>
                              </td>
                              <td class="sports_8_3" @click="selectedGame(game)" style="color: orange;">
                                <font-awesome-icon icon="fs fa-circle-plus" style="color: orange;"/>
                              </td>
                              <td class="sports_9_3" @click="selectedGame(game)" style="color: orange;">
                                <font-awesome-icon icon="fs fa-circle-plus" style="color: orange;"/>
                              </td>
                              <td class="sports_6_5">
                                {{ game.league.maxAmount | amtKor }}
                              </td>
                              <td class="sports_10_4" @click="selectedGame(game)">
                                +{{ game.foldersCnt.cnt }}
                              </td>
                            </template>
                            <template v-else-if="game.folders.length === 0 && Number(game.foldersCnt.cnt) === 0">
                              <td class="sports_7_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_8_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_9_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_6_5">
                                {{ game.league.maxAmount | amtKor }}
                              </td>
                              <td class="sports_10_4">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                            </template>
                            <template v-else>
                              <template
                                  v-if="game.folders[0].isVisible === 'n' || game.folders[0].isSuspended === 'y' ||  game.folders[0].bets.find(b => Number(b.price) === 0)">
                                <template v-if="game.foldersCnt.cnt > 0">
                                  <td class="sports_7_3" @click="selectedGame(game)" style="color: orange;">
                                    <font-awesome-icon icon="fs fa-circle-plus"/>
                                  </td>
                                  <td class="sports_8_3" @click="selectedGame(game)" style="color: orange;">
                                    <font-awesome-icon icon="fs fa-circle-plus"/>
                                  </td>
                                  <td class="sports_9_3" @click="selectedGame(game)" style="color: orange;">
                                    <font-awesome-icon icon="fs fa-circle-plus"/>
                                  </td>
                                  <td class="sports_6_5">
                                    {{ game.league.maxAmount | amtKor }}
                                  </td>
                                  <td class="sports_10_4" @click="selectedGame(game)">
                                    <font-awesome-icon icon="fs fa-circle-plus"/>
                                  </td>
                                </template>
                                <template v-else>
                                  <td class="sports_7_3">
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </td>
                                  <td class="sports_8_3">
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </td>
                                  <td class="sports_9_3">
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </td>
                                  <td class="sports_6_5">
                                    {{ game.league.maxAmount | amtKor }}
                                  </td>
                                  <td class="sports_10_4" @click="selectedGame(game)">
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </td>
                                </template>

                              </template>
                              <template v-else-if="game.folders[0].isVisible === 'y' && game.folders[0].isSuspended === 'n'">
                                <td class="sports_7_3"
                                    @click="addCart(game.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), game.folders[0], game)"
                                    :class="{'sel': checkCart(game.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"
                                >
                                  <ListPrice
                                      :price="game.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes').price"></ListPrice>
                                </td>
                                <td class="sports_8_3"
                                    @click="addCart(game.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), game.folders[0], game)"
                                    :class="{'sel': checkCart(game.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"
                                >
                                  <ListPrice v-if="game.folders[0].bets.find(e => e.name === 'X')"
                                             :price="game.folders[0].bets.find(e => e.name === 'X').price"></ListPrice>
                                </td>
                                <td class="sports_9_3"
                                    @click="addCart(game.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), game.folders[0], game)"
                                    :class="{'sel': checkCart(game.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"
                                >
                                  <ListPrice
                                      :price="game.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No').price"></ListPrice>
                                </td>
                                <td class="sports_6_5">
                                  {{ game.league.maxAmount | amtKor }}
                                </td>
                                <td class="sports_10_4" @click="selectedGame(game)">
                                  +{{ game.foldersCnt.cnt }}
                                </td>
                              </template>
                            </template>
                          </tr>
                          </tbody>
                          <tbody v-else-if="game.status === '대기'">
                          <tr style="background-color: rgb(34, 38, 39);">
                            <td class="pre_0_0">
                              <a href="#">
                                <i class="fa fa-star-o"></i>
                              </a>
                            </td>
                            <td class="sports_3_2">대기중</td>
                            <td class="sports_2_2" style="text-align: center;">
                              {{ game.homeResult }} - {{ game.awayResult }}
                            </td>
                            <td class="sports_5_2">
                              <img style="width: 14px; height: 10px;" v-if="game.location.icon" :src="game.location.icon"
                                   alt="">
                              {{ game.league | leagueName }}
                            </td>

                            <td class="sports_6_2">
                              <table width="100%" cellpadding="0" cellspacing="0">
                                <tbody>
                                <tr href="#">
                                  <td class="pre_4_1_1">{{ game.homeTeam | teamName }}</td>
                                  <td class="pre_4_1_2">vs</td>
                                  <td class="pre_4_1_3">{{ game.awayTeam | teamName }}</td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                            <td class="sports_7_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_8_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_9_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_6_5">
                              {{ game.league.maxAmount | amtKor }}
                            </td>
                            <td class="sports_10_4">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                          </tr>
                          </tbody>
                          <RenewalInplayDetail2
                            v-if="checkArray(game.id)"
                            :game="game"
                            :check-block-game="checkBlockGame"
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :block-type-setting="blockTypeSetting"
                          ></RenewalInplayDetail2>
                          <tfoot v-if="checkArray(game.id)">
                            <tr>
                                <td colspan="10" style="text-align: center; color: rgb(183, 183, 183)">
                                    인플레이 베팅에 표시되는 시간 표시는 지표 역할을 합니다. 회사는 점수 또는 시간과 같이
                                    표시되는 정보의 정확성 및 최신성에 대해 책임을 지지 않습니다.
                                </td>
                            </tr>
                          </tfoot>

                        </template>
                      </table>
                    </template>

                  </template>

                </template>
              </div>
              <!--<div class="live_wrap">-->
              <!--  <table class="live_table">-->
              <!--    <tbody>-->
              <!--    <tr>-->
              <!--      <td class="live_table_t_1 "><span class="white">경기 일정</span></td>-->
              <!--    </tr>-->
              <!--    </tbody>-->
              <!--  </table>-->
                <!--                        경기일정 -->
              <!--  <table class="live_table bg-gray" v-for="(wait, index) in waitList" :key="'waitList' +index">-->
              <!--    <colgroup>-->
              <!--      <col style="width:18%">-->
              <!--      <col style="width:82%">-->
              <!--    </colgroup>-->
              <!--    <tbody>-->
              <!--    <tr>-->
              <!--      <td class="n-tit orange" style="width: 30%;">-->
              <!--        {{ wait.startTime | formatDate('M월 D일') }} ({{ wait.startTime | formatDate('요일') }})-->
              <!--        {{ wait.startTime | formatDate('HH:ss') }}-->
              <!--      </td>-->
              <!--      <td>-->
              <!--        <div class="none-live">-->
              <!--          <img :src="wait.sport.icon ? wait.sport.icon : wait.league.icon" class="ic"-->
              <!--               style="width: 25px;">-->
              <!--          <div class="n1" style="font-weight: bold;">{{ wait.league | leagueName }}</div>-->
              <!--          <div class="n2" style="font-weight: bold;">-->
              <!--            {{ wait.homeTeam | teamName }}-->
              <!--            vs-->
              <!--            {{ wait.awayTeam | teamName }}-->
              <!--          </div>-->
              <!--        </div>-->
              <!--      </td>-->
              <!--    </tr>-->
              <!--    </tbody>-->
              <!--  </table>-->
              <!--</div>-->
            </div>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import ListPrice from "./ListPrice";
import RenewalInplayDetail from "./RenewalInplayDetail";
import RenewalInplayDetail2 from "./RenewalInplayDetail2";

import {mapState} from "vuex";

export default {
  name: "InpList",
  components: {
    // Category,
    ListPrice,
    RenewalInplayDetail,
    RenewalInplayDetail2
  },
  props: ['originList', 'addCart', 'checkCart', 'waitList', 'isLoading', 'checkBlockGame', 'blockTypeSetting'],
  computed: {
    lists: function () {
      return this.originList
    },
    ...mapState({
      linkedGame: state => state.inplay.inplayLink,
      user: state => state.user.user,
    }),
  },
  data: function () {
    return {
      openDetailGame: [],
    }
  },
  mounted: function () {
    this.$socket.client.on('inplayStats', this.matchStats)
    this.$socket.client.on('inplayMatchUpdate', this.matchUpdate)
    this.$socket.client.on('inplay', this.updateData)
  },
  methods: {
    sortBySports: function (lists) {
      const sports = lists.map(e => e.sport);
      const arrUnique = sports.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.seq === character.seq) === idx
      });
      arrUnique.sort()
      return arrUnique
    },

    // 매치 상태 변경
    matchStats: function (data) {
      const results = JSON.parse(data);
      // console.log(results, '매치점수변경');
      for (const result of results) {
        const game = this.lists.find(e => String(e.id) === String(result.EventId))
        if (game) {
          const score = result.Score;
          if (score) {
            game.homeResult = score.split(':')[0] || 0;
            game.awayResult = score.split(':')[1] || 0;
            if (!result.IsTimeout) game.currentPeriod = result.Period;
          }
        }
      }
    },
    // 매치 상태 업데이트
    matchUpdate: function (data) {
      const results = JSON.parse(data);
      for (const matchData of results) {
        const game = this.originList.find(e => String(e.id) === String(matchData.Id));
        if (game) {
          const cartItem = this.$store.state.inplay.cart.find(e => String(e.gameId) === String(matchData.Id));
          if (cartItem) {
            cartItem.betsStatus = matchData.IsSuspended || !matchData.IsVisible ? 'y' : 'n';
            cartItem.betsUpdated = true;
          }
          if (matchData.IsStarted) game.status = '진행';
          game.isVisible = matchData.IsVisible ? 'y' : 'n';
          game.isSuspended = matchData.IsSuspended ? 'y' : 'n';
          if (matchData.MatchStatus === 2) {
            game.status = '종료';
            setTimeout(() => {
              this.removeGames(game.seq)
            }, 3000)
          }
        }
      }
    },
    removeGames: function (gameSeq) {
      if (this.lists && this.lists.length > 0) {
        let index = this.lists.indexOf(e => e.seq === gameSeq)
        if (index >= 0) {
          this.lists.splice(index, 1)
        }
      }

      this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_SEQ', gameSeq);
    },

    // 배당 변경
    updateData: function (data) {
      const result = JSON.parse(data);
      // console.log(result, '배당변경')
      this.$store.dispatch('UPDATE_INPLAY_LIST', result);
    },
    addStaredGame: function (gameSeq) {
      this.$store.dispatch('TOGGLE_STARED', gameSeq);
    },
      /**
     * 토글 액션
     * */
    toggleDetail: function (id) {
      this.toggle(this.openDetailGame, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailGame.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailGame = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    selectedGame(game){
      console.log(game)
      this.toggle(this.openDetailGame, game.id)
      console.log(this.openDetailGame)
    },
    /*
    * 종목의 자세히 버튼
    * **/
    checkArray: function (id) {
      let result = this.openDetailGame.indexOf(id) >= 0
      return result
    },
  },
}
</script>

<style scoped>

</style>
