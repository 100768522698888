<template>
  <div>
<!--    <div v-if="!game" style="text-align: center;">-->
<!--      <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>-->
<!--    </div>-->
    <div class="bet-box" :class="{'disable_bet': !game || gameStatus}">
<!--      <div class="stop"-->
<!--           v-if="!game || gameStatus"-->
<!--           style="position: absolute; left: 67.2%; top:11.5%; width:250px; height: 450px; display: flex;-->
<!--           justify-content: center !important; align-items: center; background: rgba(0,0,0,0.7);-->
<!--           font-size:18px; color: #fff; z-index:99999;"-->
<!--      >지금은 베팅을 하실 수 없습니다.</div>-->
      <div class="stit"></div>
      <p class="bet-box-p">회차 <span>{{ game?.game_round }}</span></p>
      <p class="bet-box-p">시간 <span>{{ remainTime | countDown }}</span></p>
      <div class="bets power_bets">
        <button
            class="bg-blue"
            style=" height:43px;"
            :class="{'active': checkCart(normalBallOdd)}"
            @click="addCart(normalBallOdd, game.folders.find(e => e.folders_detail.indexOf('일반볼/홀짝') >= 0), game)">일반볼 홀</button>
        <button
            class="bg-red"
            style=" height:43px;"
            :class="{'active': checkCart(normalBallEven)}"
            @click="addCart(normalBallEven, game.folders.find(e => e.folders_detail.indexOf('일반볼/홀짝') >= 0), game)"
        >일반볼 짝</button>
        <button
            class="bg-green1"
            style=" height:43px;"
            :class="{'active': checkCart(powerBallUnder)}"
            @click="addCart(powerBallUnder, game.folders.find(e => e.folders_detail.indexOf('파워볼/언더오버') >= 0), game)">
        파워볼<br>0~4</button>
        <button
            class="bg-green1"
            style=" height:43px;"
            :class="{'active': checkCart(powerBallOver)}"
            @click="addCart(powerBallOver, game.folders.find(e => e.folders_detail.indexOf('파워볼/언더오버') >= 0), game)">
        파워볼<br>5~9</button>
        <button
            class="bg-blue1"
            :class="{'active': checkCart(normalLarge)}"
            @click="addCart(normalLarge, game.folders.find(e => e.folders_detail.indexOf('일반볼/소중대') >= 0), game)"
            style="width: 100%; height:43px;">일반볼 대 (81 ~ 130)</button>
        <button
            class="bg-blue1"
            style="width: 100%; height:43px;"
            :class="{'active': checkCart(normalMedium)}"
            @click="addCart(normalMedium, game.folders.find(e => e.folders_detail.indexOf('일반볼/소중대') >= 0), game)"

        >일반볼 중 (65 ~ 80)</button>
        <button
            class="bg-blue1"
            style="width: 100%; height:43px;"
            :class="{'active': checkCart(normalSmall)}"
            @click="addCart(normalSmall, game.folders.find(e => e.folders_detail.indexOf('일반볼/소중대') >= 0), game)"
        >일반볼 소 (15 ~ 64)</button>
      </div>
      <div class="mine-box">
        <div class="mine">
          <dl>
            <dt>보유머니</dt>
            <dd><strong>{{ user.members_cash | makeComma }}</strong></dd>
          </dl>
          <dl>
            <dt>배율</dt>
            <dd>{{ amount | makeComma }} X {{ totalOdds }} = {{ winnings | makeComma }}<span></span></dd>
          </dl>
          <dl>
            <dt>베팅금액</dt>
            <dd><input type="text" class="inp1" v-model="amount"></dd>
          </dl>
        </div>
      </div>
      <div class="moneys-box">
        <div class="moneys">
          <a @click="changeAmount(10000)">1만원</a>
          <a @click="changeAmount(50000)">5만원</a>
          <a @click="changeAmount(100000)">10만원</a>
          <a @click="changeAmount(500000)">50만원</a>
          <a @click="changeAmount(1000000)">100만원</a>
          <a @click="resetCart">정정</a>
        </div>
      </div>
      <div class="btns-box">
        <button @click="halfBet">HALF</button>
        <button @click="maxBet">MAX</button>
      </div>
      <div class="btn-box">
        <button class="btn-bet" @click="processBet">베팅하기</button>
      </div>
    </div>

  </div>
</template>

<script>
import moment from "moment/moment";
import {mapGetters, mapState} from "vuex";
export default {
  props: ['game', 'getGame', 'checkCart', 'gameType', 'reqData'],
  name: "DHPowerBallCart",
  data: function(){
    return {
      path: 'betting/mini/process',
      isSingle: false,
      process: false,
      complete: false,
      winnings: 0,
      amount: 10000,
      remainTime: 0,
      maxOddsAmount: 0,
      limitWinnings: 0,
      gameStatus: false,
    }
  },
  computed:{
    ...mapState({
      user: state => state.user.user,
      cart: state => state.minigame.cart,
    }),
    ...mapGetters({
      totalOdds: 'MINI_TOTAL_ODDS',
      levelSetting: 'LEVEL_SETTING',
    }),
    odds: function(){
      return Number(this.$store.getters["cart/minigameCartTotalOdds"])
    },
    minAmount: function(){
      return Number(this.levelSetting.miniSetting.minBetting);
    },
    maxAmount: function(){
      return Number(this.levelSetting.miniSetting.maxBetting);
    },
    maxOdds: function(){
      return this.levelSetting.miniSetting.maxOdds;
    },
    endTime: function(){
      return this.$store.state.setting.settings.config.option.gameEndtime.find(e => e.gameName === '파워볼').endTime
    },
    normalBallOdd: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/홀짝') >= 0).bets.find(e => e.bets_name === '홀')
    },
    normalBallEven: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/홀짝') >= 0).bets.find(e => e.bets_name === '짝')
    },
    powerBallUnder: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('파워볼/언더오버') >= 0).bets.find(e => e.bets_name === '언더')
    },
    powerBallOver: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('파워볼/언더오버') >= 0).bets.find(e => e.bets_name === '오버')
    },
    normalSmall: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/소중대') >= 0).bets.find(e => e.bets_name === '소')
    },
    normalMedium: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/소중대') >= 0).bets.find(e => e.bets_name === '중')
    },
    normalLarge: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/소중대') >= 0).bets.find(e => e.bets_name === '대')
    },
  },
  watch:{
    // 배당변경시 자동으로 최대 가능 베팅금액 변경
    odds: function (val){
      if (Number(this.maxOdds) <= Number(val)) {
        this.cart.pop();
        return alert('최대 가능 배당을 초과했습니다.');
      }
      this.calWinnings();
    },
    // 베팅금액 초과시 자동으로 최대 가능 베팅금액 변경
    amount: {
      handler(val){
        if (this.maxAmount < Number(val)) {
          this.amount = this.maxAmount;
          return alert('베팅금액은 최대베팅금액을 초과할 수 없습니다.')
        }
        if (this.balance < Number(val)) {
          this.amount = this.balance;// return alert('베팅금액은 보유금액을 초과할 수 없습니다.')
        }
        this.calWinnings();
      },
      immediate: true,
    },
    cart: {
      handler: function(val){
        if (val.length === 1) this.limitWinnings = this.maxOneFolderWinAmount;
        // if (val.length > 1) this.limitWinnings = this.maxWinAmount;
        this.calWinnings();
      },
      immediate: true,
      deep: true,
    },
    // 당첨금 변동
    winnings: function(val){
      if (Number(val) > this.limitWinnings){
        this.amount = Math.floor(this.limitWinnings / this.odds);
        alert(`당첨금액은 ${this.$options.filters.makeComma(this.limitWinnings)}을 초과할 수 없습니다.`)
      }
    }
  },
  mounted() {
    this.limitWinnings = this.levelSetting.miniSetting.maxWin;
    this.interval = setInterval(this.inter, 1000)
  },
  beforeDestroy() {
    this.clearCart()
    clearInterval(this.interval);
  },
  methods: {
    addCart: function (bet, folder, game) {
      this.$store.dispatch('ADD_BET_MINI', {
        gameId: game.game_id,
        gameSeq: game.game_seq,
        betsSeq: bet.bets_seq,
        betsStatus: bet.bets_status,
        betsId: bet.bets_id,
        betsName: bet.bets_name,
        betsUpdated: false,
        betsPrevOdds: bet.bets_price,
        sportsId: bet.game_sport,
        marketsId: null,
        foldersSeq: folder.folders_seq,
        foldersType: folder.folders_type,
        odds: folder.folders_detail.indexOf('소중대') >= 0 && bet.bets_name === '중' ? 2.6 : folder.folders_detail.indexOf('소중대') >= 0 && bet.bets_name !== '중' ? 2.9 : 1.95,
        line: bet.bets_line,
        marketNameKr: folder.folders_detail,
        marketNameEn: folder.folders_detail,
        leagueNameKr: '동행파워볼',
        leagueNameEn: '동행파워볼',
        leagueNameIcon: null,
        sportNameKr: game.game_name,
        sportNameEn: game.game_name,
        sportNameIcon: null,
        homeNameKr: folder.folders_detail.indexOf('홀짝') >= 0 ? '홀' : folder.folders_detail.indexOf('언더오버') >= 0 ? '언더' : '소',
        homeNameEn: folder.folders_detail.indexOf('홀짝') >= 0 ? '홀' : folder.folders_detail.indexOf('언더오버') >= 0 ? '언더' : '소',
        drawNameKr: folder.folders_detail.indexOf('소중대') >= 0 ? '중' : null,
        awayNameKr: folder.folders_detail.indexOf('홀짝') >= 0 ? '짝' : folder.folders_detail.indexOf('언더오버') >= 0 ? '오버' : '대',
        awayNameEn: folder.folders_detail.indexOf('홀짝') >= 0 ? '짝' : folder.folders_detail.indexOf('언더오버') >= 0 ? '오버' : '대',
        startTime: game.game_starttime,
        odds1: folder.folders_detail.indexOf('소중대') >= 0 ? '2.9' : '1.95',
        odds2: folder.folders_detail.indexOf('소중대') >= 0 ? '2.6' : null,
        odds3: folder.folders_detail.indexOf('소중대') >= 0 ? '2.9' : '1.95',
        betType: '미니게임',
        round: this.game.game_round,
      })
    },
    resetCart: function (){
      this.amount = 0;
    },
    inter: function(){
      this.gameStatus = this.gameChangeStatus();
      this.gameStarted()
    },
    gameChangeStatus: function(){
      if (this.game){
        const now = moment().unix()
        // const startTime = moment(this.game.game_starttime).unix()
        const startTime = moment(this.game.game_starttime).subtract(9, 'hours').unix()
        if (this.endTime < startTime - now) {
          return false;
        } else {
          // this.$store.dispatch('INVISIBLE_ALL_MODALS');
          this.$store.dispatch('CLEAR_MINI');
          return true
        }
      }
      return false;
    },
    gameStarted: function(){
      if (this.game){
        const now = moment().unix();
        // const startTime = moment(this.game.game_starttime).unix();
        const startTime = moment(this.game.game_starttime).subtract(9, 'hours').unix();
        this.remainTime = startTime - now;
        if (startTime - now <= 1) {
          this.getGame();
          setTimeout(this.reqData, 1000);
        }
      } else {
        this.getGame()
      }
    },
    changeAmount: function(amt){
      this.amount += amt;
    },
    changeProcess: function(){
      this.process = !this.process;
    },
    changeComplete: function(){
      this.complete = !this.complete;
    },
    processBet: function(){
      let data = {};
      if (this.cart.length === 0) return alert(`선택후 베팅을 진행해주세요.`);
      if (this.amount < this.minAmount) return alert(`최소 베팅금은 ${this.minAmount}원 입니다.`);
      if (this.cart.find(e => e.startTime < moment().subtract(9, "hours").format('YYYY-MM-DD HH:mm:ss'))) return alert('이미 시작한 경기입니다.')
      this.changeProcess();
      data.bettings = this.cart;
      data.amount = this.amount;
      data.totalOdds = this.totalOdds;
      data.winnings = this.winnings;
      this.$store.dispatch('SET_PROCESS', data);
      this.$store.dispatch('SHOW_MODAL', 'bettingMiniProcess')
      setTimeout(this.reqData, 4000);

      this.amount = 0;
      this.$store.dispatch('CLEAR_MINI');
    },
    calWinnings: function (){
      this.winnings = Math.floor(this.amount * this.totalOdds);
    },
    resetAmount: function(){
      this.amount = 0;
    },
    halfBet: function(){
      // 보유금액 * 배당이 limitWinnings에 못미칠때
      // 보유금액 전부
      if (this.balance < this.maxAmount / 2){
        this.amount = this.balance;
      }
      // 보유금액 * 배당 limitWinnings에 넘을때
      // limitWinnings 근사치에 베팅
      // 베팅금액을 조정
      if (this.balance  > this.maxAmount / 2){
        this.amount = Math.floor(this.maxAmount / 2)
      }
    },
    maxBet: function(){
      // 보유금액 * 배당이 limitWinnings에 못미칠때
      // 보유금액 전부
      console.log(this.balance, this.maxAmount);
      if (this.balance < this.maxAmount){
        this.amount = this.balance;
      }
      // 보유금액 * 배당 limitWinnings에 넘을때
      // limitWinnings 근사치에 베팅
      // 베팅금액을 조정
      if (this.balance > this.maxAmount){
        this.amount = this.maxAmount;
      }
    },
    clearCart: function(){
      this.$store.dispatch('CLEAR_MINI');
    },
  },
}
</script>

<style scoped>

</style>
