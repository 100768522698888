<template>
  <tbody
      :style="listStyle"
      @mouseenter="toggleAnimation"
      @mouseleave="toggleAnimation"
  >
  <tr
      v-for="(tableItem, index) in list"
      :key="index"
      :style="itemStyle"
  >
      <table width="100%" cellpadding="0" cellspacing="0" class="board_table_1_1">
        <tbody><tr>
          <td class="board_time_1 ng-binding">{{ tableItem.date }}</td>
          <td class="board_name_1 ng-binding">{{ tableItem.name }}</td>
          <td class="board_money_1 ng-binding">{{ tableItem.cash | makeComma }} 원</td>
        </tr>
        </tbody>
      </table>
  </tr>
  </tbody>
</template>

<script>
import moment from "moment";

export default {
  name: 'WithdrawalList',
  data: () => ({
    list: [],
    listTop: 0,
    isPaused: false,
    isMovingBackwards: false
  }),
  props: {
    scale: {
      type: Number,
      default: 1
    },
    transitionDuration: {
      type: Number,
      default: 1000
    },
    transitionDelay: {
      type: Number,
      default: 500
    },
    transition: {
      type: String,
      default: 'ease-in-out'
    },
    pauseOnHover: {
      type: Boolean,
      default: true
    },
    dateFormat: {
      type: String,
      default: 'day'
    }
  },
  computed: {
    listCount () {
      return this.list.length
    },
    itemHeight () {
      return 12
    },
    listHeight () {
      return this.itemHeight * 6
    },
    itemStyle () {
      return `height: ${this.itemHeight}px;`
    },
    listStyle () {
      let transitionDelay = this.transitionDelay
      let transitionDuration = this.transitionDuration
      if (this.listTop === 0) {
        transitionDelay = 0
        transitionDuration = 0
      }

      return `height: ${this.listHeight}px;
            transition-delay: ${transitionDelay}ms;
            transition-duration: ${transitionDuration}ms;
            transition-timing-function: ${this.transition};
            will-change: transform;
            transform: translateY(${this.listTop * 3}px)`
    },
  },
  mounted () {
    setTimeout(this.updateState, this.transitionDelay)
    this.initWithdrawalData();
  },
  methods: {
    updateState () {
      if (this.listCount <= 1) return
      if (this.isPaused) return
      if (this.isMovingBackwards) {

        this.listTop += this.itemHeight
      } else {
        this.listTop -= this.itemHeight
      }
      if (this.listTop < this.itemHeight * -1) {
        this.list.splice(0, 1)
        this.list.push(this.addWithdrawalData());
        this.list.push(this.addWithdrawalData());
        this.listTop = 0;
      }
      this.timeOutId = window.setTimeout(() => {
        this.updateState()
      }, this.transitionDuration + this.transitionDelay)
    },
    toggleAnimation () {
      if (this.pauseOnHover) {
        this.isPaused = !this.isPaused
        if (this.isPaused) {
          clearTimeout(this.timeOutId)
        } else {
          this.updateState()
        }
      }
    },
    initWithdrawalData() {
      for (let i=0; i < 6; i++){
        const data = {
          name: `${this._randomString()}****`,
          cash: this._randomAmount() * 10000,
          date: this._randomDate(),
        }
        this.list.push(data);
      }
    },
    addWithdrawalData() {
      return {
        name: `${this._randomString()}****`,
        cash: this._randomAmount() * 10000,
        date: this._randomDate(),
      }
    },
    _randomString(){
      let text = "";
      const stringText = "abcdefghijklmnopqrstuvwxyz0123456789";
      for( let i=0; i < 4; i++ )
        text += stringText.charAt(Math.floor(Math.random() * stringText.length));
      return text;
    },
    _randomAmount(range = 100){
      return Math.floor(Math.random() * range) + 1;
    },
    _randomDate(){
      return moment().subtract(this._randomAmount(300),"seconds").format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style>
.auto-scroll-tab > .v-data-table__wrapper {
  overflow-y: hidden !important;
}
.body-4 {
  font-size: 13px;
  letter-spacing: -0.2px;
}
</style>
