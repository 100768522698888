<template>
  <div class="game-side" style="margin-top: 0px;">
    <div class="list">
<!--      <a @click="alert('현재 메뉴는 구현중입니다.')">-->
<!--        <strong>EOS 파워볼 5분</strong>-->
<!--        <span>{{ remainTime | countDown }}</span>-->
<!--      </a>-->
      <a @click="alert('현재 메뉴는 구현중입니다.')">
        <strong>토큰파워볼</strong>
        <span>{{ remainTime | countDown }}</span>
      </a>
    </div>
  </div>
</template>

<script>
// import CountDown from './CountDown'
import moment from "moment";

export default {
  name: "MiniSideRight",
  props: ['game'],

  data: function() {
    return {
      path: 'game/timer',
      remainTime: 0,
    }
  },
  mounted() {
    this.interval = setInterval(this.calcTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    calcTime: function(){
      const now = moment().unix();
      const startTime = moment(this.game.game_starttime).subtract(9, 'hours').unix();
      this.remainTime = startTime - now;
    }
  },
}
</script>

<style scoped>

</style>
