import { render, staticRenderFns } from "./RenewalEuropeanMobileDetail.vue?vue&type=template&id=134c13b2&scoped=true"
import script from "./RenewalEuropeanMobileDetail.vue?vue&type=script&lang=js"
export * from "./RenewalEuropeanMobileDetail.vue?vue&type=script&lang=js"
import style0 from "./RenewalEuropeanMobileDetail.vue?vue&type=style&index=0&id=134c13b2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "134c13b2",
  null
  
)

export default component.exports