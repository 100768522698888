<template>
  <td class="soprts_in_acc_2 "
      style="width: 25%;"
      :class="{'sel': checkCart(bets)}"
      @click="addCart(bets, folder, game)"
  >
    <div class="sports_v_l_1">
      {{ name }}
    </div>
    <div class="sports_v_r_1">
      <i v-if="priceStatus === 'up'" class="ico-up blink"></i>
      <i v-if="priceStatus === 'down'" class="ico-down blink"></i>
      <span class="font_002 ">
        {{ price }}
      </span>
      <i v-if="priceStatus" class="sel_old ">({{ startPrice }})</i>
    </div>
  </td>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game'],
  data: function(){
    return {
      startPrice: null,
      priceStatus: null,
      up: false,
      down: false
    }
  },
  computed: {
    name: function(){
      let name = this.bets.name;
      if (name?.indexOf('W1andOver') >= 0){
        name = name.replace('W1andOver', `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 오버`)
        if (this.bets.line) name += ` (${this.bets.line})`
      }
      if (name?.indexOf('W2andOver') >= 0){
        name = name.replace('W2andOver', `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 오버`)
        if (this.bets.line) name += ` (${this.bets.line})`
      }
      if (name?.indexOf('12andOver') >= 0){
        name = name.replace('12andOver', '12 & 오버')
        if (this.bets.line) name += ` (${this.bets.line})`
      }
      if (name?.indexOf('W1andUnder') >= 0){
        name = name.replace('W1andUnder', `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 언더`)
        if (this.bets.line) name += ` (${this.bets.line})`
      }
      if (name?.indexOf('W2andUnder') >= 0){
        name = name.replace('W2andUnder', `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 언더`)
        if (this.bets.line) name += ` (${this.bets.line})`
      }
      if (name?.indexOf('12andUnder') >= 0){
        name = name.replace('12andUnder', '12 & 언더')
        if (this.bets.line) name += ` (${this.bets.line})`
      }

      if (name?.indexOf('1XandOver') >= 0){
        name = name.replace('1XandOver', '1X & 오버')
        if (this.bets.line) name += ` (${this.bets.line})`
        return name;
      }
      if (name?.indexOf('XandOver') >= 0){
        name = name.replace('XandOver', '무 & 오버')
        if (this.bets.line) name += ` (${this.bets.line})`
      }
      if (name?.indexOf('X2andOver') >= 0){
        name = name.replace('X2andOver', 'X2 & 오버')
        if (this.bets.line) name += ` (${this.bets.line})`
      }
      if (name?.indexOf('1XandUnder') >= 0){
        name = name.replace('1XandUnder', '1X & 언더')
        if (this.bets.line) name += ` (${this.bets.line})`
      }
      if (name?.indexOf('XAndUnder') >= 0){
        name = name.replace('XAndUnder', '무 & 언더')
        if (this.bets.line) name += ` (${this.bets.line})`
      }
      if (name?.indexOf('X2andUnder') >= 0){
        name = name.replace('X2andUnder', 'X2 & 언더')
        if (this.bets.line) name += ` (${this.bets.line})`
      }
      if (name?.indexOf('ormore') >= 0){
        name = name.replace('ormore', '골 또는 그이상')
      }
      if (name === 'AnyOther'){
        name = name.replace('AnyOther', '기타')
      }
      if (name.indexOf('Team1') >= 0){
        name = name.replace('Team1', this.game.homeTeam.nameKr || this.game.homeTeam.nameEn)
      }
      if (name.indexOf('Team2') >= 0){
        name = name.replace('Team2', this.game.awayTeam.nameKr || this.game.awayTeam.nameEn)
      }

      name = name.replace(':', ' : ')
      return name;
    },
    price() {
      return Number(this.bets.price).toFixed(2)
    }
  },
  watch: {
    price: function(newVal, val){
      if (Number(newVal) - Number(val) < 0) this.priceStatus = 'down';
      if (Number(newVal) - Number(val) > 0) this.priceStatus = 'up';
      if (Number(newVal) !== Number(val)) this.startPrice = val;
    },
    priceStatus: function(){
      setTimeout(() => {
        this.priceStatus = null
        this.startPrice = null;
      } ,10000)
    },
  },
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
