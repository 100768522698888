<template>
  <div class="in" v-if="isLoading">
    <transition-group name="fade">
      <template v-for="(market, index2) in marketSort(lists)">
        <template v-if="market.nameEn.indexOf('{sw}') >= 0">
          <template v-for="period in uniqPeriod(market.seq)">
            <div class="tit" :key="period+'MarketName'+index2" v-if="market.nameEn.indexOf('Team 1') < 0 && market.nameEn.indexOf('Team 2') < 0 ">
              {{ market | marketName(period) }}
            </div>
            <div class="tit" :key="period+'MarketName'+index2" v-else>
              {{ market | teamMarketNamePeriod(game.homeTeam, game.awayTeam, period) }}
            </div>
            <div class="cols" v-if="folder.bets.length < 4" :key="period+'folderDetail'+folder.seq" v-for="folder in lists.filter(e => e.marketId == market.id)">
              <div
                  :class="{'active': folder.bets.find(e => e.name === '1' || e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Team1') ? folder.bets.find(e => e.name === '1' || e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Team1').settlement === 'win' : false }"
              >
                {{
                  folder.bets.find(e => e.name === '1' || e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Team1')?.name | betsName
                }}
                &nbsp;&nbsp;
                {{ folder.line }}
                &nbsp;&nbsp;
              </div>
              <div
                  v-if="draw=folder.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                  :class="{'active': folder.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal') ? folder.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal').settlement === 'win' : false }"
              >
                {{
                  folder.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')?.name | betsName
                }}
                &nbsp;&nbsp;
                {{ folder.line }}
                &nbsp;&nbsp;
              </div>
              <div
                  :class="{'active': folder.bets.find(e => e.name === '2' || e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Team2') ? folder.bets.find(e => e.name === '2' || e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Team2').settlement === 'win' : false }"
              >
                {{
                  folder.bets.find(e => e.name === '2' || e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Team2')?.name | betsName
                }}
                &nbsp;&nbsp;
                {{ folder.line }}
                &nbsp;&nbsp;
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="tit" :key="'MarketName'+index2" v-if="market.nameEn.indexOf('Team 1') < 0 && market.nameEn.indexOf('Team 2') < 0 ">
            {{ market | marketName }}
          </div>
          <div class="tit" :key="'MarketName'+index2" v-else>
            {{ market | teamMarketNamePeriod(game.homeTeam, game.awayTeam) }}
          </div>
          <div class="cols" v-if="folder.bets.length < 4" :key="'folderDetail'+folder.seq" v-for="folder in lists.filter(e => e.marketId == market.id)">
            <div
                :class="{'active': folder.bets.find(e => e.name === '1' || e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Team1') ? folder.bets.find(e => e.name === '1' || e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Team1').settlement === 'win' : false }"
            >
              {{
                folder.bets.find(e => e.name === '1' || e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Team1')?.name | betsName
              }}
              &nbsp;&nbsp;
              {{ folder.line }}
              &nbsp;&nbsp;
            </div>
            <div
                v-if="draw=folder.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                :class="{'active': folder.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal') ? folder.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal').settlement === 'win' : false }"
            >
              {{
                folder.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')?.name | betsName
              }}
              &nbsp;&nbsp;
              {{ folder.line }}
              &nbsp;&nbsp;
            </div>
            <div
                :class="{'active': folder.bets.find(e => e.name === '2' || e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Team2') ? folder.bets.find(e => e.name === '2' || e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Team2').settlement === 'win' : false }"
            >
              {{
                folder.bets.find(e => e.name === '2' || e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Team2')?.name | betsName
              }}
              &nbsp;&nbsp;
              {{ folder.line }}
              &nbsp;&nbsp;
            </div>
          </div>
        </template>
      </template>
    </transition-group>
  </div>
  <div v-else style="text-align: center;">
    <Loading></Loading>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
export default {
  name: "Price",
  props: ['game'],
  components: {
    Loading,
  },
  data: function () {
    return {
      path: 'v2/game/result/detail',
      lists: null,
      originList: null,
      openDetailBet: [],
      selectedMarket: null,
      isLoading: false,
      closed: [],
    }
  },
  mounted() {
    this.initFolder();
  },
  methods: {
    initFolder: function () {
      const payload = {
        gameId: this.game.id
      }
      this.$store.dispatch('GET_RESULT_DETAIL', { payload })
          .then(data => {
            const result = data.data;
            this.originList = result.payload.list
            this.lists = this.originList;
            this.isLoading = true;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    marketSort: function (lists) {
      const markets = lists.map(e => e.market);
      const arrUnique = markets.filter((character, idx, arr)=>{
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    uniqPeriod: function (marketSeq) {
      const periods = this.lists.filter(e => e.marketSeq === marketSeq).map(e => e.periodSequence);
      let set = new Set(periods);
      const uniqueArr = [...set];
      uniqueArr.sort()
      return uniqueArr
    },
  }
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
