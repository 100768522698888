<template>
  <tr>
    <template v-if="isLoading">
      <td style="width:50%" valign="top">
        <template v-for="(row, index) in oddList">
          <table class="effect_table_1" :key="'foldersTitle' + row.seq + index">
            <tbody>
            <tr>
              <td class="effect_in_t_1">{{ row.market | marketName(row.periodSequence) }}</td>
            </tr>
            </tbody>
          </table>
          <table class="acc-open effect_table_1" :key="'foldersDetail' + row.seq + index">
            <tbody>
            <tr v-if="row.market && row.market.nameKr?.indexOf('핸디캡') >= 0">
              <td class="effect_table_in_t_1 " :class="{'sel': row.bets.find(e => e.name === 'Home')?.settlement === 'win'}">
                <span class="effect_in_left_1">{{ game.homeTeam | teamName }} ({{ row.bets.find(e => e.name === 'Home').line }})</span>
                <span class="effect_in_right_1">
                <img v-if="row.bets.find(e => e.name === 'Home')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else-if="row.bets.find(e => e.name === 'Home')?.settlement === 'wait'" src="@/assets/img/ico/res_icon002-1.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" :class="{'sel': row.bets.find(e => e.name === 'Away')?.settlement === 'win'}">
                <span class="effect_in_left_1">{{ game.awayTeam | teamName }} ({{ row.bets.find(e => e.name === 'Away').line }})</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === 'Away')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else-if="row.bets.find(e => e.name === 'Away')?.settlement === 'wait'" src="@/assets/img/ico/res_icon002-1.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
            </tr>
            <tr v-else-if="row.market && row.market.nameKr?.indexOf('승패') >= 0">
              <td class="effect_table_in_t_1 " style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'W1')?.settlement === 'win'}">
                <span class="effect_in_left_1">{{ game.homeTeam | teamName }}</span>
                <span class="effect_in_right_1">
                <img v-if="row.bets.find(e => e.name === 'W1')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else-if="row.bets.find(e => e.name === 'W1')?.settlement === 'wait'" src="@/assets/img/ico/res_icon002-1.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'W2')?.settlement === 'win'}">
                <span class="effect_in_left_1">{{ game.awayTeam | teamName }}</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === 'W2')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else-if="row.bets.find(e => e.name === 'W2')?.settlement === 'wait'" src="@/assets/img/ico/res_icon002-1.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
            </tr>
            <tr v-else-if="row.market && row.market.nameKr?.indexOf('?') >= 0">
              <td class="effect_table_in_t_1 " style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'Yes')?.settlement === 'win'}">
                <span class="effect_in_left_1">예</span>
                <span class="effect_in_right_1">
                <img v-if="row.bets.find(e => e.name === 'Yes')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else-if="row.bets.find(e => e.name === 'Yes')?.settlement === 'wait'" src="@/assets/img/ico/res_icon002-1.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'No')?.settlement === 'win'}">
                <span class="effect_in_left_1">아니오</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === 'No')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else-if="row.bets.find(e => e.name === 'No')?.settlement === 'wait'" src="@/assets/img/ico/res_icon002-1.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
            </tr>
            <tr v-else-if="row.market && row.market.nameKr?.indexOf('승무패') >= 0">
              <td class="effect_table_in_t_1 " style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'W1')?.settlement === 'win'}">
                <span class="effect_in_left_1">승</span>
                <span class="effect_in_right_1">
                <img v-if="row.bets.find(e => e.name === 'W1')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else-if="row.bets.find(e => e.name === 'W1')?.settlement === 'wait'" src="@/assets/img/ico/res_icon002-1.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'X')?.settlement === 'win'}">
                <span class="effect_in_left_1">무</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === 'X')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else-if="row.bets.find(e => e.name === 'X')?.settlement === 'wait'" src="@/assets/img/ico/res_icon002-1.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'W2')?.settlement === 'win'}">
                <span class="effect_in_left_1">패</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === 'W2')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else-if="row.bets.find(e => e.name === 'W2')?.settlement === 'wait'" src="@/assets/img/ico/res_icon002-1.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
            </tr>
            <tr v-else-if="row.market && row.market.nameKr?.indexOf('오버언더') >= 0">
              <td class="effect_table_in_t_1 " :class="{'sel': row.bets.find(e => e.name === 'Over')?.settlement === 'win'}">
                <span class="effect_in_left_1">오버 ({{ row.line }})</span>
                <span class="effect_in_right_1">
                <img v-if="row.bets.find(e => e.name === 'Over')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else-if="row.bets.find(e => e.name === 'Over')?.settlement === 'wait'" src="@/assets/img/ico/res_icon002-1.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" :class="{'sel': row.bets.find(e => e.name === 'Under')?.settlement === 'win'}">
                <span class="effect_in_left_1">언더 ({{ row.line }})</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === 'Under')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else-if="row.bets.find(e => e.name === 'Under')?.settlement === 'wait'" src="@/assets/img/ico/res_icon002-1.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
            </tr>
            <!--          <tr v-else-if="row.market && row.market.markets_name_en.indexOf('Winner') >= 0">-->
            <!--            <td class="effect_table_in_t_1 " style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === '1')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">{{ home | teamName }}</span>-->
            <!--              <span class="effect_in_right_1">-->
            <!--                <img v-if="row.bets.find(e => e.name === '1')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--            <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'X')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">무</span>-->
            <!--              <span class="effect_in_right_y">-->
            <!--                <img v-if="row.bets.find(e => e.name === 'X')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--            <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === '2')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">{{ away | teamName }}</span>-->
            <!--              <span class="effect_in_right_y">-->
            <!--                <img v-if="row.bets.find(e => e.name === '2')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--          </tr>-->
            <tr v-else-if="row.market && row.market.nameKr?.indexOf('더블찬스') >= 0">
              <td class="effect_table_in_t_1 " style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === '1X')?.settlement === 'win'}">
                <span class="effect_in_left_1">홈승 또는 무승부</span>
                <span class="effect_in_right_1">
                <img v-if="row.bets.find(e => e.name === '1X')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else-if="row.bets.find(e => e.name === '1X')?.settlement === 'wait'" src="@/assets/img/ico/res_icon002-1.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === '12')?.settlement === 'win'}">
                <span class="effect_in_left_1">홈승 또는 원정승</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === '12')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else-if="row.bets.find(e => e.name === '12')?.settlement === 'wait'" src="@/assets/img/ico/res_icon002-1.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'X2')?.settlement === 'win'}">
                <span class="effect_in_left_1">무승부 또는 원정승</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === 'X2')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else-if="row.bets.find(e => e.name === 'X2')?.settlement === 'wait'" src="@/assets/img/ico/res_icon002-1.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
            </tr>
            <!--          <tr v-else-if="row.market && row.market.nameEn.indexOf('Team To Score') >= 0">-->
            <!--            <td class="effect_table_in_t_1 " style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === '1')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">{{ home | teamName }}</span>-->
            <!--              <span class="effect_in_right_1">-->
            <!--                <img v-if="row.bets.find(e => e.name === '1')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--            <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'No Goal')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">노골</span>-->
            <!--              <span class="effect_in_right_y">-->
            <!--                <img v-if="row.bets.find(e => e.name === 'No Goal')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--            <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === '2')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">{{ away | teamName }}</span>-->
            <!--              <span class="effect_in_right_y">-->
            <!--                <img v-if="row.bets.find(e => e.name === '2')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--          </tr>-->
            <!--          <tr v-else-if="row.markets && row.market.markets_name_en.indexOf('Total Corners') >= 0">-->
            <!--            <td class="effect_table_in_t_1 " :class="{'sel': row.bets.find(e => e.name === 'Over')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">오버 {{ row.line }}</span>-->
            <!--              <span class="effect_in_right_1">-->
            <!--              <img v-if="row.bets.find(e => e.name === 'Over')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--              <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--            </span>-->
            <!--            </td>-->
            <!--            <td class="effect_table_in_t_1" :class="{'sel': row.bets.find(e => e.name === 'Under')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">언더 {{ row.line }}</span>-->
            <!--              <span class="effect_in_right_y">-->
            <!--              <img v-if="row.bets.find(e => e.name === 'Under')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--              <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--            </span>-->
            <!--            </td>-->
            <!--          </tr>-->
            <!--          <tr v-else-if="row.markets && row.market.markets_name_en.indexOf('Teams To Score') >= 0">-->
            <!--            <td class="effect_table_in_t_1 "  :class="{'sel': row.bets.find(e => e.name === 'Yes')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">예</span>-->
            <!--              <span class="effect_in_right_1">-->
            <!--                <img v-if="row.bets.find(e => e.name === 'Yes')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--            <td class="effect_table_in_t_1"  :class="{'sel': row.bets.find(e => e.name === 'No')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">아니오</span>-->
            <!--              <span class="effect_in_right_y">-->
            <!--                <img v-if="row.bets.find(e => e.name === 'No')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--          </tr>-->

            </tbody>
          </table>
        </template>
      </td>
      <td style="width:50%" valign="top">
        <template v-for="(row, index) in evenList">
          <table class="effect_table_1" :key="'foldersTitle' + row.seq + index">
            <tbody>
            <tr>
              <td class="effect_in_t_1">{{ row.market | marketName(row.periodSequence) }}</td>
            </tr>
            </tbody>
          </table>
          <table class="acc-open effect_table_1" :key="'foldersDetail' + row.seq + index">
            <tbody>
            <tr v-if="row.market && row.market.nameKr?.indexOf('핸디캡') >= 0">
              <td class="effect_table_in_t_1 " :class="{'sel': row.bets.find(e => e.name === 'Home')?.settlement === 'win'}">
                <span class="effect_in_left_1">{{ game.homeTeam | teamName }} ({{ row.bets.find(e => e.name === 'Home').line }})</span>
                <span class="effect_in_right_1">
                <img v-if="row.bets.find(e => e.name === 'Home')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" :class="{'sel': row.bets.find(e => e.name === 'Away')?.settlement === 'win'}">
                <span class="effect_in_left_1">{{ game.awayTeam | teamName }} ({{ row.bets.find(e => e.name === 'Away').line }})</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === 'Away')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
            </tr>
            <tr v-else-if="row.market && row.market.nameKr?.indexOf('승패') >= 0">
              <td class="effect_table_in_t_1 " style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'W1')?.settlement === 'win'}">
                <span class="effect_in_left_1">{{ game.homeTeam | teamName }}</span>
                <span class="effect_in_right_1">
                <img v-if="row.bets.find(e => e.name === 'W1')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'W2')?.settlement === 'win'}">
                <span class="effect_in_left_1">{{ game.awayTeam | teamName }}</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === 'W2')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
            </tr>
            <tr v-else-if="row.market && row.market.nameKr?.indexOf('?') >= 0">
              <td class="effect_table_in_t_1 " style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'Yes')?.settlement === 'win'}">
                <span class="effect_in_left_1">예</span>
                <span class="effect_in_right_1">
                <img v-if="row.bets.find(e => e.name === 'Yes')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'No')?.settlement === 'win'}">
                <span class="effect_in_left_1">아니오</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === 'No')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
            </tr>
            <tr v-else-if="row.market && row.market.nameKr?.indexOf('승무패') >= 0">
              <td class="effect_table_in_t_1 " style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'W1')?.settlement === 'win'}">
                <span class="effect_in_left_1">승</span>
                <span class="effect_in_right_1">
                <img v-if="row.bets.find(e => e.name === 'W1')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'X')?.settlement === 'win'}">
                <span class="effect_in_left_1">무</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === 'X')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'W2')?.settlement === 'win'}">
                <span class="effect_in_left_1">패</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === 'W2')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
            </tr>
            <tr v-else-if="row.market && row.market.nameKr?.indexOf('오버언더') >= 0">
              <td class="effect_table_in_t_1 " :class="{'sel': row.bets.find(e => e.name === 'Over')?.settlement === 'win'}">
                <span class="effect_in_left_1">오버 ({{ row.line }})</span>
                <span class="effect_in_right_1">
                <img v-if="row.bets.find(e => e.name === 'Over')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" :class="{'sel': row.bets.find(e => e.name === 'Under')?.settlement === 'win'}">
                <span class="effect_in_left_1">언더 ({{ row.line }})</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === 'Under')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
            </tr>
            <tr v-else-if="row.market && row.market.nameKr?.indexOf('홀/짝') >= 0">
              <td class="effect_table_in_t_1 " :class="{'sel': row.bets.find(e => e.name === 'Odd')?.settlement === 'win'}">
                <span class="effect_in_left_1">홀</span>
                <span class="effect_in_right_1">
                <img v-if="row.bets.find(e => e.name === 'Odd')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" :class="{'sel': row.bets.find(e => e.name === 'Even')?.settlement === 'win'}">
                <span class="effect_in_left_1">짝</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === 'Even')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
            </tr>
            <!--          <tr v-else-if="row.market && row.market.markets_name_en.indexOf('Winner') >= 0">-->
            <!--            <td class="effect_table_in_t_1 " style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === '1')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">{{ home | teamName }}</span>-->
            <!--              <span class="effect_in_right_1">-->
            <!--                <img v-if="row.bets.find(e => e.name === '1')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--            <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'X')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">무</span>-->
            <!--              <span class="effect_in_right_y">-->
            <!--                <img v-if="row.bets.find(e => e.name === 'X')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--            <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === '2')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">{{ away | teamName }}</span>-->
            <!--              <span class="effect_in_right_y">-->
            <!--                <img v-if="row.bets.find(e => e.name === '2')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--          </tr>-->
            <tr v-else-if="row.market && row.market.nameKr?.indexOf('더블찬스') >= 0">
              <td class="effect_table_in_t_1 " style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === '1X')?.settlement === 'win'}">
                <span class="effect_in_left_1">홈승 또는 무승부</span>
                <span class="effect_in_right_1">
                <img v-if="row.bets.find(e => e.name === '1X')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === '12')?.settlement === 'win'}">
                <span class="effect_in_left_1">홈승 또는 원정승</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === '12')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
              <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'X2')?.settlement === 'win'}">
                <span class="effect_in_left_1">무승부 또는 원정승</span>
                <span class="effect_in_right_y">
                <img v-if="row.bets.find(e => e.name === 'X2')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">
                <img v-else src="@/assets/img/ico/res_icon003-1.png">
              </span>
              </td>
            </tr>
            <!--          <tr v-else-if="row.market && row.market.nameEn.indexOf('Team To Score') >= 0">-->
            <!--            <td class="effect_table_in_t_1 " style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === '1')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">{{ home | teamName }}</span>-->
            <!--              <span class="effect_in_right_1">-->
            <!--                <img v-if="row.bets.find(e => e.name === '1')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--            <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === 'No Goal')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">노골</span>-->
            <!--              <span class="effect_in_right_y">-->
            <!--                <img v-if="row.bets.find(e => e.name === 'No Goal')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--            <td class="effect_table_in_t_1" style="width: 33% !important;" :class="{'sel': row.bets.find(e => e.name === '2')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">{{ away | teamName }}</span>-->
            <!--              <span class="effect_in_right_y">-->
            <!--                <img v-if="row.bets.find(e => e.name === '2')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--          </tr>-->
            <!--          <tr v-else-if="row.markets && row.market.markets_name_en.indexOf('Total Corners') >= 0">-->
            <!--            <td class="effect_table_in_t_1 " :class="{'sel': row.bets.find(e => e.name === 'Over')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">오버 {{ row.line }}</span>-->
            <!--              <span class="effect_in_right_1">-->
            <!--              <img v-if="row.bets.find(e => e.name === 'Over')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--              <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--            </span>-->
            <!--            </td>-->
            <!--            <td class="effect_table_in_t_1" :class="{'sel': row.bets.find(e => e.name === 'Under')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">언더 {{ row.line }}</span>-->
            <!--              <span class="effect_in_right_y">-->
            <!--              <img v-if="row.bets.find(e => e.name === 'Under')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--              <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--            </span>-->
            <!--            </td>-->
            <!--          </tr>-->
            <!--          <tr v-else-if="row.markets && row.market.markets_name_en.indexOf('Teams To Score') >= 0">-->
            <!--            <td class="effect_table_in_t_1 "  :class="{'sel': row.bets.find(e => e.name === 'Yes')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">예</span>-->
            <!--              <span class="effect_in_right_1">-->
            <!--                <img v-if="row.bets.find(e => e.name === 'Yes')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--            <td class="effect_table_in_t_1"  :class="{'sel': row.bets.find(e => e.name === 'No')?.settlement === 'win'}">-->
            <!--              <span class="effect_in_left_1">아니오</span>-->
            <!--              <span class="effect_in_right_y">-->
            <!--                <img v-if="row.bets.find(e => e.name === 'No')?.settlement === 'win'" src="@/assets/img/ico/res_icon001.png">-->
            <!--                <img v-else src="@/assets/img/ico/res_icon003-1.png">-->
            <!--              </span>-->
            <!--            </td>-->
            <!--          </tr>-->

            </tbody>
          </table>
        </template>
      </td>
    </template>
    <template v-else>
      <td style="text-align: center; width: 100%">
        <Loading></Loading>
      </td>
    </template>
  </tr>
</template>

<script>
import Loading from '@/components/Loading'
export default {
  name: "Price",
  props: ['game'],
  components: {
    Loading
  },
  data: function () {
    return {
      path: 'v2/game/result/detail',
      oddList: null,
      evenList: null,
      originList: null,
      openDetailBet: [],
      selectedMarket: null,
      isLoading: false,
      closed: [],
    }
  },
  mounted() {
    this.initFolder();
  },
  methods: {
    initFolder: function () {
      const payload = {
        gameId: this.game.id
      }
      return this.$store.dispatch('GET_RESULT_DETAIL', { payload })
          .then(data => {
            const result = data.data;
            this.originList = result.payload.list
            this.oddList = this.originList.filter((e, index) => index % 2 === 1);
            this.evenList = this.originList.filter((e, index) => index % 2 === 0);
            this.isLoading = true;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
  }
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
