<template>
  <dl>
    <dd>
      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
    </dd>
  </dl>
</template>

<script>
export default {
  name: "MPrice",
}
</script>

<style scoped>
</style>
