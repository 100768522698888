<template>

  <section  v-if="!this.$store.state.page.isMobile">
    <h2 class="subTitle">카지노 충전 CASINO CHARGE MONEY</h2>


    <div class="nbox">

      <div class="charge_text">
        <h3 class="tit-bet more_info" @click="btnToggle" style="border-top: 1px solid #a97bf5; display:flex; align-items:center;    justify-content: space-between; ">주의사항<img src="@/assets/img/ico/ico_down03w.png"></h3>
<!--        <transition name="fade">-->
<!--          <p class="charge_text_info" v-if="toggle">-->
<!--            ● 바카라 게임 안내-->
<!--            회원은 플레이어(Player)와 뱅커(Banker) 중 어느 핸드가 이길지 예상하여 베팅을 합니다.<br>-->
<!--            비길 것을 예상하여 타이(Tie)에 함께 베팅하기도 합니다.<br>-->
<!--            베팅금액은 하단 최대배팅금액 규정 범위 내에서 하시면 됩니다.<br>-->
<!--            딜러가 드로잉 룰에 따라 플레이어와 뱅커에 카드를 드로잉하여 플레이어, 뱅커 중 9에 가까운 쪽이 이깁니다.<br>-->
<!--            플레이어, 뱅커의 각 2장 혹은 3장의 합으로 게임 승패가 결정됩니다.<br><br>-->

<!--            TIP<br>-->
<!--            많은 분들이 플레이어는 회원, 뱅커는 카지노의 영역으로 오해하는데, 회원이 플레이어와 뱅커 중 이기는 쪽을 예상하여 베팅을 하는 게임입니다.<br>-->
<!--            회원은 플레이어와 뱅커 어느 쪽이든 베팅 할 수 있습니다.<br>-->

<!--            ● 바카라 게임 규정 및 주의사항 안내<br>-->
<!--            먼저 카지노 배팅은 매우 짧은 텀으로 진행되는 게임입니다.<br>-->
<!--            따라서, 베팅 후 바로 문의 주셔도 배팅 취소는 어떠한 사유라도 절대 불가합니다.<br>-->
<!--            또한, 기본적으로 볼빅 미니게임 규정에 준하여 취급됩니다. (촤대 배팅 금액)<br>-->
<!--            입금금액 + 보너스 포인트 전부 합하여 500% 이상 롤링 배팅 완료 후 환전 가능합니다.<br>-->
<!--            롤링을 원하지 않으실 경우 충전 후 바로 고객센터로 문의 주시면 보너스 포인트를 차감해 드리겠습니다.<br>-->
<!--            이해가 되시지 않는 부분은 고객센터로 사전 문의 후에 이용해 주시길 바랍니다.<br>-->
<!--            감사합니다.<br><br>-->

<!--            또한 카지노 배팅은 기본적으로 놀이터 규정에 준하여 적용되오며,<br>-->
<!--            단, 한 회차 최대배팅액은 100만원까지만 가능하십니다.<br>-->
<!--            <span>규정 위반하여 낙첨된 회차는 전 금액 몰수처리// 당첨된 회차는 적특처리 됩니다.</span><br>-->
<!--            ex) 예를들어 150만원 충전 후 한 회차 130만 배팅시에는 최대배팅금 초과로 <span>"낙첨시 전 금액 몰수"</span> 처리 되오며<br>-->
<!--            당첨시에는 "적특처리" 되겠습니다.<br>-->
<!--            반드시 규정 위반하여 불이익 당하시지 않도록 주의 해주시길 바랍니다.-->
<!--          </p>-->
<!--        </transition>-->

        <h3 class="tit-bet">카지노 충전신청</h3>
        <div class="charge_box">
          <div style="padding: 20px;">
            <table>
              <tbody>
              <tr>
                <th>보유머니</th>
                <td>
                  <p>{{ user.members_cash | makeComma }}원 / <span>보유 크레딧 : {{ casinoBalance | makeComma }}</span></p>
                </td>
              </tr>
              <tr>
                <th>충전금액</th>
                <td>
                  <div>
                    <input type="number" v-model="amount" @focusout="amountRound">원
                  </div>
                  <ul>
                    <li @click="addAmount(1000)">1,000원</li>
                    <li @click="addAmount(5000)">5,000원</li>
                    <li @click="addAmount(10000)">1만원</li>
                    <li @click="addAmount(50000)">5만원</li>
                    <li @click="addAmount(100000)">10만원</li>
                    <li @click="addAmount(200000)">20만원</li>
                    <li @click="addAmount(500000)">50만원</li>
                    <li @click="addAmount(1000000)">100만원</li>
                    <li @click="addAmount(2000000)">200만원</li>
                    <li @click="addAmount(5000000)">500만원</li>
                    <li @click="allAmount">전체금액</li>

                    <li @click="resetAmount">정정</li>
                  </ul>
                  <p>보유 크레딧을 확인 후 보유 한도내에서 충전할 금액을 입력해주세요.</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <button class="charge_btn" v-if="!isLoading" @click="reqDeposit">충전하기</button>

          <div style="text-align: center; background: #1c2021;" v-else>
            <Loading></Loading>
          </div>
        </div>

        <h3 class="tit-bet">충전내역</h3>
        <table class="listTable02" style="width: 100%">
          <tbody><tr>
            <th>번호</th>
            <th>구분</th>
            <th>신청금액</th>
            <th>신청일자</th>
            <th>상태</th>
          </tr>
          <tr v-if="lists.length === 0">
            <td colspan="5">검색된 내역이 없습니다.</td>
          </tr>
          <tr v-else v-for="(row, index) in lists" :key="'depositList'+index">
            <td>{{ (( payload.page -1 ) * payload.size) + (index + 1)  }}</td>
            <td>충전</td>
            <td>{{ row.cash_amount | makeComma }}</td>
            <td>{{ row.cash_regdatetime | formatDate('YYYY-MM-DD') }}</td>
            <td>{{ row.cash_status  }}</td>
          </tr>
          </tbody>
        </table>
<!--        <div class="pagingBox black">-->
<!--          <div class="paging">-->
<!--            <a href="javascript:void(0)" class="btnFirst"><img src="@/assets/img/ico/ico_first_black.png"></a>-->
<!--            <a href="javascript:void(0)" class="btnPrev"><img src="@/assets/img/ico/ico_prev_black.png"></a>-->

<!--            <a href="javascript:void(0)" class="on">1</a>-->
<!--            <a href="javascript:void(0)">2</a>-->
<!--            <a href="javascript:void(0)">3</a>-->
<!--            <a href="javascript:void(0)">4</a>-->
<!--            <a href="javascript:void(0)">5</a>-->
<!--            <a href="javascript:void(0)">6</a>-->
<!--            <a href="javascript:void(0)">7</a>-->
<!--            <a href="javascript:void(0)">8</a>-->
<!--            <a href="javascript:void(0)">9</a>-->
<!--            <a href="javascript:void(0)">10</a>-->

<!--            <a href="javascript:void(0)" class="btnNext"><img src="@/assets/img/ico/ico_next_black.png"></a>-->
<!--            <a href="javascript:void(0)" class="btnLast"><img src="@/assets/img/ico/ico_last_black.png"></a>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

  </section>
  <div class="mini-game" v-else>
    <h2 class="subTitle2" style="font-size: 18px; margin-bottom:0">카지노 충전 CASINO CHARGE MONEY</h2>
    <div class="charge_text">
      <h3 class="tit-bet more_info" @click="btnToggle" style="border-top: 1px solid #a97bf5; display:flex; align-items:center;    justify-content: space-between;margin-top: 0 ">주의사항<img src="@/assets/img/ico/ico_down03w.png"></h3>
<!--      <transition name="fade">-->
<!--        <p class="charge_text_info" v-if="toggle" style="display:block">-->
<!--          ● 바카라 게임 안내-->
<!--          회원은 플레이어(Player)와 뱅커(Banker) 중 어느 핸드가 이길지 예상하여 베팅을 합니다.<br>-->
<!--          비길 것을 예상하여 타이(Tie)에 함께 베팅하기도 합니다.<br>-->
<!--          베팅금액은 하단 최대배팅금액 규정 범위 내에서 하시면 됩니다.<br>-->
<!--          딜러가 드로잉 룰에 따라 플레이어와 뱅커에 카드를 드로잉하여 플레이어, 뱅커 중 9에 가까운 쪽이 이깁니다.<br>-->
<!--          플레이어, 뱅커의 각 2장 혹은 3장의 합으로 게임 승패가 결정됩니다.<br><br>-->

<!--          TIP<br>-->
<!--          많은 분들이 플레이어는 회원, 뱅커는 카지노의 영역으로 오해하는데, 회원이 플레이어와 뱅커 중 이기는 쪽을 예상하여 베팅을 하는 게임입니다.<br>-->
<!--          회원은 플레이어와 뱅커 어느 쪽이든 베팅 할 수 있습니다.<br>-->

<!--          ● 바카라 게임 규정 및 주의사항 안내<br>-->
<!--          먼저 카지노 배팅은 매우 짧은 텀으로 진행되는 게임입니다.<br>-->
<!--          따라서, 베팅 후 바로 문의 주셔도 배팅 취소는 어떠한 사유라도 절대 불가합니다.<br>-->
<!--          또한, 기본적으로 볼빅 미니게임 규정에 준하여 취급됩니다. (촤대 배팅 금액)<br>-->
<!--          입금금액 + 보너스 포인트 전부 합하여 500% 이상 롤링 배팅 완료 후 환전 가능합니다.<br>-->
<!--          롤링을 원하지 않으실 경우 충전 후 바로 고객센터로 문의 주시면 보너스 포인트를 차감해 드리겠습니다.<br>-->
<!--          이해가 되시지 않는 부분은 고객센터로 사전 문의 후에 이용해 주시길 바랍니다.<br>-->
<!--          감사합니다.<br><br>-->

<!--          또한 카지노 배팅은 기본적으로 놀이터 규정에 준하여 적용되오며,<br>-->
<!--          단, 한 회차 최대배팅액은 100만원까지만 가능하십니다.<br>-->
<!--          <span>규정 위반하여 낙첨된 회차는 전 금액 몰수처리// 당첨된 회차는 적특처리 됩니다.</span><br>-->
<!--          ex) 예를들어 150만원 충전 후 한 회차 130만 배팅시에는 최대배팅금 초과로 <span>"낙첨시 전 금액 몰수"</span> 처리 되오며<br>-->
<!--          당첨시에는 "적특처리" 되겠습니다.<br>-->
<!--          반드시 규정 위반하여 불이익 당하시지 않도록 주의 해주시길 바랍니다.-->
<!--        </p>-->
<!--      </transition>-->
      <h3 class="tit-bet">카지노 충전신청</h3>
      <div class="charge_box">
        <div style="padding: 10px;">
          <table>
            <tbody>
            <tr>
              <th>보유머니</th>
              <td>
                <p>{{ user.members_cash | makeComma }}원 / <span>보유 크레딧 : {{ casinoBalance | makeComma }}</span></p>
              </td>
            </tr>
            <tr>
              <th>충전금액</th>
              <td>
                <div>
                  <input type="number" v-model="amount" @focusout="amountRound">원
                </div>
                <ul>
                  <li @click="addAmount(1000)">1,000원</li>
                  <li @click="addAmount(5000)">5,000원</li>
                  <li @click="addAmount(10000)">1만원</li>
                  <li @click="addAmount(50000)">5만원</li>
                  <li @click="addAmount(100000)">10만원</li>
                  <li @click="addAmount(200000)">20만원</li>
                  <li @click="addAmount(500000)">50만원</li>
                  <li @click="addAmount(1000000)">100만원</li>
                  <li @click="addAmount(2000000)">200만원</li>
                  <li @click="addAmount(5000000)">500만원</li>
                  <li @click="allAmount">전체금액</li>
                  <li @click="resetAmount">정정</li>
                </ul>
                <p>보유 금액을 확인 후 보유 한도내에서 충전할 금액을 입력해주세요.</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <button class="charge_btn" v-if="!isLoading" @click="reqDeposit">충전하기</button>
        <div style="text-align: center; background: #1c2021;" v-else>
          <Loading></Loading>
        </div>
      </div>

      <h3 class="tit-bet">충전내역</h3>
      <table class="listTable02" style="width: 100%">
        <tbody><tr>
          <th>번호</th>
          <th>게임</th>
          <th>신청금액</th>
          <th>신청일자</th>
          <th>상태</th>
        </tr>
        <tr v-if="lists.length === 0">
          <td colspan="5">검색된 내역이 없습니다.</td>
        </tr>
        <tr v-else v-for="(row, index) in lists" :key="'depositList'+index">
          <td>{{ (( payload.page -1 ) * payload.size) + (index + 1)  }}</td>
          <td>카지노</td>
          <td>{{ row.cash_amount | makeComma }}</td>
          <td>{{ row.cash_regdatetime | formatDate('YYYY-MM-DD') }}</td>
          <td>{{ row.cash_status  }}</td>
        </tr>
        </tbody>
      </table>
<!--      <div class="pagingBox black">-->
<!--        <div class="paging">-->
<!--          <a href="javascript:void(0)" class="btnFirst mobile"><img src="@/assets/img/ico/ico_first_black.png"></a>-->
<!--          <a href="javascript:void(0)" class="btnPrev mobile"><img src="@/assets/img/ico/ico_prev_black.png"></a>-->

<!--          <a href="javascript:void(0)" class="mobile">1</a>-->
<!--          <a href="javascript:void(0)" class="mobile">2</a>-->
<!--          <a href="javascript:void(0)" class="mobile">3</a>-->
<!--          <a href="javascript:void(0)" class="mobile">4</a>-->
<!--          <a href="javascript:void(0)" class="mobile">5</a>-->
<!--          <a href="javascript:void(0)" class="mobile">6</a>-->
<!--          <a href="javascript:void(0)" class="mobile">7</a>-->
<!--          <a href="javascript:void(0)" class="mobile">8</a>-->
<!--          <a href="javascript:void(0)" class="mobile">9</a>-->
<!--          <a href="javascript:void(0)" class="mobile">10</a>-->

<!--          <a href="javascript:void(0)" class="btnNext mobile"><img src="@/assets/img/ico/ico_next_black.png"></a>-->
<!--          <a href="javascript:void(0)" class="btnLast mobile"><img src="@/assets/img/ico/ico_last_black.png"></a>-->
<!--        </div>-->
<!--      </div>-->
    </div>


  </div></template>

<script>
import Loading from '@/components/Loading'
import {mapState} from "vuex";

export default {
  name: "Main",
  components: {
    Loading
  },
  data: function(){
    return {
      toggle: false,
      amount: 0,
      casinoBalance: 0,
      isLoading: false,
      payload: {
        page: 1,
        size: 10,
        type: '출금',
        detailType: '카지노',
      },
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      lists: state => state.user.depositHistory
    }),
  },
  watch: {
    amount: function(amt){
      if (amt > this.cash){
        this.amount = this.cash;
        this.amountRound()
      }
    }
  },
  mounted() {
    window.scrollTo(0,0);
    this.checkBalance();
    this.reqData();
  },
  methods: {
    reqData: function(){
      this.$store.dispatch('DEPOSIT_HISTORY', { payload: this.payload })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    checkBalance: function(){
      this.$store.dispatch('CHECK_CASINO_WALLET')
          .then(data => {
            if (Array.isArray(data)){
              const result = data[0].data;
              if (result.success){
                this.casinoBalance = result.payload.balance;
              }
            } else {
              const result = data.data;
              if (result.success){
                this.casinoBalance = result.payload.balance;
              }
            }
          }).catch(err => {
            // console.log(err);
        alert(err.response.data.message);
      })
    },
    btnToggle: function(){
      return this.toggle = !this.toggle;
    },
    addAmount: function(amt){
      return this.amount += amt;
    },
    resetAmount: function(){
      return this.amount = 0;
    },
    reqDeposit: function(){
      if (this.amount < 10000) return alert('최소 10,000원 이상 신청하셔야합니다.');
      if (this.amount > this.cash) {
        this.amount = this.cash;
        this.amountRound();
        return alert('보유금액 이상 신청하셨습니다.');
      }
      this.isLoading = true;
      const data = {
        payload: {
          amount: this.amount
        }
      };
      this.$store.dispatch('PROCESS_CASINO_DEPOSIT', data)
          .then(data => {
            const result = data.data;
            if (result.success){
              this.reqData();
              this.isLoading = false;
              this.$store.dispatch('ME')
              location.reload();
            }
          })
          .catch(err => {
            this.isLoading = false;
            alert(err.response.data.error);
          })
    },
    amountRound: function(){
      let result = Math.floor(this.amount / 1000)
      this.amount = result * 1000
    },
    allAmount: function(){
      this.amount = this.user.members_cash;
      this.amountRound();
    }
  },

}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
