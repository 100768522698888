<template>
  <section class="customer">
    <ul class="locationList">
      <li>HOME</li>
      <li>이벤트</li>
    </ul>
<!--    <h2 class="subTitle">이벤트</h2>-->


    <div class="cusbox">

      <div class="nbox">
        <div class="cusLeft">
          <p class="subj">CATEGORIES</p>
          <ul>
            <li><a @click="movePage('bbs/c/l')">고객센터</a></li>
            <li><a @click="movePage('bbs/n/l')">공지사항</a></li>
            <li><a @click="movePage('bbs/e/l')" class="on">이벤트</a></li>
            <li><a @click="movePage('board/faq/list')">자주 묻는 질문</a></li>
            <li><a @click="movePage('board/rule/list')">배팅규정</a></li>
          </ul>
        </div>
        <div class="cusRight">
          <div class="eventView">
            <div class="title">{{ article.board_title }}</div>
            <div class="imgView">
              <img :src="article.board_img" alt="">
            </div>
            <div class="context" v-html="renderHtml(article.board_content)">
            </div>
          </div>
          <div class="btnWrap">
            <a @click="movePage('bbs/e/l')" class="btnList">목록으로</a>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: "Detail",
  data: function(){
    return {
      path: 'board/detail/' + this.$route.params.seq,
      article: null,
    }
  },
  mounted: function() {
    this.reqData()
  },
  methods: {
    reqData: function () {
      return this.$store.dispatch('auth/post', {path: this.path, data: {payload: this.payload}})
          .then(data => {
            const result = data.data;
            this.article = result.payload.article;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    movePage: function(page){
      this.$router.push({ path: `/${page}`})
    },
    renderHtml: function(str){
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    }
  }
}
</script>

<style scoped>

</style>
