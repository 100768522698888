<template>
  <PCDetail v-if="!this.$store.state.page.isMobile"></PCDetail>
  <MobileDetail v-else></MobileDetail>
</template>

<script>
import PCDetail from './PCDetail';
import MobileDetail from './MobileDetail';

export default {
  name: "EuropeanDetail",
  components: {
    PCDetail,
    MobileDetail,
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

.categorys::-webkit-scrollbar{
  display:none;
}
</style>
