<template>
  <td class="soprts_in_acc_2 "
      :style="[width === 2 ? {'width': '50%'} : {'width': '33%'}]"
      :class="{'sel': checkCart(bets)}"
      @click="addCart(bets, folder, game)"
  >
    <div class="sports_v_l_1">
      {{ teamName }}
    </div>
    <div class="sports_v_r_1">
      <i v-if="priceStatus === 'up'" class="ico-up blink"></i>
      <i v-if="priceStatus === 'down'" class="ico-down blink"></i>
      <span class="font_002 ">
        {{ Number(bets.price).toFixed(2) }}
      </span>
      <i v-if="priceStatus" class="sel_old ">({{ startPrice }})</i>
    </div>
  </td>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game', 'team', 'width'],
  data: function(){
    return {
      startPrice: null,
      priceStatus: null,
    }
  },
  mounted() {
    console.log(this.width);
  },
  computed: {
    type: function(){
      let type = 'home';
      if (this.bets.name === 'W1' || this.bets.name === 'Home' || this.bets.name === 'Over' || this.bets.name === '1X' || this.bets.name === 'Odd' || this.bets.name === 'Yes') return 'home';
      if (this.bets.name === 'X' || this.bets.name === '12') return 'draw';
      if (this.bets.name === 'W2' || this.bets.name === 'Away' || this.bets.name === 'Under' || this.bets.name === 'X2' || this.bets.name === 'Odd' || this.bets.name === 'No') return 'away';
      return type
    },
    price: function(){
      return Number(this.bets.price).toFixed(2)
    },
    teamName: function(){
      let name = this.team.nameKr || this.team.nameEn;
      if (this.bets.name in ['1', '2']) name = `${this.team.nameKr || this.team.nameEn}`;
      if (this.folder.market.nameEn.includes('Handicap')) name +=  ` (${this.bets.line.replace(' (0-0)', '')})`;
      if (this.bets.name === 'Over') return `오버 ${this.folder.line}` ;
      if (this.bets.name === 'Under') return `언더 ${this.folder.line}`;
      if (this.bets.name === 'X') name = '무';
      if (this.bets.name === '1X') name = '1팀승 또는 무승부';
      if (this.bets.name === '12') name = '1팀승 또는 2팀승';
      if (this.bets.name === 'X2') name = '무승부 또는 2팀승';
      if (this.bets.name === 'Odd') name = '홀';
      if (this.bets.name === 'Even') name = '짝';
      if (this.bets.name === 'Yes') name = '예';
      if (this.bets.name === 'No') name = '아니오';
      if (this.bets.name === 'No Goal') name = '노골';
      return name;
    },
  },
  watch: {
    price: function(newVal, val){
      if (Number(newVal) - Number(val) < 0) this.priceStatus = 'up';
      if (Number(newVal) - Number(val) > 0) this.priceStatus = 'down';
      if (Number(newVal) !== Number(val)) this.startPrice = val;
    },
    priceStatus: function(){
      setTimeout(() => {
        this.priceStatus = null
        this.startPrice = null;
      } ,5000)
    },
  },
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
