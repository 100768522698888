<template>
  <section v-if="!this.$store.state.page.isMobile">
<!--    <h2 class="subTitle">마이페이지</h2>-->


    <div class="nbox size">

      <ul class="mypTabs">
        <li><router-link to="/deposit">충전신청</router-link></li>
        <li><router-link to="/withdrawal">환전신청</router-link></li>
        <li><router-link to="/point/change">포인트전환</router-link></li>
        <li><router-link to="/point/list" class="on">포인트 사용 내역</router-link></li>
      </ul>

<!--      <p class="mypSubj"><a @click="toggleReq">포인트 사용 내역</a></p>-->
<!--      <transition name="fade">-->
<!--        <div class="mypSelectBox" v-if="true" style="display: block">-->
<!--        <div class="selectBox">-->
<!--          <label>일자</label>-->
<!--          <select id="sel01">-->
<!--            <option>첫충전</option>-->
<!--            <option>매충전</option>-->
<!--            <option>전환</option>-->
<!--            <option>낙첨</option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <div class="date">-->
<!--          <input type="text" value="2018.08.23 ~ 2018.03.02">-->
<!--          <a href="#" class="icoCalendar">-->
<!--            <img src="@/assets/img/ico/ico_calendar.png">-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--      </transition>-->
      <p class="mypSubj"><a @click="toggleList">사용 리스트 내역</a></p>
      <transition name="fade">
        <div class="tableWrap" v-if="true" style="display: block">
        <table class="titleTable01 mt10">
          <colgroup>
            <col width="200" />
            <col width="200" />
            <col width="200" />
            <col width="200" />
            <col width="200" />
          </colgroup>
          <tr>
            <th>일시</th>
            <th>구분</th>
            <th>차감 포인트</th>
            <th>가산 포인트</th>
            <th>잔여 포인트</th>
          </tr>
          <tr v-for="(row, index) in lists" :key="'pointList'+index">
            <td>{{ row.cash_regdatetime | formatDate('YYYY-MM-DD') }}</td>
            <td>{{ row.admin ? row.admin.members_type.indexOf('관리자') >= 0 ? '관리자' : '회원' : '시스템' }}</td>
            <td>{{ row.cash_amount * -1 > 0 ? row.cash_amount : 0 | makeComma }}</td>
            <td>{{ row.cash_amount > 0 ? row.cash_amount : 0 | makeComma }}</td>
            <td>{{ row.cash_after | makeComma }}</td>
          </tr>
        </table>
      </div>
      </transition>
    </div>
  </section>
  <div v-else>
    <div class="header2">
      <div class="left">
        <a href="#" class="btn-prev"></a>
      </div>
      <h2>마이페이지</h2>
    </div>
    <div class="tabs1" style="margin-top: 10px;">
      <router-link to="/deposit">충전신청</router-link>
      <router-link to="/withdrawal" >환전신청</router-link>
      <router-link to="/point/change" >포인트전환</router-link>
      <router-link to="/point/list" class="active">포인트</router-link>
      <router-link to="/recommender" >추천인</router-link>
    </div>



    <div class="nbox size">
      <p class="mypSubj"><a href="#">포인트 내역</a></p>
      <div class="tableWrap" style="display: block">
        <table class="titleTable01 mt10">
          <tbody>
          <tr>
            <th>일시</th>
            <th>구분</th>
            <th>차감 포인트</th>
            <th>가산 포인트</th>
            <th>잔여 포인트</th>
          </tr>
          <tr v-for="(row, index) in lists" :key="'pointList'+index">
            <td>{{ row.cash_regdatetime | formatDate('YYYY-MM-DD') }}</td>
            <td>{{ row.admin ? row.admin.members_type.indexOf('관리자') >= 0 ? '관리자' : '회원' : '시스템' }}</td>
            <td>{{ row.cash_amount * -1 > 0 ? row.cash_amount : 0 | makeComma }}</td>
            <td>{{ row.cash_amount > 0 ? row.cash_amount : 0 | makeComma }}</td>
            <td>{{ row.cash_after | makeComma }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


</template>

<script>
// import SideBox from '../components/SideBox'

export default {
  name: "PointList",
  components: {
    // SideBox
  },
  data: function(){
    return {
      amount: 0,
      account: '',
      showReq: false,
      showList: false,
      showDescription: false,
      payload: {
        page: 1,
        size: 20,
        startDate: null,
        endDate: null,
        detailType: '포인트'
      },
      lists: [],
      pagination: {}
    }
  },
  computed: {
    balance: function (){
      return this.$store.state.auth.user.members_cash
    },
    point: function (){
      return this.$store.state.auth.user.members_point
    },
    level: function (){
      return this.$store.state.auth.user.members_level
    },
    nickname: function (){
      return this.$store.state.auth.user.members_nickname
    },
  },
  watch: {
    depositAmount: function(val){
      this.depositAmount = val;
    }
  },
  mounted: function() {
    window.scrollTo(0,0);
    this.reqData()
  },
  methods: {
    reqData: function(){
      return this.$store.dispatch('auth/post', {path: 'balance/list', data: {payload: this.payload} })
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.pagination = result.payload.pagination
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    movePage: function(page){
      this.$router.push({ path: `/${page}`})
    },
    toggleReq: function(){
      this.showReq = !this.showReq;
    },
    toggleList: function(){
      this.showList = !this.showList;
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
