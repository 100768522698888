<template>
  <div id="id">
    <router-view></router-view>
  </div>
</template>
<script>

// import {mapState} from "vuex";

export default {
  name: 'App',
  // computed: {
  //   ...mapState({
  //     user: state => state.user.user,
  //     version: state => state.version.version,
  //   }),
  //   menuVisible(){
  //     return this.$store.getters['MENU_VISIBLE'];
  //   },
  // },
  // data: () => ({
  //   currentMenu: '메인',
  // }),
  // watch: {
  //   $route(pathInfo) {
  //     // 긴급점검 체크
  //     this.currentMenu = pathInfo.name;
  //     if (pathInfo.path !== '/bbs/msg/l'){
  //       if (this.user?.notes?.filter(n => n.readAt === null).length > 0) this.openShowNotes();
  //     }
  //     this.displayCheck(pathInfo)
  //   },
  // },
  // created() {
  //   this.displayCheck(this.$route)
  // },
  // mounted() {
  //   this.$store.dispatch('GET_SETTING')
  //   setTimeout(() => {
  //     if (this.user) this.checkLogin();
  //     this.interval = setInterval(() => {
  //       if (this.user) this.checkLogin();
  //     }, 15000);
  //   }, 1000)
  //   this.$socket.client.on('logOut', () => {
  //     console.log('로그아웃 실행')
  //     const params = {
  //         sitename: this.user.members_sitename,
  //         members_seq: this.user.members_seq,
  //         members_nickname: this.user.members_nickname,
  //         session: 'App.vue/mounted socket block',
  //         error: 'App.vue mounted socket 관리자 강제'
  //       }
  //     this.$store.dispatch('ERROR_LOG', params)
  //     this.$store.dispatch('LOGOUT');
  //     location.reload();
  //   })
  // },
  // beforeDestroy() {
  //   clearInterval(this.interval);
  // },
  // methods: {
  //   checkLogin(){
  //     this.$store.dispatch('ME')
  //         .then((data) => {
  //           const result = data.data.payload;
  //           result.member.currentMenu = this.currentMenu;
  //           result.member.clientVersion = this.version;
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           const params = {
  //             sitename: this.user.members_sitename,
  //             members_seq: this.user.members_seq,
  //             members_nickname: this.user.members_nickname,
  //             session: 'App.vue/checkLogin catch block',
  //             error: JSON.stringify(err)
  //           }
  //           this.$store.dispatch('ERROR_LOG', params)
  //           this.$store.dispatch('LOGOUT')
  //           this.$router.push({ path: '/main' })
  //         })
  //
  //     this.user.currentMenu = this.currentMenu;
  //     this.user.clientVersion = this.version;
  //     this.$socket.client.emit('setUser', { user: this.user })
  //   },
  //   openShowNotes(){
  //     this.$store.dispatch('INVISIBLE_ALL_MODALS')
  //     this.$store.dispatch('SHOW_MODAL', 'notes')
  //   },
  //   displayCheck: function(pathInfo){
  //     // 한국형
  //     if (pathInfo.name?.includes('한국형')) {
  //       const display = this.menuVisible.find(e => e.name === '스포츠-한국형');
  //       if (!display.visible){
  //         this.$store.dispatch('SET_ERR_MSG', '한국형은 이용하실 수 없습니다.');
  //         this.$store.dispatch('SHOW_MODAL', 'error2');
  //       }
  //     }
  //
  //     // 유럽형
  //     if (pathInfo.name?.includes('유럽형')) {
  //       const display = this.menuVisible.find(e => e.name === '스포츠-유럽형');
  //       if (!display.visible){
  //         this.$store.dispatch('SET_ERR_MSG', '유럽형은 이용하실 수 없습니다.');
  //         this.$store.dispatch('SHOW_MODAL', 'error2');
  //       }
  //     }
  //
  //     // 유럽형
  //     if (pathInfo.name?.includes('실시간')) {
  //       const display = this.menuVisible.find(e => e.name === '라이브');
  //       if (!display.visible){
  //         this.$store.dispatch('SET_ERR_MSG', '실시간은 점검중입니다.');
  //         this.$store.dispatch('SHOW_MODAL', 'error2');
  //       }
  //     }
  //
  //     if (pathInfo.name?.includes('인플레이')) {
  //       const display = this.menuVisible.find(e => e.name === '인플레이');
  //       if (!display.visible){
  //         this.$store.dispatch('SET_ERR_MSG', '인플레이는 점검중입니다.');
  //         this.$store.dispatch('SHOW_MODAL', 'error2');
  //       }
  //     }
  //
  //     if (pathInfo.name?.includes('토큰-룰렛')) {
  //       const display = this.menuVisible.find(e => e.name === '토큰-룰렛');
  //       if (!display.visible){
  //         this.$store.dispatch('SET_ERR_MSG', '룰렛은 점검중입니다.');
  //         this.$store.dispatch('SHOW_MODAL', 'error2');
  //       }
  //     }
  //
  //     if (pathInfo.name?.includes('토큰-하이로우')) {
  //       const display = this.menuVisible.find(e => e.name === '토큰-하이로우');
  //       if (!display.visible){
  //         this.$store.dispatch('SET_ERR_MSG', '하이로우는 점검중입니다.');
  //         this.$store.dispatch('SHOW_MODAL', 'error2');
  //       }
  //     }
  //
  //     if (pathInfo.name?.includes('토큰-하이로우5초')) {
  //       const display = this.menuVisible.find(e => e.name === '토큰-하이로우5초');
  //       if (!display.visible){
  //         this.$store.dispatch('SET_ERR_MSG', '하이로우5초는 점검중입니다.');
  //         this.$store.dispatch('SHOW_MODAL', 'error2');
  //       }
  //     }
  //
  //     if (pathInfo.name?.includes('토큰-바카라')) {
  //       const display = this.menuVisible.find(e => e.name === '토큰-바카라');
  //       if (!display.visible){
  //         this.$store.dispatch('SET_ERR_MSG', '바카라는 점검중입니다.');
  //         this.$store.dispatch('SHOW_MODAL', 'error2');
  //       }
  //     }
  //
  //     if (pathInfo.name?.includes('카지노-입금')) {
  //       const display = this.menuVisible.find(e => e.name === '카지노-입금');
  //       if (!display.visible){
  //         this.$store.dispatch('SET_ERR_MSG', '카지노 입금은 점검중입니다.');
  //         this.$store.dispatch('SHOW_MODAL', 'error2');
  //       }
  //     }
  //
  //     if (pathInfo.name?.includes('카지노-출금')) {
  //       const display = this.menuVisible.find(e => e.name === '카지노-출금');
  //       if (!display.visible){
  //         this.$store.dispatch('SET_ERR_MSG', '카지노 출금은 점검중입니다.');
  //         this.$store.dispatch('SHOW_MODAL', 'error2');
  //       }
  //     }
  //
  //     if (pathInfo.name?.includes('로투스-홀짝')) {
  //       const display = this.menuVisible.find(e => e.name === '로투스-홀짝');
  //       if (!display.visible){
  //         this.$store.dispatch('SET_ERR_MSG', '홀짝은 점검중입니다.');
  //         this.$store.dispatch('SHOW_MODAL', 'error2');
  //       }
  //     }
  //
  //     if (pathInfo.name?.includes('로투스-바카라1')) {
  //       const display = this.menuVisible.find(e => e.name === '로투스-바카라1');
  //       if (!display.visible){
  //         this.$store.dispatch('SET_ERR_MSG', '바카라1은 점검중입니다.');
  //         this.$store.dispatch('SHOW_MODAL', 'error2');
  //       }
  //     }
  //
  //     if (pathInfo.name?.includes('로투스-바카라2')) {
  //       const display = this.menuVisible.find(e => e.name === '로투스-바카라2');
  //       if (!display.visible){
  //         this.$store.dispatch('SET_ERR_MSG', '바카라2은 점검중입니다.');
  //         this.$store.dispatch('SHOW_MODAL', 'error2');
  //       }
  //     }
  //
  //     if (pathInfo.name?.includes('파워볼')) {
  //       const display = this.menuVisible.find(e => e.name === '파워볼');
  //       if (!display.visible){
  //         this.$store.dispatch('SET_ERR_MSG', '파워볼은 점검중입니다.');
  //         this.$store.dispatch('SHOW_MODAL', 'error2');
  //       }
  //     }
  //
  //     if (pathInfo.name?.includes('파워사다리')) {
  //       const display = this.menuVisible.find(e => e.name === '파워사다리');
  //       if (!display.visible){
  //         this.$store.dispatch('SET_ERR_MSG', '파워사다리는 점검중입니다.');
  //         this.$store.dispatch('SHOW_MODAL', 'error2');
  //       }
  //     }
  //
  //     // if (pathInfo.path.indexOf('EOS1m') >= 0) {
  //     //   const display = this.menuVisible.find(e => e.name === 'EOS-파워볼1분');
  //     //   if (!display.visible){
  //     //     this.$store.dispatch('SET_ERR_MSG', 'EOS 파워볼은 점검중입니다.');
  //     //     this.$store.dispatch('SHOW_MODAL', 'error2');
  //     //   }
  //     // }
  //     // if (pathInfo.path.indexOf('EOS2m') >= 0) {
  //     //   const display = this.menuVisible.find(e => e.name === 'EOS-파워볼2분');
  //     //   if (!display.visible){
  //     //     this.$store.dispatch('SET_ERR_MSG', 'EOS 파워볼은 점검중입니다.');
  //     //     this.$store.dispatch('SHOW_MODAL', 'error2');
  //     //   }
  //     // }
  //     // if (pathInfo.path.indexOf('EOS3m') >= 0) {
  //     //   const display = this.menuVisible.find(e => e.name === 'EOS-파워볼3분');
  //     //   if (!display.visible){
  //     //     this.$store.dispatch('SET_ERR_MSG', 'EOS 파워볼은 점검중입니다.');
  //     //     this.$store.dispatch('SHOW_MODAL', 'error2');
  //     //   }
  //     // }
  //     // if (pathInfo.path.indexOf('N파워볼') >= 0) {
  //     //   const display = this.menuVisible.find(e => e.name === 'EOS-파워볼5분');
  //     //   if (!display.visible){
  //     //     this.$store.dispatch('SET_ERR_MSG', '파워볼은 점검중입니다.');
  //     //     this.$store.dispatch('SHOW_MODAL', 'error2');
  //     //   }
  //     // }
  //
  //   },
  // }
}
</script>
