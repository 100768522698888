<template>
  <td v-if="isLoading" width="260" valign="top" style="width: 15%;">
    <div class="game_left_wrap">
      <div>
        <div style="display: block;" id="myDiv" class="animate-bottom ">
          <div class="left_search_wrap">
            <div class="left_search">
              <form name="sm">
                <input id="pop" class="input_style05" name="k" placeholder="팀 이름" v-model="teamName" @keyup="filterByGames('team', teamName)">
                <span id="popo" class="popover-wrapper">
                  <a href="#" data-role="popover" data-target="example-popover">
                  <img id="spop" src="@/assets/img/ico/search_icon.jpg">
                  </a>
                  <div class="popover-modal example-popover">
                    <div class="popover-body">
                      <div class="example-body-in">
                        <table class="example_table_1 " cellpadding="0" cellspacing="0">
                        <tbody>
                        <tr>
                          <td width="100%" align="center" style="padding:15px 0 15px 0">
                            검색 결과가 없습니다.
                          </td>
                        </tr>
                        </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </span>
              </form>
            </div>
          </div>
          <div class="left_tab_wrap">

              <div class="left_tab_on_1">
                <div class="left_tab_l_1 ">
                  실시간
                </div>
                <div class="menu_count_on ">
                  {{ originList.length | makeComma }}
                </div>
              </div>
            <router-link :to="'list/european'">
              <div class="left_tab_1">
                <div class="left_tab_l_1 ">
                  스포츠
                </div>
                <div class="menu_count_on ">
                  {{ count }}
                </div>
              </div>
            </router-link>
          </div>
          <table class="example_table_1 " cellpadding="0" cellspacing="0" style="display:none">
            <tbody>
            <tr>
              <td width="100%" align="center" style="padding:15px 0 15px 0">
                현재 진행중인 경기가 없습니다.
              </td>
            </tr>
            </tbody>
          </table>
          <!-- ngIf: LS.length > 0 -->

          <!-- 스포츠 > 국가 > 리그 -->
          <div class="left_menu_wrap " style="">
            <template v-for="(sport, sportIndex) in lists">
              <div class="accordion-header" :key="'sports'+sportIndex"
                   :class="{'open': openSports.includes(String(sport.id))}"
                   @click="toggleSportsDetail2(String(sport.id))"
              >
                <img width="15" :src="sport.icon">&nbsp;
                <span>{{ sport | sportName }}</span>
                <div class="left_menu_count ">
                  <span>{{ sport.locations.length }}</span>
                </div>
              </div>

              <template v-for="location in sport.locations">
                <transition name="fade" :key="'locationRightSort'+ location">
                  <div
                      v-if="openSports.includes(String(sport.id))"
                      class="accordion-header"
                      :class="{'open': openLocations.includes(String(sport.id) + String(location.id))}"
                      :key="'locationRightSort'+ location"
                      style="display: block;"
                      @click="toggleLocationsDetail2(String(sport.id) + String(location.id))"
                  >
                    <img width="15" :src="location.icon">&nbsp;
                    <span>{{ location | locationName }}</span>
                    <div class="left_menu_count">
                      <span>{{ location.leagues.length }}</span>
                    </div>
                  </div>
                </transition>
                <template v-for="league in location.leagues">
                  <div
                      class="accordion-header3_1"
                      :key="'leaguesDetail'+ location.id + league.id"
                      style="display: block; color: #8a8c8d;"
                      v-if="openLocations.includes(String(sport.id) + String(location.id))"
                      @click="toggleLeaguesDetail2(String(league.id))"
                  >
                    <img width="15" :src="league.icon">&nbsp;
                    <span>{{ league | leagueName }}</span>
                    <div class="left_menu_count">
                      <span>{{ league.games.length }}</span>
                    </div>
                  </div>
                  <div :key="'inplayGameDetail'+league.id"
                       v-if="openLeagues.includes(String(league.id))"
                  >
                    <div v-for="game in league.games" :key="'gameDetail'+game.seq">
                      <router-link :to="`/sport/live/d?gid=${game.id}`">
                        <table class="left_menu_in_1 " cellpadding="0" cellspacing="0">
                          <tbody>
                          <tr>
                            <td class="left_menu_in_1_l ">
                              {{ game.homeTeam | teamName }}
                            </td>
                            <td class="left_menu_in_1_r ">
                              {{ game.homeResult }}
                            </td>
                          </tr>
                          <tr>
                            <td class="left_menu_in_1_l ">
                              {{ game.awayTeam | teamName }}
                            </td>
                            <td class="left_menu_in_1_r ">
                              {{ game.awayResult }}
                            </td>
                          </tr>
                          <tr>
                            <td class="left_menu_in_1_l">
                              <span class="font_001 ">
                                {{ game | currentPeriod3('period') }}
                              </span>
                              <span v-if="game.remainTime" class="orange--text">
                                남은시간 {{ game.remainTime.split('.') ? game.remainTime.split('.')[0] : '' }}
                              </span>
                            </td>
                            <td class="left_menu_count_1">
                              <div style="padding-top: 1px; width: 15px; height: 15px">
                                <font-awesome-icon icon="fs fa-lock"/>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </router-link>
                    </div>

                  </div>
                </template>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </td>
  <td v-else style="text-align: center; width:15%;">
    <Loading></Loading>
  </td>
</template>

<script>
import {mapState} from "vuex";
import Loading from '@/components/Loading'

export default {
  name: "SideBar2",
  props: ['checkBlockGame'],
  components: {
    Loading
  },
  data: function(){
    return {
      teamName: null,
      openSports: [],
      openLocations: [],
      openLeagues: [],
      count: 0,
      isViewLink: false,
      isLoading: false,
    }
  },
  computed: {
    sports: function () {
      const sports = this.originList.map(e => e.sport);
      const arrUnique = sports.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.seq === character.seq) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    lists(){
      this.sports.map(sport => {
        sport.locations = this.locations(sport.id)
        sport.locations.map(location => {
          location.leagues = this.leagues(sport.id, location.id)
          location.leagues.map(league => {
            league.games = this.originList.filter(game => String(game.leagueId) === String(league.id))
          })
        })
      })
      return this.sports
    },
    ...mapState({
      originList: state => state.inplay.originList,
    }),
  },
  mounted() {
    this.initFolder();
    this.$socket.client.on('inplayMatchStats2', this.matchStats2)
  },
  beforeDestroy: function () {
    this.$socket.client.off('matchUpdate');
  },
  methods: {
    initFolder(payload){
      this.isLoading = false;
      this.$store.dispatch('GET_INPLAY_LIST', { payload }).then(() => {
        this.isLoading = true;
      })
    },
    leagues: function(sport, location){
      const data = this.originList.filter(e => String(e.sportId) === String(sport) && String(e.locationId) === String(location));
      const leagues = data.map(e => e.league)
      const arrUnique = leagues.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    locations: function(sport){
      const data = this.originList.filter(e => String(e.sportId) === String(sport));
      const locations = data.map(e => e.location)
      const arrUnique = locations.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    toggleSportsDetail2: function (id) {
      const index = this.openSports.findIndex(sport => sport === id);
      if (index < 0) return this.openSports.push(id);
      return this.openSports.splice(index, 1);
    },
    toggleLocationsDetail2: function (id) {
      const index = this.openLocations.findIndex(location => location === id);
      if (index < 0) return this.openLocations.push(id);
      return this.openLocations.splice(index, 1);
    },
    toggleLeaguesDetail2: function (id) {
      const index = this.openLeagues.findIndex(league => league === id);
      if (index < 0) return this.openLeagues.push(id);
      return this.openLeagues.splice(index, 1);
    },
    matchStats2: function(data){
      const results = JSON.parse(data);
      for (const r in results) {
        const result = results[r];
        // console.log(result, 'leftSide');
        this.originList.map(g => {
          if (String(result.EventId) === String(g.id)) {
            const score = result.Score;
            g.homeResult = score.split(':')[0] || 0;
            g.awayResult = score.split(':')[1] || 0;
            // console.log(g, result)
            if (result.EventType !== 'period' && !result.IsTimeout) g.currentPeriod = result.Period;
            if (result.Info) g.subResult = result.Info;
            if (result.RemainingTime) {
              g.remainTime = result.RemainingTime;
              setTimeout(()=>{
                g.remainTime = null;
              }, 5000)
            }
          }
        })
      }
    },
    moveDetail: function(game){
      this.$router.push({ path: '/sport/live/d', query: { gid: game.seq }})
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
