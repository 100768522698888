<template>
  <div class="vs">
    <img style="width: 9px; margin-top: -4px;" src="@/assets/img/ico/ico_secret.png">
  </div>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game', 'team'],
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
