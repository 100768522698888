import { render, staticRenderFns } from "./MKoreanPrice.vue?vue&type=template&id=1661c05a&scoped=true"
import script from "./MKoreanPrice.vue?vue&type=script&lang=js"
export * from "./MKoreanPrice.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1661c05a",
  null
  
)

export default component.exports