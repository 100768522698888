<template>
  <section style="min-height: 800px;">
    <table width="100%" style="margin-top: 20px; width: 88%; margin-left:20px;">
      <tbody>
      <tr>
        <LeftSide :origin-list="originList" :filter-by-games="filterByGames" :is-loading="isLoading"></LeftSide>

        <!-- left -->
        <td width="80%" style="padding:0 10px 0 10px; min-width: 80%" valign="top" v-if="isLoading">
          <table width="100%" valign="top" border="0" cellspacing="0" cellpadding="0" class="ng-isolate-scope">
            <tbody>
            <tr v-if="lists">
              <td width="100%">
                <!-- ngInclude: centerTab -->
                <div class="ng-scope"
                     :class="{
                        'football_wrap': game && game.sportSeq === 210,
                        'hockey_wrap': game && game.sportSeq === 211,
                        'volleyball_wrap': game && game.sportSeq === 214,
                        'basketball_wrap': game && game.sportSeq === 212,
                        'baseball_wrap': game && game.sportSeq === 220,
                     }"
                >
                  <div class="etc_left_45">
                    <router-link to="/sport/prematch" class="live_sport_b ti-cursor"> ◀ </router-link>
                    <div class="etc_left_in_tennis">
                      <!--    리그명    -->
                      <p class="ng-binding">
                        {{ game.league | leagueName }}
                      </p>
                      <!--       팀명       -->
                      <span class="etc_time ng-binding ng-hide">
                        {{ game.homeTeam | teamName }}
                      </span>
                      <span class="etc_time ng-binding ng-hide">
                        {{ game.awayTeam | teamName }}
                      </span>
                    </div>
                  </div>
                </div>
                <div style="">
                  <div style="display: block;" id="clive" class="animate-bottom ">
                    <div class="game_center_wrap">
                      <!--  경기정보  -->
                      <div class="live_wrap"
                           v-if="game.isVisible === 'n'  || game.isSuspended === 'y'">
                        <div class="ng-scope" style="">

                          <table class="bet_table_2" style="height:400px; width:100%; font-weight: 700; font-size:15px;"
                                 cellpadding="0" cellspacing="0">
                            <tbody>
                            <tr>
                              <td> 현재 가능한 베팅이 없습니다.<br><br><br> 베팅이 가능한 항목은 화면에 자동으로 나타납니다.</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="live_wrap" v-else>
                        <!--  종목별 헤더 -->
                        <div class="list-type1" v-for="(row, index) in marketSort(lists)"
                             :key="'europeanFoldersDetail'+index">
                          <div class="item">
                            <template v-if="row.nameEn?.indexOf('{sw}') >= 0">
                              <template v-for="period in uniqPeriod(row.seq)">
                                <div
                                    :key="'EuropeanFoldersTitle'+row+game.seq+period"
                                    class="title"
                                    v-if="lists.filter(e => String(marketId) === String(row.id) && String(e.periodSequence) === String(period)).filter(e => e.isVisible === 'y').length > 0">
                                  {{ row | marketName(period) }}
                                </div>
                                <div
                                    :key="'europenaFoldersDetail'+row+game.seq+period"
                                    class="before"
                                    v-if="lists.filter(e => String(marketId) === String(row.id) && String(e.periodSequence) === period).filter(e => e.isVisible === 'y').length > 0">
                                  <div class="box"
                                       v-for="(row2, index2) in lists.filter(e => String(marketId) === String(row.id) && String(e.periodSequence) === period)"
                                       :key="'europeanfolders2'+index2">
                                    <template v-if="row2.isVisible === 'y' && row2.isSuspended === 'y'">
                                      <div class="bet-box">
                                        <!--             홈팀영역             -->
                                        <Lock
                                            :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === 'Odd' || e.name === '1X')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.homeTeam"
                                            :game="game"
                                            :width="row2.bets.length"
                                        ></Lock>
                                        <!--             무승부영역             -->
                                        <Lock
                                            v-if="row2.bets.find(e => e.name === 'X' || e.name === '12')"
                                            :bets="row2.bets.find(e => e.name === 'X' || e.name === '12')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.awayTeam"
                                            :game="game"
                                            :width="row2.bets.length"
                                        ></Lock>
                                        <!--             어웨이영역             -->
                                        <Lock
                                            :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'Even' || e.name === 'X2')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.awayTeam"
                                            :game="game"
                                            :width="row2.bets.length"
                                        ></Lock>
                                      </div>
                                    </template>
                                    <template v-else-if="row2.isVisible === 'y' && row2.isSuspended === 'n'">
                                      <div class="bet-box">
                                        <!--             홈팀영역             -->
                                        <Price
                                            :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === 'Odd' || e.name === '1X' || e.name === 'Yes')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.homeTeam"
                                            :game="game"
                                            :width="row2.bets.length"
                                        ></Price>
                                        <!--             무승부영역             -->
                                        <Price
                                            v-if="row2.bets.find(e => e.name === 'X' || e.name === '12')"
                                            :bets="row2.bets.find(e => e.name === 'X' || e.name === '12')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.awayTeam"
                                            :game="game"
                                            :width="row2.bets.length"
                                        ></Price>
                                        <!--             어웨이영역             -->
                                        <Price
                                            :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'Even' || e.name === 'X2' || e.name === 'No')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.awayTeam"
                                            :game="game"
                                            :width="row2.bets.length"
                                        ></Price>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </template>
                            </template>
                            <template v-else>
                              <div class="title">
                                {{ row | marketName }}
                              </div>
                              <template v-if="row.selectionCnt === 2">
                                <div class="before">
                                  <div class="box"
                                       v-for="(row2, index2) in lists.filter(e => String(marketId) === String(row.id))"
                                       :key="'europeanfolders2'+index2">
                                    <template v-if="row2.isVisible === 'y' && row2.isSuspended === 'y'">
                                      <div class="bet-box">
                                        <!--             홈팀영역             -->
                                        <Lock
                                            :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === 'Odd' || e.name === 'Yes')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.homeTeam"
                                            :game="game"
                                        ></Lock>

                                        <!--             어웨이영역             -->
                                        <Lock
                                            :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'Even' || e.name === 'No')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.awayTeam"
                                            :game="game"
                                        ></Lock>
                                      </div>
                                    </template>
                                    <template v-else-if="row2.isVisible === 'y' && row2.isSuspended === 'n'">
                                      <div class="bet-box">
                                        <!--             홈팀영역             -->
                                        <Price
                                            :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === 'Odd' || e.name === 'Yes')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.homeTeam"
                                            :game="game"
                                            :width="row2.bets.length"

                                        ></Price>
                                        <!--             어웨이영역             -->
                                        <Price
                                            :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'Even' || e.name === 'No')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.awayTeam"
                                            :game="game"
                                            :width="row2.bets.length"
                                        ></Price>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </template>
                              <template v-else-if="row.selectionCnt === 3">
                                <div class="before">
                                  <div class="box"
                                       v-for="(row2, index2) in lists.filter(e => String(marketId) === String(row.id))"
                                       :key="'europeanfolders2'+index2">
                                    <template v-if="row2.isVisible === 'y' && row2.isSuspended === 'y'">
                                      <div class="bet-box">
                                        <!--             홈팀영역             -->
                                        <Lock
                                            :bets="row2.bets.find(e => e.name === 'W1' || e.name === '1X')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.homeTeam"
                                            :game="game"
                                            :width="row2.bets.length"
                                        ></Lock>
                                        <!--             무승부영역             -->
                                        <Lock
                                            :bets="row2.bets.find(e => e.name === 'X' || e.name === '12')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.awayTeam"
                                            :game="game"
                                            :width="row2.bets.length"
                                        ></Lock>
                                        <!--             어웨이영역             -->
                                        <Lock
                                            :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'X2')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.awayTeam"
                                            :game="game"
                                            :width="row2.bets.length"
                                        ></Lock>
                                      </div>
                                    </template>
                                    <template v-else-if="row2.isVisible === 'y' && row2.isSuspended === 'n'">
                                      <div class="bet-box">
                                        <!--             홈팀영역             -->
                                        <Price
                                            :bets="row2.bets.find(e => e.name === 'W1' || e.name === '1X')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.homeTeam"
                                            :game="game"
                                            :width="row2.bets.length"
                                        ></Price>
                                        <!--             무승부영역             -->
                                        <Price
                                            v-if="row2.bets.find(e => e.name === 'X' || e.name === '12')"
                                            :bets="row2.bets.find(e => e.name === 'X' || e.name === '12')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.awayTeam"
                                            :game="game"
                                            :width="row2.bets.length"
                                        ></Price>
                                        <!--             어웨이영역             -->
                                        <Price
                                            :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'X2')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :team="game.awayTeam"
                                            :game="game"
                                            :width="row2.bets.length"
                                        ></Price>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </template>
                              <template v-else>
                                <div class="before">
                                  <template v-for="(row2, index2) in lists.filter(e => String(marketId) === String(row.id))">
                                    <template v-for="l in arrLenDiv(row2.bets, 4)">
                                      <div class="box" :key="'europeanfolders2'+l + index2">
                                        <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                          <div class="bet-box" :key="'manybetseuropena2' + bet.id">
                                            <template v-if="row2.isVisible === 'y' && row2.isSuspended === 'y'">
                                              <Lock
                                                  :key="'betsDetail'+bet.id"
                                                  :bets="bet"
                                                  :folder="row2"
                                                  :add-cart="addCart"
                                                  :check-cart="checkCart"
                                                  :team="game.homeTeam"
                                                  :game="game"
                                              ></Lock>
                                            </template>
                                            <template v-else-if="row2.isVisible === 'y' && row2.isSuspended === 'n'">
                                              <Price
                                                  :key="'betsDetail3'+bet.id"
                                                  :bets="bet"
                                                  :folder="row2"
                                                  :add-cart="addCart"
                                                  :check-cart="checkCart"
                                                  :team="game.awayTeam"
                                                  :game="game"
                                              ></Price>
                                            </template>
                                          </div>
                                        </template>
                                      </div>
                                    </template>
                                  </template>

                                  <!--                            <div class="box" -->
                                  <!--                                 v-for="(row2, index2) in lists.filter(e => String(marketId) === String(row.id))"-->
                                  <!--                                 :key="'europeanfolders2'+index2"-->
                                  <!--                            >-->
                                  <!--                              <template v-if="row2.isVisible === 'y' && row2.isSuspended === 'y'">-->
                                  <!--                                <template v-for="l in arrLenDiv(row2.bets, 4)">-->
                                  <!--                                  <div class="bet-box" :key="'manybetseuropena' + l">-->
                                  <!--                                    <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">-->
                                  <!--                                      <Lock-->
                                  <!--                                          :key="'betsDetail'+bet.id"-->
                                  <!--                                          :bets="bet"-->
                                  <!--                                          :folder="row2"-->
                                  <!--                                          :add-cart="addCart"-->
                                  <!--                                          :check-cart="checkCart"-->
                                  <!--                                          :team="game.homeTeam.namekr ? game.homeTeam.namekr : game.homeTeam.nameEn"-->
                                  <!--                                          :game="game"-->
                                  <!--                                      ></Lock>-->
                                  <!--                                    </template>-->
                                  <!--                                  </div>-->
                                  <!--                                </template>-->
                                  <!--                              </template>-->
                                  <!--                              <template v-if="row2.isVisible === 'y' && row2.isSuspended === 'n'">-->
                                  <!--                                <template v-for="l in arrLenDiv(row2.bets, 4)">-->
                                  <!--                                  <div class="bet-box" :key="'manybetseuropena2' + l">-->
                                  <!--                                    <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">-->
                                  <!--                                      <Price-->
                                  <!--                                          :key="'betsDetail3'+bet.id"-->
                                  <!--                                          :bets="bet"-->
                                  <!--                                          :folder="row2"-->
                                  <!--                                          :add-cart="addCart"-->
                                  <!--                                          :check-cart="checkCart"-->
                                  <!--                                          :team="game.awayTeam.namekr ? game.awayTeam.namekr : game.awayTeam.nameEn"-->
                                  <!--                                          :game="game"-->
                                  <!--                                      ></Price>-->
                                  <!--                                    </template>-->
                                  <!--                                  </div>-->
                                  <!--                                </template>-->
                                  <!--                              </template>-->
                                  <!--                            </div>-->
                                </div>
                              </template>
                            </template>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-else>
              <td style="text-align: center;">
                <Loading></Loading>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
        <td v-else>
          <div style="text-align: center;">
            <Loading></Loading>
          </div>
        </td>

        <td width="260" class="game_right_wrap" valign="top" style="left: calc(73vw + 230px); position: fixed;"
            v-if="!sticky">
          <NewCart :type="cartType" :toggle-stick="toggleStick"></NewCart>
        </td>
        <td width="260" class="game_right_wrap" valign="top" style="left: calc(73vw + 230px); position: absolute;"
            v-else>
          <NewCart :type="cartType" :toggle-stick="toggleStick"></NewCart>
        </td>
      </tr>
      </tbody>
    </table>
    <!--    <table width="100%" style="margin-top: 50px; width: 88%; margin-left:20px;" v-else>-->
    <!--      <tbody>-->
    <!--        <tr>-->
    <!--        <td width="260" valign="top" style="text-align: center; left:80%; min-width: 260px;">-->
    <!--          <Loading></Loading>-->
    <!--        </td>-->
    <!--        &lt;!&ndash; left &ndash;&gt;-->
    <!--        <td width="80%" style="padding:0 10px 0 10px; text-align: center;" valign="top">-->
    <!--          <Loading></Loading>-->
    <!--        </td>-->

    <!--        <td width="260" class="game_right_wrap" valign="top" style="left: calc(73vw + 230px); position: absolute;">-->
    <!--          <Loading></Loading>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      </tbody>-->
    <!--    </table>-->
  </section>
</template>

<script>
import NewCart from '@/components/Betting/EuropeanCart'
import LeftSide from '@/components/Game/Sports/European/LeftSide'
import Loading from '@/components/Loading'
import Price from '@/components/Game/Sports/European/DetailPrice';
import Lock from '@/components/Game/Sports/European/DetailLock';
import MSG from "@/contants/msg";
import {mapState} from "vuex";

export default {
  name: "Sports",
  components: {
    LeftSide,
    NewCart,
    Loading,
    Price,
    Lock
  },
  data: function () {
    return {
      type: 'european',
      category: null,
      isLoading: false,
      lists: null,
      sticky: false,
      game: null,
      openDetailGame: [],
      cartType: 'sports',
      payload: {
        gameSeq: this.$route.query.gid,
      },
      selectedGame: null,
      selectedMarket: null,
    }
  },
  computed: {
    //보너스
    bonus: function () {
      return this.$store.state.setting.settings ? this.$store.state.setting.settings.config.option.bettingOption.sportsFolder.filter(e => e.active === true) : []
    },
    // 마감시간
    endTime: function () {
      return this.$store.state.setting.settings.config.option.gameEndtime.find(e => e.gameName === '스포츠').endTime
    },
    originList: function () {
      return this.$store.getters['list/europeanList']
    },
    cartItem() {
      return this.$store.state.sports.cart
    },
    ...mapState({
      originList: state => state.sports.originList,
      // dateCnt: state => state.sports.dateCnt,
      user: state => state.user.user,
    }),
  },
  watch: {
    lists(val){
      console.log(val);
    }
  },
  mounted: function () {
    // console.log(this.$store.state.sports.cart,444)
    this.$store.dispatch('cart/resetSportCart', '프리매치(유럽형)')
    this.initDetail(this.payload)
    this.$socket.client.on('prematch', this.updateData)
    // this.interval = setInterval(this.removeGames, 1000)
  },
  beforeDestroy: function () {
    this.$socket.client.off('prematch')
    clearInterval(this.interval);
  },
  methods: {
    initDetail(payload) {
      this.$store.dispatch('GET_DETAIL', {payload}).then((res) => {
        const data = res.data;
        this.lists = data.payload.list;
        this.game = data.payload.game;
        this.isLoading = true;
      })
    },
    initFolder(payload) {
      this.$store.dispatch('GET_LIST', {payload})
    },
    updateData: function (data) {
      if (this.lists && this.lists.length === 0) return;
      const result = JSON.parse(data)
      for (const i in result) {
        const gameData = result[i];
        // console.log(gameData);
        const game = this.originList.find(e => String(e.id) === String(gameData.MatchId))
        if (game) {
          // 폴더들
          const folder = game.folders.find(e => String(e.id) === String(gameData.Id))
          if (folder) {
            folder.isVisible = gameData.IsVisible ? 'y' : 'n';
            folder.isSuspended = gameData.IsSuspended ? 'y' : 'n';
            for (const b in gameData.Selections) {
              const socketBet = gameData.Selections[b];
              const cartItem = this.store.state.sports.cart.find(e => String(e.betsId) === String(socketBet.Id));
              const bet = folder.bets.find(e => String(e.id) === String(socketBet.Id))
              if (bet) {
                bet.price = Number(socketBet.Price).toFixed(2);
                if (cartItem) {
                  cartItem.odds = bet.price;
                  cartItem.betsStatus = socketBet.IsSuspended ? 'y' : 'n';
                  cartItem.betsUpdated = true;
                }
              }
            }
          }
        }
      }
    },
    /*
    * 종목의 자세히 버튼
    * **/
    checkArray: function (id) {
      let result = this.openDetailGame.indexOf(id) >= 0
      return result
    },
    /**
     * 토글 액션
     * */

    toggleDetail: function (id) {
      this.toggle(this.openDetailGame, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailGame.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailGame = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    checkCart: function (bets, type) {
      if (type === '보너스') {
        return null;
      } else {
        let index = this.cartItem.find(item => item.betsSeq === bets.seq)
        return !!index;
      }
    },
    // 시간이 지난 항목들 안보이게 설정
    // removeGames: function () {
    //   if (this.lists && this.lists.length > 0) {
    //     let endEvents = this.lists.filter(e => this.$options.filters.gameStarted(e.startTime, this.endTime))
    //     endEvents.forEach((ele) => {
    //       let index = this.lists.indexOf(ele)
    //       if (index >= 0) {
    //         this.lists.splice(index, 1)
    //       }
    //     })
    //   }
    //
    //
    //   if (this.$store.state.sports.cart) {
    //     let endEvents = this.$store.state.sports.cart.filter(e => this.$options.filters.gameStarted(e.startTime, this.endTime))
    //     endEvents.forEach((ele) => {
    //       let index = this.$store.state.sports.cart.indexOf(ele)
    //       if (index >= 0) {
    //         this.$store.state.sports.cart.splice(index, 1)
    //       }
    //     })
    //   }
    // },
    movePage: function (page) {
      this.$router.push({path: `/${page}`})
    },
    // 베팅카트에 담기
    addCart: function (bets, folders, game) {
      if (this.user) {
        const config = this.user.members_detail_setting;
        if (!config.베팅권한.유럽형.active) {
          return alert(MSG.authorization);
        }
      } else {
        return alert(MSG.isNotLogin);
      }
      const cartItem = {
        gameId: game.id,
        gameSeq: game.seq,
        betsSeq: bets.seq,
        betsStatus: bets.status,
        betsId: bets.id,
        betsName: bets.name,
        betsUpdated: false,
        betsPrevOdds: bets.price,
        sportsId: game.sportSeq,
        marketsId: folders.marketSeq,
        foldersSeq: folders.seq,
        foldersType: folders.type,
        odds: bets.price,
        line: bets.line ? bets.line : null,
        leagueNameKr: game.league.nameKr,
        leagueNameEn: game.league.nameEn,
        leagueNameIcon: game.league.icon,
        sportNameKr: game.sport.nameKr,
        sportNameEn: game.sport.nameEn,
        sportNameIcon: game.sport.icon,
        homeNameKr: game.homeTeam.nameKr,
        homeNameEn: game.homeTeam.nameEn,
        awayNameKr: game.awayTeam.nameKr,
        awayNameEn: game.awayTeam.nameEn,
        marketNameKr: folders.market.nameKr,
        marketNameEn: folders.market.nameEn,
        startTime: game.startTime,
        odds1: null,
        odds2: null,
        odds3: null,
        betType: '프리매치(유럽형)',
        detailType: null,
        currentPeriod: game.currentPeriod,
        currentTime: game.currentTime,
        homeScore: game.homeResult,
        awayScore: game.awayResult,
        penalty: null
      };

      if (cartItem.marketNameKr?.indexOf('{sw}') >= 0) {
        if (folders.periodSequence !== '0') {
          cartItem.marketNameKr = cartItem.marketNameKr.replace('{sw}', folders.periodSequence)
          cartItem.marketNameEn = cartItem.marketNameEn.replace('{sw}', folders.periodSequence)
        }
      }

      if (cartItem.marketNameKr?.indexOf('1팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('1팀', cartItem.homeNameKr || cartItem.homeNameEn)
      }

      if (cartItem.marketNameKr?.indexOf('2팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('2팀', cartItem.awayNameKr || cartItem.awayNameEn)
      }

      this.$store.dispatch('ADD_EUROPEAN_CART_ITEM', cartItem)
    },
    // 게임종목
    changeCategory: function (category) {
      this.category = category;
      if (category === 'LINK') {
        this.lists = [];
        this.linkedGame.map(s => {
          const lists = this.originList.filter(l => s.id === l.id);
          lists.map(folder => this.lists.push(folder));
        })
        return;
      }
      if (!category) return this.lists = this.originList;
      this.lists = this.originList.filter(e => e.sportSeq === category);
    },

    // 리그필터
    filterByGames: function (sortType, target) {
      if (sortType === 'all') {
        return this.lists = this.originList;
      }

      if (sortType === 'league') {
        this.lists = this.originList.filter(e => e.leagueSeq === target);
      }

      if (sortType === 'date') {
        this.lists = this.originList.filter(e => e.startTime.indexOf(target) >= 0);
      }

      if (sortType === 'sport') {
        this.lists = this.originList.filter(e => e.sportSeq === target);
      }

      if (sortType === 'team') {
        this.lists = this.originList.filter(e =>
            e.homeTeam.nameEn.indexOf(target) >= 0 ||
            e.homeTeam.namekr.indexOf(target) >= 0 ||
            e.awayTeam.nameEn.indexOf(target) >= 0 ||
            e.awayTeam.namekr.indexOf(target) >= 0
        );
      }
    },
    toggleStick: function () {
      this.sticky = !this.sticky;
    },
    marketSort: function(lists){
      const markets = lists.map(e => e.market);
      const arrUnique = markets.filter((character, idx, arr)=>{
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    arrLenDiv: function (arr, div = 4) {
      return Math.floor(arr.length / div) + 1
    },
    selectMarket: function(market) {
      this.selectedMarket = market;
    },
    uniqPeriod: function (marketSeq) {
      const periods = this.lists.filter(e => String(e.marketId) === String(marketSeq)).map(e => e.periodSequence);
      let set = new Set(periods);
      const uniqueArr = [...set];
      uniqueArr.sort()
      return uniqueArr
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

.categorys::-webkit-scrollbar {
  display: none;
}
</style>
