<template>
  <dl
      :class="{'active': checkCart(bets)}"
      @click="addCart(bets, folder, game)"
  >
    <dt>
      {{ teamName }}
    </dt>
    <dd>
      <span v-if="bets.name === 'Over'" class="material-icons up">
        expand_more
      </span>
      {{ bets.price }}
      <span v-if="bets.name === 'Under'" class="material-icons down">
      expand_more
      </span>
    </dd>
  </dl>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game', 'team'],
  data: function(){
    return {
      startPrice: null,
      up: false,
      down: false
    }
  },
  computed: {
    type: function(){
      let type = 'home';
      if (this.bets.name === 'W1' || this.bets.name === 'Over' || this.bets.name === '1X' || this.bets.name === 'Odd' || this.bets.name === 'Yes' || this.bets.name === 'Home') return 'home';
      if (this.bets.name === 'X' || this.bets.name === '12' || this.bets.name === 'No Goal') return 'draw';
      if (this.bets.name === 'W2' || this.bets.name === 'Under' || this.bets.name === 'X2' || this.bets.name === 'Odd' || this.bets.name === 'No' || this.bets.name === 'Away') return 'away';
      return type
    },
    price: function(){
      return this.bets.price
    },
    teamName: function(){
      let name = this.team.nameKr || this.team.nameEn;
      if (this.folder.market.nameEn.includes('Handicap')) name +=  ` (${this.bets.line.replace(' (0-0)', '')})`;
      if (this.bets.name === 'Over') return `오버 ${this.folder.line}` ;
      if (this.bets.name === 'Under') return `언더 ${this.folder.line}`;
      if (this.bets.name === 'X') name = '무';
      if (this.bets.name === '1X') name = '1팀승 또는 무승부';
      if (this.bets.name === '12') name = '1팀승 또는 2팀승';
      if (this.bets.name === 'X2') name = '무승부 또는 2팀승';
      if (this.bets.name === 'Odd') name = '홀';
      if (this.bets.name === 'Even') name = '짝';
      if (this.bets.name === 'Yes') name = '예';
      if (this.bets.name === 'No') name = '아니오';
      if (this.bets.name === 'No Goal') name = '노골';
      return name;
    },
    line: function(){
      if (this.folder.market.nameEn.includes('Under/Over')) {
        return ` (${this.bets.line})`
      }
      if (this.folder.market.nameEn.includes('Handicap')) {
        return ` (${this.bets.line.replace(' (0-0)', '')})`
      }
      return ''
    }
  },
  watch: {
    price: function(newVal, val){
      // console.log(newVal)
      if (Number(newVal) - Number(val) < 0) this.down = true;
      if (Number(newVal) - Number(val) > 0) this.up = true;
      if (Number(newVal) !== Number(val)) this.startPrice = val;
    },
    up: function(){
      setTimeout(() => {
        this.up = false
        this.startPrice = null;
      } ,10000)
    },
    down: function(){
      setTimeout(() => {
        this.down = false
        this.startPrice = null;
      } ,10000)
    }
  },
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
