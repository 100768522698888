<template>
  <div class="effect_more select">
    <div class="effect_more select" @mouseleave="showSport = false" @mouseover="showSport = true">
      <button class="myValue effect_tab_select_t_1">
        <span>종목 선택</span>
        <img src="@/assets/img/ico/arrow_right.png" style="margin-top:-2px">
      </button>
      <ul class="effect_tab_select_1" style="display: block;" v-if="showSport" @focusout="showSport = false">
        <li v-for="(sport, sportIndex) in sports" :key="'sports'+sportIndex">
          <div>
            <span style="color: #8a8c8d;" @click="filterByGames('sport', sport.id)">
              {{ sport | sportName }}
            </span>
          </div>
        </li>
      </ul>
    </div>
    <div class="effect_more select" @mouseover="showLeague = true" @mouseleave="showLeague = false">
      <button class="myValue effect_tab_select_t_1" @mouseover="showLeague = true" >
        <span>리그 선택</span>
        <img src="@/assets/img/ico/arrow_right.png" style="margin-top:-2px">
      </button>
      <ul class="effect_tab_select_1" style="display: block;" v-if="showLeague">
        <li v-for="(league, leagueIndex) in leagues" v-bind:key="'leaguesId'+leagueIndex">
          <a @click="filterByGames('league', league.id)">
            <span style="color: #8a8c8d;">
              {{ league | leagueName }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Category",
  props: ['filterByGames', 'originList'],
  data: function(){
    return {
      teamName: null,
      openSports: [],
      openLocations: [],
      showLeague: false,
      showSport: false,
      selectedSports: null
    }
  },
  computed: {
    sports: function(){
      const sports = this.originList.map(e => e.sport);
      const arrUnique = sports.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.seq === character.seq) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    leagues: function(){
      const leauges = this.originList.map(e => e.league);
      const arrUnique = leauges.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.seq === character.seq) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
