<template>
  <div class="cont">
    <div class="cart-item" v-for="(item, idx) in cartItem" :key="'SingleMcartItem'+idx">
      <div class="cols">
        <div class="left">
          {{ item.homeNameKr || item.homeNameEn }}
          <span class="vs">vs</span><br>
          {{ item.awayNameKr || item.awayNameEn }}
        </div>
        <div class="right">
          <button @click="deleteItemCart(item)" class="btn-del">
            <span class="material-icons">close</span>
          </button>
        </div>
      </div>
      <div class="cols">
        <div class="left">{{ item.marketNameKr || item.marketNameEn }}</div>
        <div class="right" style="color: #506570;">({{ item.betsPrevOdds }})</div>
      </div>
      <div class="cols">
        <div class="left"><span class="orange">{{ item | cartBetsName }}</span></div>
        <div class="right">{{ item.odds }}</div>
      </div>
      <div class="cols">
        <div class="left">베팅금액</div>
        <div class="center"><input type="text" class="inp1" v-model="item.betAmount" @keyup="setBetAmount(item)"></div>
<!--        <div class="right"><button class="btn1" @click="maxBet">맥스</button></div>-->
      </div>
      <div class="cols">
        <div class="left">예상당첨금액</div>
        <div class="center">
          <span class="orange">{{ item.totalResult | makeComma }}</span>원
        </div>
        <!--        <div class="right"><button class="btn1" @click="maxBet">맥스</button></div>-->
      </div>
    </div>
    <div class="bet-area">
      <div class="bet-btn">
        <button @click="processBet">베팅하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import MSG from '@/contants/msg';
import {mapState} from "vuex";

export default {
  name: "Single",
  props: ['clearCart', 'singleBetCartSetAmount', 'fixed'],
  computed:{
    levelSetting: function (){
      return this.$store.getters["LEVEL_SETTING"]
    },
    minAmount: function(){
      return Number(this.levelSetting.prematchSetting.minBetting);
    },
    maxAmount: function(){
      return Number(this.levelSetting.prematchSetting.live.maxBetting);
    },
    maxWinAmount: function(){
      return Number(this.levelSetting.prematchSetting.live.maxWin);
    },
    maxOneFolderBetAmount: function(){
      return Number(this.levelSetting.prematchSetting.live.maxBetting);
    },
    maxOdds: function(){
      return this.levelSetting.prematchSetting.maxOdds;
    },
    oneFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.one;
    },
    ...mapState({
      user: state => state.user.user,
      cartItem: state => state.live.cart,
    }),
  },
  watch:{
    cartItem: {
      handler(val){
        const index = val.length;

        val[index-1].betAmount = 0
        val[index-1].totalResult = val[index-1].betAmount * val[index-1].odds
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    processBet: function(){
      this.$store.dispatch('INVISIBLE_ALL_MODALS')
      if (this.fixed) return alert('점검중 입니다.');
      if (this.cartItem.find(b => Number(b.odds) <= 1 )){
        this.$store.dispatch('SET_ERR_MSG', MSG.notAvailableBet);
        this.$store.dispatch('SHOW_MODAL', 'bettingStatus');
        return;
      }
      // 최소 금액
      const minAmountCheck = this.cartItem.find(e => e.betAmount < this.minAmount);
      if (minAmountCheck) return alert(`최소 베팅금은 ${this.$options.filters.makeComma(this.minAmount)}원 입니다.`);

      // 단폴최대
      const maxAmountCheck = this.cartItem.find(e => e.betAmount > this.maxOneFolderBetAmount);
      if (maxAmountCheck) return alert(`단폴 최대 베팅금은 ${this.$options.filters.makeComma(this.maxOneFolderBetAmount)}원 입니다.`);

      // 단폴 당첨금 최대
      const maxWinCheck = this.cartItem.find(e => e.totalResult > this.maxWinAmount);
      if (maxWinCheck) return alert(`단폴 최대 당첨금은 ${this.$options.filters.makeComma(this.maxWinAmount)}원 입니다.`);

      // 싱글벳
      if (this.balance < this.totalCartAmount()) {
        this.changeProcess();
        this.changeComplete();
        return alert('베팅금액은 보유금액을 초과할 수 없습니다.');
      }

      let data = {};
      if (['', 0, '0'].indexOf(this.user.members_detail_setting.베팅차감.스포츠.one) < 0){
        let confirmMsg = `단폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.one}% 배당 하락이 발생합니다.`;
        const oneConfirm = confirm(confirmMsg);
        if (!oneConfirm) {
          return;
        }
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.one, '유저설정-단폴베팅')
      } else {
        if (this.oneFolderDeduction) {
          let confirmMsg = `단폴 베팅시 ${this.oneFolderDeduction}% 배당 하락이 발생합니다.`;
          const singleConfirm = confirm(confirmMsg);
          if (!singleConfirm) {
            return;
          }
          this.singleBetDeduction()
        }
      }
      data.bettings = this.cartItem;
      this.$store.dispatch('SET_PROCESS', data);
      if (this.cartItem.find(b => b.betsUpdated === true)){
        this.$store.dispatch('SHOW_MODAL', 'bettingUpdate')
        this.amount = 0;
        this.clearCart();
        return;
      }

      if (this.cartItem.find(b => b.betsStatus === 'y')){
        this.$store.dispatch('SET_ERR_MSG', MSG.notAvailableBet);
        this.$store.dispatch('SHOW_MODAL', 'bettingStatus');
        return;
      }

      if (this.isUpdate && this.cartItem.find(e => String(e.betsPrevOdds) !== String(e.odds))){
        this.$store.dispatch('SHOW_MODAL', 'bettingUpdateProcess')
        return;
      }


      this.$store.dispatch('SHOW_MODAL', 'bettingProcessLiveSingle')
      this.amount = 0;
      this.clearCart();
    },
    singleBetDeduction:function(){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = this.oneFolderDeduction;
        this.cartItem[c].penaltyReason = '단폴 베팅';
      }
    },
    deleteItemCart: function(obj){
      return this.$store.dispatch('DELETE_CART_ITEM', obj)
    },
    setBetAmount(item){
      let result = Math.floor(item.odds * item.betAmount);
      if (this.maxOneFolderBetAmount < item.betAmount) {
        item.betAmount = Number(this.maxOneFolderBetAmount);
        item.totalResult = Math.floor(item.odds * item.betAmount);
        this.$forceUpdate();
        return alert(`단폴 최대 베팅금은 ${this.$options.filters.makeComma(this.maxOneFolderBetAmount)}원 입니다.`)
      }
      if (this.maxWinAmount < result) {
        item.betAmount = Math.floor(this.maxWinAmount / item.odds);
        item.totalResult = Math.floor(item.betAmount * item.betAmount);
        this.$forceUpdate();
        return alert(`단폴 최대 당첨금은 ${this.$options.filters.makeComma(this.maxWinAmount)}원 입니다.`);
      }

      item.totalResult = result;
      this.$forceUpdate();
    },
    totalCartAmount: function(){
      let total = 0;
      for (const i in this.cartItem){
        total += Number(this.cartItem[i].betAmount)
      }
      return total;
    },
    betDeduction:function(penalty, reason){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = penalty;
        this.cartItem[c].penaltyReason = reason;
      }
    },
  }
}
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
