import { render, staticRenderFns } from "./DetailCorrectScorePrice.vue?vue&type=template&id=3f5e4323&scoped=true"
import script from "./DetailCorrectScorePrice.vue?vue&type=script&lang=js"
export * from "./DetailCorrectScorePrice.vue?vue&type=script&lang=js"
import style0 from "./DetailCorrectScorePrice.vue?vue&type=style&index=0&id=3f5e4323&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f5e4323",
  null
  
)

export default component.exports