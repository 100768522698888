<template>
  <section v-if="!this.$store.state.page.isMobile">
    <div class="nbox" style="width: 1400px;">
      <div class="game-side" style="margin-top: 0px;">
        <div class="list">
          <a  @click="movePage('lotus/oe')" >
            <strong>홀짝</strong>
          </a>
          <a @click="movePage('lotus/baccarat1')">
            <strong>바카라1</strong>
          </a>
          <a  @click="movePage('lotus/baccarat2')" >
            <strong>바카라2</strong>
          </a>
          <!--      <a :class="{'active': gType ==='graph'}">-->
          <!--        <strong>그래프</strong>-->
          <!--      </a>-->
          <a class="active">
            <strong>식보</strong>
          </a>
        </div>
      </div>
      <div class="contLeft">

        <!--        <ul class="tabs">-->
        <!--          <li><a @click="movePage('lotus/baccarat1')">로투스 바카라</a></li>-->
        <!--          <li><a @click="movePage('lotus/oe')">로투스 홀짝</a></li>-->
        <!--          <li><a @click="movePage('lotus/sicbo')">식보</a></li>-->
        <!--        </ul>-->

<!--        <div class="cateTabs">-->
<!--          <a @click="movePage('lotus/baccarat1')" class="on">로투스 바카라</a>-->
<!--          <a @click="movePage('lotus/baccarat2')" class="on">로투스 바카라2</a>-->
<!--          <a @click="movePage('lotus/oe')" >홀짝</a>-->
<!--        </div>-->
        <div class="gameBox">
          <iframe v-if="token" ref="gameIframe" :src="`https://stream04.lotusgm22.com?gType=mo&token=${token}`" frameborder="0" scrolling="no" style="width:888px;height:660px;" allowtransparency="true"></iframe>
        </div>


        <h3 class="tit-bet">베팅내역</h3>
        <table class="listTable03">
          <tbody><tr>
            <th>베팅일시</th>
            <th>회차</th>
            <th>베팅</th>
            <th>베팅액</th>
            <th>결과</th>
            <th>적중액</th>
          </tr>
          <tr>
            <td>2022-02-22</td>
            <td>1371</td>
            <td><span class="blue">홀</span></td>
            <td>10,000</td>
            <td>진행중</td>
            <td><strong class="black">0</strong></td>
          </tr>

          </tbody>
        </table>

        <div class="pagingBox black">

          <div class="paging">
            <a href="javascript:void(0)" class="btnFirst"><img src="@/assets/img/ico/ico_first_black.png"></a>
            <a href="javascript:void(0)" class="btnPrev"><img src="@/assets/img/ico/ico_prev_black.png"></a>

            <a href="javascript:void(0)" class="on">1</a>
            <a href="javascript:void(0)">2</a>
            <a href="javascript:void(0)">3</a>
            <a href="javascript:void(0)">4</a>
            <a href="javascript:void(0)">5</a>
            <a href="javascript:void(0)">6</a>
            <a href="javascript:void(0)">7</a>
            <a href="javascript:void(0)">8</a>
            <a href="javascript:void(0)">9</a>
            <a href="javascript:void(0)">10</a>

            <a href="javascript:void(0)" class="btnNext"><img src="@/assets/img/ico/ico_next_black.png"></a>
            <a href="javascript:void(0)" class="btnLast"><img src="@/assets/img/ico/ico_last_black.png"></a>
          </div>
        </div>



      </div>

      <div class="contRight">
        <OddEvenCart></OddEvenCart>
      </div>
    </div>

  </section>
  <div class="mini-game" v-else>
    <div class="game-wrap" style="width:100%; height: 690px; margin: 0 auto;">
      <iframe v-if="token" ref="gameIframe" :src="`https://stream04.lotusgm22.com?gType=mo&token=${token}`" frameborder="0" scrolling="no" style="width:100%;height:690px;" allowtransparency="true"></iframe>
    </div>
    <MOddEvenCart :game="game" :get-game="getGame"></MOddEvenCart>
  </div>
</template>

<script>
import OddEvenCart from "@/components/Betting/OddEvenCart";
import MOddEvenCart from "@/components/Betting/MOddEvenCart";

export default {
  name: "OddEven",
  components: {
    OddEvenCart,
    MOddEvenCart
  },
  data: function(){
    return {
      path: 'lotus/sicbo',
      token: null,
      game: null,
      lists: [],
    }
  },
  created: function(){
    this.getToken()
    this.interval = setInterval(this.continuousSession, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    getToken: function(){
      return this.$store.dispatch('auth/post', {path: this.path })
          .then(data => {
            const result = data.data;
            this.token = result.payload.access_token;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    getGame: function(){
      return this.$store.dispatch('auth/post', { path: 'game/lotus/sicbo' })
          .then(data => {
            const result = data.data;
            this.game = result.payload.game;
            this.lists = result.payload.list
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    movePage:function(path){
      this.$router.push(`/${path}`)
    },
    continuousSession: function(){
      const msg = { game: 'oe', domain: 'vv-11.com'};
      this.$refs.gameIframe.contentWindow.postMessage(msg, 'https://stream.lotusgm02.com')
    },
  }
}
</script>

<style scoped>
.powerball {
  height: 131px;
  margin: 0px 0px !important;
  padding: 5px 5px !important;
}
</style>
