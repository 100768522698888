<template>
  <div class="cusLeft">
    <p class="subj">CATEGORIES</p>
    <ul>
      <li><a @click="movePage('bbs/c/l')"  :class="{'on': category === 'customer'}">고객센터</a></li>
      <li><a @click="movePage('bbs/n/l')"  :class="{'on': category === 'notice'}">공지사항</a></li>
      <li><a @click="movePage('bbs/e/l')" :class="{'on': category === 'event'}">이벤트</a></li>
      <li><a @click="movePage('board/faq/list')" :class="{'on': category === 'faq'}">자주 묻는 질문</a></li>
      <li><a @click="movePage('board/rule/list')" :class="{'on': category === 'rule'}">배팅규정</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SideLink",
  props: ['category'],
  methods: {
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
  }
}
</script>

<style scoped>

</style>
