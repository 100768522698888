<template>
  <li>
    <span class="util_time" id="sTime">{{ time }}</span>
  </li>
</template>

<script>
import moment from "moment";

export default {
  name: 'CurrentTime',
  data: () => ({
    time: moment().tz('Asia/Seoul').format('HH:mm:ss')
  }),
  mounted() {
    setInterval(() => {
      this.time = moment().tz('Asia/Seoul').format('HH:mm:ss')
    }, 1000)
  },
}
</script>

<style>
.util_time {width:90px; height:34px; line-height:34px; text-align:center;  border-right:solid 1px #2d3232; display:inline-block}
</style>
