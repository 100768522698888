<template>
  <!-- Pagination -->
  <div class="pagingBox2">
    <div class="paging02">
      <a @click="movePage(1)" class="btnFirst"><img src="@/assets/img/ico/ico_first02.png"></a>
      <a @click="movePage(1)" class="btnPrev"><img src="@/assets/img/ico/ico_prev02.png"></a>
      <a v-for="row in pagination.end" @click="movePage(row)" :key="'paginated' + row" :class="{'on': payload.page === row}">{{ row }}</a>
      <a @click="movePage(pagination.end)" class="btnNext"><img src="@/assets/img/ico/ico_next02.png"></a>
      <a @click="movePage(pagination.end)" class="btnLast"><img src="@/assets/img/ico/ico_last02.png"></a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['pagination', 'payload', 'reqData'],
  components:{
  },
  name: "Pagination",
  methods: {
    movePage: function (data){
      this.payload.page = data;
      this.reqData(this.payload);
    },
  },
}
</script>
