<template>
  <section v-if="!this.$store.state.page.isMobile" style="min-height: 600px;">
    <div class="wrapper">
      <h2 class="title">파워볼</h2>
      <div class="h40"></div>
      <PowerballFolders v-if="game && user" :game="game" :init-data="initData" :type="url"></PowerballFolders>
      <PowerballCart v-if="game && user" :game="game"></PowerballCart>
    </div>
  </section>
  <div class="mini-game" v-else>
    <div
        class="game-wrap powerball"
        style="
        height: 555px;
        padding-left: 30px !important;
        padding-top: 60px !important;
      "
    >
      <iframe
          id="miniViewFrame"
          name="miniViewFrame"
          :src="`${url}?is_mobile=Y`"
          frameborder="0"
          scrolling="no"
          style="transform: scale(1.2);"
          allowtransparency="true"
      ></iframe>
    </div>
    <MCartPowerball
        :game="game"
        :init-data="initData"
        :req-data="reqData"
    ></MCartPowerball>
    <table class="listTable02 listTable002" style="width: 100%">
      <caption>
        배팅내역
      </caption>
      <tbody>
      <tr>
        <td>회차</td>
        <td>베팅일시</td>
        <td>베팅</td>
        <td>베팅액</td>
        <td>적중액</td>
        <td>결과</td>
      </tr>
      <tr v-for="(row, index) in lists" :key="'minigameList' + index">
        <td
            style="
              background: #fff;
              border-left: 1px solid #efefef;
              border-bottom: 1px solid #efefef;
            "
        >
          {{ row.betting_round }} 회차
        </td>
        <td
            style="
              background: #fff;
              border-left: 1px solid #efefef;
              border-bottom: 1px solid #efefef;
            "
        >
          {{ row.betting_regdatetime | formatDate("MM-DD HH:mm:ss") }}
        </td>
        <td
            style="
              background: #fff;
              border-left: 1px solid #efefef;
              border-bottom: 1px solid #efefef;
            "
        >
          {{ row.betting_bet_name }}
        </td>
        <td
            style="
              background: #fff;
              border-left: 1px solid #efefef;
              border-bottom: 1px solid #efefef;
            "
        >
          {{ row.betting_bet_amount | makeComma }}
        </td>
        <td
            style="
              background: #fff;
              border-left: 1px solid #efefef;
              border-bottom: 1px solid #efefef;
            "
        >
          {{ row.betting_payed_amount | makeComma }}
        </td>
        <td
            style="
              background: #fff;
              border-left: 1px solid #efefef;
              border-bottom: 1px solid #efefef;
            "
        >
            <span
                :class="{
                blue: row.betting_total_result === 'hit',
                red: row.betting_total_result === 'miss',
              }"
            >{{ row.betting_total_result | bettingStatus }}</span
            >
        </td>
      </tr>
      <!--      <tr>-->
      <!--        <td colspan="6">검색된 내역이 없습니다.</td>-->
      <!--      </tr>-->
      </tbody>
    </table>
  </div>
</template>

<script>
// import SideRight from "../../../components/Game/MiniGame/SideRight";
import PowerballCart from "../../../components/Betting/PowerballCart";
import PowerballFolders from "../../../components/Game/MiniGame/PowerballFolders.vue";
import MCartPowerball from "../../../components/Betting/MCartPowerball.vue";

export default {
  name: "PowerBall",
  components: {
    PowerballCart,
    PowerballFolders,
    MCartPowerball,
  },
  computed: {
    type: function(){
      if (this.$route.params.type === 'EOS5m') return 'eos_powerball/5min'
      if (this.$route.params.type === 'EOS3m') return 'eos_powerball/3min'
      if (this.$route.params.type === 'EOS1m') return 'eos_powerball/1min'
      return 'eos_powerball/5min'
    },
    title: function(){
      if (this.$route.params.type === 'EOS5m') return 'EOS 파워볼 5분'
      if (this.$route.params.type === 'EOS3m') return 'EOS 파워볼 3분'
      if (this.$route.params.type === 'EOS1m') return 'EOS 파워볼 1분'
      if (this.$route.params.type === 'BOGGLE2m') return '보글 파워볼'

      return 'EOS 파워볼 5분'
    },
    url: function(){
      if (this.$route.params.type === 'EOS5m') return `https://ntry.com/scores/${this.type}/main.php`
      if (this.$route.params.type === 'EOS3m') return `https://ntry.com/scores/${this.type}/main.php`
      if (this.$route.params.type === 'EOS1m') return `https://ntry.com/scores/${this.type}/main.php`
      if (this.$route.params.type === 'BOGGLE2m') return `https://bepick.net/live/bubblepower/scrap`

      return `https://ntry.com/scores/${this.type}/main.php`
    },
    user(){
      return this.$store.state.user.user;
    }
  },
  created: function () {
    // const config = this.$store.state.auth.member.members_detail_setting;
    // if (!config.베팅권한.파워볼.active) {
    //   this.$store.dispatch("SET_ERR_MSG", "파워볼은 점검중입니다.");
    //   this.$store.dispatch("SHOW_MODAL", "error2");
    // }
    // this.getGame();
    this.reqData();
  },
  watch: {
    url(){
      this.initData();
      this.reqData();
    }
  },
  mounted() {
    this.initData();
    this.reqData();
    this.interval = setInterval(this.remaining, 1000);
    this.interval2 = setInterval(() => {
      if (localStorage.authToken){
        this.$store.dispatch('ME')
            .catch(() => {
              delete localStorage.authToken
            })
        this.$store.dispatch('GET_SETTING')
      }
    }, 10000)
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.interval2)
  },
  data: () => ({
    game: null,
    slip: [0, 1],
    cart: [0],
    betBtns1: [
      { title: '홀', bet: '1.95', color: 'grey darken-1 white--text', action: '', name: '일반볼' },
      { title: '짝', bet: '1.95', color: 'grey darken-1 white--text', action: '', name: '일반볼' },
      { title: '오버', bet: '1.95', color: 'grey darken-1 white--text', action: '' , name: '일반볼'},
      { title: '언더', bet: '1.95', color: 'grey darken-1 white--text', action: '' , name: '일반볼'}
    ],
    betBtns2: [
      { title: '소', bet: '2.75', color: 'grey darken-1 white--text', action: '', name: '일반볼' },
      { title: '중', bet: '2.5', color: 'grey darken-1 white--text', action: '', name: '일반볼' },
      { title: '대', bet: '2.75', color: 'grey darken-1 white--text', action: '', name: '일반볼' }
    ],
    betBtns3: [
      { title: '홀+오버', bet: '3.7', color: 'grey darken-1 white--text', action: '', name: '일반볼' },
      { title: '홀+언더', bet: '3.7', color: 'grey darken-1 white--text', action: '', name: '일반볼' },
      { title: '짝+오버', bet: '3.7', color: 'grey darken-1 white--text', action: '', name: '일반볼' },
      { title: '짝+언더', bet: '3.7', color: 'grey darken-1 white--text', action: '', name: '일반볼' }
    ],
    betBtns4: [
      { title: '홀', bet: '1.95', color: 'grey darken-1 white--text', action: '', name: '파워볼' },
      { title: '짝', bet: '1.95', color: 'grey darken-1 white--text', action: '', name: '파워볼' },
      { title: '오버', bet: '1.95', color: 'grey darken-1 white--text', action: '', name: '파워볼' },
      { title: '언더', bet: '1.95', color: 'grey darken-1 white--text', action: '', name: '파워볼' }
    ],
    betBtns5: [
      { title: '0', bet: '7.5', color: 'grey darken-1 white--text', action: '', name: '파워볼' },
      { title: '1', bet: '7.5', color: 'grey darken-1 white--text', action: '', name: '파워볼' },
      { title: '2', bet: '7.5', color: 'grey darken-1 white--text', action: '', name: '파워볼' },
      { title: '3', bet: '7.5', color: 'grey darken-1 white--text', action: '', name: '파워볼' },
      { title: '4', bet: '7.5', color: 'grey darken-1 white--text', action: '', name: '파워볼' },
      { title: '5', bet: '7.5', color: 'grey darken-1 white--text', action: '', name: '파워볼' },
      { title: '6', bet: '7.5', color: 'grey darken-1 white--text', action: '', name: '파워볼' },
      { title: '7', bet: '7.5', color: 'grey darken-1 white--text', action: '', name: '파워볼' },
      { title: '8', bet: '7.5', color: 'grey darken-1 white--text', action: '', name: '파워볼' },
      { title: '9', bet: '7.5', color: 'grey darken-1 white--text', action: '', name: '파워볼' }
    ],
    endTime: 10,
    minute: 0,
    second: 0,
    remainSeconds: 3601,
    betPossible: true,
    lists: [],
  }),
  methods: {
    initData() {
      this.$store.dispatch('MINI_GAME_BALL', { type: this.$route.params.type })
          .then((response) => {
            let data;
            if (Array.isArray(response)){
              data = response[0].data;
            } else {
              data = response.data
            }
            this.game = data.payload.game;
          })
          .catch((error) => {
            // 로그인 실패시 처리
            this.signInErrors = error.response.data
          })
    },
    reqData: function(){
      const payload = {
        page: 1,
        size: 5,
        type: this.$route.params.type === 'EOS1m' ? 'EOS 파워볼 1분' : this.$route.params.type === 'EOS3m' ? 'EOS 파워볼 3분' : this.$route.params.type === 'EOS5m' ? 'EOS 파워볼 5분' : '보글 파워볼'
      };
      this.$store.dispatch('GET_MINI_HISTORY', { payload })
          .then((response) => {
            let data;
            if (Array.isArray(response)){
              data = response[0].data;
            } else {
              data = response.data
            }
            this.lists = data.payload.list;
          })
    },
  }
};
</script>

<style scoped>
.powerball {
  height: 131px;
  margin: 0px 0px !important;
  padding: 5px 5px !important;
}
.listTable002 caption {
  background: #2e3334;
  color: #fff;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
.listTable002 td {
  height: 30px;
  font-size: 11px;
  background: #d1d1d1;
  color: #000;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
#miniViewFrame {
  width: 300px;
  height: 500px;
}


.wrapper{
  width:1280px;
  margin: 0 auto;
}

h2.title{
  font-weight:100;
  letter-spacing: -0.05em;
  text-align:center;
  font-size:38px;
  margin-top:70px;
  margin-bottom:50px;
  position: relative;
}
h2.title:before{
  content:"";
  /*background:url('~@/assets/img/etc/title_bg1.png') 0px 0px no-repeat;*/
  position: absolute;
  left:50%;
  top:50%;
  margin-left:-113px;
  margin-top:-36px;
  width:239px;
  height:78px;
  z-index: -1;
}

body>.wrapper {
  z-index: 2;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  width: 640px;
}

@media all and (max-width:767px) {
  .wrapper {
    width: 100% !important;
    box-sizing: border-box !important;;
    margin: 0 auto !important;;
  }

  h2.title{
    font-weight: 100;
    letter-spacing: -0.05em;
    text-align: center;
    font-size: 38px;
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;
  }

  .h40 {
    position: relative;
    height: 40px;
  }
}
</style>
