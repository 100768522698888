import AuthenticationService from '../../services/axios';

const getDefaultState = () => {
  return {
    cart: []
  }
}

const state = getDefaultState()

const actions = {
  GET_ODD_EVEN_TOKEN(_, params) {
    return AuthenticationService.post(`/lotus/oe`, params)
      .then((response) => {
        return response
      })
  },
  GET_BACARAT1_TOKEN(_, params) {
    return AuthenticationService.post(`/lotus/baca`, params)
      .then((response) => {
        return response
      })
  },
  GET_ODD_EVEN(_, params) {
    return AuthenticationService.post(`/game/lotus/oe`, params)
      .then((response) => {
        return response
      })
  },
  GET_BACARAT1(_, params) {
    return AuthenticationService.post(`/game/lotus/baccarat1`, params)
      .then((response) => {
        return response
      })
  },
  GET_LOTUS_HISTORY(_, params) {
    return AuthenticationService.post(`/betting/lotus/results`, params)
      .then((response) => {
        return response
      })
  },
  PROCESS_BET_LOTUS(_, params) {
    return AuthenticationService.post(`/betting/mini/process`, params)
      .then((response) => {
        return response
      })
  },
  TIMER(_, params) {
    return AuthenticationService.post(`/game/timer/${params.type}`)
      .then((response) => {
        return response
      })
  },
  ADD_BET_LOTUS: function({commit}, obj) {
    //카트에 item이 없을 경우
    commit('SET_CART', obj);
  },
  CLEAR_LOTUS({commit}) {
    commit('CLEAR_CART')
  }
}

const mutations = {
  SET_CART(state, obj) {
    state.cart = [obj]
  },
  CLEAR_CART(state) {
    state.cart = []
  },
}

const getters = {
  //카트 안에 담긴 배당내역의 총합
  LOTUS_TOTAL_ODDS: function(state) {
    const total = state.cart.reduce((prev, curr) => prev * Number(curr.odds), 1);
    return total
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
