<template>
  <table width="100%" valign="top" border="0" cellspacing="0" cellpadding="0"  class="ng-isolate-scope">
    <tbody>
    <tr>
      <td width="100%">
        <!-- ngInclude: centerTab -->
        <div style="">
          <div style="display: block;" id="clive" class="animate-bottom ">
            <div class="game_center_wrap">
              <div  class="sports_wrap" >
                <template v-if="originList.length === 0">
                  <table class="sports_title_table_1" cellpadding="0" cellspacing="0">
                    <tbody>
                    <tr>
                      <td colspan="5">
                        <span class="live_tab_text_2"></span>
                      </td>
                      <!--                      <td width="7%" style="color: #d82731;">맥스</td>-->
                      <td width="110px">승</td>
                      <td width="110px">무</td>
                      <td width="110px">패</td>
                      <td width="4%">맥스</td>
                      <td width="6%" class="font_002">더보기</td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="sports_table_1" cellpadding="0" cellspacing="0">
                    <tbody>
                    <tr>
                      <td width="100%" align="center" style="padding:15px 0 15px 0; color:#8a8c8d; text-align: center;">
                        현재 진행중인 경기가 없습니다.
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </template>
                <template v-else>
                  <template v-for="sport in sortBySports(lists)">
                    <table :key="'titleSportName'+sport.nameEn" class="sports_title_table_1" cellpadding="0" cellspacing="0">
                      <tbody>
                      <tr>
                        <td colspan="5">
                          <span class="live_tab_text_2"><img :src="sport.icon" style="width: 15px; height: 15px;" alt="">&nbsp;&nbsp;<span>{{ sport | sportName }}</span></span>
                        </td>
                        <!--                        <td width="7%" style="color: #d82731;">맥스</td>-->
                        <td width="110px">승</td>
                        <td width="110px">무</td>
                        <td width="110px">패</td>
                        <td width="4%">맥스</td>
                        <td width="6%" class="font_002">더보기</td>
                      </tr>
                      </tbody>
                    </table>

                    <table v-for="(game, gameIndex) in lists.filter(e => e.sportSeq === sport.seq)" :key="'gameIndex'+gameIndex + sport.nameEn" class="sports_table_1"
                           cellpadding="0" cellspacing="0">
                      <tbody v-if="game.status ==='종료' || game.isVisible === 'n' || game.isSuspended === 'y'">
                      <tr style="background-color: rgb(34, 38, 39);">
                        <td class="pre_0_0">
                          <a @click="addStaredGame(game)" style="color: #8a8c8d;">
                            <font-awesome-icon v-if="linkedGame.find(e => e.id === game.id)" icon="fa fa-star"/>
                            <font-awesome-icon v-else icon="fa-regular fa-star"/>
                          </a>
                        </td>
                        <td class="sports_3_2" v-if="game.status === '종료'">종료</td>
                        <td class="sports_3_2" v-else>{{ game | currentPeriod3('period') }}</td>
                        <td class="sports_2_2" style="text-align: center; cursor: pointer;" @click="selectedGame(game)">
                          {{ game.homeResult }} - {{ game.awayResult }}
                        </td>
                        <td class="sports_5_2" @click="selectedGame(game)" style="cursor: pointer;">
                          <img style="width: 14px; height: 10px;" v-if="game.location.icon" :src="game.location.icon"
                               alt="">
                          {{ game.league | leagueName }}
                        </td>

                        <td class="sports_6_2" @click="selectedGame(game)" style="cursor: pointer;">
                          <table width="100%" cellpadding="0" cellspacing="0">
                            <tbody>
                            <tr href="#">
                              <td class="pre_4_1_1">{{ game.homeTeam | teamName }}</td>
                              <td class="pre_4_1_2">vs</td>
                              <td class="pre_4_1_3">{{ game.awayTeam | teamName }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                        <!--                        <td class="sports_6_5">-->
                        <!--                          {{ game.league.maxAmount | amtKor }}-->
                        <!--                        </td>-->
                        <td class="sports_7_3">
                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                        </td>
                        <td class="sports_8_3">
                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                        </td>
                        <td class="sports_9_3">
                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                        </td>
                        <td class="sports_6_5">
                          {{ game.league.maxAmount | amtKor }}
                        </td>
                        <td class="sports_10_4" @click="selectedGame(game)">
                          +{{ game.foldersCnt.cnt }}
                        </td>
                      </tr>
                      </tbody>
                      <tbody v-else-if="game.status ==='진행' && game.isVisible === 'y' && game.isSuspended === 'n'">
                      <tr style="background-color: rgb(34, 38, 39);">
                        <td class="sports_1" style="background-color: rgb(34, 38, 39);">
                          <a @click="addStaredGame(game)" style="color: #8a8c8d;">
                            <font-awesome-icon v-if="linkedGame.find(e => e.id === game.id)" icon="fa fa-star"/>
                            <font-awesome-icon v-else icon="fa-regular fa-star"/>
                          </a>
                        </td>
                        <td class="sports_3_2">
                          {{ game | currentPeriod3('period') }}
                          {{ game.sportSeq === 210 ? `${game.currentTime}'` : '' }}
                        </td>
                        <td class="sports_2_2" style="text-align: center; cursor: pointer;" @click="selectedGame(game)">
                          {{ game.homeResult }} - {{ game.awayResult }}
                        </td>
                        <td class="sports_5_2" @click="selectedGame(game)" style="cursor: pointer;">
                          <img style="width: 14px; height: 10px;" v-if="game.location.icon" :src="game.location.icon"
                               alt="">
                          {{ game.league | leagueName }}
                        </td>
                        <td class="sports_6_2">
                          <table width="100%" cellpadding="0" cellspacing="0">
                            <tbody>
                            <tr @click="selectedGame(game)" style="cursor: pointer;">
                              <td class="pre_4_1_1">{{ game.homeTeam | teamName }}</td>
                              <td class="pre_4_1_2">vs</td>
                              <td class="pre_4_1_3">{{ game.awayTeam | teamName }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                        <template v-if="checkBlockGame(game)">
                          <td class="sports_7_3">
                            <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                          </td>
                          <td class="sports_8_3">
                            <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                          </td>
                          <td class="sports_9_3">
                            <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                          </td>
                          <td class="sports_6_5">
                            {{ game.league.maxAmount | amtKor }}
                          </td>
                          <td class="sports_10_4">
                            <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                          </td>
                        </template>
                        <template v-else-if="game.folders.length === 0 && Number(game.foldersCnt.cnt) > 0">
                          <td class="sports_7_3" @click="selectedGame(game)">
                            <font-awesome-icon icon="fs fa-circle-plus" style="color: #ffa800;"/>
                          </td>
                          <td class="sports_8_3" @click="selectedGame(game)">
                            <font-awesome-icon icon="fs fa-circle-plus" style="color: #ffa800;"/>
                          </td>
                          <td class="sports_9_3" @click="selectedGame(game)">
                            <font-awesome-icon icon="fs fa-circle-plus" style="color: #ffa800;"/>
                          </td>
                          <td class="sports_6_5">
                            {{ game.league.maxAmount | amtKor }}
                          </td>
                          <td class="sports_10_4" @click="selectedGame(game)">
                            +{{ game.foldersCnt.cnt }}
                          </td>
                        </template>
                        <template v-else-if="game.folders.length === 0 && Number(game.foldersCnt.cnt) === 0">
                          <td class="sports_7_3">
                            <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                          </td>
                          <td class="sports_8_3">
                            <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                          </td>
                          <td class="sports_9_3">
                            <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                          </td>
                          <td class="sports_6_5">
                            {{ game.league.maxAmount | amtKor }}
                          </td>
                          <td class="sports_10_4">
                            <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                          </td>
                        </template>
                        <template v-else>
                          <template
                              v-if="game.folders[0].isVisible === 'n' || game.folders[0].isSuspended === 'y' ||  game.folders[0].bets.find(b => Number(b.price) === 0)">
                            <template v-if="game.foldersCnt.cnt > 0">
                              <td class="sports_7_3" @click="selectedGame(game)" style="color: #ffa800;">
                                <font-awesome-icon icon="fs fa-circle-plus"/>
                              </td>
                              <td class="sports_8_3" @click="selectedGame(game)" style="color: #ffa800;">
                                <font-awesome-icon icon="fs fa-circle-plus"/>
                              </td>
                              <td class="sports_9_3" @click="selectedGame(game)" style="color: #ffa800;">
                                <font-awesome-icon icon="fs fa-circle-plus"/>
                              </td>
                              <td class="sports_6_5">
                                {{ game.league.maxAmount | amtKor }}
                              </td>
                              <td class="sports_10_4" @click="selectedGame(game)">
                                <font-awesome-icon icon="fs fa-circle-plus"/>
                              </td>
                            </template>
                            <template v-else>
                              <td class="sports_7_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_8_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_9_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_6_5">
                                {{ game.league.maxAmount | amtKor }}
                              </td>
                              <td class="sports_10_4" @click="selectedGame(game)">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                            </template>

                          </template>
                          <template
                              v-else-if="game.folders[0].isVisible === 'y' && game.folders[0].isSuspended === 'n'">
                            <td class="sports_7_3"
                                @click="addCart(game.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), game.folders[0], game)"
                                :class="{'sel': checkCart(game.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"
                            >
                              <ListPrice
                                  :price="game.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes').price"></ListPrice>
                            </td>
                            <td class="sports_8_3"
                                @click="addCart(game.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), game.folders[0], game)"
                                :class="{'sel': checkCart(game.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"
                            >
                              <ListPrice v-if="game.folders[0].bets.find(e => e.name === 'X')"
                                         :price="game.folders[0].bets.find(e => e.name === 'X').price"></ListPrice>
                            </td>
                            <td class="sports_9_3"
                                @click="addCart(game.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), game.folders[0], game)"
                                :class="{'sel': checkCart(game.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"
                            >
                              <ListPrice
                                  :price="game.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No').price"></ListPrice>
                            </td>
                            <td class="sports_6_5">
                              {{ game.league.maxAmount | amtKor }}
                            </td>
                            <td class="sports_10_4" @click="selectedGame(game)">
                              +{{ game.foldersCnt.cnt }}
                            </td>
                          </template>
                        </template>
                      </tr>
                      </tbody>
                      <tbody v-else-if="game.status === '대기'">
                      <tr style="background-color: rgb(34, 38, 39);">
                        <td class="pre_0_0">
                          <a href="#">
                            <i class="fa fa-star-o"></i>
                          </a>
                        </td>
                        <td class="sports_3_2">대기중</td>
                        <td class="sports_2_2" style="text-align: center;">
                          {{ game.homeResult }} - {{ game.awayResult }}
                        </td>
                        <td class="sports_5_2">
                          <img style="width: 14px; height: 10px;" v-if="game.location.icon" :src="game.location.icon"
                               alt="">
                          {{ game.league | leagueName }}
                        </td>

                        <td class="sports_6_2">
                          <table width="100%" cellpadding="0" cellspacing="0">
                            <tbody>
                            <tr href="#">
                              <td class="pre_4_1_1">{{ game.homeTeam | teamName }}</td>
                              <td class="pre_4_1_2">vs</td>
                              <td class="pre_4_1_3">{{ game.awayTeam | teamName }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                        <td class="sports_7_3">
                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                        </td>
                        <td class="sports_8_3">
                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                        </td>
                        <td class="sports_9_3">
                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                        </td>
                        <td class="sports_6_5">
                          {{ game.league.maxAmount | amtKor }}
                        </td>
                        <td class="sports_10_4">
                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </template>

                </template>
              </div>
              <div class="live_wrap">
                <table class="live_table">
                  <tbody>
                  <tr>
                    <td class="live_table_t_1 "><span class="white">경기 일정</span></td>
                  </tr>
                  </tbody>
                </table>
                <!--                        경기일정 -->
                <table class="live_table bg-gray" v-for="(wait, index) in waitList" :key="'waitList' +index">
                  <colgroup>
                    <col style="width:18%">
                    <col style="width:82%">
                  </colgroup>
                  <tbody>
                  <tr>
                    <td class="n-tit orange" style="width: 30%;">
                      {{ wait.startTime | formatDate('M월 D일') }} ({{ wait.startTime | formatDate('요일') }})
                      {{ wait.startTime | formatDate('HH:ss') }}
                    </td>
                    <td>
                      <div class="none-live">
                        <img :src="wait.sport.icon ? wait.sport.icon : wait.league.icon" class="ic"
                             style="width: 25px;">
                        <div class="n1" style="font-weight: bold;">{{ wait.league | leagueName }}</div>
                        <div class="n2" style="font-weight: bold;">
                          {{ wait.homeTeam | teamName }}
                          vs
                          {{ wait.awayTeam | teamName }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import ListPrice from "./ListPrice";
import {mapState} from "vuex";

export default {
  name: "InpList",
  components: {
    // Category,
    ListPrice,
  },
  props: ['originList', 'addCart', 'checkCart', 'waitList', 'isLoading', 'selectedGame', 'checkBlockGame'],
  computed: {
    lists: function () {
      return this.originList
    },
    ...mapState({
      linkedGame: state => state.inplay.inplayLink,
      user: state => state.user.user,
    }),
  },
  mounted: function () {
    this.$socket.client.on('inplayStats', this.matchStats)
    this.$socket.client.on('inplayMatchUpdate', this.matchUpdate)
    this.$socket.client.on('inplay', this.updateData)

    console.log(this.originList.length)
  },
  methods: {
    sortBySports: function (lists) {
      const sports = lists.map(e => e.sport);
      const arrUnique = sports.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.seq === character.seq) === idx
      });
      arrUnique.sort()
      return arrUnique
    },

    // 매치 상태 변경
    matchStats: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const result = results[r];
        const game = this.lists.find(e => String(e.id) === String(result.EventId))
        if (game) {
          const score = result.Score;
          if (score) {
            game.homeResult = score.split(':')[0] || 0;
            game.awayResult = score.split(':')[1] || 0;
            if (!result.IsTimeout) game.currentPeriod = result.Period;
          }
        }
      }
    },
    // 매치 상태 업데이트
    matchUpdate: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const matchData = results[r];
        // console.log('matchUpdate', matchData);
        const game = this.originList.find(e => String(e.id) === String(matchData.Id));
        if (game) {
          const cartItem = this.$store.state.inplay.cart.find(e => String(e.gameId) === String(matchData.Id));
          if (cartItem) {
            cartItem.betsStatus = matchData.IsSuspended || !matchData.IsVisible ? 'y' : 'n';
            cartItem.betsUpdated = true;
          }
          if (matchData.IsStarted) game.status = '진행';
          game.isVisible = matchData.IsVisible ? 'y' : 'n';
          game.isSuspended = matchData.IsSuspended ? 'y' : 'n';
          if (matchData.MatchStatus === 2) {
            game.status = '종료';
            setTimeout(() => {
              this.removeGames(game.seq)
            }, 3000)
          }
        }
      }
    },
    removeGames: function (gameSeq) {
      if (this.lists && this.lists.length > 0) {
        let index = this.lists.indexOf(e => e.seq === gameSeq)
        if (index >= 0) {
          this.lists.splice(index, 1)
        }
      }
      // 카트 아이템수정
      if (this.$store.state.inplay.cart) {
        const cartItems = this.$store.state.inplay.cart;
        let index = cartItems.indexOf(e => e.seq === gameSeq)
        if (index >= 0) {
          cartItems.splice(index, 1);
          this.$store.state.inplay.cart = cartItems;
        }
      }
    },

    // 배당 변경
    updateData: function (data) {
      const result = JSON.parse(data);
      this.$store.dispatch('UPDATE_INPLAY_LIST', result);
    },
    addStaredGame: function (gameSeq) {
      this.$store.dispatch('TOGGLE_STARED', gameSeq);
    },
  },
}
</script>

<style scoped>

</style>
