<template>
  <div class="main_board_wrap"  style="cursor: pointer; margin: 0px;">
    <div class="main_title_2" id="tooltip1" dx-tooltip="defaultModeOptions">마감 임박경기</div>
    <div id="tabs">
      <ul style="width: 434px;">
        <li>
          <a style="cursor: pointer"  :class="{'tabulous_active' : selectedSport === null}" @click="sort(null)">&nbsp;&nbsp;&nbsp;전체&nbsp;&nbsp;&nbsp;</a>
        </li>

        <li class="ng-scope" style="" v-for="(sport, index) in sports" :key="'inpSport'+index">
          <a :class="{'tabulous_active' : selectedSport === sport.seq}" style="cursor:pointer" @click="sort(sport.seq)">
            &nbsp;&nbsp;&nbsp;<img :src="sport.icon" width="15">&nbsp;&nbsp;&nbsp;
          </a>
        </li>
      </ul>
    </div>
    <table width="100%" cellpadding="0" cellspacing="0" class="board_table_2">
      <tbody>
      <tr>
        <td class="board_table_2_t" style="color:#A97BF5;width:29px;padding-left:20px;">시각</td>
        <td class="board_table_2_t" style="color:#A97BF5;width:212px"></td>
        <td class="board_table_2_t" style="color:#A97BF5;width:55px;">승</td>
        <td class="board_table_2_t" style="color:#A97BF5;width:55px;">무</td>
        <td class="board_table_2_t" style="color:#A97BF5;width:55px;">패</td>
      </tr>
      </tbody>
    </table>
    <div class="main_board" v-if="isLoading">
      <div id="tableTicker_wrap3" style="width: 100%; height: 139px; position: relative; overflow: hidden;"><table width="100%" cellpadding="0" cellspacing="0" class="myTable11" style="position: absolute; top: 0px; margin-top: 0px;">
        <tbody>
        <!-- ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL -->
        <tr class="ng-scope">
          <td>
            <table width="100%" cellpadding="0" cellspacing="0" class="board_table_1_1">
              <tbody>
              <tr>
                <table width="100%" cellpadding="0" cellspacing="0" class="board_table_1_1">
                  <tbody
                      :style="listStyle"
                      @mouseenter="toggleAnimation"
                      @mouseleave="toggleAnimation"
                  >
                    <tr
                      v-for="(tableItem, index) in lists"
                      :key="index"
                      :style="itemStyle"
                      @click="moveDetail(tableItem.seq)"
                    >
                      <table width="100%" cellpadding="0" cellspacing="0" class="board_table_1_1">
                        <tbody>
                        <tr  style="cursor: pointer" class="ng-scope">
                          <td class="board_bet_home_2 ng-binding" style="width:29px;text-align:left;padding-left:20px;">
                            {{ tableItem.startTime | formatDate('HH:mm')}}
                          </td>
                          <td class="board_bet_home_2 ng-binding" style="width:212px;text-align:left;padding-left:20px;">
                            {{ tableItem.homeTeam | teamName }} <font style="color:#5c7785;padding:0 5px 0 5px;">vs</font> {{ tableItem.awayTeam | teamName }}
                          </td>
                          <template v-if="tableItem.isVisible === 'y'">
                            <template v-if="tableItem.isSuspended === 'n'">
                              <template v-if="tableItem.folders[0]">
                                <td style="width:60px" class="board_bet_victory_1">
                                <span class="font_002 ng-binding">
                                  {{
                                    Number(tableItem.folders[0].bets.find(e => e.name === '1' || e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Home').price).toFixed(2)
                                  }}
                                </span>
                                  <!--                            <i class="fa fa-fw fa-lock" style="font-size:3px"></i>-->
                                </td>
                                <td style="width:60px" class="board_bet_victory_1">
                                <span class="font_002 ng-binding">
                                  {{
                                    tableItem.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal') ? Number(tableItem.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal').price).toFixed(2) : '-'
                                  }}
                                </span>
                                  <!--                            <i class="fa fa-fw fa-lock" style="font-size:3px"></i>-->
                                </td>
                                <td style="width:60px" class="board_bet_victory_1">
                                <span class="font_002 ng-binding">
                                  {{
                                    Number(tableItem.folders[0].bets.find(e => e.name === '2' || e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'Away').price).toFixed(2)
                                  }}
                                </span>
                                  <!--                            <i class="fa fa-fw fa-lock" style="font-size:3px" ></i>-->
                                </td>
                              </template>
                              <template v-else>
                                <td style="width:60px" class="board_bet_victory_1">
                                  <i class="fa fa-fw fa-lock" style="font-size:3px"></i>
                                </td>
                                <td style="width:60px" class="board_bet_victory_1">
                                  <i class="fa fa-fw fa-lock" style="font-size:3px"></i>
                                </td>
                                <td style="width:60px" class="board_bet_victory_1">
                                  <i class="fa fa-fw fa-lock" style="font-size:3px" ></i>
                                </td>
                              </template>
                            </template>
                            <template v-else-if="tableItem.isSuspended === 'y'">
                              <td style="width:60px" class="board_bet_victory_1">
                                <i class="fa fa-fw fa-lock" style="font-size:3px"></i>
                              </td>
                              <td style="width:60px" class="board_bet_victory_1">
                                <i class="fa fa-fw fa-lock" style="font-size:3px"></i>
                              </td>
                              <td style="width:60px" class="board_bet_victory_1">
                                <i class="fa fa-fw fa-lock" style="font-size:3px" ></i>
                              </td>
                            </template>
                          </template>
                        </tr>
                        </tbody>
                      </table>
                    </tr>
                  </tbody>
                </table>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
      </div>
    </div>
    <div class="main_board" v-else style="text-align: center; padding-top:20px;">
      <Loading></Loading>
    </div>
  </div>


</template>

<script>
import {mapState} from "vuex";
import moment from "moment-timezone";
import Loading from '@/components/Loading'

export default {
  name: 'PreAutoScroll',
  components: {
    Loading
  },
  data: () => ({
    selectedSport: null,
    lists: [],
    category: null,
    listTop: 0,
    isPaused: false,
    isMovingBackwards: false,
    isLoading: false,
    payload: {
      date: moment().tz('Asia/Seoul').format('YYYY-MM-DD')
    },
  }),
  props: {
    scale: {
      type: Number,
      default: 1
    },
    transitionDuration: {
      type: Number,
      default: 1000
    },
    transitionDelay: {
      type: Number,
      default: 500
    },
    transition: {
      type: String,
      default: 'ease-in-out'
    },
    pauseOnHover: {
      type: Boolean,
      default: true
    },
    dateFormat: {
      type: String,
      default: 'day'
    }
  },
  watch: {
    selectedSport(val){
      if (!val) return this.lists = this.originList;
      this.lists = this.originList.filter(e => e.sportSeq === val)
    }
  },
  computed: {
    listCount () {
      return this.lists.length
    },
    itemHeight () {
      return 12
    },
    listHeight () {
      return this.itemHeight * this.listCount
    },
    itemStyle () {
      return `height: ${this.itemHeight}px;`
    },
    listStyle () {
      let transitionDelay = this.transitionDelay
      let transitionDuration = this.transitionDuration
      if (this.listTop === 0) {
        transitionDelay = 0
        transitionDuration = 0
      }

      return `height: ${this.listHeight}px;
            transition-delay: ${transitionDelay}ms;
            transition-duration: ${transitionDuration}ms;
            transition-timing-function: ${this.transition};
            will-change: transform;
            transform: translateY(${this.listTop * 3}px)`
    },
    sports(){
      const sportsList = [...new Set(this.originList.map(e => e.sport).map(JSON.stringify))].map(JSON.parse)
      sportsList.sort((a,b) => {
        const dateA = new Date(b.id);
        const dateB = new Date(a.id);
        return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
      })
      return sportsList
    },
    ...mapState({
      originList: state => state.lsport.originList,
    }),
  },

  created () {
    this.initFolder(this.payload);
    setTimeout(this.updateState, this.transitionDelay)
  },
  methods: {
    // 경기요청
    initFolder(payload){
      this.isLoading = false;
      this.$store.dispatch('GET_LSPORT_LIST', { payload }).then(() => {
        this.lists = this.originList;
        this.isLoading = true;
      })
    },
    updateState () {
      if (this.listCount <= 1) return
      if (this.isPaused) return
      if (this.listCount < 4) return;
      if (this.isMovingBackwards) {
        this.listTop += this.itemHeight
      } else {
        this.listTop -= this.itemHeight
      }
      if (this.listTop < this.itemHeight * -1 && this.lists.length >= 4) {
        const newList = this.lists.splice(0, 1)
        this.lists.push(...newList)
        this.listTop = 0
      }
      this.timeOutId = window.setTimeout(() => {
        this.updateState()
      }, this.transitionDuration + this.transitionDelay)
    },
    toggleAnimation () {
      if (this.pauseOnHover) {
        this.isPaused = !this.isPaused
        if (this.isPaused) {
          clearTimeout(this.timeOutId)
        } else {
          this.updateState()
        }
      }
    },
    moveDetail(id) {
      console.log(id)
      this.$router.push({path: '/sport/korean'})
    },
    sort(seq){
      this.selectedSport = seq;
    }
  }
}
</script>

<style>
.auto-scroll-tab > .v-data-table__wrapper {
  overflow-y: hidden !important;
}
.body-4 {
  font-size: 13px;
  letter-spacing: -0.2px;
}


/* tab */
#tabs,#tabs2 {width:100%;}
#tabs ul,#tabs2 ul {width:100% ; float:left; background:#293133; margin:0 0 1px 0}
#tabs li,#tabs2 li {float:left; height:34px;margin:0; }
#tabs li a,#tabs2 li a {display: block;padding:0 8px 0 8px; background: #293133;text-decoration: none;color: #c1c3c4;height:34px;line-height:34px;text-align:center;}
#tabs li a:hover,#tabs2 li a:hover {background: #222627;color: #c1c3c4;}
#tabs_container {padding:0;position: relative;float:left; width:100%;background:#262a2b;}
#tabs_container div {width:100%; }

.main_title_2 {width:434px;  height:38px; float:left; border:solid 1px #262a2a;  color:#b7b7b7; line-height:38px; font-size: 14px; font-weight:700; letter-spacing:-1px; text-indent:20px; position:relative}

.deadline {float:right; margin:0 20px 0 0; font-size:12px; }
.deadline li{float:left;text-indent:0px;margin:0 0 0 20px }
.deadline li.time_on {border-bottom:solid 2px #ef1c26}
.deadline li.time_on a {color:#ffffff !important; }

.board_table_2 {border-bottom:solid 1px #1c2021;background:#262a2b;}
.board_table_2 tr td {height:34px; text-align:center}
.board_table_2_t {height:34px;background:#222627;text-align:center; }
.board_bet_time{width:13%; }
.board_bet_home{width:22%; color:#7da0b2}
.board_bet_expedition{width:22%; color:#7da0b2}
.board_bet_victory{width:12%; color:#ef1c26}
.board_bet_draw{width:12%; color:#ef1c26}
.board_bet_loss{width:12%; color:#ef1c26}
.board_bet_score{width:7%; }

.main_board_wrap {width:432px; float:left;}
</style>
