import AuthenticationService from '../../services/axios';

const getDefaultState = () => {
  return {
    cart: [],
  }
}

const state = getDefaultState()

const actions = {
  MINI_GAME_BALL(_, params) {
    return AuthenticationService.post(`/game/powerball/${params.type}`, params)
      .then((response) => {
        return response;
      })
      .catch((error) => console.log("getGame 에러", error));
  },
  GET_MINI_HISTORY(_, params) {
    return AuthenticationService.post(`/betting/minigame/results`, params)
      .then((response) => {
        return response
      })
  },
  TIMER(_, params) {
    return AuthenticationService.post(`/game/timer/${params.type}`)
      .then((response) => {
        return response
      })
  },
  ADD_BET_MINI: function({commit}, obj) {
    //카트에 item이 없을 경우
    commit('SET_CART', obj);
  },
  CLEAR_MINI({commit}) {
    commit('CLEAR_CART')
  }
}

const mutations = {
  SET_CART(state, obj) {
    state.cart = [obj]
  },
  CLEAR_CART(state) {
    state.cart = []
  },
}

const getters = {
  //카트 안에 담긴 배당내역의 총합
  MINI_TOTAL_ODDS: function(state) {
    let total = 0;
    if(state.cart.length > 0) total = Number(state.cart[0].odds);
    return total
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
