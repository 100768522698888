<template>
  <dl class="home" :class="{'active': checkCart(bets)}" @click="addCart(bets, folder, game)">
    <dt style="width: auto;" >
      {{ team | teamName }} {{ additionalName }}
    </dt>
    <dd>
      <span class="ib">
        <i v-if="up" class="ico-up blink"></i>
        <i v-if="down" class="ico-down blink"></i>
        <img v-if="folder.market.nameEn.includes('Handicap') && checkCart(bets)" style="width: 8px; margin-top: -3px;" src="@/assets/img/ico/white/h.png" alt="">
        <img v-if="folder.market.nameEn.includes('Handicap') && !checkCart(bets)" style="width: 8px; margin-top: -3px;" src="@/assets/img/ico/red/h.png" alt="">
        <img v-if="bets.name === 'Over'" style="width: 9px; height: 8px; margin-top: -3px;" src="@/assets/img/ico/m_ico_up.png" alt="">
        <span v-if="up || down" style="font-size: 8px; margin-left: 3px; margin-right: 3px;">
          {{ startPrice }}
        </span>
      </span>
      <span class="orange1 ib">{{ price }}</span>
    </dd>
  </dl>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game', 'team'],
  data: function(){
    return {
      startPrice: null,
      up: false,
      down: false
    }
  },
  computed: {
    price: function(){
      return Number(this.bets.price).toFixed(2)
    },
    additionalName: function(){
      let name = '';
      if (this.folder.market?.nameKr === '1이닝 득무득') name = '(1이닝 득점)'
      return name;
    }
  },
  watch: {
    price: function(newVal, val){
      if (Number(newVal) - Number(val) < 0) this.down = true;
      if (Number(newVal) - Number(val) > 0) this.up = true;
      if (Number(newVal) !== Number(val)) this.startPrice = val;
    },
    up: function(){
      this.down = false;
      setTimeout(() => {
        this.up = false
        this.startPrice = null;
      } ,10000)
    },
    down: function(){
      this.up = false;
      setTimeout(() => {
        this.down = false
        this.startPrice = null;
      } ,10000)
    }
  },
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
